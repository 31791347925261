import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listCarrier = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.CARRIER.LIST, {
    params,
    signal,
  });

  return response;
};

export const CarrierList = async () => {
  const response = await axiosInterceptor.get(API.CARRIER.CARRIER_LIST);

  return response;
};

export const addCarrier = async (data: any) => {
  const response = await axiosInterceptor.post(API.CARRIER.CREATE, data);

  return response;
};

export const updateCarrier = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CARRIER.UPDATE}/${id}`,
    data
  );

  return response;
};

export const updateCarrierImage = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CARRIER.UPDATE_IMAGE}/${id}`,
    data
  );

  return response;
};

export const deleteCarrier = async (id: number) => {
  const response = await axiosInterceptor.delete(`${API.CARRIER.DELETE}/${id}`);

  return response;
};

export const updateCarrierLimitation = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CARRIER.UPDATE}/limitation/${id}`,
    data
  );

  return response;
};

export const getCarrierServicePrices = async (id: number) => {
  const response = await axiosInterceptor.get(
    `${API.CARRIER.GET_SERVICE_PRICES}/${id}`
  );

  return response;
};

export const updateCarrierServicePrices = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CARRIER.UPDATE_SERVICE_PRICES}/${id}`,
    data
  );

  return response;
};
