import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';
import pdf from 'src/assets/img/pdf-icon.png';

const FileUploadLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-64 w-64 max-w-64">
      <div className="flex items-center gap-3 ">
        <div className="w-8 h-10 flex-none custom-loading">
          <img src={pdf} className="w-full h-full object-contain" />
        </div>

        <div className="truncate custom-loading">
          <h6 className="text-grayLight900 font-medium truncate">
            Tech requirements.pdf
          </h6>
          <span className="font-normal text-grayLight600">200 KB</span>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 text-xs  max-w-64 min-w-[25%] w-[25%]">
      <span className="custom-loading">Jan 4, 2024</span>
    </td>
    <td className="px-5 py-4 max-w-64 min-w-[25%] w-[25%]">
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none custom-loading">
          <img src={avatar} className="h-full w-full" />
        </div>
        <div className="custom-loading">
          <p className="w-32 truncate text-grayLight900 font-medium">
            Kate Morrison
          </p>
          <span className="font-normal">Sales Rep.</span>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 max-w-16 w-16 min-w-16">
      <div className="flex gap-2 custom-loading">
        <Trash01 className="w-4 h-4 cursor-pointer" />
      </div>
    </td>
  </>
);

export default FileUploadLoaderRow;
