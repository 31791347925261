import {
  DownloadCloud02,
  UploadCloud02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { LOAD_INSURANCE_STATUS, USER_ROLE } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import {
  createPdf,
  deleteFile,
  getLoadInsuranceFiles,
  loadInsuranceDetail,
  updateLoadInsurance,
  uploadLoadInsurance,
} from 'src/services/LoadInsurance';
import {
  capitalizeFirstLetter,
  convertToGBMBKB,
  downloadPdf,
  getFormattedNumber,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../assets/img/Avatar7.png';
import doubleCheck from '../../assets/img/double-check.svg';
import pdf from '../../assets/img/pdf-icon.png';

import InternalChatAndNotes from './InternalChatAndNotes';
import LoadInsuranceFiles from './LoadInsuranceFiles';
import LoadInsuranceFilesLoading from './LoadInsuranceFilesLoading';
import RequestLoadInsurance from './RequestLoadInsurance';

// import LoadInsuranceFilesLoading from './LoadInsuranceFilesLoading';

const initAction = {
  isEdit: false,
  mode: '',
  id: 0,
  data: {
    name: '',
    value: '',
  },
};
const FilesTab: any = [
  {
    value: 'all',
    name: 'View all',
  },
  {
    value: 'yourfiles',
    name: 'Your files',
  },
  {
    value: 'sharedfiles',
    name: 'Shared files',
  },
];

const LoadInsuranceDetails = () => {
  const { hasRoleV2 } = useRolePermission();
  const { id } = useParams<{ id: any }>();
  const inputRef = useRef<any>(null);
  const [uploadFile, setUploadFile] = useState<any>();
  const [fileAttachmentFilter, setFileAttachmentFilter] = useState('all');

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { currentUser } = useContext(AuthContext);
  const { reloadCounts } = useContext(EntityCountsContext);

  const navigate = useNavigate();
  const [fileList, setFileList] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loadInsurance, setLoadInsurance] = useState<any>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isPDFDownload, setIsPDFDownload] = useState(false);
  // const [uploadFile, setUploadFile] = useState<any>();
  const [action, setAction] = useState(initAction);
  const [isShowChatAndNotesSideBar, setIsShowChatAndNotesSideBar] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileListLoading, setIsFileListLoading] = useState(false);

  const getFiles = () => {
    setIsFileListLoading(true);
    getLoadInsuranceFiles({ filterType: fileAttachmentFilter }, id)
      .then((response: any) => {
        if (response) {
          setFileList(response?.data);
          setTotal(response?.total);
          setIsFileListLoading(false);
        }
      })
      .finally(() => {
        setIsFileListLoading(false);
      });
  };

  const getDetail = () => {
    setIsLoading(true);
    loadInsuranceDetail(id)
      .then((response: any) => {
        if (response) {
          setLoadInsurance(response?.data);
          setIsLoading(false);

          if (response.isReloadCount) {
            reloadCounts();
          }

          getFiles();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateStatus = (status: string) => {
    if (status === 'Approved') {
      setIsPDFDownload(true);
      createPdf(id)
        .then((response: any) => {
          // getDetail();
          setIsPDFDownload(false);
          setLoadInsurance(response?.data);
          WalToast.success(
            `Load Insurance ${capitalizeFirstLetter(status)} successfully`
          );
        })
        .catch((e: any) => {
          setIsPDFDownload(false);
          console.error(e?.response?.message);
          WalToast.error(e?.response?.message);
        })
        .finally(() => {
          setIsPDFDownload(false);
        });
    } else {
      setIsLoading(true);
      const params = {
        status: status,
      };
      updateLoadInsurance(params, id)
        .then((response: any) => {
          if (response) {
            setIsLoading(false);
            getDetail();
          }
        })
        .catch((e: any) => {
          setIsLoading(false);
          console.error(e?.response?.message);
          WalToast.error(e?.response?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClickOutside = (event: any) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setAction(initAction);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action]);

  const FilesheadCells = useMemo(
    () => [
      {
        id: 'filename',
        name: 'File Name',
      },
      {
        id: 'dateuploaded',
        name: 'Date Uploaded',
      },

      {
        id: 'uploadedby',
        name: 'Uploaded By',
      },
      {
        id: '',
        name: '',
      },
    ],
    []
  );

  useEffect(() => {
    getFiles();
  }, [fileAttachmentFilter]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsFileUploading(true);
    setUploadProgress(0);
    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: any) => {
        let totalFile = progressEvent.total ?? 0;
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / totalFile
        );
        // const percentCompleted = Math.round(
        //   (progressEvent.loaded * 100) / progressEvent.total
        // );
        setUploadProgress(percentCompleted);
      },
    };

    const file = acceptedFiles[0];

    setUploadFile(file);
    const appendFormData: any = new FormData();
    appendFormData.append('loadInsuranceId', id);
    appendFormData.append('uploadedBy', currentUser?.id);
    appendFormData.append('fileOriginalName', file?.name);
    appendFormData.append('size', file?.size);
    appendFormData.append('file', file);
    uploadLoadInsurance(appendFormData, config)
      .then((response: any) => {
        if (response) {
          getFiles();
          setUploadProgress(0);
          setTotal(response.total);
          setIsFileUploading(false);
          setUploadFile(null);
        }
      })
      .catch((e: any) => {
        setUploadProgress(0);
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        );
      })
      .finally(() => {
        setIsFileUploading(false);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled: isFileUploading,
  });

  const onAttachmentRowClick = (url: any) => () => {
    console.log('url', url);

    window.open(url, '_blank');
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteFile(action?.id)
          .then(() => {
            getFiles();
            setAction(initAction);
            WalToast.success('Statement Deleted successfully');
          })
          .catch((e: any) => {
            setAction(initAction);
            WalToast.error(
              e?.response?.data?.message ??
                'something went wrong please try again'
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setAction(initAction);
      }
    },
    [action]
  );

  return (
    <PageSectionLayout
      header={
        <Header
          // title={`Load Insurance ID: #${loadInsurance?.load_insurance_id}`}
          title={
            <div className="flex gap-x-2.5 gap-y-2 items-center flex-wrap">
              Load Insurance:{' '}
              <span className={`${isLoading ? 'custom-loading ' : ''}`}>
                #{isLoading ? 'CL-AOI1-141' : loadInsurance?.load_insurance_id}
              </span>
              <div className="flex items-center [&amp;>div:not(:first-child)]:border-white [&amp;>div:not(:first-child)]:border-[1.5px] [&amp;>div:not(:first-child)]:-ml-2">
                {loadInsurance?.members?.map((groupCus: any) => (
                  <TooltipCmp
                    message={`${groupCus?.firstName} ${groupCus?.lastName}`}
                    key={groupCus?.id}
                  >
                    {groupCus.image && groupCus?.imageUrl ? (
                      <div className="rounded-full bg-white">
                        <img
                          src={`${groupCus?.imageUrl}${groupCus.image}`}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="rounded-full bg-white">
                        <img
                          src={TeamIcon1}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    )}
                  </TooltipCmp>
                ))}
              </div>
              {(loadInsurance?.status || isLoading) && (
                <>
                  <BadgeCmp
                    style="modern"
                    mainClassName={`rounded-md ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    type={
                      loadInsurance?.status === LOAD_INSURANCE_STATUS.OPEN
                        ? 'primary'
                        : loadInsurance?.status ===
                          LOAD_INSURANCE_STATUS.APPROVED
                        ? 'success'
                        : loadInsurance?.status ===
                          LOAD_INSURANCE_STATUS.DECLINED
                        ? 'error'
                        : 'success'
                    }
                  >
                    <p data-test-id="wal-QuotingDashboard-status">
                      {isLoading ? 'opened' : loadInsurance?.status}
                    </p>
                  </BadgeCmp>
                </>
              )}
            </div>
          }
          desc="View and manage files for load insurance"
          mainContainerClassName="md:flex-nowrap"
          rightSideContent={
            <div className="flex gap-3 flex-wrap">
              {isShowChatAndNotesSideBar ? (
                <ButtonCmp
                  className="btn_secondary_black xl:hidden"
                  onClick={() => setIsShowChatAndNotesSideBar(false)}
                >
                  <p className="w-1.5 h-1.5 bg-successSecondary rounded-full"></p>
                  Messages
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  className="btn_secondary_black"
                  onClick={() => setIsShowChatAndNotesSideBar(true)}
                >
                  <p className="w-1.5 h-1.5 bg-successSecondary rounded-full"></p>
                  Messages
                </ButtonCmp>
              )}

              {(hasRoleV2(USER_ROLE.ADMIN) ||
                hasRoleV2(USER_ROLE.MANAGER) ||
                hasRoleV2(USER_ROLE.CLAIMS)) &&
              loadInsurance?.status !== LOAD_INSURANCE_STATUS.APPROVED &&
              loadInsurance?.status !== LOAD_INSURANCE_STATUS.DECLINED ? (
                <>
                  <ButtonCmp
                    className="btn_primary"
                    loading={isPDFDownload}
                    disabled={isPDFDownload}
                    onClick={() => updateStatus(LOAD_INSURANCE_STATUS.APPROVED)}
                  >
                    Approve
                  </ButtonCmp>

                  <ButtonCmp
                    className="btn-outline-primary"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => updateStatus(LOAD_INSURANCE_STATUS.DECLINED)}
                  >
                    Decline
                  </ButtonCmp>
                </>
              ) : loadInsurance?.status === LOAD_INSURANCE_STATUS.APPROVED ? (
                <>
                  <ButtonCmp
                    className="btn-outline-primary"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                      downloadPdf(
                        `${loadInsurance?.pdfUrl}${loadInsurance?.pdfName}`
                      );
                    }}
                  >
                    <DownloadCloud02 className="w-4 h-4" />
                    Download Proof of Load Insurance
                  </ButtonCmp>
                </>
              ) : null}
              <ButtonCmp
                className="btn_secondary_black lg:px-[9px] px-2"
                onClick={() => {
                  navigate(ROUTES.LOAD_INSURANCE);
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </div>
          }
        />
      }
      contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative "
    >
      <div className="flex h-full ">
        <div className="flex-1 xl:pr-8 overflow-auto custom-scrollbar-v2 pt-5 xxl:gap-4 gap-3 flex flex-col">
          {loadInsurance?.status === LOAD_INSURANCE_STATUS.APPROVED && (
            <div className="rounded-md border border-utilitysuccess300 bg-green25 px-3 py-2 flex mb-3">
              <div className="flex-none mr-1">
                <img src={doubleCheck} />
              </div>
              <div className="text-xs">
                <span className="text-grayLight900 text-xs font-medium">
                  Load Insurance approved on
                </span>
                <span className="text-xs font-semibold text-primary">
                  &nbsp;
                  {moment(loadInsurance?.approvedAt).format('Do MMMM, YYYY')}
                  &nbsp;
                </span>
                <span className="text-xs font-medium text-grayLight900">
                  by {`${loadInsurance?.firstName} ${loadInsurance?.lastName}`}.
                </span>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center">
            <h6 className="text-grayLight900">Overview</h6>
            <ButtonCmp
              className="btn-outline-primary"
              loading={isLoading}
              disabled={isLoading}
              onClick={() =>
                setAction((old) => ({
                  ...old,
                  ...{
                    mode: 'request',
                    data: loadInsurance,
                  },
                }))
              }
            >
              Edit
            </ButtonCmp>
          </div>

          <ul className="flex flex-wrap gap-4 mb-2">
            <li className="min-w-[105px] w-[12%]">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Order ID
              </p>
              <div className=" text-primary700 font-medium text-sm ">
                {loadInsurance?.fullId ? (
                  <a
                    href={`https://wal.roserocket.com/#/ops/orders/${loadInsurance?.fullId}`}
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation to prevent `td` click
                    }}
                    className="text-primary700 leading-tight truncate"
                    target="_blank"
                  >
                    #{loadInsurance?.fullId}
                  </a>
                ) : (
                  '-'
                )}
              </div>
            </li>
            <li className="min-w-[105px] w-[12%]">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Customer
              </p>
              <div className=" text-primary700 font-medium text-sm ">
                <Link
                  to={`${PATH.CUSTOMER_DETAILS}/${loadInsurance?.customerId}`}
                  className="text-primary700 text-sm font-medium"
                >
                  <TooltipCmp
                    message={
                      loadInsurance?.customer &&
                      loadInsurance?.customer.length > 24
                        ? loadInsurance?.customer
                        : null
                    }
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p
                      className={`xlm:max-w-36 max-w-40 truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {isLoading
                        ? 'Airtek Ontario Inc'
                        : loadInsurance?.customerName
                        ? loadInsurance?.customerName
                        : '-'}
                    </p>
                  </TooltipCmp>
                </Link>
              </div>
            </li>
            <li className="min-w-[105px] w-[12%]">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Claim ID
              </p>
              <div className=" text-primary700 font-medium text-sm ">
                {loadInsurance?.claimID ? (
                  <Link to={'#'}>#{loadInsurance?.claimID}</Link>
                ) : (
                  '-'
                )}
              </div>
            </li>
            <li className="min-w-[105px] w-[12%]">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Dispute ID
              </p>
              <div className=" text-primary700 font-medium text-sm ">
                {loadInsurance?.disputeID ? (
                  <Link to={'#'}>#{loadInsurance?.disputeID}</Link>
                ) : (
                  '-'
                )}
              </div>
            </li>

            <li className="min-w-[105px] w-[12%] relative">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Commodity
              </p>
              <div className="flex items-center">
                <p
                  className={`text-grayLight900 text-sm font-medium xlm:max-w-56 max-w-40 truncate ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {isLoading ? 'Test Commodity' : loadInsurance?.commodity}
                </p>
              </div>
            </li>
            <li className="min-w-[105px] w-[12%] relative">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Commercial value
              </p>

              <div className="flex items-center">
                <p
                  className={`text-grayLight900 text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {isLoading
                    ? 'Test Commodity'
                    : `$${getFormattedNumber(loadInsurance?.commercial)}`}
                </p>
              </div>
            </li>
            <li className="min-w-[105px] w-[12%] relative">
              <p className="text-grayLight600 text-xs font-normal mb-1">
                Insurance amount
              </p>

              <div className="flex items-center">
                <p
                  className={`text-grayLight900 text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {isLoading
                    ? 'Test Commodity'
                    : `$${getFormattedNumber(loadInsurance?.insurance_amount)}`}
                </p>
              </div>
            </li>
          </ul>
          <div className="flex gap-3">
            <label className="w-full cursor-pointer rounded-xl border border-borderSecondary py-3.5 px-5 text-center bg-white hover:border-primary">
              <div className="cursor-pointer" {...getRootProps()}>
                <label className="cursor-pointer">
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">PDF</p>
                  </div>
                </label>
                <input accept=".pdf" {...getInputProps()} />
              </div>
            </label>
          </div>
          {uploadFile && (
            <div className="rounded-xl border border-utilityGray200 p-4 flex gap-x-3 ">
              <div>
                <img src={pdf} className="h-10 w-10 object-contain" />
              </div>
              <div className="flex-1">
                <p className="text-textSecondary text-sm font-medium">
                  {uploadFile?.name}
                </p>
                <span className="text-grayLight600 text-sm font-normal">
                  {convertToGBMBKB(uploadFile?.size)} – {uploadProgress}%
                  uploaded
                </span>
              </div>
              <div
                className="radial-progress text-[10px] text-primary"
                role="progressbar"
                style={
                  {
                    '--value': uploadProgress,
                    '--size': '2rem',
                  } as React.CSSProperties & { '--value': number }
                }
              ></div>
            </div>
          )}

          <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mb-5 flex-1">
            <div className="table-top-header ">
              <div className="table-left-wrap ">
                <div className={`table-title-wrap`}>
                  <h5 className="table-title">Files & Attachments</h5>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={isLoading ? 'custom-loading' : ''}
                  >
                    {total} {total > 1 ? 'Files' : 'File'}
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  Here is your files for load insurance
                </p>
              </div>
              <TabButton
                tabArray={FilesTab}
                parentClassName="w-full"
                isActive={fileAttachmentFilter}
                handleOnClick={(e: any) =>
                  setFileAttachmentFilter(e.target.dataset.value)
                }
              />
            </div>
            <div className="h-full w-full border-t border-gray100 flex flex-col">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide ">
                <TableCmp
                  headCells={FilesheadCells}
                  tableDataArr={fileList}
                  isTableDataLoading={isFileListLoading}
                  tableRowCmpProps={{
                    deleteFile: (row: any) =>
                      setAction((old) => ({
                        ...old,
                        ...{
                          mode: 'deleteFile',
                          id: row?.id,
                        },
                      })),
                    onRowClick: onAttachmentRowClick,
                  }}
                  TableLoaderRowCmp={LoadInsuranceFilesLoading}
                  TableRowCmp={LoadInsuranceFiles}
                />
              </div>
              {!fileList.length && !isLoading && (
                <NotFoundUI
                  title={'No file attachment found'}
                  desc={`There are no file attachment found`}
                  containerClassName=" !h-auto flex-1"
                />
              )}
              {action.mode && (
                <DeleteModal
                  moduleName={`file from load Insurance`}
                  handleClose={handleModalClose}
                />
              )}
            </div>
          </div>
          {action.mode === 'request' ? (
            <RequestLoadInsurance
              getDetail={getDetail}
              loadInsurance={loadInsurance}
              handleClose={() => {
                setAction(initAction);
                getDetail();
              }}
            />
          ) : (
            ''
          )}
        </div>
        <InternalChatAndNotes
          loadInsurance={loadInsurance}
          isShowChatAndNotesSideBar={isShowChatAndNotesSideBar}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
        />
      </div>
    </PageSectionLayout>
  );
};

export default LoadInsuranceDetails;
