import { Zap } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { enableCustomerOnCredit } from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

interface IProps {
  customerId: any;
  customerCreditDetail: any;
  handleClose: any;
}

const EnableCustomerOnCredit = ({
  customerId,
  customerCreditDetail,
  handleClose,
}: IProps) => {
  const [creditLimit, setCreditLimit] = useState<any>(0);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const handleEnableUserOnCreditLimit = () => {
    setIsApiLoading(true);
    enableCustomerOnCredit({
      customerId: customerId,
      creditLimitToSet: creditLimit,
      currentAccountReceivable: customerCreditDetail?.currentAccountReceivable,
    })
      .then((response: any) => {
        WalToast.success(response?.message);
        handleClose(true);
      })
      .catch((e: any) => {
        console.error(e);
        handleClose(false);
        WalToast.error(
          'Something went wrong while updating customer credit status'
        );
      })
      .finally(() => {
        setIsApiLoading(false);
      });
  };

  return (
    <CommonModal
      title={'Configure Customer Credit Limit'}
      titleDesc={'Transition customer from pre-pay to credit-based orders.'}
      handleClose={() => (isApiLoading ? false : handleClose(false))}
      headerIcon={<Zap />}
      size={'max-w-[688px]'}
      isOverflow={true}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Enable"
      primaryBtnOnClick={handleEnableUserOnCreditLimit}
      primaryBtnLoading={isApiLoading}
      secondaryBtnDisabled={isApiLoading}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        <InputText
          label="Set Credit Limit"
          placeholder="Enter new credit limit"
          parentClassName="mb-2.5"
          value={creditLimit ?? 0}
          onChangeFunc={(e) => setCreditLimit(e?.target?.value)}
          inputType="number"
        />
      </div>
    </CommonModal>
  );
};

export default EnableCustomerOnCredit;
