import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { LOAD_INSURANCE_STATUS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const LoadInsuranceRow = ({ data, dowloadPdf }: any) => {
  const navigate = useNavigate();

  return (
    <>
      <td
        className={`px-5 py-4 w-[19.2%] min-w-[19.2%] max-w-[1px] `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center">
            {data.orderCommissioneeImage ? (
              <div className="table-profile-wrap notification-mark-wrap">
                <img
                  className="table-profile-icn-circle-xl"
                  src={
                    data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                  }
                  alt={data.orderCommissioneeFirstName}
                  title={data.orderCommissioneeFirstName}
                  onError={onError}
                />
                {data?.hasUnreadNotification ? (
                  <span className={`notification-dot`}></span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="table-profile-wrap notification-mark-wrap">
                <div className="table-profile-user-circle-xl">
                  {getShortName(
                    `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                  )}
                </div>
                {data?.hasUnreadNotification ? (
                  <span className={`notification-dot`}></span>
                ) : (
                  ''
                )}
              </div>
            )}
            <div className="ml-3 text-xs font-medium truncate max-w-[70%]">
              <h6 className="text-grayLight900 font-medium leading-tight truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.load_insurance_id}
              </p>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-xs font-medium max-w-32">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.orderCommissionees?.split(',').length} Sales Reps.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.load_insurance_id}
            </p>
          </div>
        ) : data.orderCommissionees ? (
          <div className="flex items-center">
            <div className="relative flex-none">
              <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                {getShortName(data.orderCommissionees)}
              </div>
              <span
                className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
              ></span>
            </div>
            <div className="ml-2 text-xs font-medium max-w-[70%]">
              <h6 className="text-grayLight900 font-medium leading-tight truncate gap-1">
                <span className="inline-block truncate">
                  {data.orderCommissionees}
                </span>
                {/* <TooltipCmp
                message={`${data.orderCommissionees} is not an active user on WALHQ`}
              >
                <InfoCircle className="h-3.5 w-3.5" />
              </TooltipCmp> */}
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.load_insurance_id}
              </p>
            </div>
          </div>
        ) : (
          <div className="ml-2 text-xs font-medium max-w-[70%]">
            <p>-</p>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.load_insurance_id}
            </p>
          </div>
        )}
      </td>
      <td
        className={`px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%] `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <div className="flex flex-wrap gap-1">
          {data.status === LOAD_INSURANCE_STATUS.APPROVED && (
            <BadgeCmp style="modern" type="success">
              Approved
            </BadgeCmp>
          )}
          {data.status === LOAD_INSURANCE_STATUS.OPEN && (
            <BadgeCmp style="modern" type="primary">
              Open
            </BadgeCmp>
          )}

          {data.status === LOAD_INSURANCE_STATUS.DECLINED && (
            <BadgeCmp style="modern" type="error">
              Declined
            </BadgeCmp>
          )}
        </div>
      </td>
      <td
        className={`px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%] `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>
          <a
            href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation to prevent `td` click
            }}
            className="text-primary700 font-normal leading-tight truncate underline"
            target="_blank"
          >
            #{data.fullId}
          </a>
        </span>
      </td>

      <td
        className={`px-5 py-4 min-w-60 w-60 max-w-60 cursor-pointer `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        {data.createdAt ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.createdAt
                ? getDateWithSuffixFormat(
                    getFormattedDate(
                      data.createdAt,
                      `MMM Do , hh:mm A`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        ) : (
          ''
        )}
      </td>
      <td
        className={`px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%] `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>{data.commodity}</span>
      </td>
      <td
        className={`px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%] `}
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>${getFormattedNumber(data.commercial)}</span>
      </td>
      <td className={`px-5 py-4 w-[64px] max-w-[64px] min-w-[64px] `}>
        <div className="flex gap-2">
          {LOAD_INSURANCE_STATUS.APPROVED === data?.status ? (
            <TooltipCmp message={'Download Pdf'}>
              <DownloadCloud02
                className="w-4 h-4"
                onClick={() => dowloadPdf(data)}
              />
            </TooltipCmp>
          ) : (
            ''
          )}

          {/* <Link to={`${PATH.LOAD_INSURANCE}/${data.id}`}>
            <Eye className="w-4 h-4" />
          </Link> */}
        </div>
      </td>
    </>
  );
};

export default LoadInsuranceRow;
