import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { getShortName } from 'src/utils/CommonFunctions';

import defaultimage from '../../../assets/img/default-image.jpg';

interface IProps {
  customerGroupDetail: any;
  isCustomerGroupDetailLoading: boolean;
}

const CustomerGroupDetailRightSideSection = ({
  customerGroupDetail,
  isCustomerGroupDetailLoading,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <>
      <h6 className="text-grayLight900 font-medium text-sm">
        <span>Customers </span>
        <span
          className={`rounded-md ${
            isCustomerGroupDetailLoading ? 'custom-loading' : ''
          }`}
        >
          ({customerGroupDetail?.customersCount})
        </span>
      </h6>
      <div>
        {isCustomerGroupDetailLoading ? (
          <div className="rounded-xl border border-utilityGray200 p-3 shadow-none transition-all duration-[0.3s] hover:shadow-md cursor-pointer mb-4">
            <div className="">
              <div className="flex gap-1.5">
                <div className="w-8 h-8 rounded-md custom-loading">
                  <img
                    src={defaultimage}
                    className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                  />
                </div>
                <div>
                  <h6 className="text-grayLight900 text-xs font-semibold mb-0.5 custom-loading">
                    HC Companies
                  </h6>
                  <p className="text-grayLight600 text-xs font-normal custom-loading">
                    2425 Boul Edouard-Michelin, Terrebonne, CA
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 empty:mt-0 mt-4">
                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-yellow300"
                  mainClassName="custom-loading"
                >
                  Test
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-[#B2AC17]"
                  mainClassName="custom-loading"
                >
                  Top
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-utilityPink600"
                  mainClassName="custom-loading"
                >
                  Dormat
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  type="lightblue"
                  mainClassName="custom-loading"
                >
                  Prospect
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-fgSuccessPrimary"
                  mainClassName="custom-loading"
                >
                  New
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  type="primary"
                  mainClassName="custom-loading"
                >
                  Active
                </BadgeCmp>
              </div>
            </div>
          </div>
        ) : (
          customerGroupDetail?.customers.map((groupCustomer: any) => (
            <div
              className="rounded-xl border border-utilityGray200 p-3 shadow-none transition-all duration-[0.3s] hover:shadow-md cursor-pointer mb-4"
              key={groupCustomer?.id}
              onClick={() =>
                navigate(`${PATH.CUSTOMER_DETAILS}/${groupCustomer?.id}`)
              }
            >
              <div className="">
                <div className="flex gap-1.5">
                  {groupCustomer?.image && groupCustomer?.imageUrl ? (
                    <img
                      src={groupCustomer?.imageUrl + groupCustomer?.image}
                      className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                      {getShortName(`${groupCustomer?.name}`)}
                    </div>
                  )}
                  <div>
                    <h6 className="text-grayLight900 text-xs font-semibold mb-0.5">
                      {groupCustomer?.name}
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal">
                      {`${groupCustomer?.address1}, ${groupCustomer?.city}, ${groupCustomer?.country}`}
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 empty:mt-0 mt-4">
                  {groupCustomer?.new_segment.length > 0 ? (
                    <>
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.TEST
                      ) && (
                        <BadgeCmp
                          style="modern"
                          badgeDotColor="bg-yellow300"
                          mainClassName=""
                        >
                          {CUSTOMER_SEGMENT_TYPES.TEST}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.TOP
                      ) && (
                        <BadgeCmp
                          style="modern"
                          badgeDotColor="bg-[#b2ac17]"
                          mainClassName=" "
                        >
                          {CUSTOMER_SEGMENT_TYPES.TOP}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.DORMANT
                      ) && (
                        <BadgeCmp
                          style="modern"
                          badgeDotColor="bg-utilityPink600"
                          mainClassName=""
                        >
                          {CUSTOMER_SEGMENT_TYPES.DORMANT}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.PROSPECT
                      ) && (
                        <BadgeCmp
                          style="modern"
                          type="lightblue"
                          mainClassName=""
                        >
                          {CUSTOMER_SEGMENT_TYPES.PROSPECT}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.NEW
                      ) && (
                        <BadgeCmp
                          style="modern"
                          badgeDotColor="bg-fgSuccessPrimary"
                          mainClassName=""
                        >
                          {CUSTOMER_SEGMENT_TYPES.NEW}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.ACTIVE
                      ) && (
                        <BadgeCmp
                          style="modern"
                          type="primary"
                          mainClassName=""
                        >
                          {CUSTOMER_SEGMENT_TYPES.ACTIVE}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.INACTIVE
                      ) && (
                        <BadgeCmp style="modern" type="error" mainClassName="">
                          {CUSTOMER_SEGMENT_TYPES.INACTIVE}
                        </BadgeCmp>
                      )}
                      {groupCustomer?.new_segment?.includes(
                        CUSTOMER_SEGMENT_TYPES.AT_RISK
                      ) && (
                        <BadgeCmp style="modern" type="gray" mainClassName="">
                          {CUSTOMER_SEGMENT_TYPES.AT_RISK}
                        </BadgeCmp>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default CustomerGroupDetailRightSideSection;
