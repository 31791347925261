import React, { useMemo, useState } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import FormModal from './form';

const Test = () => <div></div>;

const AssignCarrier = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const headCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
        rowClassName: '',
      },
      {
        id: 'carrier_type',
        name: 'Carrier Type',
        rowClassName: '',
      },
      {
        id: 'quoteid',
        name: 'Quote ID',
        rowClassName: '',
      },
      {
        id: 'total_weight',
        name: 'Total weight',
        rowClassName: '',
      },
      {
        id: 'total_cost',
        name: 'Total Cost',
        rowClassName: '',
      },
      {
        id: 'additionals',
        name: 'Additionals',
        rowClassName: '',
      },
      {
        id: 'total_price',
        name: 'Total Price',
        rowClassName: '',
      },
    ],
    []
  );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 ">
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination table-without-title-header">
          <TableCmp
            headCells={headCells}
            tableDataArr={[{}]}
            TableRowCmp={Test}
          />
        </div>
        <NotFoundUI
          title=""
          desc="In order to continue, please assign a carrier to facilitate the next actions"
          containerClassName="!h-auto !my-6"
          contentClassName="before:!bg-none"
          descClass="!max-w-[800px]"
          iconClassName="!hidden"
          buttonText="Add a carrier"
          handleActionType={() => setIsFormOpen(true)}
          buttonClassName="!btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
          btnClass="!mt-1"
        />
      </div>

      {isFormOpen && <FormModal closeForm={closeForm} />}
    </>
  );
};

export default AssignCarrier;
