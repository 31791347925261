import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ConfirmModal from 'src/components/ConfirmModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { TIMEZONE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import { updateFeedbackStatus } from 'src/services/FeedbackService';
import WalToast from 'src/utils/WalToast';

import BadgeCmp from '../../components/BadgeCmp';
import ButtonCmp from '../../components/ButtonCmp';
import CustomPagination from '../../components/CustomPagination';
import InputText from '../../components/InputText/InputText';
import NotFoundUI from '../../components/NotFoundUI';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';
import { useAppDispatch } from '../../redux/hooks';

import { getFeedBack } from './Feedback.slice';
import FeedbackCreate from './FeedbackCreate';
import FeedbackLoading from './FeedbackLoading';
import FeedbackRow from './FeedbackRow';

const initParams = {
  search: '',
  // sortType: 'DESC',
  // sortField: 'createdAt',
  page: 1,
  limit: 10,
  filter: {
    status: '',
  },
};

const Feedback = () => {
  const dispatch: any = useAppDispatch();
  const { setActionTooltipGlobalOpen, appearance } = useContext(BasicContext);
  const [feedbacks, setFeedbacks] = useState<any>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [currentTimeZone, setCurrentTimeZone] = useState('');
  const [isCreateTicket, setIsCreateTicket] = useState<boolean>(false);
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [feedbackId, setFeedbackId] = useState();
  const [displayStatus, setDisplayStatus] = useState('');
  const [action, setAction] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const { entityType, setEntityType } = useContext(NotificationsContext);
  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const getFeedBackList = async (paramsValue: any) => {
    setFeedbacks([]);
    const result = await dispatch(getFeedBack(paramsValue));

    if (result.type === getFeedBack.fulfilled.toString()) {
      // console.log('result', result.payload);
      setFeedbacks(result.payload?.feedbackList);
      setTotals(result.payload?.total);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getFeedBackList(params);
  }, [params, isRefresh]);

  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    if (timeZone) {
      setCurrentTimeZone(appearance?.timezone ?? timeZone);
    } else {
      setCurrentTimeZone(appearance?.timezone ?? TIMEZONE);

      // setCurrentTimeZone(TIMEZONE);
    }
  });

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'search') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const headCells = useMemo(
    () => [
      {
        id: 'id',
        name: 'Id',
        sortable: false,
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
      },
      {
        id: 'createdAt',
        name: 'Generate Time',
        sortable: false,
      },
      {
        id: 'user',
        name: 'User',
        sortable: false,
      },
      {
        id: 'code',
        name: 'Quote',
        sortable: false,
      },
      {
        id: 'description',
        name: 'Description',
        sortable: false,
      },
      {
        id: 'image',
        name: 'Image',
        sortable: false,
      },

      {
        id: 'action',
        className: 'w-16',
      },
    ],
    []
  );

  const statuses = useMemo(
    () => [
      // {
      //   id: 1,
      //   label: 'All',
      //   value: 'all',
      //   // counter: counter.All,
      // },
      {
        id: 2,
        label: 'Pending',
        value: 'pending',
        // counter: counter.Opened,
      },
      {
        id: 3,
        label: 'In Review',
        value: 'inReview',
        // counter: counter.Active,
      },
      {
        id: 4,
        label: 'Solved',
        value: 'solved',
        // counter: counter.Won,
      },
      {
        id: 5,
        label: 'Ignored',
        value: 'ignored',
        // counter: counter.Won,
      },
    ],
    []
  );

  const findSelectedStatus = (value: any) =>
    statuses.find((status) => status.value === value) || 'all';

  // const handleStatus = (status: string) => () => {
  //     console.log("status", status);
  //     setParams((old) => ({ ...old, page: 1, filter: { ...old.filter, status } }));
  // };

  const handleAction = (id: any, status: any, displayStatusProp: any) => {
    setFeedbackStatus(status);
    setFeedbackId(id);
    setDisplayStatus(displayStatusProp);
    setAction(true);
  };

  const handleConfirmModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        setIsLoading(true);
        const appendFormData: any = new FormData();
        appendFormData.append('status', feedbackStatus);
        updateFeedbackStatus(feedbackId, appendFormData)
          .then(() => {
            setAction(false);
            getFeedBackList(params);
            setIsRefresh(true);
            WalToast.success('Feedback status updated successfully', '');
          })
          .finally(() => {
            setIsLoading(false);
          })
          .catch(() => WalToast.error('Feedback status not updated', ''));
      } else {
        setAction(false);
      }
    },
    [action]
  );

  const tooltipItems = (
    <div className="menu bg-base-100 rounded-box w-36">
      <div
        onClick={() => handleAction(feedbackId, 'inReview', 'in review')}
        className="py-2"
      >
        <div className="flex items-center">In review</div>
      </div>
      <div
        onClick={() => handleAction(feedbackId, 'solved', 'solved')}
        className="py-2"
      >
        <div className="flex items-center">Solved</div>
      </div>
      <div
        onClick={() => handleAction(feedbackId, 'ignored', 'ignored')}
        className="py-2"
      >
        <div className="flex items-center">Ignored</div>
      </div>
    </div>
  );

  useEffect(() => {
    if (entityType && entityType === 'feedback_create') {
      getFeedBackList(params);
      setEntityType(null);
    }
  }, [entityType]);

  useEffect(() => {
    if (feedbacks && feedbacks.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (entityCounts?.feedbackCount && entityCounts?.feedbackCount > 0) {
          const param = { type: 'feedback_create' };
          handleNotificationRead(param);

          const newFeedback = feedbacks.map((feedback: any) => ({
            ...feedback,
            hasUnreadNotification: false,
          }));

          setFeedbacks(newFeedback);
        }
      }, 5000);
    }
  }, [feedbacks, entityCounts, isNotificationLoading]);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Support"
            desc="Track, manage and forecast your customers and orders."
            mainContainerClassName="sm:flex-nowrap sm:flex-row"
            rightClassName="sm:w-auto"
          />
        }
      >
        <div className="table-wrap-main w-full flex-1 ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title ">Requests</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals > 0
                    ? `${totals} ${totals > 1 ? 'Feedbacks' : 'Feedback'} `
                    : 'No Feedback'}
                </BadgeCmp>
              </div>
            </div>
            <InputText
              inputName="search"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput sm:min-w-[auto] min-w-full"
              isClearable={true}
            />
            <div className="table-statustbox">
              <SelectBox
                name="segmentGroup"
                id="segmentGroup"
                className="form_control"
                size="sm"
                placeholder="All Types"
                isClearable={params?.filter?.status !== 'all'}
                options={statuses}
                onChangeFunc={(e: any) => {
                  let status = e ? e.value : '';
                  setParams((old) => ({
                    ...old,
                    page: 1,
                    filter: { ...old.filter, status },
                  }));
                }}
                value={findSelectedStatus(params?.filter?.status)}
              />
            </div>
            <ButtonCmp
              className="btn-outline-primary flex smd:w-fit w-full"
              onClick={() => {
                setActionTooltipGlobalOpen(false);
                setIsCreateTicket(true);
              }}
              iconSide="left"
              icon={<Plus className="text-primary h-4 w-4" />}
            >
              Create a Ticket
            </ButtonCmp>
          </div>

          <div className="h-full w-full border-t border-gray100">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={feedbacks}
                TableLoaderRowCmp={FeedbackLoading}
                TableRowCmp={FeedbackRow}
                // params={params}
                // setParams={setParams}
                tableRowCmpProps={{
                  setFeedbackId: setFeedbackId,
                  tooltipItems: tooltipItems,
                  currentTimeZone: currentTimeZone,
                }}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={params.limit}
                isTableRowClickable={false}
              />
            </div>
            {feedbacks.length <= 0 && !isLoading ? (
              <NotFoundUI
                handleActionType={() => {}}
                title="No Feedback found"
                desc="There are no data for feedbacks you can create new ticket.."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            ) : (
              ''
            )}
          </div>
          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={10}
              totalRecords={totals}
              currentPage={params?.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
      {action && (
        <ConfirmModal
          show={action}
          title="Are you sure?"
          description={`Do you want to mark this feedback as ${displayStatus}`}
          button1="Yes, I am sure"
          button2="Cancel"
          handleClose={handleConfirmModalClose}
        />
      )}
      {isCreateTicket && (
        <FeedbackCreate
          getList={() => getFeedBackList(params)}
          isOpen={isCreateTicket}
          handleClose={() => setIsCreateTicket(false)}
        />
      )}
    </>
  );
};

export default Feedback;
