import { yupResolver } from '@hookform/resolvers/yup';
import {
  Edit05,
  File06,
  CurrencyDollar,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { updateDisputeStatus } from 'src/services/DisputeService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const disputeResolveSchema = yup.object().shape({
  disputeResolveDescription: yup
    .string()
    .trim()
    .required('Description is required.')
    .min(50, 'Dispute resolution description must be at least 50 characters.')
    .max(
      250,
      'Dispute resolution description should be no longer than 250 characters.'
    ),
  disputeID: yup.string().required('DisputeID is required.'),
  disputeFinalAmount: yup.string().required('Dispute amount is required.'),
});

interface IProps {
  handleClose: () => void;
  dispute: any;
  reloadDisputes: () => void;
  isDisputeListPage: boolean;
  handleModifyDisputeAmount?: () => void;
}

const DisputeResolve = ({
  handleClose,
  dispute,
  reloadDisputes,
  isDisputeListPage,
  handleModifyDisputeAmount,
}: IProps) => {
  const navigate = useNavigate();

  const [isDisputeStatusUpdateLoading, setIsDisputeStatusUpdateLoading] =
    useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(disputeResolveSchema),
    defaultValues: {
      disputeResolveDescription: '',
    },
  });

  const disputeResolveDescription = watch('disputeResolveDescription');

  useEffect(() => {
    if (dispute && dispute?.disputeID) {
      setValue('disputeID', `#${dispute?.disputeID}`);
    }

    if (dispute && dispute?.disputeAmount && !dispute?.finalDisputeAmount) {
      setValue('disputeFinalAmount', dispute?.disputeAmount);
    }

    if (dispute && dispute?.finalDisputeAmount) {
      setValue('disputeFinalAmount', dispute?.finalDisputeAmount);
    }
  }, []);

  useEffect(() => {
    if (dispute && dispute?.disputeAmount && !dispute?.finalDisputeAmount) {
      setValue('disputeFinalAmount', dispute?.disputeAmount);
    }

    if (dispute && dispute?.finalDisputeAmount) {
      setValue('disputeFinalAmount', dispute?.finalDisputeAmount);
    }
  }, [dispute]);

  const onSubmit = (formData: any) => {
    setIsDisputeStatusUpdateLoading(true);

    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (key !== 'disputeID') {
        appendFormData.append(key, value);
      }
    });

    if (dispute && dispute?.disputeID) {
      appendFormData.append('disputeID', dispute?.disputeID);
    }
    appendFormData.append('status', 'Resolved');

    updateDisputeStatus(appendFormData)
      .then(() => {
        if (isDisputeListPage && reloadDisputes) {
          reloadDisputes();
        } else {
          navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
        }
      })
      .catch(() =>
        WalToast.error('Something went wrong while resolving dispute.')
      )
      .finally(() => {
        setIsDisputeStatusUpdateLoading(false);
        handleClose();
      });
  };

  const headerIcon = <File06 className="w-7 h-7" />;

  const memorizedBadge = useMemo(
    () =>
      disputeResolveDescription &&
      disputeResolveDescription?.trim()?.length > 0 && (
        <div className="text-grayLight900 text-xs">
          {disputeResolveDescription.trim().length}
          &nbsp;/&nbsp;250
        </div>
      ),
    [disputeResolveDescription]
  );

  return (
    <CommonModal
      title={`Resolve Dispute`}
      titleDesc={`Add description to resolve dispute`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isDisputeStatusUpdateLoading}
      primaryBtnDisabled={isDisputeStatusUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isDisputeStatusUpdateLoading}
    >
      <div className="p-5">
        <Controller
          name="disputeID"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Dispute ID"
              inputName="disputeID"
              placeholder="Dispute ID"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.disputeID?.message ?? ''}
              labelClassName="block mb-1.5"
            />
          )}
        />

        <Controller
          name="disputeFinalAmount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Resolution Amount"
              inputName="disputeFinalAmount"
              placeholder="Resolution Amount"
              parentClassName="mb-4"
              className="pl-8"
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.disputeFinalAmount?.message ?? ''}
              labelClassName="block mb-1.5"
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
              lableIconRight={
                handleModifyDisputeAmount ? (
                  <Edit05
                    className="w-4 h-4 relative text-primary cursor-pointer"
                    onClick={() => {
                      if (handleModifyDisputeAmount)
                        handleModifyDisputeAmount();
                    }}
                  />
                ) : (
                  false
                )
              }
            />
          )}
        />

        <Controller
          name="disputeResolveDescription"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="disputeResolveDescription"
              label="Resolution description"
              placeholder="Enter Resolve Dispute Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.disputeResolveDescription?.message ?? ''}
              asTextarea="textarea"
              lableIconRight={memorizedBadge}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default DisputeResolve;
