export interface IProps {
  addressDetails: any;
  loadLinkPostingDetail: any;
  validatePostToLoadBoardForm: boolean;
  setIsPostCreateLoading: any;
  setSyncLiveData: any;
  syncLiveData: any;
  setLoadLinkPostingDetail: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
  isRefresh: any;
  setIsRefresh: any;
}

export interface ITransportLead {
  createdAt: string;
  updatedAt: string;
  id: number;
  leadToken: string;
  dateAvailability: string;
  sourceCity: string;
  sourceState: string;
  sourceCountry: string;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  vehicleSize: string;
  vehicleType: string;
  dfo: string;
  dirO: string;
  dfd: string;
  vehicleAttributes: string;
  company: string;
  companyPhone: string;
  companyEmail: string;
  createdOn: string;
  isNew: boolean;
  postId: number;
  comment?: string;
}

export interface ILeadRowProps {
  data: ITransportLead;
}

export enum LoadBoardCellId {
  Company = 'company',
  PhoneNumber = 'companyPhone',
  DistanceFromOrigin = 'dfo',
  EquipmentSize = 'vehicleSize',
  Availability = 'dateAvailability',
  Posted = 'createdOn',
  Comment = 'comment',
}
