import { Copy04, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dimensionObj } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';

interface IProps {
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
}

const Dimensions = ({ handlingUnitOptions, isHandlingUnitLoading }: IProps) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  console.log('errors', errors);

  const watchOrderDimension = watch('order_dimensions');

  const addItems = () => {
    const dimensions = [...watchOrderDimension, dimensionObj];
    setValue('order_dimensions', dimensions);
  };

  const duplicateItems = () => {
    const formData = watchOrderDimension || [];

    if (formData.length > 0) {
      const lastItem = { ...formData[formData.length - 1] };
      delete lastItem.id;
      setValue('order_dimensions', [...formData, lastItem]);
    } else {
      setValue('order_dimensions', [dimensionObj]);
    }
  };

  const removeItems = (index: number) => () => {
    const formData = watchOrderDimension || [];

    console.log('index', index);

    const updatedFormData = formData.filter(
      (_: any, idx: any) => idx !== index
    );

    setValue('order_dimensions', updatedFormData);
  };

  return (
    <>
      <h6 className="text-textSecondary text-sm font-medium mb-1.5">
        Please Specify the Dimensions
      </h6>
      <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs p-4 mb-4 last:mb-0">
        {watchOrderDimension &&
          watchOrderDimension.map((item: any, index: number) => (
            <div
              className="flex flex-wrap sm:-m-1.5 !mb-6 last:mb-0 text-nowrap"
              key={index}
            >
              <Controller
                name={`order_dimensions[${index}].handlingUnit`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SelectBox
                    name="handling_unit"
                    id="handling_unit"
                    className="form_control"
                    label="Handling unit"
                    labelClassName={'form_label block mb-1.5'}
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    size="sm"
                    options={handlingUnitOptions}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value);
                    }}
                    isSearchable={false}
                    value={
                      value
                        ? handlingUnitOptions.filter(
                            (val: any) => value === val.value
                          )
                        : handlingUnitOptions.filter(
                            (val: any) =>
                              handlingUnitOptions[0]?.value === val.value
                          )
                    }
                    required
                    isLoading={isHandlingUnitLoading}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].handlingUnitNo`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="handlingUnitNo"
                    inputType="number"
                    label="№ of HU"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    required
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].length`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="length"
                    inputType="number"
                    label="Length (in)"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    required
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].width`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="width"
                    inputType="number"
                    label="Width (in)"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    required
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].height`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="height"
                    inputType="number"
                    label="Height (in)"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    required
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].weight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="weight"
                    inputType="number"
                    label="Unit Weight(lbs)"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    required
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].totalWeight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="totalWeight"
                    inputType="number"
                    label="Total weight (lbs)"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].freightClass`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="freightClass"
                    inputType="number"
                    label="Freight Class"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].refNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="refNumber"
                    inputType="number"
                    label="REF Number"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name={`order_dimensions[${index}].refNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="description"
                    label="Description"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[9.1%] min-w-min"
                    onChangeFunc={onChange}
                    errorText={error ? error.message : null}
                  />
                )}
              />
              <div className="flex w-[84px]">
                <div className="sm:p-1.5 w-[46px]">
                  <label className="form_label block mb-1.5">D.N.S</label>
                  <Controller
                    name={`order_dimensions[${index}].isStack`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        isChecked={value ?? true}
                        onChange={onChange}
                        labelClassName="lg:h-[36px] h-[34px] flex items-center"
                        name="isStack"
                      />
                    )}
                  />
                </div>
                <div className="m-1.5 lg:h-9 h-[34px] self-end flex items-center">
                  <div
                    className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer`}
                    onClick={() => removeItems(index)()}
                  >
                    <Trash01 className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="flex gap-x-4">
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Copy04 className="w-4 h-4" />}
            onClick={duplicateItems}
          >
            Duplicate
          </ButtonCmp>
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => addItems()}
          >
            Add Row
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default Dimensions;
