import { AlertTriangle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';

interface IProps {
  handleClose: any;
  claimDisputeData: any;
}

const OrderNoSalesRepWarning = ({ handleClose, claimDisputeData }: IProps) => {
  const navigate = useNavigate();
  const isClaim = claimDisputeData?.claimAmount;

  return (
    <CommonModal
      title={`Order not eligible for this ${isClaim ? 'claim' : 'dispute'} `}
      titleDesc={`No Sales Rep Assigned, Attach sales rep to customer's order.`}
      handleClose={handleClose}
      size={'max-w-[500px]'}
      headerIcon={<AlertTriangle className="w-7 h-7" />}
      primaryBtnText="Open Customer"
      primaryBtnOnClick={() => {
        localStorage.setItem(
          TABLE_IDS.CUSTOMER_DETAILS,
          JSON.stringify('allocation_history')
        );
        navigate(`${PATH.CUSTOMER_DETAILS}/${claimDisputeData?.customerId}`);
      }}
      secondaryBtnText="Close"
      secondaryBtnOnClick={() => handleClose()}
    >
      <div className="p-5 text-sm">
        <p>
          {`${
            isClaim ? 'Claimed' : 'Disputed'
          } order has no Sales rep assigned so can not open ${
            isClaim ? 'claim' : 'dispute'
          }, please update allocation history of order's customer to first.`}
        </p>
      </div>
    </CommonModal>
  );
};

export default OrderNoSalesRepWarning;
