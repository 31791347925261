import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';

const FileLoadingRow = () => (
  <>
    <td className="px-5 py-4">
      <h6 className="text-grayLight900 font-semibold">
        <span className="custom-loading">
          sales-backup-orders-2024-09-25_15-00-02.xlsx
        </span>
      </h6>
      <p>
        <span className="custom-loading">Size: 825kb</span>
      </p>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">01/09/2024</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">01/09/2024</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">01/09/2024</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">$310,485.67</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">$310,485.67</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">$310,485.67</span>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">$310,485.67</span>
    </td>

    <td className="px-5 py-4">
      <div className=" gap-4 flex justify-center">
        <ButtonCmp className="btn-ghost !shadow-none custom-loading">
          <DownloadCloud02 className="w-4 h-4 relative cursor-pointer" />
        </ButtonCmp>
      </div>
    </td>
  </>
);

export default FileLoadingRow;
