import React from 'react';
import NotFoundUI from 'src/components/NotFoundUI';

const CustomerGroupDetailAnalyticsTab = () => (
  <>
    <NotFoundUI
      title="Coming Soon"
      desc="Lorem Ipsum is simply dummy text of the printing and type-setting-industry. Lorem Ipsum has been the industry's standard."
      containerClassName="my-0 flex-1"
      contentClassName="before:bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-[280px]"
      iconClassName="!hidden"
      iconSecondaryClassName="!flex"
    />
  </>
);

export default CustomerGroupDetailAnalyticsTab;
