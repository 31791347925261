import { yupResolver } from '@hookform/resolvers/yup';
import {
  Calendar,
  CurrencyDollar,
  Percent02,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { CarrierList } from 'src/services/CarrierService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import AdditionalCharge from '../AdditionalCharge';

const schema = yup.object().shape({
  carrier_id: yup.number().nullable(),
  budget_cost: yup.number().required('This field is required'),
  carrier_booking_date: yup.date().required('This field is required'),
  fuel: yup.number().required('This field is required'),
  total_sale_price: yup.number().required('This field is required'),
  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup.number().required('charge is required'),
      price: yup.number().required('price is required'),
    })
  ),
});

const selectAllOption: { value: null | number; label: string } = {
  value: null,
  label: 'Unassigned Carrier',
};

interface IProps {
  closeForm: () => void;
}
export interface IFormData {
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    price: null | number;
  }>;
  carrier_id: number;
  budget_cost: number;
  carrier_booking_date: Date;
  fuel: number;
  total_sale_price: number;
}

const FormModal = ({ closeForm }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCarrierListLoading, setIsCarrierListLoading] =
    useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any[]>([]);

  // const { handleSubmit, control, watch } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     carrier_id: undefined,
  //     budget_cost: undefined,
  //     carrier_booking_date: moment().toDate(),
  //     fuel: undefined,
  //     total_sale_price: undefined,
  //     additional_charge: [],
  //   },
  // });

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      carrier_id: undefined,
      budget_cost: undefined,
      carrier_booking_date: moment().toDate(),
      fuel: undefined,
      total_sale_price: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          price: null,
        },
      ],
    },
  });

  console.log('carrierList', carrierList);
  const { handleSubmit, watch, control } = methods;
  const watchCarrierId = watch('carrier_id');

  const getListCarrier = () => {
    setIsCarrierListLoading(true);
    CarrierList()
      .then((response: any) => {
        const result: any = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'imageUrl',
          'image'
        );
        const updateOptions = [selectAllOption, ...result];
        setCarrierList(updateOptions);
      })
      .finally(() => setIsCarrierListLoading(false))
      .catch(console.error);
  };

  useEffect(() => {
    getListCarrier();
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    // const formattedData = {
    //   ...formData,
    //   appointmentDate: moment(formData.appointmentDate).format('YYYY-MM-DD'),
    //   type,
    //   orderId,
    // };

    // createOrUpdateAppointment(formattedData)
    //   .then((response) => {
    //     if (response.data) {
    //       console.log('appointment set properly', response.data);
    //       handleClose();
    //     }
    //   })
    //   .catch(console.error)
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  return (
    <CommonModal
      title="Add a Carrier"
      titleDesc="Attach a carrier to this order, if applicable at this time."
      handleClose={closeForm}
      headerIcon={<Plus />}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText="Assign"
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      isOverflow={false}
      secondaryBtnOnClick={closeForm}
    >
      <>
        <FormProvider {...methods}>
          <div className="p-5 flex flex-col gap-y-4">
            <SelectBox
              label="Quote ID"
              labelClassName="form_label block mb-1.5"
              name="quoteId"
              id="quoteId"
              size="sm"
              placeholder="Select Quote ID"
              noOptionMessage="No Quote ID Found"
              isSearchable={true}
              className="form_control"
              // isClearable={true}
              options={carrierList}
              // onChangeFunc={(e: any) => onChange(e?.value)}
              value={'#SQ-B-101662'}
              // isLoading={isCarrierListLoading}
              // errorText={error ? error.message : null}
            />
            <Controller
              name="carrier_id"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SelectBox
                  label="Select Carrier"
                  labelClassName="form_label block mb-1.5"
                  name="customerId"
                  id="customerId"
                  size="sm"
                  placeholder="All Customers"
                  noOptionMessage="No Customers Found"
                  isSearchable={true}
                  className="form_control"
                  // isClearable={true}
                  options={carrierList}
                  onChangeFunc={(e: any) => onChange(e?.value)}
                  value={carrierList?.find((e) => e?.value == value)}
                  isLoading={isCarrierListLoading}
                  errorText={error ? error.message : null}
                  required
                />
              )}
            />
            <InputText
              label="Base Carrier Rate"
              inputName="base_carrier_rate"
              placeholder="Enter Base Carrier Rate"
              parentClassName=""
              className="pl-8"
              value={'10.00'}
              // onChangeFunc={onChange}
              // errorText={error ? error.message : null}
              labelClassName="block mb-1.5"
              icon={
                <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
              }
            />
            <InputText
              label="PRO Number"
              inputName="pro_number"
              placeholder="Enter PRO Number"
              value={'10.00'}
              labelClassName="block mb-1.5"
            />
            {/* <AdditionalCharge /> */}

            <InputText
              label="Fuel"
              inputName="fuel"
              placeholder="Enter Fuel Value"
              parentClassName=""
              className="pl-8 w-full"
              value={'20'}
              // onChangeFunc={onChange}
              // errorText={error ? error.message : null}
              labelClassName="block mb-1.5"
              icon={
                <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
              }
            />

            <InputText
              label="Total Sale Price"
              inputName="total_sale_price"
              placeholder="Enter Total Sale Price"
              parentClassName=""
              className="pl-8 w-full"
              value={'10.00'}
              // onChangeFunc={onChange}
              // errorText={error ? error.message : null}
              labelClassName="block mb-1.5"
              icon={
                <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
              }
            />

            <Controller
              name="budget_cost"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="Budget Cost"
                  inputName="budget_cost"
                  placeholder="Enter Budget Cost"
                  parentClassName=""
                  className="pl-8"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={error ? error.message : null}
                  labelClassName="block mb-1.5"
                  icon={
                    <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                  }
                />
              )}
            />
            {watchCarrierId && (
              <>
                <AdditionalCharge />
                <div className="flex w-full gap-3">
                  <div className=" w-1/2">
                    <Controller
                      name="fuel"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputText
                          label="Fuel"
                          inputName="fuel"
                          placeholder="Enter Fuel Value"
                          parentClassName=""
                          className="pl-8 w-full"
                          value={value}
                          onChangeFunc={onChange}
                          errorText={error ? error.message : null}
                          labelClassName="block mb-1.5"
                          icon={
                            <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                          }
                        />
                      )}
                    />
                  </div>

                  <div className=" w-1/2">
                    <Controller
                      name="total_sale_price"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputText
                          label="Total Sale Price"
                          inputName="total_sale_price"
                          placeholder="Enter Total Sale Price"
                          parentClassName=""
                          className="pl-8 w-full"
                          value={value}
                          onChangeFunc={onChange}
                          errorText={error ? error.message : null}
                          labelClassName="block mb-1.5"
                          icon={
                            <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}

            <Controller
              name="carrier_booking_date"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelect
                  inputName="carrier_booking_date"
                  className="form_control"
                  parentClassName="datepicker-w-auto one-month-datepicker w-full"
                  label="Carrier Booking Date"
                  selected={value}
                  onChangeFunc={(e: any) => onChange(e?.value)}
                  minDate={moment().toDate()}
                  labelClassName="block"
                  placeholder="Select Date"
                  dateFormat="dd/MM/yyyy"
                  errorText={error?.message}
                  icon={<Calendar className="h-5 w-5" />}
                  value={getDateWithSuffixFormat(getFormattedPickupDate(value))}
                />
              )}
            />
          </div>
        </FormProvider>
      </>
    </CommonModal>
  );
};

export default FormModal;
