import {} from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
  // goalTarget,
} from 'src/utils/CommonFunctions';

import { IRowProps } from './Target.interface';

const TargetRow = ({ data }: IRowProps) => (
  <>
    <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <div className="flex items-center gap-3">
        <div className="flex-none">
          {data.image ? (
            <img
              className="table-profile-icn-circle-xl "
              src={data.imageUrl + data.image}
              alt=""
              title=""
            />
          ) : (
            <div className="table-profile-customer-circle-xl">
              {getShortName(`${data.userName}`)}
            </div>
          )}
        </div>
        <div className="w-32">
          <h6 className="text-grayLight900 font-semibold leading-5 truncate">
            {data.userName.length > 20 ? (
              <TooltipCmp message={data.userName}>{data.userName}</TooltipCmp>
            ) : (
              data.userName
            )}
          </h6>
          {/* <p className="truncate">Account Executive</p> */}
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span>
        ${getFormattedNumber(data.completedProfit, false, false, true)}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span>${getFormattedNumber(data.targetProfit, false, false, true)}</span>
    </td>

    <td className="px-5 py-4 min-w-[200px] w-[12%]">
      <span
        className={`${getProgressClass(data.completedProfitPercentage, false)}`}
      >
        {data.completedProfitPercentage}%
      </span>
      {/* <TooltipCmp
        message={
          data.completedProfitPercentage >= 100
            ? `${data.completedProfitPercentage}%`
            : ''
        }
      >
        <div
          className={`radial-progress text-[10px] ${getProgressClass(
            data.completedProfitPercentage,
            false
          )}`}
          style={
            {
              '--value': data.completedProfitPercentage,
              '--size': '2rem',
            } as React.CSSProperties & { '--value': number }
          }
          role="progressbar"
        >
          {data.completedProfitPercentage >= 100 ? (
            <>
              {' '}
              <Check className="w-4 h-4" />
            </>
          ) : (
            <span className="text-grayLight900 font-medium">
              {data.completedProfitPercentage}%
            </span>
          )}

          <div
            className="target-div"
            style={
              {
                '--value': goalTarget(),
              } as React.CSSProperties & { '--value': number }
            }
          ></div>
        </div>
      </TooltipCmp> */}
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.completedLoads}</span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.targetLoads}</span>
    </td>
    <td className="px-5 py-4 min-w-[200px] w-[12%]">
      <span
        className={`${getProgressClass(data.completedLoadsPercentage, false)}`}
      >
        {data.completedLoadsPercentage}%
      </span>
      {/* <TooltipCmp
        message={
          data.completedLoadsPercentage >= 100
            ? `${data.completedLoadsPercentage}%`
            : ''
        }
      >
        <div
          className={`radial-progress text-[10px]  ${getProgressClass(
            data.completedLoadsPercentage,
            false
          )}`}
          style={
            {
              '--value': data.completedLoadsPercentage,
              '--size': '2rem',
            } as React.CSSProperties & { '--value': number }
          }
          role="progressbar"
        >
          {data.completedLoadsPercentage >= 100 ? (
            <>
              <Check className="w-4 h-4" />
            </>
          ) : (
            <span className="text-grayLight900 font-medium">
              {data.completedLoadsPercentage}%
            </span>
          )}

          <div
            className="target-div"
            style={
              {
                '--value': goalTarget(),
              } as React.CSSProperties & { '--value': number }
            }
          ></div>
        </div>
      </TooltipCmp> */}
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.customersCount}</span>
    </td>
    {/* <td className="px-5 py-4">
      <div className="flex gap-3 justify-center">
        <Link
          to={`${PATH.TARGET_SET}`}
          className="text-primary text-xs font-semibold underline decoration-1"
          onClick={() => {
            localStorage.setItem(
              TABLE_IDS.TARGET_LIST_BY_CUSTOMER,
              JSON.stringify({
                ...initParams,
                userId: data.userId,
                targetMonth,
              })
            );
          }}
        >
          <div className="px-3 text-primary text-xs font-semibold">
            {data.targetCustomersCount ? 'Edit' : 'Set'}
          </div>
        </Link>
      </div>
    </td> */}
  </>
);

export default TargetRow;
