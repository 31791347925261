import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import { PackagePlus } from 'lucide-react';
import moment from 'moment';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
// import ButtonCmp from 'src/components/ButtonCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

import ServiceDetail from './ServiceDetail';

interface ReqQuotedRowProps {
  data: any;
  requestSpotQuote: any;
  currency: string;
  currentUser: any;
  handleActionType: any;
  selectedCarrierRates: any;
  allChecked: boolean;
  setAllChecked: any;
  addressDetails: any;
}

const ReqQuotedRow: React.FC<ReqQuotedRowProps> = ({
  data,
  // requestSpotQuote,
  currency,
  currentUser,
  handleActionType,
  // selectedCarrierRates,
  // allChecked,
  // setAllChecked,
  addressDetails,
}) => (
  <>
    <td className="px-5 py-4">
      <div className="flex items-center">
        {data.image ? (
          <img
            src={`${data.imageUrl}${data.image}`}
            className="table-profile-icn-circle-xl rounded-md"
            alt={data.name}
            title={data.name}
            onError={onError}
          />
        ) : (
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(`${data.name}`)}
          </div>
        )}

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.name}
          </h6>
          <p className="text-grayLight600 font-normal leading-tight">
            <span className="inline align-middle">{data.email}</span>
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">-</td>
    <td className="px-5 py-4">
      <span
        dangerouslySetInnerHTML={{
          __html: data.projectedDeliveryDate
            ? getDateWithSuffixFormat(
                moment.utc(data.projectedDeliveryDate).format(`MMM Do YYYY`)
              )
            : '-',
        }}
      />
    </td>
    <td className="px-5 py-4">
      {data.totalCharge === 0
        ? '-'
        : currency === CURRENCY.CAD
        ? `$${data.totalCharge.toFixed(2)}`
        : `$${data.totalChargeUSD.toFixed(2)}`}
    </td>
    {data.additionalCharges > 0 ? (
      <td className="px-5 py-4">
        <ActionTooltip
          openOnHover={true}
          tooltipPosition="center"
          isOpenChildTopPosOnTop={true}
          type="dark"
          message={<ServiceDetail carrierServices={data} />}
        >
          {data.additionalCharges === 0
            ? '-'
            : currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.additionalCharges)}`
            : `$${getFormattedNumber(data.additionalChargesUSD)}`}
        </ActionTooltip>
      </td>
    ) : (
      <td className="px-5 py-4">
        {data.additionalCharges === 0
          ? '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.additionalCharges)}`
          : `$${getFormattedNumber(data.additionalChargesUSD)}`}
      </td>
    )}
    <td className="px-5 py-4">
      {data.carrierQuoteId
        ? currency === CURRENCY.CAD
          ? `$${data.finalCharge.toFixed(2)}CAD`
          : `$${data.finalChargeUSD.toFixed(2)}USD`
        : '-'}
    </td>
    {/* <td className="px-5 py-4">
      {process.env.REACT_APP_MODE !== 'production' &&
        (currentUser.doNotAskSpotQuote ? (
          <ButtonCmp
            type="submit"
            className={`btn_secondary_black`}
            onClick={() =>
              requestSpotQuote(data.id, currentUser.doNotAskSpotQuote)
            }
            disabled={data.isSpotRequestSent}
          >
            {data.isSpotRequestSent ? 'Request Sent' : 'Request Spot'}
          </ButtonCmp>
        ) : data.isSpotRequestSent ? (
          <ButtonCmp
            className={`btn-outline-primary w-full`}
            onClick={() => handleActionType('addQuoteDetail', '', data)()}
          >
            Add Quote Details
          </ButtonCmp>
        ) : (
          <ButtonCmp
            type="submit"
            className={`btn_secondary_black w-full ${
              data.isSpotRequestSent ? 'bg-opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={() =>
              handleActionType('spotQuoteConfirmation', 'view', data)()
            }
            disabled={data.isSpotRequestSent}
          >
            {data.isSpotRequestSent ? 'Request Sent' : 'Request Spot'}
          </ButtonCmp>
        ))}
    </td> */}
    {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
      <td className={`px-5 py-4 w-[128px] max-w-[128px] min-w-[128px]`}>
        {data?.spotQuoteEmail &&
          process.env.REACT_APP_MODE !== 'production' &&
          (currentUser.doNotAskSpotQuote ? (
            <div className="flex justify-end gap-3 ">
              <ArrowUp className="w-4 h-4 text-primary" />
            </div>
          ) : data.isSpotRequestSent ? (
            <div className="flex justify-end gap-3">
              <PackagePlus
                className="w-4 h-4"
                onClick={() => handleActionType('addQuoteDetail', '', data)()}
              />
              {/* <CheckCircleBroken className="text-success" /> */}
            </div>
          ) : (
            <div className="flex justify-end gap-3">
              <ArrowUp className="w-4 h-4 text-primary" />
            </div>
          ))}
      </td>
    )}
    {/* {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
      <td className="px-5 py-4">
        {data?.spotQuoteEmail &&
          process.env.REACT_APP_MODE !== 'production' &&
          (currentUser.doNotAskSpotQuote ? (
            <CheckBox
              // checked={
              //   selectedCarrierRates.some((form: any) => form.id === data.id) ||
              //   allChecked
              // }
              // onChangeFunc={handleActionType('spotquoteConfirmation', 'view', data)}
              onChangeFunc={() => {}}
              parentClassName=""
            />
          ) : data.isSpotRequestSent ? (
            <div className="flex gap-2">
              <PackagePlus
              // onClick={() => handleActionType('addQuoteDetail', '', data)()}
              />
              {/* <CheckCircleBroken className="text-success" /> */}
    {/* </></div>
          ) : (
            <CheckBox
              // checked={
              //   selectedCarrierRates.some((form: any) => form.id === data.id) ||
              //   allChecked
              // }
              // onChangeFunc={handleActionType('spotquoteConfirmation', 'view', data)}
              onChangeFunc={() => {}}
              parentClassName=""
            />
          ))} 
      </td>
    )} */}
  </>
);

export default ReqQuotedRow;
