import {
  ArrowLeft,
  CreditCard01,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import TableCmp from 'src/components/TableCmp';
import { ONBOARDING_CUSTOMER_STATUS, orgTypeArr } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import {
  businessCreditApplicationDetail,
  deleteStatementFile,
  linkCompanyToCreditSafe,
  updateOnboarding,
} from 'src/services/CustomerOnBoardingService';
import {
  getFormattedDate,
  getLabelByValue,
  getShortName,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import AccountsOnbording from '../../CustomerOnboarding/AccountsOnbording';
import BankOnboarding from '../../CustomerOnboarding/BankOnboarding';
import BusinessOnboarding from '../../CustomerOnboarding/BusinessOnboarding';
import CompanyInfoOnboarding from '../../CustomerOnboarding/CompanyInfoOnboarding';
import CreditOnboarding from '../../CustomerOnboarding/CreditOnboarding';
import OfficersOnboarding from '../../CustomerOnboarding/OfficersOnboarding';
import OverviewOnboarding from '../../CustomerOnboarding/OverviewOnboarding';
import StatementOnboarding from '../../CustomerOnboarding/StatementOnboarding';
import TraderOnboarding from '../../CustomerOnboarding/TraderOnboarding';
import NextTasks from '../NextTasks';

import AddNotes from './AddNotes';
import AttachmentModal from './AttachmentModal';
import CreditLimitModal from './CreditLimitModal';
import CustomerCreditInfo from './CustomerCreditInfo';
import LinkCompanyToCreditSafeModal from './LinkCompanyToCreditSafeModal';

// import Header from 'src/components/PageSectionLayout/Header/Header';

const initReviewDetail = {
  personal_detail: {},
  accounts_payable: {},
  overview: {},
  business_address: {},
  company_info: {},
  officers: {},
  creditReferences: {},
  tradeReferences: {},
  bank_info: {},
  financeStatement: {},
  creditLimitInfo: {},
  creditSafeCompanyList: [],
};

const initAction = {
  mode: '',
  id: 0,
};

const CreditDashboardDetail = () => {
  const { id } = useParams<{ id: any }>();

  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const [reviewDetail, setReviewDetail] = useState(initReviewDetail);
  const [action, setAction] = useState(initAction);
  const [personalDetail, setPersonalDetail] = useState<any>(null);

  const [creditSafeToken, setCreditSafeToken] = useState();
  const [isShowInitialCreditLimit, setIsShowInitialCreditLimit] =
    useState(false);
  const [isCreditLimitDetailLoading, setIsCreditLimitDetailLoading] =
    useState(false);
  const [selectedCreditSafeCompanyId, setSelectedCreditSafeCompanyId] =
    useState(undefined);
  const [
    isShowLinkCustomerToCreditSafeModal,
    setIsShowLinkCustomerToCreditSafeModal,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const StatementHeadCells = useMemo(
    () => [
      {
        id: 'file_name',
        name: 'File name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'year',
        name: 'Year',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'quarter',
        name: 'Quarter',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'action',
        name: '',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const OverviewHeadCells = useMemo(
    () => [
      {
        id: 'organization_name',
        name: 'Organization Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'date',
        name: 'Date',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'fax',
        name: 'Fax',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'email',
        name: 'Email',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const BusinessHeadCells = useMemo(
    () => [
      {
        id: 'city',
        name: 'City',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'state',
        name: 'State',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'postal',
        name: 'Postal',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'country',
        name: 'Country',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const CompanyInfoHeadCells = useMemo(
    () => [
      {
        id: 'organization_type',
        name: 'Organization Type',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'business_started_date',
        name: 'Business Started Date',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'federal_tax_id_number',
        name: 'Federal Tax ID Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'type_of_business',
        name: 'Type of Business',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'dnb_number',
        name: 'D&B (DUNS) Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const AccountsHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'email',
        name: 'Email',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const OfficersHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'title',
        name: 'Title',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'address',
        name: 'Address',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'city_state_zip',
        name: 'City/State/Zip',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );
  const BankHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'bank_contact_name',
        name: 'Bank Contact Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'email',
        name: 'Email',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'address',
        name: 'Address',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );
  const CreditHeadCells = useMemo(
    () => [
      {
        id: 'company_name',
        name: 'Company Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'email',
        name: 'Email',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'credit_amount',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
        name: 'Credit Amount',
      },
      {
        id: '#',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );
  const TraderHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'city',
        name: 'City',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'email',
        name: 'Email',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
      {
        id: 'extra',
        rowClassName: 'w-[20%] max-w-[20%] min-w-[20%]',
      },
    ],
    []
  );

  const getDetail = () => {
    businessCreditApplicationDetail(null, id)
      .then((response: any) => {
        setIsLoading(true);

        if (response) {
          const orgArr =
            response?.data?.company_info?.organization_type?.split(',');
          const orgNameArr = orgArr?.map((val: any) =>
            getLabelByValue(val, orgTypeArr)
          );

          setReviewDetail({
            ...response?.data,
            company_info: {
              ...response?.data?.company_info,
              organization_type: orgNameArr ? orgNameArr?.join(', ') : '', // assuming orgNameArr is already defined elsewhere
            },
          });
          console.log(
            'response?.data?.personal_detail',
            response?.data?.personal_detail
          );
          setPersonalDetail(response?.data?.personal_detail);
          setSelectedCreditSafeCompanyId(
            response?.data?.personal_detail?.credit_safe_companyId
          );
          setIsLoading(false);
        }
      })
      .catch(() =>
        WalToast.error('something went wrong please try again later..!!')
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteStatementFile(action?.id)
          .then(() => {
            getDetail();
            WalToast.success('Statement Deleted successfully');
          })
          .catch((e: any) =>
            WalToast.error(
              e?.response?.data?.message ??
                'something went wrong please try again'
            )
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
      setAction(initAction);
    },
    [action]
  );

  const updateStatus = (status: string) => {
    updateOnboarding({ id: id, status: status })
      .then(() => {
        getDetail();

        if (status === 'Approved') {
          setAction((old: any) => ({
            ...old,
            ...{ mode: 'next-task', id: id },
          }));
        }
      })
      .catch(() => WalToast.error('something went wrong please try again'));
  };

  const linkCompany = (isUpdate?: boolean) => {
    setIsCreditLimitDetailLoading(true);
    linkCompanyToCreditSafe(selectedCreditSafeCompanyId, {
      isUpdate: isUpdate,
      id: id,
      creditSafeToken: creditSafeToken,
    })
      .then((response: any) => {
        setIsShowLinkCustomerToCreditSafeModal(false);
        setIsCreditLimitDetailLoading(false);

        setPersonalDetail((old: any) => ({
          ...old,
          credit_safe_companyId: response?.data?.companyId,
        }));
        setReviewDetail((old: any) => ({
          ...old,

          creditLimitInfo: response?.data,
        }));
      })
      .catch((e: any) => {
        setIsCreditLimitDetailLoading(false);
        console.error(e);
        WalToast.error('Something went wrong while fetching task list');
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <PageSectionLayout
      header={
        <>
          {/* <Header
          title="Credit Dashboard"
          desc="Manage, segment and view your customers here."
        /> */}
        </>
      }
      contentClassName="!p-0"
      headerClassName="!p-0"
    >
      <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[102px] before:h-[70px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
        <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
          <div
            className="flex items-center cursor-pointer gap-1.5"
            onClick={() => {
              navigate(ROUTES.CREDIT_DASHBOARD);
            }}
          >
            <ArrowLeft className="h-4 w-4" />
            <span className="text-grayLight600 text-xs font-semibold">
              Back to Credit Dashboard{' '}
            </span>
          </div>
          <div
            className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer"
            onClick={() => {
              navigate(ROUTES.CREDIT_DASHBOARD);
            }}
          >
            <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
          </div>
        </div>
        <div className=" xlm:mx-8 mx-5 relative xl:pt-[10px] sm:pt-2 pt-[26px] pb-[26px] flex max-sm:flex-col sms:gap-4 gap-2 sm:items-end justify-between  border-b border-utilityGray200">
          <div className="flex ms:gap-4 gap-2 smd:items-end w-full">
            <label
              className={`relative [&>div:nth-child(2)]:opacity-0 hover:[&>div:nth-child(2)]:opacity-100 xlm:w-36 xl:w-36 mdm:w-28 sm:w-24 w-16 xlm:h-36 xl:h-36 mdm:h-28 sm:h-24 h-16 rounded-xl overflow-hidden justify-center items-center flex border-4 border-white bg-utilityGray100 shadow-lg text-primary xl:text-6xl sm:text-4xl sms:text-2xl text-base font-semibold tracking-[-1.2px] flex-none ${
                isLoading ? 'custom-loading custom-loading-image' : ''
              }`}
            >
              {/* <img
              className="w-full h-full relative "
              src={}
              alt="House Team"
              title="House Team"
            /> */}
              {/* <span>OR</span> */}
              {personalDetail?.image && personalDetail?.imageUrl ? (
                <img
                  className="w-full h-full relative rounded-xl object-contain"
                  src={`${personalDetail?.imageUrl}${personalDetail?.image}`}
                />
              ) : (
                <div>
                  {personalDetail?.customer_name
                    ? getShortName(personalDetail?.customer_name)
                    : ''}
                </div>
              )}
            </label>

            <div className="smd:flex-1 flex smd:flex-row flex-col justify-between sm:mt-2 gap-2 smd:items-end">
              {/* <div className="flex justify-between"> */}
              <div className="">
                <div className="flex gap-1 items-center">
                  <h6
                    className={`mr-3 text-grayLight900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]  ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {personalDetail?.customer_name}
                  </h6>
                  <div className={isLoading ? 'custom-loading' : ''}>
                    {!isLoading && (
                      <>
                        {personalDetail &&
                          personalDetail?.status ===
                            ONBOARDING_CUSTOMER_STATUS.APPROVED && (
                            <BadgeCmp style="modern" type="success">
                              Approved
                            </BadgeCmp>
                          )}

                        {personalDetail &&
                          personalDetail?.status ===
                            ONBOARDING_CUSTOMER_STATUS.FORM_PENDING && (
                            <BadgeCmp style="modern" type="info">
                              Form Pending
                            </BadgeCmp>
                          )}
                        {personalDetail &&
                          personalDetail?.status ===
                            ONBOARDING_CUSTOMER_STATUS.IN_REVIEW && (
                            <BadgeCmp style="modern" type="info">
                              In Review
                            </BadgeCmp>
                          )}
                        {personalDetail &&
                          personalDetail?.status ===
                            ONBOARDING_CUSTOMER_STATUS.FORM_COMPLETED && (
                            <BadgeCmp style="modern" type="primary">
                              Form Completed
                            </BadgeCmp>
                          )}
                        {personalDetail &&
                          personalDetail?.status ===
                            ONBOARDING_CUSTOMER_STATUS.DENIED && (
                            <BadgeCmp style="modern" type="error">
                              Denied
                            </BadgeCmp>
                          )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={`flex mt-1 ${isLoading ? 'custom-loading' : ''}`}
                >
                  <span className="text-grayLight600 sm:text-sm text-xs sm:mt-0 mt-0.5 font-medium">
                    {`${personalDetail?.address || ''} ${
                      personalDetail?.city || ''
                    } ${personalDetail?.state || ''} ${
                      personalDetail?.postal || ''
                    } ${personalDetail?.country || ''} `}
                  </span>
                </div>
                <div className="flex gap-1.5 items-center mt-2">
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <span className="text-xs text-grayLight900 font-medium">
                    {personalDetail?.form_view_date
                      ? `Viewed on: ${getFormattedDate(
                          personalDetail.form_view_date,
                          'MMM Do, hh:mm:ss A',
                          true,
                          true
                        )}`
                      : 'Not Reviewed'}
                  </span>
                </div>
              </div>

              <div className="flex max-xls:flex-col max-xls:items-end gap-2 smd:pt-2 flex-none">
                {!isLoading &&
                  personalDetail?.status !==
                    ONBOARDING_CUSTOMER_STATUS.APPROVED &&
                  personalDetail?.status !==
                    ONBOARDING_CUSTOMER_STATUS.DENIED && (
                    <div className="flex gap-2">
                      {personalDetail?.status !==
                        ONBOARDING_CUSTOMER_STATUS.NEW &&
                        (hasRoleV2('admin') ||
                          hasRoleV2('manger') ||
                          hasRoleV2('finance')) && (
                          <>
                            <ButtonCmp
                              type="button"
                              className="btn_primary "
                              onClick={() => {
                                setIsShowInitialCreditLimit(true);
                                // updateStatus(
                                //   ONBOARDING_CUSTOMER_STATUS.APPROVED
                                // )
                              }}
                            >
                              Approve
                            </ButtonCmp>
                            <ButtonCmp
                              type="submit"
                              className="btn_secondary_black"
                              onClick={() =>
                                updateStatus(ONBOARDING_CUSTOMER_STATUS.DENIED)
                              }
                            >
                              Decline
                            </ButtonCmp>
                          </>
                        )}
                      {/* {!personalDetail?.credit_safe_companyId &&
                        personalDetail?.status !== 'New' && (
                          <div className="z-[0] relative">
                            <ButtonCmp
                              onClick={() =>
                                setIsShowLinkCustomerToCreditSafeModal(true)
                              }
                              className=""
                            >
                              Request to link for credit
                            </ButtonCmp>
                          </div>
                        )} */}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-[75%] mt-[30px] xlm:px-8 px-5 pb-5">
            <div className="flex flex-col gap-8 ">
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Overview
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={OverviewHeadCells}
                        tableDataArr={[reviewDetail?.overview]}
                        isTableDataLoading={isLoading}
                        TableRowCmp={OverviewOnboarding}
                      />
                    </div>
                    {!reviewDetail?.overview ||
                    personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW ? (
                      <NotFoundUI
                        title="No Overview found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Business Address
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={BusinessHeadCells}
                        tableDataArr={[reviewDetail?.business_address]}
                        TableRowCmp={BusinessOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Business Address found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Company Information
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={CompanyInfoHeadCells}
                        tableDataArr={[reviewDetail?.company_info]}
                        TableRowCmp={CompanyInfoOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Company Information found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Accounts Payable Contact
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={AccountsHeadCells}
                        tableDataArr={[reviewDetail?.accounts_payable]}
                        TableRowCmp={AccountsOnbording}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Accounts Payable Contact found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Officers & Owners
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={OfficersHeadCells}
                        tableDataArr={
                          reviewDetail?.officers ? reviewDetail.officers : []
                        }
                        TableRowCmp={OfficersOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Officers & Owners found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Bank Information
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={BankHeadCells}
                        tableDataArr={[reviewDetail?.bank_info]}
                        TableRowCmp={BankOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Bank Information found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Credit References
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={CreditHeadCells}
                        tableDataArr={reviewDetail.creditReferences}
                        tableRowCmpProps={{
                          setAction: setAction,
                        }}
                        TableRowCmp={CreditOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Credit References found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Trader References
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={TraderHeadCells}
                        tableDataArr={reviewDetail.tradeReferences}
                        TableRowCmp={TraderOnboarding}
                      />
                    </div>
                    {personalDetail?.status ===
                      ONBOARDING_CUSTOMER_STATUS?.NEW && (
                      <NotFoundUI
                        title="No Trader References found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Supporting Financial Statements
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={StatementHeadCells}
                        tableDataArr={reviewDetail?.financeStatement}
                        tableRowCmpProps={{
                          setAction: setAction,
                        }}
                        TableRowCmp={StatementOnboarding}
                      />
                    </div>
                    {!reviewDetail.financeStatement ? (
                      <NotFoundUI
                        title="No financial statement found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    ) : (
                      <NotFoundUI
                        title="No financial statement found"
                        desc="Currently, no data has been found for this section"
                        containerClassName="!h-auto !my-6"
                        contentClassName="before:!bg-none"
                        iconClassName="!hidden"
                      />
                    )}
                  </div>
                </div>
                {action.mode === 'delete' && (
                  <DeleteModal
                    moduleName="Fianance Statement"
                    handleClose={handleModalClose}
                  />
                )}

                {action.mode === 'add-attachment' && (
                  <AttachmentModal
                    creditReferenceId={action.id}
                    // setAction={setAction}
                    // action={action}
                    handleClose={() => setAction(initAction)}
                  />
                )}
                {action.mode === 'add-notes' && (
                  <AddNotes
                    creditReferenceId={action.id}
                    handleClose={() => setAction(initAction)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-[25%] mt-[30px] xlm:px-8 px-5 pb-5">
            {personalDetail?.credit_safe_companyId ? (
              <CustomerCreditInfo
                creditLimitDetail={reviewDetail?.creditLimitInfo}
                isCreditLimitDetailLoading={isCreditLimitDetailLoading}
              />
            ) : (
              <div className="border border-utilityGray200 rounded-md">
                {reviewDetail?.creditSafeCompanyList?.length > 0 ? (
                  reviewDetail?.creditSafeCompanyList?.map(
                    (value: any, index: any) => (
                      <p
                        className="text-xs text-grayLight900 font-medium p-2 border-b border-utilityGray200"
                        key={index}
                      >
                        {value?.creditSafeId}
                      </p>
                    )
                  )
                ) : (
                  <NotFoundUI
                    title="Not Found credit request link"
                    desc="There are no data for carriers."
                    icon={<CreditCard01 className="w-5 h-5 " />}
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                    buttonText="Request to link for credit"
                    buttonClassName="!btn_secondary_black"
                    handleActionType={() =>
                      setIsShowLinkCustomerToCreditSafeModal(true)
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {action.mode === 'next-task' && (
          <NextTasks handleClose={() => setAction(initAction)} />
        )}
        {isShowLinkCustomerToCreditSafeModal && (
          <LinkCompanyToCreditSafeModal
            customerId={id}
            selectedCreditSafeCompanyId={selectedCreditSafeCompanyId}
            setSelectedCreditSafeCompanyId={setSelectedCreditSafeCompanyId}
            isCreditLimitDetailLoading={isCreditLimitDetailLoading}
            linkCompany={linkCompany}
            setCreditSafeToken={setCreditSafeToken}
            handleClose={() => setIsShowLinkCustomerToCreditSafeModal(false)}
          />
        )}
      </div>
      {isShowInitialCreditLimit && (
        <CreditLimitModal
          customerId={id}
          handleClose={() => setIsShowInitialCreditLimit(false)}
        />
      )}
    </PageSectionLayout>
  );
};

export default CreditDashboardDetail;
