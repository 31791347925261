import React from 'react';
import ReactApexChart from 'react-apexcharts';

const QuotesGraph = () => {
  const options: any = {
    chart: {
      type: 'donut',
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },

    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    parentHeightOffset: 0,
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              color: '#333',
              offsetY: -10,
              formatter: () => 'Total Requests',
            },
            value: {
              fontSize: '24px',
              color: '#333',
              offsetY: 5,
              formatter: (val: any) => `${parseInt(val)}`,
            },
            total: {
              show: true,
              label: 'Total Requests',
              fontSize: '16px',
              formatter: () => 1025, // Display the total value in the center
            },
          },
        },
      },
    },
    colors: [
      '#000f89',
      '#444CE7',
      '#6172F3',
      '#8098F9',
      '#A4BCFD',
      '#C7D7FE',
      '#EAECF0',
    ],
    labels: ['LTL', 'Private', 'FTL', 'Dismiss', 'Other', 'Outstanding'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '148px',
            height: '148px',
          },
        },
      },
    ],
    tooltip: {
      // Your tooltip settings
    },
    dataLabels: {
      enabled: false, // This line disables the percentage display
    },
  };

  const colors = options.colors;
  const labels = options.labels;
  const seriesData = [12, 15, 25, 12, 15, 25]; // Your series data

  const data = labels.map((label: any, index: number) => ({
    name: label,
    count: seriesData[index],
    color: colors[index],
  }));

  return (
    <div className="flex items-center justify-around p-5 border border-gray-200 rounded-lg w-full gap-3">
      <div id="chart" className="w-[30%]">
        <ReactApexChart
          options={options}
          series={seriesData}
          type="donut"
          height={'148px'}
          width={'148px'}
        />
      </div>
      <div className="grid grid-cols-3 gap-3">
        {data.map((item: any, index: number) => (
          <div key={index} className="">
            <div className="flex items-center   gap-1">
              <span
                className="w-2 h-2 rounded-full "
                style={{ backgroundColor: item.color }}
              ></span>
              <span className=" ">{item.name}</span>
            </div>
            <span className="ml-3 ">{item.count}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuotesGraph;
