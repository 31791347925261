import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { NotificationsContext } from 'src/context/NotificationsContext';
import {
  deleteClaimDisputeTask,
  getClaimDisputeTaskList,
} from 'src/services/ClaimAndDisputeService';
import WalToast from 'src/utils/WalToast';

import ActionItemsLoadingRow from './ActionItemsLoadingRow';
import ActionItemsRowCmp from './ActionItemsRowCmp';
import CreateUpdateTaskModal from './CreateUpdateTaskModal';

interface IProps {
  claimId?: number;
  disputeId?: number;
  claim?: any;
  dispute?: any;
  userOptions: any;
}

export const initParams = {
  sortType: 'desc',
  sortField: 'taskName',
};

const ActionItem = ({
  claimId,
  disputeId,
  claim,
  dispute,
  userOptions,
}: IProps) => {
  const { entityType, entityId, setEntityType } =
    useContext(NotificationsContext);
  const [params, setParams] = useState(initParams);
  const [claimDisputeTask, setClaimDisputeTask] = useState<any>([]);
  const [isClaimDisputeTaskLoading, setIsClaimDisputeTaskLoading] =
    useState(false);
  const [claimDisputeTotal, setClaimDisputeTotal] = useState<any>([]);
  const [isShowAddTaskModal, setIsShowAddTaskModal] = useState(false);
  const [selectedActionItemForEdit, setSelectedActionItemForEdit] =
    useState<any>(undefined);

  const getClaimDisputeTask = () => {
    setIsClaimDisputeTaskLoading(true);
    setClaimDisputeTask([]);

    getClaimDisputeTaskList({
      ...params,
      claimID: claimId!,
      disputeID: disputeId!,
    })
      .then((response: any) => {
        if (response && response.claimDisputeActions) {
          const responseData = response.claimDisputeActions;
          setClaimDisputeTask(responseData);
          setClaimDisputeTotal(response?.total ?? 0);
          setIsClaimDisputeTaskLoading(false);
        }
      })
      .catch((e: any) => {
        console.error(e);
        WalToast.error('Something went wrong while fetching task list');
      });
  };

  useEffect(() => {
    getClaimDisputeTask();
  }, [params]);

  const actionHeadCells = useMemo(
    () => [
      {
        id: 'taskName',
        name: 'Task Name',
        sortable: true,
      },
      {
        id: 'progressStatus',
        name: 'Status',
        sortable: true,
      },
      {
        id: 'assignedto',
        name: 'Assigned To',
        sortable: true,
      },

      {
        id: 'dueDate',
        name: 'Due Date',
        sortable: true,
      },

      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  useEffect(() => {
    if (
      (entityType === 'claim_action' && entityId === claimId) ||
      (entityType === 'dispute_action' && entityId === disputeId)
    ) {
      getClaimDisputeTask();
      setEntityType(null);
    }
  }, [entityType, entityId]);

  const updateTaskToList = (updatedTask: any) => {
    const updatedTasks = claimDisputeTask.map((cdTask: any) =>
      cdTask.id === updatedTask.id ? { ...cdTask, ...updatedTask } : cdTask
    );
    setClaimDisputeTask(updatedTasks);
  };

  const removeTaskFromList = (deletedTaskId: any) => {
    const updatedTasks = claimDisputeTask
      .map((cdTask: any) => (cdTask.id === deletedTaskId ? false : cdTask))
      .filter((e: any) => e);
    setClaimDisputeTask(updatedTasks);
    setClaimDisputeTotal((oldValue: number) => oldValue - 1);
  };

  const handleDeleteTaskFromList = (deleteTaskId: any) => {
    deleteClaimDisputeTask(deleteTaskId)
      .then(() => {
        // WalToast.success(`Task deleted successfully`);
        removeTaskFromList(deleteTaskId);
      })
      .catch((e) => {
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong while deleting task.'
        );
      });
  };

  const handleEditTaskFromList = (taskData: any) => {
    setSelectedActionItemForEdit(taskData);
    setIsShowAddTaskModal(true);
  };

  return (
    <>
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Action Items</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${
                  isClaimDisputeTaskLoading ? 'custom-loading' : ''
                }`}
              >
                {claimDisputeTotal} {claimDisputeTotal > 1 ? 'tasks' : 'task'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {`View and create actions items that must be completed to resolve the 
                ${claim ? 'claim' : ''}${dispute ? 'dispute' : ''}.
              `}
            </p>
          </div>
          <ButtonCmp
            className=" btn-outline-primary"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => setIsShowAddTaskModal(true)}
          >
            Add task
          </ButtonCmp>
        </div>
        <div className="h-full w-full border-t border-gray100 flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={actionHeadCells}
              tableDataArr={claimDisputeTask}
              TableLoaderRowCmp={ActionItemsLoadingRow}
              TableRowCmp={ActionItemsRowCmp}
              numberOfSkeletonRows={3}
              tableRowCmpProps={{
                handleDeleteTaskFromList: handleDeleteTaskFromList,
                handleEditTaskFromList: handleEditTaskFromList,
                updateTaskToList: updateTaskToList,
              }}
              params={params}
              setParams={setParams}
              isTableDataLoading={isClaimDisputeTaskLoading}
            />
          </div>
          {claimDisputeTask.length <= 0 && !isClaimDisputeTaskLoading && (
            <NotFoundUI
              title={'No action items found'}
              desc={`There are no action items added`}
              containerClassName="!h-auto !my-6 flex-1"
              iconClassName="!hidden"
            />
          )}
        </div>
      </div>

      {isShowAddTaskModal && (
        <CreateUpdateTaskModal
          handleClose={() => {
            setIsShowAddTaskModal(false);
            setSelectedActionItemForEdit(undefined);
          }}
          userOptions={userOptions}
          getClaimDisputeTask={getClaimDisputeTask}
          claimID={claimId}
          disputeID={disputeId}
          selectedActionItemForEdit={selectedActionItemForEdit}
        />
      )}
    </>
  );
};

export default ActionItem;
