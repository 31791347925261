import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import defaultImage from '../../../../assets/img/default-image.jpg';

const salesPriceRow = () => {
  console.log();

  return (
    <>
      <td className="px-5 py-4 w-[23.86%] max-w-[1px] min-w-[23.86%]">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            <img className="table-profile-icn-circle-xl" src={defaultImage} />

            {/* IF NO IMAGE FOUND BELOW COMMENTED CODE WILL BE USED */}
            {/* <div className="table-profile-customer-circle-xl">WA</div> */}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              Olivia Rhye
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              USD Billing Account
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>$474.71</span>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>$210.03</span>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>$901.06</span>
      </td>
      <td className="px-5 py-4 w-[56px] min-w-[56px] max-w-[56px]">
        <Edit01 className="w-4 h-4 cursor-pointer" />
      </td>
    </>
  );
};

export default salesPriceRow;
