import _ from 'lodash';
import mapboxgl, { SourceSpecification } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchBox from 'src/components/SearchBox';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { CustomerList } from 'src/services/CustomerService';
import { getPopulationData } from 'src/services/GeographyService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetter,
  getSelectBoxOptions,
  getTime,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import defaultImage from '../../../assets/img/default-image.jpg';

import CustomerFilterModal from './CustomerFilterModal';

const initParams: any = {
  segment: '',
  team: 'all',
  member: 'all',
};

const MapBox = ({
  setCustomerId,
  setHasSearched,
  setSearchResult,
  isDisplayCustomers,
  isDisplayDetail,
  setIsDisplayDetail,
  mapFilters,
  setMapFilters,
  setAllRoutes,
  routeFilters,
  allRoutes,
  hasSearched,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const { hasRoleV2 } = useRolePermission();

  // const googleMapsApiKey = window?.GOOGLE_API_KEY;
  // const [fromLong, setFromLong] = useState(0);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  // const [fromLat, setFromLat] = useState(0);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [customers, setCustomers] = useState<any>();
  const [params, setParams] = useState(initParams);
  const [customerMarkerPin, setCustomerMarkerPin] = useState<any>([]);
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);
  // const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        setMapLoaded(true);
        const layers = mapRef.current?.getStyle()?.layers;
        const labelLayer = layers?.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        );

        if (labelLayer && mapRef.current) {
          mapRef.current.addLayer(
            {
              id: 'add-3d-buildings',
              source: 'composite',
              'source-layer': 'building',
              filter: ['==', 'extrude', 'true'],
              type: 'fill-extrusion',
              minzoom: 15,
              paint: {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height'],
                ],
                'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
            },
            labelLayer.id
          );
        }

        if (mapRef.current) {
          mapRef.current.addSource('points', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-77.03238901390978, 38.913188059745586],
                  },
                  properties: {
                    title: 'Mapbox DC',
                  },
                },
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-122.414, 37.776],
                  },
                  properties: {
                    title: 'Mapbox SF',
                  },
                },
              ],
            },
          });
        }
      });
    }
  }, []);

  // Function to calculate distance between two points using Haversine formula
  const getDistance = async (
    point1: [number, number],
    point2: [number, number]
  ): Promise<number> => {
    const [lon1, lat1] = point1;
    const [lon2, lat2] = point2;

    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${lon1},${lat1};${lon2},${lat2}?access_token=${mapboxgl.accessToken}&geometries=geojson`
    );

    const json = await query.json();

    // Extract the distance (in meters) from the response
    const distance = json?.routes?.[0]?.distance; // Distance in meters

    // Convert the distance to kilometers
    return distance ? distance / 1000 : 0;
  };

  // Function to get population from Google Places API using latitude and longitude
  const getPopulation = async (lat: number, lng: number) => {
    try {
      const apiParams = { lat, lng };

      try {
        const population = await getPopulationData(apiParams)
          .then((response) => response?.data?.population || 0)
          .catch((error) => {
            console.error('Error fetching population data:', error);

            return 0; // Return 'Unknown' on error
          });

        return population;
      } catch (error) {
        console.error('Error in getPopulation function:', error);

        return 0;
      }
    } catch (error) {
      console.error('Error fetching population data:', error);

      return 'Unknown';
    }
  };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  // Function to find cities along the route within a specified radius
  const getCitiesAlongRoute = async ({
    route,
    radius,
  }: {
    route: [number, number][];
    radius: number;
  }) => {
    const cities: any = [];
    const checkedCities = new Set();

    const numSamples = Math.min(1500, route.length);
    const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));

    const addCity = (
      cityName: string,
      fullName: string,
      straightLineDistance: number,
      population: number,
      cityCoords: [number, number]
    ) => {
      const cityInfo = {
        name: cityName,
        fullName,
        distance: straightLineDistance,
        population: population !== null ? population : 'Unknown',
        coordinates: cityCoords,
      };
      cities.push(cityInfo);
      checkedCities.add(fullName);
    };

    for (let i = 0; i < route.length; i += sampleInterval) {
      const [lon, lat] = route[i];

      try {
        const query = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`
        );
        const json = await query.json();

        if (json.features && json.features.length > 0) {
          for (const feature of json.features) {
            const cityName = feature.text;
            const fullName = feature.place_name || 'Unknown Place';
            const cityCoords = feature.center;

            if (fullName && !checkedCities.has(fullName)) {
              const straightLineDistance = await getDistance(
                route[0],
                cityCoords
              );
              const population = await getPopulation(lat, lon);

              if (radius > 0) {
                if (straightLineDistance <= radius) {
                  if (routeFilters?.population > 0) {
                    if (population <= routeFilters?.population) {
                      addCity(
                        cityName,
                        fullName,
                        straightLineDistance,
                        population,
                        cityCoords
                      );
                    }
                  } else {
                    addCity(
                      cityName,
                      fullName,
                      straightLineDistance,
                      population,
                      cityCoords
                    );
                  }
                }
              } else if (routeFilters?.population > 0) {
                if (population <= routeFilters?.population) {
                  addCity(
                    cityName,
                    fullName,
                    straightLineDistance,
                    population,
                    cityCoords
                  );
                }
              } else {
                console.log('else');
                addCity(
                  cityName,
                  fullName,
                  straightLineDistance,
                  population,
                  cityCoords
                );
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching place data:', error);
      }
    }

    const sortedCities = cities
      .sort((a: any, b: any) => (b.population || 0) - (a.population || 0))
      .slice(0, 10);

    return sortedCities;
  };

  const setCustomerMarker = async () => {
    let customerMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      console.log('customer', customer);
      // let custAddress = {
      //   address1: customer?.address1,
      //   city: customer?.city,
      //   postal: customer?.postal,
      //   state: customer?.state,
      //   countryCode: customer?.country,
      // };
      let fullAddress = [
        customer.address1,
        customer.city,
        customer.state,
        customer.postal,
        customer.countryCode,
      ]
        .filter(Boolean)
        .join(', ');

      console.log('custAddress', fullAddress);

      const cityCoords = await geocodeAddress(fullAddress);
      const el = document.createElement('div');
      el.className = 'marker';

      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker'; // Assign a class for additional styling
      parentDiv.id = customer?.id;
      el.id = customer?.id;
      const csutomerImageDiv = document.createElement('div');
      csutomerImageDiv.className = 'customer-image'; // Assign a class for additional styling
      csutomerImageDiv.id = customer?.id;

      // Set marker image based on customer data
      if (customer.image) {
        el.style.backgroundImage = `url(${customer.imageUrl + customer.image})`;
      } else {
        el.style.backgroundImage = `url(${defaultImage})`;
      }
      csutomerImageDiv.appendChild(el); // Append the marker to the parent div
      parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div

      // Create marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // if (navigator.geolocation) {
      //   navigator.geolocation.watchPosition(
      //     function (position) {
      //       const lng = position.coords.longitude;
      //       const lat = position.coords.latitude;

      //       // Safely update marker's position
      //       marker.setLngLat([lng, lat]);

      //       // Center the map at the new location
      //       mapRef?.current?.setCenter([lng, lat]);
      //     },
      //     function (error) {
      //       console.error('Error watching position: ', error);
      //     },
      //     {
      //       enableHighAccuracy: true, // Enable high accuracy for better precision
      //     }
      //   );
      // } else {
      //   console.error('Geolocation is not supported by this browser.');
      // }

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;

        setCustomerId(elementId);
        if (!isDisplayDetail) setIsDisplayDetail(true);
      });

      // Add zoom out functionality to the zoom out button
      document
        .getElementById(`zoomOutButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          // Assuming you have a map object, apply zoom out
          if (mapRef?.current)
            mapRef?.current.setZoom(mapRef?.current.getZoom() + 2); // Adjust this method according to your map library
        });

      document
        .getElementById(`backButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          let ele = document.getElementById(`customer_${customer.id}`);

          if (ele) {
            ele.classList.add('d-none'); // Toggle the 'd-none' class to show/hide the card box
          }
        });

      marker.getElement().addEventListener('click', (event: any) => {
        console.log('event', event);
        const elementId = event.target.id;
        console.log('elementId', elementId);
        setIsDisplayDetail(true);
        setCustomerId(elementId);
      });

      customerMarkers.push(marker);

      if (i >= customers.length - 1) {
        marker.setLngLat([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(5);
      }

      // Center the map at the new location
      // mapRef?.current?.flyTo({
      //   center: [cityCoords[0], cityCoords[1]],
      //   zoom: 14,
      // });
    }

    setCustomerMarkerPin(customerMarkers);
  };

  useEffect(() => {
    console.log('customers', customers, '  ', isCustomerLoading);
    if (customers) setCustomerMarker();
  }, [customers]);

  const removeCustomerMarkers = () => {
    console.log('remove');
    customerMarkerPin.forEach((marker: any) => {
      console.log('marker pin', marker);
      marker.remove();
    });
    setCustomerMarkerPin([]);
  };
  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: string, to: string) => {
      removeCustomerMarkers();

      if (!mapLoaded || !mapRef.current) {
        console.warn('Map not fully loaded yet');
        setSearchResult({
          status: 'error',
          error: 'Map not fully loaded yet. Please try again.',
        });

        return;
      }

      try {
        setHasSearched(true);
        setIsDisplayDetail(true);
        setIsDisplayDetail(true);
        setSearchResult({ status: 'loading', message: 'Fetching route...' });

        const [fromLongitude, fromLatitude] = await geocodeAddress(from);
        const [toLongitude, toLatitude] = await geocodeAddress(to);
        // setFromLong(fromLongitude);
        // setFromLat(fromLatitude);

        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
          { method: 'GET' }
        );
        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];
        const route = data.geometry.coordinates;

        setSearchResult({
          status: 'loading',
          message: 'Fetching routes...',
          from,
          to,
          distance: `${Math.round(data.distance / 1000)} km`,
          duration: `${getTime(Math.round(data.duration))}`,
          cities: [],
          radius: routeFilters?.radius,
        });

        setAllRoutes(route);
        const geojson: SourceSpecification = {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        };

        mapRef.current?.addSource('route', geojson);
        mapRef.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#444CE7',
            'line-width': 3,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }
        mapRef.current?.fitBounds(bounds, {
          padding: { left: 10, right: 800 }, // Add more padding on the right to move the map left
          linear: true,
        });

        const cities = await getCitiesAlongRoute({
          route,
          radius: routeFilters?.radius,
        });

        setSearchResult((old: any) => ({
          ...old,
          status: 'success',
          cities: cities,
        }));
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded]
  );

  const getCustomer = async (signal: any) => {
    // setIsCustomerLoading(true);
    setIsCustomerLoading(true);

    try {
      setHasSearched(false);
      setSearchResult(null);

      if (mapRef.current?.getLayer('route')) {
        mapRef.current.removeLayer('route');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current.removeSource('route');
      }
      const result = await CustomerList(params, signal);

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);
        setCustomers(filteredData);
        setIsCustomerLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsCustomerLoading(false);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setIsDisplayDetail(false);
    setCustomerId(null);

    if (isDisplayCustomers) {
      setHasSearched(false);
      removeCustomerMarkers();
      getCustomer(signal);
    } else {
      setCustomerId(null);
      removeCustomerMarkers();
    }

    return () => {
      controller.abort();
    };
  }, [isDisplayCustomers, params]);

  useEffect(() => {
    initializeMap();
  }, []);
  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);

    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES).map(
      (keyValue: any) => ({
        id: keyValue?.[1],
        value: capitalizeFirstLetter(keyValue?.[1]),
      })
    );
    setSegmentListOption(getSelectBoxOptions(statusArray, 'id', 'value'));
  }, []);

  const getFilteredCity = async () => {
    console.log('getFiltersCity');
    setSearchResult((old: any) => ({
      ...old,
      message: 'Fetching Cities as per filter',
      status: 'loading',
    }));
    const cities = await getCitiesAlongRoute({
      route: allRoutes,
      // fromLatLong: [fromLong, fromLat],
      radius: routeFilters.radius,
    });
    console.log('getFiltersCity cities', cities);
    setSearchResult((old: any) => ({
      ...old,
      status: 'success',
      cities: cities,
    }));
  };
  useEffect(() => {
    if (hasSearched) getFilteredCity();
  }, [routeFilters]);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      const unassignedSelectBoxOption = {
        value: 'unassigned',
        label: 'Unassigned',
        image: null,
        isImage: false,
      };

      if (hasRoleV2('admin')) {
        setUserList([unassignedSelectBoxOption, ...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  return (
    <div className="relative h-full w-full geography-map">
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      <SearchBox onSearch={handleSearch} />
      {mapFilters.filterType === 'customer_detail' && (
        <CustomerFilterModal
          handleClose={() =>
            setMapFilters((old: any) => ({
              ...old,
              filterType: '',
            }))
          }
          params={params}
          setParams={setParams}
          teamListOptions={teamListOptions}
          userList={userList}
          segmentListOption={segmentListOption}
        />
      )}
    </div>
  );
};

export default MapBox;
