import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { convertToGBMBKB, getFormattedNumber } from 'src/utils/CommonFunctions';

const FileListRow = ({ data }: any) => {
  const onDownloadFile = () => {
    const url = `${data.fileUrl}${data.fileName}`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <td className="px-5 py-4">
        <h6 className="text-grayLight900 font-semibold max-w-40 truncate">
          {data.fileName?.split('/')?.[1]}
        </h6>
        <div className="flex gap-2">
          <p>Size: {convertToGBMBKB(data.size)}</p>
        </div>
      </td>

      <td className="px-5 py-4">
        {moment(data.createdAt).format('DD/MM/YYYY')}
      </td>

      <td className="px-5 py-4">
        {moment(data.startDate).format('DD/MM/YYYY')}
      </td>

      <td className="px-5 py-4">{moment(data.endDate).format('DD/MM/YYYY')}</td>

      <td className="px-5 py-4">
        ${getFormattedNumber(data.monthlyProjectedMargin)}
      </td>

      <td className="px-5 py-4">
        {getFormattedNumber(data.projectedMarginVariance, true, true)}
      </td>

      <td className="px-5 py-4">
        ${getFormattedNumber(data.dailyBookedMargin)}
      </td>

      <td className="px-5 py-4">
        ${getFormattedNumber(data.dailyDeliveredMargin)}
      </td>

      <td className="px-5 py-4">
        <div className=" gap-4 flex justify-center">
          <ButtonCmp
            className="btn-ghost !shadow-none"
            disabled={!data?.fileUrl || !data?.fileName}
            onClick={onDownloadFile}
          >
            <DownloadCloud02 className="w-4 h-4 relative cursor-pointer" />
          </ButtonCmp>
        </div>
      </td>
    </>
  );
};

export default FileListRow;
