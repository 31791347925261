import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { deleteAppointment } from 'src/services/OrderService';

import AppointmentModal from './AppointmentModal';

interface IProps {
  action: any;
  setAction: any;
  orderId: any;
}

const AppointmentDetails = ({ action, setAction, orderId }: IProps) => {
  const handleDeleteAppointment = () => {
    deleteAppointment({ orderId, type: action.mopde })
      .then(() => {})
      .catch(console.error);
  };

  console.log('handleDeleteAppointment', handleDeleteAppointment);

  const appointmentDetails: any = {
    'carrier-appointment': {
      title: 'Set Carrier Appointment',
      description: 'Assign a carrier to complete your shipment',
    },
    'shipper-appointment': {
      title: 'Set Shipper Appointment',
      description: 'Assign a shipper to complete your shipment',
    },
    'delivery-appointment': {
      title: 'Set Consignee Appointment',
      description: 'Assign a consignee to complete your shipment',
    },
  };

  return (
    <>
      <h5 className="text-grayLight900 text-xl font-semibold">
        Set Your Appointments
      </h5>

      <div className="flex flex-wrap sm:-m-1.5">
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Shipper Pickup Appointment
          </h6>
          <div
            className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer"
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'shipper-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Set Appointment
            </p>
          </div>
        </div>
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Pickup Appointment
          </h6>
          <div
            className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs p-4 flex items-center gap-x-3 cursor-pointer"
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'carrier-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Set Appointment
            </p>
          </div>
        </div>

        {/* <div className="mx-1.5 bg-borderPrimary w-[1px]"></div>

        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Delivery Appointment
          </h6>
          <div className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs p-4 flex items-center gap-x-3 cursor-pointer">
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Set Appointment
            </p>
          </div>
        </div> */}
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Consignee Delivery Appointment
          </h6>
          <div
            className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs p-4 flex items-center gap-x-3 cursor-pointer"
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'delivery-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Set Appointment
            </p>
          </div>
        </div>
      </div>

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={appointmentDetails[action.mode].title}
          modalDesc={appointmentDetails[action.mode].description}
          setAction={setAction}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default AppointmentDetails;
