import { Eye, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const MessageListLoading = ({
  isLoadMore,
  loading,
  isEmailToggle,
  isLoadingMore,
}: any) => (
  <div
    className={` w-full  flex-col justify-start items-start flex transition-all duration-[0.5s] ${
      isLoadMore ? '' : 'border-r border-gray-200'
    } ${loading ? '' : 'md:w-[25%]'}`}
  >
    {!isEmailToggle && !isLoadingMore && (
      <>
        <div className="w-full px-4 py-5 justify-between items-center flex">
          <div className="flex gap-2 items-center  mr-4">
            <h5 className="text-grayLight900 text-lg font-semibold custom-loading">
              Inbox
            </h5>
            <BadgeCmp
              type="primary"
              style="modern"
              isHidePillDot
              mainClassName={`bg-utilityPurple50 border-utilityPurple200 ${
                loading ? '' : 'custom-loading'
              }`}
              // mainClassName="bg-utilityPurple50 border-utilityPurple200 custom-loading"
              badgeTextColor="!text-primary fon-medium"
            >
              02
            </BadgeCmp>
          </div>
          <div className="flex items-center ">
            <ButtonCmp className={` lg:px-[9px] px-2  mr-3 custom-loading`}>
              <Eye className={`w-4 h-4`} />
            </ButtonCmp>
            <ButtonCmp className={` lg:px-[9px] px-2  mr-3 custom-loading`}>
              <Eye className={`w-4 h-4`} />
            </ButtonCmp>
          </div>
        </div>

        <InputText
          inputName="search"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input "
          value={''}
          isClearable
          inputType="text"
          parentClassName="w-full px-4 pb-3"
          inputWrapClass="!justify-start custom-loading"
        />
      </>
    )}
    <div className="w-full h-[calc(100vh_-_257px)] min-h-[calc(100vh_-_257px)] max-h-[calc(100vh_-_257px)] overflow-auto custom-scrollbar-v2">
      {Array.from({ length: isLoadMore ? 2 : 9 }).map((_, index) => (
        <div
          className="last:border-b-0 border-b border-utilityGray200"
          key={index}
        >
          <div className="m-4 flex gap-1.5 items-center cursor-pointer custom-loading">
            <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start"></p>
            <div className="flex-1">
              <h6 className="text-sm textSecondary  font-medium ">Anthropic</h6>
              <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary ">
                Lets get you signed in All you have to ...
              </p>
            </div>
            <div className="text-xs text-grayLight600 font-normal ">2d ago</div>
            <button
              className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 "
              type="submit"
            >
              <XClose className="w-4 h-4 " />
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default MessageListLoading;
