import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const monthlyCommissionStatement = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.COMMISSION.MONTHLY_COMMISSION_REPORT,
    {
      params,
    }
  );

  return response;
};

export const allMonthCommissionReport = async (params: any) => {
  const response = await axiosInterceptor.get(API.COMMISSION.ALL_MONTH_REPORT, {
    params,
  });

  return response;
};

export const salesRepConflictsList = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.COMMISSION.SALES_REP_CONFLICTS,
    {
      params,
    }
  );

  return response;
};

export const salesRepOrderList = async (params: any) => {
  const response = await axiosInterceptor.get(API.COMMISSION.SALES_REP_ORDERS, {
    params,
  });

  return response;
};

export const resolveConflict = async (params: any) => {
  const response = await axiosInterceptor.post(
    API.COMMISSION.RESOLVE_CONFLICT,

    params
  );

  return response;
};

export const removeConflict = async (params: any) => {
  const response = await axiosInterceptor.post(
    API.COMMISSION.REMOVE_CONFLICT,
    params
  );

  return response;
};

export const exportMonthlyReport = async (params: any) => {
  const response = await axiosInterceptor.get(API.COMMISSION.EXPORT_MONTHLY, {
    params,
    responseType: 'blob',
  });

  return response;
};

export const exportSalesRepReport = async (params: any) => {
  const response = await axiosInterceptor.get(API.COMMISSION.EXPORT_SALES_REP, {
    params,
    responseType: 'blob',
  });

  return response;
};

export const removeFromCommissioned = async (params: any) => {
  const response = await axiosInterceptor.post(
    API.COMMISSION.REMOVE_FROM_COMMISSIONED,
    params
  );

  return response;
};
