import React from 'react';

interface IProps {
  children: any;
  style?: 'pill' | 'modern';
  type?:
    | 'primary'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'gray'
    | 'red'
    | 'lightblue'
    | 'yellow'
    | 'orange'
    | 'blue'
    | 'pink';
  mainClassName?: string;
  isHidePillDot?: boolean;
  badgeDotColor?: string;
  badgeTextColor?: string;
  dataValue?: any;
}

const BadgeCmp = ({
  children,
  style = 'pill',
  type,
  mainClassName = '',
  isHidePillDot = false,
  badgeDotColor,
  badgeTextColor,
  dataValue,
}: IProps) => (
  <div
    className={`${mainClassName} px-[6px] py-[2px] border inline-flex shadow items-center justify-start
            ${
              style === 'pill' && type === 'primary'
                ? 'bg-primary50 border-primary'
                : ''
            }
			
			${
        style === 'pill' && type === 'info'
          ? 'bg-utilityBlue50 border-utilityBlue200 text-utilityBlue700'
          : ''
      }
            ${
              style === 'pill' && type === 'success'
                ? 'bg-success50 border-fgSuccessPrimary '
                : ''
            }
            ${
              style === 'pill' && type === 'error'
                ? 'bg-utilityOrange50 border-utilityOrange'
                : ''
            }
            ${
              style === 'pill' && type === 'warning'
                ? 'border-warning700 bg-warning50'
                : ''
            }
            ${
              style === 'pill' && type === 'lightblue'
                ? 'border-blue500 bg-primary50'
                : ''
            }
            ${
              style === 'pill' && type === 'pink'
                ? 'border-utilityPink600 bg-utilityPink200'
                : ''
            }
            ${
              style === 'pill' && type === 'orange'
                ? 'border-orange500 bg-utilityOrange50'
                : ''
            }
            ${
              style === 'pill' && type === 'yellow'
                ? 'border-yellow300 bg-utilityOrange50'
                : ''
            }
            ${
              style === 'pill' && type === 'gray'
                ? 'border-textSecondary bg-gray50'
                : ''
            }
            ${
              style === 'pill' && type === 'red'
                ? 'border-danger500 bg-danger50'
                : ''
            }
            ${style === 'pill' ? 'rounded-full' : ''}
            ${style === 'modern' ? 'rounded-md border-borderPrimary' : ''}
        `}
    data-value={dataValue}
  >
    <div
      className={`text-center text-[10px] leading-[14px] font-medium  flex items-center capitalize
      ${badgeTextColor} ${
        style === 'pill' && type === 'primary' ? 'text-primary' : ''
      }
				${style === 'pill' && type === 'gray' ? 'text-grayDark' : ''}
                ${
                  style === 'pill' && type === 'success'
                    ? 'text-fgSuccessPrimary'
                    : ''
                }
                ${
                  style === 'pill' && type === 'error'
                    ? 'text-utilityOrange700'
                    : ''
                }
                ${
                  style === 'pill' && type === 'warning'
                    ? 'text-warning700'
                    : ''
                }
                ${
                  style === 'pill' && type === 'lightblue' ? 'text-blue500' : ''
                }
                ${
                  style === 'pill' && type === 'pink'
                    ? 'text-utilityPink600'
                    : ''
                }
                ${style === 'pill' && type === 'orange' ? 'text-orange500' : ''}
                ${
                  style === 'pill' && type === 'gray'
                    ? 'text-textSecondary'
                    : ''
                }
                ${style === 'pill' && type === 'red' ? 'text-danger500' : ''}
                ${style === 'pill' && type === 'yellow' ? 'text-orange400' : ''}
				${style === 'modern' ? 'text-textSecondary' : ''}
            `}
      data-value={dataValue}
    >
      {style === 'modern' && !isHidePillDot && (
        <div
          className={`h-1.5 w-1.5 rounded-full mr-1 ${badgeDotColor}
            ${type === 'primary' ? 'bg-primary500' : ''}
            ${type === 'info' ? 'bg-fgInfoPrimary' : ''}
            ${type === 'success' ? 'bg-successSecondary ' : ''}
            ${type === 'error' ? 'bg-orange500' : ''}
              ${type === 'red' ? 'bg-danger500' : ''}
            ${type === 'warning' ? 'bg-utilityOrange400' : ''}
            
            ${type === 'lightblue' ? 'bg-blue500' : ''}
            ${type === 'gray' ? 'bg-grayText' : ''}
            ${type === 'pink' ? 'bg-[#EE46BC]' : ''}
            ${type === 'blue' ? 'bg-[#0BA5EC]' : ''}
          `}
        ></div>
      )}
      {children}
    </div>
  </div>
);

export default BadgeCmp;
