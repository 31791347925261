import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const Row = ({ data }: any) => {
  console.log('data', data);

  return (
    <>
      <td className={`px-5 py-4  min-w-96 w-96 max-w-96`}>
        <div className="flex items-center truncate">
          <div className="table-profile-wrap notification-mark-wrap">
            {data?.image ? (
              <img
                src={`${data?.imageUrl}${data?.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data.name}`)}
              </div>
            )}
            {data.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>

          <div className="pl-3 w-[calc(100%_-_58px)]">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.name}
            </h6>
          </div>
        </div>
      </td>
      <td className={`px-5 py-4 min-w-56 w-56 max-w-56`}>
        <span className="flex gap-1">
          {data.isApiConnection ? (
            <BadgeCmp style="pill" type="success">
              API
            </BadgeCmp>
          ) : (
            ''
          )}
          {data.isSpotQuote ? (
            <BadgeCmp style="pill" type="primary">
              Spot
            </BadgeCmp>
          ) : (
            ''
          )}
        </span>
      </td>
      <td className={`px-5 py-4`}>
        {(data.isFreightcom || data.isFreightcom === 1) && (
          <div className="flex items-center gap-1">
            <div className="font-medium">Freightcom</div>
          </div>
        )}
        {(data.isBanyan || data.isBanyan === 1) && (
          <div className="flex items-center gap-1">
            <div className="font-medium">Banyan</div>
          </div>
        )}
        {!data.isBanyan && !data.isFreightcom && (
          <div className="flex items-center gap-1">
            <div className="font-medium">Direct</div>
          </div>
        )}
      </td>

      <td className={`px-5 py-4`}>
        <span className="">{data.rateCount}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.quoteCount}</span>
      </td>

      {/* <td className="px-5 py-4">
                                                    <span className="">{data.email}</span>
                                                </td> */}
      <td className={`px-5 py-4`}>
        <span className="flex">
          <TooltipCmp message="Edit">
            <Edit01 className="w-4 h-4 cursor-pointer flex items-center font-semibold flex-none" />
          </TooltipCmp>
        </span>
      </td>
    </>
  );
};

export default Row;
