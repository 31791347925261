import { yupResolver } from '@hookform/resolvers/yup';
import { UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import {
  createCustomerContact,
  updateCustomerContact,
} from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const schema = yup.object().shape({
  contactFullName: yup
    .string()
    .required('Contact full name is required.')
    .max(25, 'Contact full name cannot be longer than 25 characters.'),
  contactRole: yup
    .string()
    .required('Contact role is required.')
    .max(25, 'Contact role cannot be longer than 25 characters.'),
  contactPhone: yup.string().required('Contact phone number is required.'),
  contactPhoneCode: yup.string(),
  contactEmail: yup
    .string()
    .required('Contact email address is required.')
    .email('Contact email address is not valid.'),
});

interface IProps {
  customerId: string;
  handleClose: any;
  isEdit?: boolean;
  editContactData?: any;
}

const AddNewCustomerContactModal = ({
  customerId,
  handleClose,
  isEdit,
  editContactData,
}: IProps) => {
  const [isUpdateSalesRepHistoryLoading, setIsUpdateSalesRepHistoryLoading] =
    useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEdit && editContactData) {
      setValue('contactFullName', editContactData?.contactFullName);
      setValue('contactRole', editContactData?.contactRole);
      setValue('contactEmail', editContactData?.contactEmail);

      const phoneNumber = parsePhoneNumberFromString(
        editContactData?.contactPhone
      );

      if (phoneNumber?.nationalNumber && phoneNumber?.countryCallingCode) {
        setValue('contactPhone', phoneNumber?.nationalNumber);
        setValue('contactPhoneCode', `+${phoneNumber?.countryCallingCode}`);
      }
    }
  }, []);

  const onSubmit = (formData: any) => {
    formData.contactPhone = `+${formData.contactPhoneCode}${formData.contactPhone}`;
    delete formData.contactPhoneCode;
    setIsUpdateSalesRepHistoryLoading(true);

    if (isEdit) {
      updateCustomerContact(editContactData?.id, formData)
        .then((response: any) => {
          WalToast.success(response?.message);
          handleClose(true);
        })
        .finally(() => {
          setIsUpdateSalesRepHistoryLoading(false);
        })
        .catch(console.log);
    } else {
      createCustomerContact(customerId, formData)
        .then((response: any) => {
          WalToast.success(response?.message);
          handleClose(true);
        })
        .finally(() => {
          setIsUpdateSalesRepHistoryLoading(false);
        })
        .catch(console.log);
    }
  };

  return (
    <CommonModal
      title={!isEdit ? 'Add Contacts' : 'Edit Contacts'}
      titleDesc={
        !isEdit
          ? 'Add and manage your contacts'
          : 'Edit and manage your contacts'
      }
      handleClose={() => handleClose(false)}
      headerIcon={<UsersPlus />}
      size={'xl:max-w-[647px] max-w-[551px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isUpdateSalesRepHistoryLoading}
      primaryBtnDisabled={isUpdateSalesRepHistoryLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isUpdateSalesRepHistoryLoading}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        <Controller
          name="contactFullName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="contactFullName"
              label="Name"
              labelClassName="block mb-1.5"
              placeholder="Enter Contact Full Name"
              value={value}
              onChangeFunc={onChange}
              errorText={
                errors.contactFullName ? errors.contactFullName.message : ''
              }
            />
          )}
        />
        <Controller
          name="contactRole"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="contactRole"
              label="Role"
              labelClassName="block mb-1.5"
              placeholder="Enter Contact Role Name"
              value={value}
              onChangeFunc={onChange}
              errorText={errors.contactRole ? errors.contactRole.message : ''}
            />
          )}
        />
        <div className="flex max-sm:flex-col -mx-1.5">
          <div className="px-1.5 max-sm:mb-4 sm:w-1/2">
            <label className="form_label block mb-1.5">Phone number</label>
            <Controller
              name="contactPhone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="contactPhone"
                  onNumberChange={(
                    country: any,
                    phone: any,
                    code: any,
                    isValid: boolean
                  ) => {
                    onChange(phone);
                    setValue('contactPhoneCode', code);

                    if (isValid || phone === '') {
                      clearErrors('contactPhone');
                    } else {
                      setError('contactPhone', {
                        type: 'manual',
                        message: 'Contact phone number is invalid.',
                      });
                    }
                  }}
                  errors={
                    errors.contactPhone ? errors.contactPhone.message : ''
                  }
                  value={`${watch('contactPhoneCode')}${value}`}
                />
              )}
            />
          </div>
          <Controller
            name="contactEmail"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="contactEmail"
                label="Email"
                labelClassName="block mb-1.5"
                placeholder="Enter Contact Email Address"
                parentClassName="sm:w-1/2 px-1.5"
                value={value}
                onChangeFunc={onChange}
                errorText={
                  errors.contactEmail ? errors.contactEmail.message : ''
                }
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default AddNewCustomerContactModal;
