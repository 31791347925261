import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useContext, useEffect, useState } from 'react';
import { BasicContext } from 'src/context/BasicContext';

import ButtonCmp from '../ButtonCmp';

interface IProps {
  title?: any;
  handleClose(status: boolean): any;
  children: any;
  titleDesc?: any;
  size?: string;
  headerIcon?: any;
  headerInfoClass?: string;
  headerIconClass?: string;
  headerSecondIcon?: any;
  headerIconSecondClass?: string;
  modalHeaderClass?: string;
  modalFooterClass?: string;
  modalPrimaryBtnClass?: string;
  modalSecondaryBtnTextClass?: string;
  modalSecondaryBtnText2Class?: string;
  primaryBtnLoading?: boolean;
  primaryBtnDisabled?: boolean;
  primaryBtnText?: string;
  dangerBtnText?: string;
  primaryButtonIcon?: any;
  primaryBtnOnClick?: (e?: any) => void;
  modalClassName?: string;
  secondaryBtnLoading?: boolean;
  secondaryBtnDisabled?: boolean;
  secondaryBtnText?: string;
  secondaryBtnColor?: string;
  secondaryBtnOnClick?: (e?: any) => void;

  secondaryBtnLoading2?: boolean;
  secondaryBtnDisabled2?: boolean;
  secondaryBtnText2?: string;
  secondaryBtnColor2?: string;
  secondaryBtnOnClick2?: () => void;

  isOverflow?: boolean;
  closeBtnClass?: string;
  modalHeaderTextClass?: string;
  modalParentClass?: string;
  isEscapeClose?: boolean;
}

const CommonModal = ({
  size,
  handleClose,
  children,
  title,
  titleDesc,
  headerIcon,
  headerIconClass,
  headerInfoClass,
  headerSecondIcon,
  headerIconSecondClass,
  modalHeaderClass,
  modalFooterClass,
  modalPrimaryBtnClass,
  primaryBtnLoading,
  primaryBtnDisabled,
  primaryBtnText,
  dangerBtnText,
  primaryButtonIcon,
  primaryBtnOnClick,
  secondaryBtnLoading,
  secondaryBtnDisabled,
  secondaryBtnText,
  secondaryBtnColor,
  secondaryBtnOnClick,
  closeBtnClass,
  modalHeaderTextClass,
  modalSecondaryBtnTextClass,
  modalSecondaryBtnText2Class,
  isOverflow = true,
  secondaryBtnLoading2,
  secondaryBtnDisabled2,
  secondaryBtnText2,
  secondaryBtnColor2,
  modalClassName,
  modalParentClass,
  isEscapeClose = true,
  secondaryBtnOnClick2,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
  } = useContext(BasicContext);
  useEffect(() => {
    if (isEscapeClose) {
      const handleEscapePress = (event: any) => {
        if (event.key === 'Escape') {
          handleClose(false);
        }
      };
      document.addEventListener('keydown', handleEscapePress);

      return () => {
        document.removeEventListener('keydown', handleEscapePress);
      };
    }
  }, [handleClose]);

  const handleCloseModal = (status: boolean) => {
    const rootElement = document.querySelector('#root');

    if (rootElement) {
      rootElement.classList.remove('tooltipShow');
    }
    setIsOpen(status);
    handleClose(status);
  };

  useEffect(() => {
    if (isOpen && tooltipGlobalOpen) {
      setTooltipGlobalOpen(false);
      setActionTooltipGlobalOpen(false);
    }
  }, [isOpen, tooltipGlobalOpen]);

  return (
    <dialog className={`modal modal-root ${modalParentClass}`} open>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[2px]"></div>
      <div className={`modal-box ${size} p-0 flex flex-col`}>
        {title && (
          <header
            className={`flex justify-between border-b px-5 py-3.5 ${modalHeaderClass}`}
          >
            <div
              className={`flex xxl:gap-4 gap-3 md:flex-row ${modalHeaderTextClass}`}
            >
              {headerIcon && (
                <div
                  className={`md:h-[43px] h-8 md:w-[43px] w-8 relative z-[6] md:p-2 p-1.5 rounded-[10px] shadow-sm border border-gray10 text-textSecondary flex items-center justify-center flex-none ${headerIconClass}`}
                >
                  {headerIcon}
                </div>
              )}
              {headerSecondIcon && (
                <div
                  className={`md:h-[43px] h-8 md:w-[43px] w-8 relative z-[6] md:p-2 p-1.5 rounded-[10px] shadow-sm border border-gray10 text-textSecondary flex items-center justify-center flex-none ${headerIconSecondClass}`}
                >
                  {headerSecondIcon}
                </div>
              )}
              <div
                className={`flex flex-col justify-center gap-1 ${headerInfoClass}`}
              >
                <div className="text-grayLight900 text-base font-semibold break-words">
                  {title}
                </div>
                <div className="text-grayLight600 text-xs font-normal leading-tight break-words empty:hidden">
                  {titleDesc}
                </div>
              </div>
            </div>
            <div
              className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full flex-none ${closeBtnClass}`}
            >
              <XClose
                className="text-gray400 hover:text-textSecondary"
                onClick={() => handleCloseModal(false)}
              />
            </div>
          </header>
        )}
        <article
          className={`flex-1 scrollbar-hide ${modalClassName} ${
            isOverflow ? 'overflow-y-auto' : ''
          }`}
        >
          {children}
        </article>
        {(secondaryBtnText || primaryBtnText || dangerBtnText) && (
          <footer
            className={`px-5 py-[14px] border-t flex flex-wrap gap-3 md:flex-row flex-col-reverse ${modalFooterClass}`}
          >
            {secondaryBtnText && (
              <ButtonCmp
                className={`${
                  secondaryBtnColor ?? 'btn_secondary_black text-textSecondary '
                } flex-1 ${modalSecondaryBtnTextClass}`}
                onClick={secondaryBtnOnClick}
                loading={secondaryBtnLoading}
                disabled={secondaryBtnDisabled}
              >
                {secondaryBtnText}
              </ButtonCmp>
            )}
            {secondaryBtnText2 && (
              <ButtonCmp
                className={`${
                  secondaryBtnColor2 ??
                  'btn_secondary_black text-textSecondary '
                } flex-1 ${modalSecondaryBtnText2Class}`}
                onClick={secondaryBtnOnClick2}
                loading={secondaryBtnLoading2}
                disabled={secondaryBtnDisabled2}
              >
                {secondaryBtnText2}
              </ButtonCmp>
            )}
            {primaryBtnText && (
              <ButtonCmp
                className={`btn_primary flex-1 ${modalPrimaryBtnClass}`}
                onClick={() => {
                  if (primaryBtnOnClick) {
                    primaryBtnOnClick();
                  }
                }}
                loading={primaryBtnLoading}
                disabled={primaryBtnLoading || primaryBtnDisabled}
                icon={primaryButtonIcon}
              >
                {primaryBtnText}
              </ButtonCmp>
            )}
            {dangerBtnText && (
              <ButtonCmp
                className="btn_danger flex-1"
                onClick={primaryBtnOnClick}
                loading={primaryBtnLoading}
                disabled={primaryBtnLoading || primaryBtnDisabled}
                autofocus={true}
              >
                {dangerBtnText}
              </ButtonCmp>
            )}
          </footer>
        )}
      </div>
    </dialog>
  );
};

export default memo(CommonModal);
