import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { RiSendPlane2Fill } from 'react-icons/ri';
import InputText from 'src/components/InputText/InputText';
import Loader from 'src/components/Loader';
import { API } from 'src/constants/api';
import { AI_CHATBOT_APP_API_URL } from 'src/constants/common';

interface Message {
  question: string;
  answer: string;
}

const ChatBot = () => {
  const [message, setMessage] = useState('');
  const [messageListdata, setMessageListdata] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef<HTMLDivElement | null>(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Scroll to the bottom whenever messageListdata changes
  useEffect(() => {
    scrollToBottom();
  }, [messageListdata]);
  // const [error, setError] = useState(null);

  const onSendMessage = async (msg: string) => {
    if (!msg || loading) {
      return;
    }
    setLoading(true); // Set loader active
    setMessageListdata((prevMessageList) => [
      ...prevMessageList,
      {
        question: msg,
        answer: '', // Empty answer initially
      },
    ]);
    setMessage('');
    const headers = {
      'Content-Type': 'application/json',
    };
    const payload = {
      user_question: msg,
    };

    try {
      const res = await axios.post(
        `${AI_CHATBOT_APP_API_URL}${API.AI_CHATBOT.QUESTION}`,
        payload,
        {
          headers: headers,
        }
      );

      if (res?.status === 200) {
        // Update message list with the answer
        setMessageListdata((prevMessageList) => {
          // Update the last message with the answer
          const updatedMessageList = [...prevMessageList];

          updatedMessageList[updatedMessageList.length - 1].answer =
            res?.data?.result;

          return updatedMessageList;
        });

        setLoading(false); // Disable loader once response is received
      }
    } catch (error) {
      console.error('Error making the POST request', error);
      setLoading(false); // Disable loader even on error
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // If Shift + Enter, allow the default behavior (add a new line)
        return;
      } else {
        // If Enter without Shift, prevent the default behavior (no new line)
        e.preventDefault();

        if (message.trim()) {
          onSendMessage(message.trim()); // Send the message/
        }
      }
    }
  };

  return (
    <div className="flex flex-col h-full p-4">
      {/* Message List */}
      <div className="flex-grow overflow-auto w-2/4 mx-auto custom-scrollbar-v2">
        {messageListdata.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <InputText
              inputName="chatbot"
              label="What can I help with?"
              placeholder="Message Chatbot"
              value={message}
              onChangeFunc={(e: any) => setMessage(e?.target?.value)}
              labelClassName="block mb-1.5"
              className="h-auto w-full overflow-hidden ignore-blur"
              asTextarea="textarea"
              parentClassName={'w-full'}
              handleKeyDown={handleKeyDown}
              rows={1} // Starts with a single line
            />
            <button
              onClick={() => onSendMessage(message.trim())}
              className="mt-4"
              disabled={loading}
            >
              <RiSendPlane2Fill className="h-10 w-10" />
            </button>
          </div>
        ) : (
          messageListdata.map((msgs, index) => (
            <div key={index} className="mb-4">
              {/* Answer on the left */}
              <div className="text-right max-w-lg ml-auto w-fit">
                <div className="bg-gray-200 text-black p-3 rounded-lg shadow ">
                  {msgs.question}
                </div>
              </div>

              {/* Question on the right */}
              <div className="text-left max-w-lg mb-10 w-fit">
                <div className="bg-blue-500 text-black p-3 rounded-lg shadow">
                  {msgs.answer ||
                    (loading && index === messageListdata.length - 1 ? (
                      <div className="relative h-3 w-3 mr-3">
                        <Loader spinnerClass={'h-4 w-4'} />
                      </div>
                    ) : (
                      ''
                    ))}
                </div>
              </div>
            </div>
          ))
        )}
        {/* Empty div to act as the scroll target */}
        <div ref={messageEndRef}></div>
      </div>

      {/* Input area at the bottom */}
      {messageListdata.length > 0 && (
        <div className="flex items-center justify-center w-2/4 mx-auto">
          <InputText
            inputName="chatbot"
            label="What can I help with?"
            placeholder="Message Chatbot"
            value={message}
            onChangeFunc={(e: any) => setMessage(e?.target?.value)}
            labelClassName="block mb-1.5"
            className="h-auto w-full overflow-hidden ignore-blur"
            asTextarea="textarea"
            parentClassName={'w-full'}
            handleKeyDown={handleKeyDown}
            rows={1} // Starts with a single line
          />
          <button
            onClick={() => onSendMessage(message.trim())}
            className="mt-4"
            disabled={loading}
          >
            <RiSendPlane2Fill className="h-10 w-10" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
