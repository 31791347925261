import {
  ClockPlus,
  ReverseLeft,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import { getShortName, isValidJSON } from 'src/utils/CommonFunctions';

import ReplyModal from '../../QuotingRequest/ReplyModal';

const EmailDetailView = ({
  emailDetails,
  handleClose,
  isShowEmailDetailView,
  isClicked,
}: any) => {
  const [quoteEmailBody, setQuoteEmailBody] = useState<any>(null);
  const [quoteEmailDetail, setQuoteEmailDetail] = useState<any>(null);
  const [isShowReplyModal, setIsShowReplyModal] = useState<boolean>(false);
  const [timeAgo, setTimeAgo] = useState('');
  useEffect(() => {
    const emailBody =
      isValidJSON(emailDetails?.emailBody) &&
      JSON.parse(emailDetails?.emailBody);
    setQuoteEmailDetail(emailBody);
    setQuoteEmailBody(emailBody.body?.content?.replace(/\\|\r\n/g, ''));
  }, [emailDetails]);
  const modalRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (isClicked) {
          handleClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose]);

  const calculateTimeAgo = (arrivalTime: any) => {
    const now = new Date() as any;
    const emailTime = new Date(arrivalTime) as any;
    const differenceInSeconds = Math.floor((now - emailTime) / 1000);

    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    return { hours, minutes, seconds };
  };

  const formatTimeAgo = ({ hours, minutes, seconds }: any) => {
    const formattedHours =
      hours > 0 ? `${hours.toString().padStart(2, '0')}` : '00';
    const formattedMinutes =
      minutes > 0 ? `${minutes.toString().padStart(2, '0')}` : '00';
    const formattedSeconds = `${seconds.toString().padStart(2, '0')}`;

    if (hours >= 23) {
      return moment(quoteEmailDetail?.receivedDateTime).fromNow(true);
    }

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    // Update the timeAgo state initially
    setTimeAgo(
      formatTimeAgo(calculateTimeAgo(quoteEmailDetail?.receivedDateTime))
    );

    // Update the timeAgo state every second
    const interval = setInterval(() => {
      setTimeAgo(
        formatTimeAgo(calculateTimeAgo(quoteEmailDetail?.receivedDateTime))
      );
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [quoteEmailDetail?.receivedDateTime]);

  if (!isShowEmailDetailView) {
    return null;
  }

  return (
    <>
      <CommonModal
        handleClose={handleClose}
        size={'absolute bottom-[70px] right-5 z-[99] overflow-hidden max-w-fit'}
        modalHeaderClass="hidden"
        modalClassName="flex-none max-h-[calc(100vh_-_178px)] h-[calc(100vh_-_178px)] min-h-[calc(100vh_-_178px)] md:w-[641px] w-[90vw] "
      >
        <div className="h-full flex flex-col" ref={modalRef}>
          <div className="flex flex-wrap xxl:gap-4 gap-3 w-full pb-3 px-5 pt-5 justify-between border-b border-utilityGray200 sticky top-0 bg-white z-[2]">
            <div className="w-12 h-12 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none">
              <span className="text-primary text-xl font-semibold">
                {quoteEmailDetail?.from?.emailAddress?.name
                  ? getShortName(
                      quoteEmailDetail?.from?.emailAddress?.name?.toUpperCase()
                    )
                  : ''}
              </span>
            </div>
            <div className="flex-1 flex flex-wrap xxl:gap-3 gap-2 md:flex-row flex-col ">
              <div className="flex-1">
                <div className="flex flex-wrap gap-x-2 gap-y-0 items-center mb-0.5">
                  <h6 className="text-grayLight900 text-base font-semibold flex xxl:gap-4 gap-3 items-center">
                    {quoteEmailDetail?.from?.emailAddress?.name}
                  </h6>
                  <div className="rounded-md border border-utilityGray200 bg-gray50 flex px-1.5 py-0.5">
                    <a
                      href="mailto:katherine.moss@wal.com"
                      className="text-textSecondary text-xs font-medium"
                    >
                      {quoteEmailDetail?.from?.emailAddress?.address}
                    </a>
                  </div>
                </div>
                <div className="text-grayLight600 text-sm text-left">
                  <span className="font-bold mr-1">Subject: </span>
                  <span className="font-normal">
                    {quoteEmailDetail?.subject}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div className="text-grayLight600 text-xs font-normal mb-1">
                  {moment(quoteEmailDetail?.receivedDateTime).format(
                    'dddd, MMMM D, YYYY h:mm A'
                  )}
                </div>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName="rounded-[8px] border border-success200 bg-success50 "
                  badgeTextColor="!text-success700"
                  isHidePillDot
                >
                  <ClockPlus className="w-3 h-3 me-1" /> {timeAgo}
                </BadgeCmp>
              </div>
              <div
                className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full -mt-[7px] -mr-[7px] `}
              >
                <XClose
                  className="text-gray400 hover:text-textSecondary"
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
          {/* <div
            className="py-5 px-5"
            dangerouslySetInnerHTML={{
              __html: quoteEmailBody,
            }}
            
          ></div> */}
          <div className="my-5 px-5 flex-1 overflow-y-auto scrollbar-hide ">
            <iframe
              srcDoc={quoteEmailBody}
              style={{ border: 'none', width: '100%', height: '100%' }}
            />
          </div>
          <div className="mb-5 mr-5 text-right">
            <ButtonCmp
              className="btn_primary "
              icon={<ReverseLeft className="w-4 h-4" />}
              onClick={() => setIsShowReplyModal(true)}
            >
              Reply
            </ButtonCmp>
          </div>
        </div>
      </CommonModal>
      {isShowReplyModal && (
        <ReplyModal
          emailDetails={quoteEmailDetail}
          handleClose={() => setIsShowReplyModal(false)}
        />
      )}
    </>
  );
};

export default EmailDetailView;
