import moment from 'moment';
import React from 'react';
import { getShortName, onError } from 'src/utils/CommonFunctions';

interface IProps {
  internalNote: any;
}

const InternalNoteCard = ({ internalNote }: IProps) => (
  <li
    className="px-2 py-3 w-full first:border-t-0 border-t border-utilityGray200"
    key={internalNote?.id}
  >
    <div className="flex  gap-2">
      {internalNote?.isUnread ? (
        <p className="bg-primary500 w-2 h-2 rounded flex-none mt-3"></p>
      ) : (
        <p className=" w-2 h-2 rounded flex-none mt-3"></p>
      )}

      <div className="flex items-center flex-wrap gap-2 w-full">
        {internalNote?.image && internalNote?.imageUrl ? (
          <div className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary flex-none relative">
            <img
              src={`${internalNote?.imageUrl}${internalNote?.image}`}
              onError={onError}
              className="w-full h-full rounded-full"
            />
            <span
              className={`border border-white rounded-full ${
                internalNote?.user_status === 'online'
                  ? 'bg-successSecondary'
                  : 'bg-danger500'
              } w-2 h-2 absolute bottom-0 right-0 user-status-${
                internalNote?.id
              }`}
            ></span>
          </div>
        ) : (
          <div className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none relative">
            <div className="flex items-center h-full w-full text-xs justify-center">
              {getShortName(
                `${internalNote?.firstName} ${internalNote?.lastName}`
              )}
            </div>
            <span
              className={`border border-white rounded-full ${
                internalNote?.user_status === 'online'
                  ? 'bg-successSecondary'
                  : 'bg-danger500'
              } w-2 h-2 absolute bottom-0 right-0 user-status-${
                internalNote?.id
              }`}
            ></span>
          </div>
        )}
        <h6 className="text-textSecondary text-xs font-semibold flex-1 truncate">
          {internalNote?.firstName} {internalNote?.lastName}
        </h6>
        <span className="text-gray500 text-xs font-normal self-start">
          {moment.utc(internalNote?.createdAt).fromNow()}
        </span>
        <div className="text-grayLight600 text-xs font-normal w-full">
          {internalNote?.content}
        </div>
      </div>
    </div>
  </li>
);

export default InternalNoteCard;
