import { yupResolver } from '@hookform/resolvers/yup';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { PATH } from 'src/constants/path';
import { CustomersAllList } from 'src/services/CustomerService';
import { createOrder, getRecentOrders } from 'src/services/OrderService';
import { getSelectBoxOptions, getShortName } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import defaultimage from '../../assets/img/default-image.jpg';

const schema = yup.object().shape({
  customerId: yup.number().required('Cutomer is required'),
});

const CreateOrder = ({ setIsCreateOrder }: any) => {
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [recentOrderList, setRecentOrderList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerId: undefined,
    },
  });

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getRecentOrderList = () => {
    getRecentOrders()
      .then((response) => {
        if (response?.data) {
          setRecentOrderList(response?.data);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    getCustomerList();
    getRecentOrderList();
  }, []);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    createOrder(formData)
      .then((response) => {
        if (response?.data) {
          navigate(`${PATH.ORDER_DETAILS}/${response?.data?.id}`);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setIsCreateOrder(false);
  };

  console.log('recentOrderList', recentOrderList);

  return (
    <CommonModal
      title={'Create New Order'}
      titleDesc={'Enter Details for New Order'}
      handleClose={handleClose}
      size={'max-w-[540px] overflow-unset'}
      headerIcon={<PackagePlus />}
      primaryBtnText={'Create Order'}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      isOverflow={false}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        <Controller
          name="customerId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SelectBox
              label="Select Customer"
              labelClassName="form_label block mb-1.5"
              name="customerId"
              id="customerId"
              size="sm"
              placeholder="All Customers"
              noOptionMessage="No Customers Found"
              isSearchable={true}
              className="form_control"
              isClearable={true}
              options={customerList}
              onChangeFunc={(e: any) => onChange(e?.value)}
              value={customerList?.find((e) => e?.value == value)}
              isLoading={isCustomerLoading}
              errorText={errors.customerId ? errors.customerId.message : null}
            />
          )}
        />

        {recentOrderList && recentOrderList?.length > 0 && (
          <>
            <h6 className="mt-5 text-textSecondary text-sm font-medium mb-1.5">
              Recent
            </h6>
            <ul className="flex flex-col gap-y-3">
              {recentOrderList.map((order: any, index: number) => (
                <li
                  className="rounded-md p-1.5 flex items-center gap-x-2.5 cursor-pointer bg-transparent hover:bg-blue25"
                  key={index}
                  onClick={() => {
                    setValue('customerId', order?.customerId?.id);
                  }}
                >
                  {order?.customerId?.image ? (
                    <img
                      src={defaultimage}
                      className="w-8 h-8 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div className="table-profile-wrap">
                      <div className="table-profile-user-circle-xl">
                        {getShortName(order?.customerName)}
                      </div>
                    </div>
                  )}
                  <h6 className="text-grayLight900 text-sm font-medium">
                    {order?.customerName}
                  </h6>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default CreateOrder;
