import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import { listHandlingUnit } from 'src/services/CommonService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import { classOptions, equipmentTypeOptions } from '../../order.interface';

import AdditionalServices from './AdditionalServices';
import Dimensions from './Dimensions';

const AddOrderDetails = () => {
  const { control } = useFormContext();
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);

  useEffect(() => {
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  return (
    <>
      <h5 className="text-grayLight900 text-xl font-semibold">
        Add Order Details
      </h5>
      <div className="flex flex-wrap sm:-m-1.5">
        <Controller
          name={`orderType`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectBox
              name="orderType"
              id="orderType"
              className="form_control"
              label="Order Type"
              labelClassName={'form_label block mb-1.5'}
              parentClassName="sm:p-1.5 sm:w-1/3"
              size="sm"
              options={classOptions}
              onChangeFunc={(e: any) => {
                onChange(e?.value);
              }}
              isSearchable={false}
              value={
                value
                  ? classOptions.filter((val: any) => value === val.value)
                  : classOptions.filter((val: any) => 'ltl' === val.value)
              }
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`equipmentType`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectBox
              name="equipmentType"
              id="equipmentType"
              className="form_control"
              label="Equipment Type"
              labelClassName={'form_label block mb-1.5'}
              parentClassName="sm:p-1.5 sm:w-1/3"
              size="sm"
              options={equipmentTypeOptions}
              onChangeFunc={(e: any) => {
                onChange(e?.value);
              }}
              isSearchable={false}
              value={
                value
                  ? equipmentTypeOptions.filter(
                      (val: any) => value === val.value
                    )
                  : equipmentTypeOptions.filter(
                      (val: any) => 'dryvan' === val.value
                    )
              }
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`poNumber`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              inputName="PO_Number"
              label="PO Number"
              placeholder="Enter PO number"
              value={value}
              labelClassName="block mb-1.5"
              className=""
              parentClassName="sm:p-1.5 sm:w-1/3"
              onChangeFunc={onChange}
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`refNumber`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              inputName="refNumber"
              label="REF Number"
              placeholder="Enter REF number"
              value={value}
              labelClassName="block mb-1.5"
              className=""
              parentClassName="sm:p-1.5 sm:w-1/3"
              onChangeFunc={onChange}
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`linearFootage`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              inputName="linearFootage"
              label="Linear Footage"
              placeholder="Enter number"
              value={value}
              labelClassName="block mb-1.5"
              className=""
              parentClassName="sm:p-1.5 sm:w-1/3"
              onChangeFunc={onChange}
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`declaredValue`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              inputName="Declared_Value"
              label="Declared Value"
              placeholder="Enter number"
              value={value}
              labelClassName="block mb-1.5"
              className=""
              parentClassName="sm:p-1.5 sm:w-1/3"
              onChangeFunc={onChange}
              errorText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name={`specialNotes`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              inputName="Special_Notes"
              label="Special Notes"
              placeholder="Enter notes"
              value={value}
              labelClassName="block mb-1.5"
              className=""
              parentClassName="sm:p-1.5 w-full"
              onChangeFunc={onChange}
              errorText={error ? error.message : null}
            />
          )}
        />
      </div>
      <div>
        <Dimensions
          handlingUnitOptions={handlingUnitOptions}
          isHandlingUnitLoading={isHandlingUnitLoading}
        />
      </div>
      <div>
        <AdditionalServices />
      </div>

      <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs p-4 mb-4 last:mb-0">
        <Toggle
          // isDisabled={action.mode === 'viewCarrier'}
          labelPosition="left"
          label={`Would you like to insure this load?`}
          isChecked={true}
          onChange={() => {}}
          labelClassName="justify-between"
          labelTextClassName="text-textSecondary text-sm font-medium"
        />
      </div>
    </>
  );
};

export default AddOrderDetails;
