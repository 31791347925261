import {
  ArrowDown,
  ArrowUp,
  ArrowUpRight,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import useCountUp from 'src/hooks/useCountUp';

import {
  capitalizeFirstLetter,
  getFormattedNumber,
  useRolePermission,
} from '../../../utils/CommonFunctions';

import GrossProfit from './GrossProfit';
import { GraphType } from './OverView.interface';

interface IProps {
  salesData: any;
  currentFilterType: any;
  boxLabel: any;
  startDate: any;
  endDate: any;
  userId: any;
  teamId: any;
  isDataLoading: any;
}

const SalesCardBox = ({
  salesData,
  currentFilterType,
  boxLabel,
  startDate,
  endDate,
  userId,
  teamId,
  isDataLoading,
}: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const [graphTitle, setGraphTitle] = useState('');
  const [graphSubTitle, setGraphSubTitle] = useState('');
  const [graphType, setGraphType] = useState<string | null>(
    GraphType.MARGIN_PERCENTAGE.value
  );
  const [isDisplayGrossProfit, setIsDisplayGrossProfit] =
    useState<boolean>(false);

  const setIndividualGraphData = (
    graphTypeParam: string,
    graphTitleParam: string,
    graphSubTtl: string
  ) => {
    setGraphType(graphTypeParam);
    setGraphTitle(graphTitleParam);
    setGraphSubTitle(graphSubTtl);
    setIsDisplayGrossProfit(true);
  };

  return (
    <>
      <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1 xxl:p-5 xl:p-3 p-4">
        <div className=" ">
          <div className="w-full justify-between flex items-start">
            <div className="text-grayLight900 xxl:text-sm xl:text-[11px] text-xs font-medium flex justify-start items-start xxl:pr-4 pr-2">
              Total Margin {capitalizeFirstLetter(currentFilterType)}
            </div>
            <div
              className="flex justify-between items-center cursor-pointer flex-none"
              onClick={() =>
                setIndividualGraphData(
                  GraphType.TOTAL_MARGIN.value,
                  `Total Margin ${capitalizeFirstLetter(currentFilterType)}`,
                  `${GraphType.TOTAL_MARGIN.label} ${capitalizeFirstLetter(
                    currentFilterType
                  )}`
                )
              }
            >
              <div className="flex text-[11px] items-center text-primary font-semibold">
                View Graph <ArrowUpRight className="h-4 w-4" />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between pt-4 ">
            <div className="w-full flex flex-col justify-end">
              <div
                className={` ${isDataLoading ? 'w-fit custom-loading' : ''}`}
              >
                <span className="font-semibold xxl:text-3xl text-2xl">
                  {getFormattedNumber(
                    useCountUp(
                      salesData?.totalMargin
                        ? salesData?.totalMargin?.value.toFixed(2)
                        : 0
                    )
                  )}
                </span>
                <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                  $
                </sup>
              </div>
              <div className="w-full flex  justify-between ">
                <div
                  className={`w-fit flex justify-center items-center mt-2 ${
                    isDataLoading ? 'custom-loading' : ''
                  }`}
                >
                  {salesData?.totalMargin?.growth >= 0 ? (
                    <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                  ) : (
                    <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                  )}

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {useCountUp(
                        Math.abs(
                          salesData?.totalMargin?.growth
                            ? salesData?.totalMargin?.growth.toFixed(2)
                            : 0
                        )
                      )}
                      %
                    </span>{' '}
                    &nbsp;vs. {boxLabel}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1 xxl:p-5 xl:p-3 p-4">
        <div className="w-full justify-between flex  items-start">
          <div className="text-grayLight900 xxl:text-sm xl:text-[11px] text-xs font-medium flex justify-start items-start xxl:pr-4 pr-2">
            Number of Loads {capitalizeFirstLetter(currentFilterType)}
          </div>
          <div
            className="flex justify-between items-center cursor-pointer flex-none"
            onClick={() =>
              setIndividualGraphData(
                GraphType.ORDER_COUNT.value,
                `Number of loads ${capitalizeFirstLetter(currentFilterType)}`,
                `${GraphType.ORDER_COUNT.label} ${capitalizeFirstLetter(
                  currentFilterType
                )}`
              )
            }
          >
            <div className="flex text-[11px] items-center text-primary font-semibold">
              View Graph <ArrowUpRight className="h-4 w-4" />
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-4">
          <div className="flex flex-col justify-end flex-auto">
            <div className="flex justify-between items-center">
              <span
                className={`font-semibold xxl:text-3xl text-2xl ${
                  isDataLoading ? 'w-fit custom-loading pl-8' : ' '
                }`}
              >
                {getFormattedNumber(
                  useCountUp(
                    salesData?.orderCount ? salesData?.orderCount?.value : 0
                  ),
                  false
                )}
              </span>
            </div>

            <div
              className={`flex items-center mt-2 ${
                isDataLoading ? 'w-fit custom-loading' : ' '
              }`}
            >
              {salesData?.orderCount?.growth >= 0 ? (
                <ArrowUp className="w-4 h-4 text-successSecondary flex-none " />
              ) : (
                <ArrowDown className="w-4 h-4 text-danger flex-none " />
              )}
              <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                <span className="xxl:text-sm text-xs">
                  {useCountUp(
                    Math.abs(
                      salesData?.orderCount?.growth
                        ? salesData?.orderCount?.growth.toFixed(2)
                        : 0
                    )
                  )}
                  %
                </span>{' '}
                &nbsp;vs. {boxLabel}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          hasRoleV2('manager') || hasRoleV2('admin')
            ? 'xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full'
            : 'xl:min-w-[calc(25%_-_12px)] lg:min-w-[calc(50%_-_12px)] min-w-full'
        } bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg xxl:p-5 xl:p-3 p-4 flex-1`}
      >
        <div className="w-full justify-between font-medium flex  items-start">
          <h2 className="xxl:text-sm xl:text-[11px] text-xs font-medium xxl:pr-4 pr-2">
            Number of Customers
          </h2>
          <div
            className="flex text-[11px] items-center text-primary font-semibold cursor-pointer flex-none"
            onClick={() =>
              setIndividualGraphData(
                GraphType.CUSTOMER.value,
                'Number of Customers',
                GraphType.CUSTOMER.label
              )
            }
          >
            View Graph <ArrowUpRight className="h-4 w-4" />
          </div>
        </div>
        <div className="pt-4 text-start">
          <span
            className={`w-fit flex font-semibold xxl:text-3xl text-2xl ${
              isDataLoading ? 'w-fit custom-loading pl-8' : ''
            }`}
          >
            {useCountUp(salesData?.customerCount?.value ?? 0)}
          </span>
          <div
            className={`w-fit flex items-center mt-2 ${
              isDataLoading ? 'w-fit custom-loading' : ''
            }`}
          >
            {salesData?.customerCount?.growth >= 0 ? (
              <ArrowUp className="w-4 h-4 text-green500 flex-none " />
            ) : (
              <ArrowDown className="w-4 h-4 text-danger flex-none " />
            )}
            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
              <span className="xxl:text-sm text-xs">
                {useCountUp(
                  Math.abs(
                    salesData?.customerCount?.growth
                      ? salesData?.customerCount?.growth.toFixed(2)
                      : 0
                  )
                )}
                %
              </span>{' '}
              &nbsp;vs. {boxLabel}
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${
          hasRoleV2('manager') || hasRoleV2('admin')
            ? 'xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full'
            : 'xl:min-w-[calc(25%_-_12px)] lg:min-w-[calc(50%_-_12px)] min-w-full'
        } bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1 xxl:p-5 xl:p-3 p-4 `}
      >
        <div className="flex justify-between  items-start font-medium">
          <h2 className="xxl:text-sm xl:text-[11px] text-xs font-medium xxl:pr-4 pr-2">
            Avg. Margin Per Load
          </h2>
          <div
            className="flex text-[11px] items-center text-primary font-semibold cursor-pointer flex-none"
            onClick={() =>
              setIndividualGraphData(
                GraphType.DAILY_MARGIN.value,
                'Avg Daily Margin',
                GraphType.DAILY_MARGIN.label
              )
            }
          >
            View Graph <ArrowUpRight className="h-4 w-4" />
          </div>
        </div>

        <div className="pt-4 text-start">
          <div className={`w-fit ${isDataLoading ? 'custom-loading' : ''}`}>
            <span className="font-semibold xxl:text-3xl text-2xl">
              {getFormattedNumber(
                useCountUp(
                  salesData?.averageDailyMargin
                    ? salesData?.averageDailyMargin?.value.toFixed(2)
                    : 0
                )
              )}
            </span>
            <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
              $
            </sup>
          </div>

          <div
            className={`w-fit flex items-center mt-2 ${
              isDataLoading ? 'custom-loading' : ''
            }`}
          >
            {salesData?.averageDailyMargin?.growth >= 0 ? (
              <ArrowUp className="w-4 h-4 text-green500 flex-none " />
            ) : (
              <ArrowDown className="w-4 h-4 text-danger flex-none " />
            )}
            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
              <span className="xxl:text-sm text-xs">
                {useCountUp(
                  Math.abs(
                    salesData?.averageDailyMargin?.growth
                      ? salesData?.averageDailyMargin?.growth.toFixed(2)
                      : 0
                  )
                )}
                %
              </span>{' '}
              &nbsp;vs. {boxLabel}
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${
          hasRoleV2('manager') || hasRoleV2('admin')
            ? 'xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full'
            : 'xl:min-w-[calc(25%_-_12px)] lg:min-w-[calc(50%_-_12px)] min-w-full'
        } bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all  hover:shadow-lg flex-1 xxl:p-5 xl:p-3 p-4`}
      >
        <div className="flex justify-between  items-start font-medium ">
          <h2 className="xxl:text-sm xl:text-[11px] text-xs xxl:pr-4 pr-2">
            Margin Percentage
          </h2>
          <div
            className="flex text-[11px] items-center text-primary font-semibold cursor-pointer flex-none"
            onClick={() =>
              setIndividualGraphData(
                GraphType.MARGIN_PERCENTAGE.value,
                'Margin Percentage',
                GraphType.MARGIN_PERCENTAGE.label
              )
            }
          >
            View Graph <ArrowUpRight className="h-4 w-4" />
          </div>
        </div>
        <div className="pt-4 text-start">
          <div className={`w-fit ${isDataLoading ? 'custom-loading' : ''}`}>
            <span className="font-semibold xxl:text-3xl text-2xl">
              {getFormattedNumber(
                useCountUp(
                  salesData?.marginPercentage
                    ? salesData?.marginPercentage?.value.toFixed(2)
                    : 0
                )
              )}
            </span>
            <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
              %
            </sup>
          </div>
          <div
            className={`w-fit flex items-center mt-2 ${
              isDataLoading ? 'custom-loading' : ''
            }`}
          >
            {salesData?.marginPercentage?.growth >= 0 ? (
              <ArrowUp className={`w-4 h-4 text-green500 flex-none `} />
            ) : (
              <ArrowDown className={`w-4 h-4 text-danger flex-none `} />
            )}
            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
              <span className="xxl:text-sm text-xs">
                {useCountUp(
                  Math.abs(
                    salesData?.marginPercentage?.growth
                      ? salesData?.marginPercentage?.growth.toFixed(2)
                      : 0
                  )
                )}
                %
              </span>{' '}
              &nbsp;vs. {boxLabel}
            </p>
          </div>
        </div>
      </div>

      <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full xxl:p-5 xl:p-3 p-4 bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1">
        <div className="w-full justify-between font-medium flex  items-start">
          <h2 className="xxl:text-sm xl:text-[11px] text-xs font-medium xxl:pr-4 pr-2">
            New Customers
          </h2>
          <div
            className="flex text-[11px] items-center text-primary font-semibold cursor-pointer flex-none"
            onClick={() =>
              setIndividualGraphData(
                GraphType.NEW_CUSTOMER.value,
                'New Customers',
                GraphType.NEW_CUSTOMER.label
              )
            }
          >
            View Graph <ArrowUpRight className="h-4 w-4" />
          </div>
        </div>
        <div className="pt-4 text-start">
          <span
            className={`font-semibold xxl:text-3xl text-2xl ${
              isDataLoading ? 'custom-loading pl-8' : ''
            }`}
          >
            {getFormattedNumber(
              useCountUp(
                salesData?.newCustomerCount
                  ? salesData?.newCustomerCount?.value
                  : 0
              ),
              false
            )}
          </span>
          <div
            className={`w-fit flex items-center mt-2 ${
              isDataLoading ? 'custom-loading' : ''
            }`}
          >
            {salesData?.newCustomerCount?.growth >= 0 ? (
              <ArrowUp className="w-4 h-4 text-green500 flex-none " />
            ) : (
              <ArrowDown className="w-4 h-4 text-danger flex-none " />
            )}
            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
              <span className="xxl:text-sm text-xs">
                {useCountUp(
                  Math.abs(
                    salesData?.newCustomerCount?.growth
                      ? salesData?.newCustomerCount?.growth.toFixed(2)
                      : 0
                  )
                )}
                %
              </span>{' '}
              &nbsp;vs. {boxLabel}
            </p>
          </div>
        </div>
      </div>

      {isDisplayGrossProfit && (
        <GrossProfit
          handleClose={() => setIsDisplayGrossProfit(false)}
          graphType={graphType}
          graphTitle={graphTitle}
          graphDesc={graphSubTitle}
          teamId={teamId}
          userId={userId}
          currentFilterType={currentFilterType}
          startDate={startDate}
          endDate={endDate}
          setGraphType={setGraphType}
        />
      )}
    </>
  );
};

export default SalesCardBox;
