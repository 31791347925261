import { Minus, Plus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const TargetSetLoadingRow = () => (
  <>
    <td className="px-5 py-3 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <div className="flex items-center gap-3">
        <div className="flex-none">
          <div className="table-profile-customer-circle-xl custom-loading">
            A
          </div>
        </div>
        <div className="w-32 custom-loading">
          <h6 className="text-grayLight900 font-semibold leading-5 truncate">
            ########
          </h6>
          {/* <p className="truncate">
            Apt. 271 62656 Jacobi Curve, Douglas view, OK 07770-1159
          </p> */}
        </div>
      </div>
    </td>
    <td className="px-5 py-3 min-w-[14rem] w-[14rem] max-w-[14rem]">
      <div className="flex flex-wrap gap-1">
        <BadgeCmp
          style="modern"
          badgeDotColor="bg-yellow300"
          mainClassName=" custom-loading"
        >
          active
        </BadgeCmp>
        {/* <BadgeCmp
          style="modern"
          badgeDotColor="bg-yellow300"
          mainClassName=" custom-loading"
        >
          at-risk
        </BadgeCmp> */}
      </div>
    </td>
    <td className="px-5 py-3 min-w-[20%] w-[20%] max-w-[20%]">
      <span className="custom-loading">$107,246.51</span>
      <span className="custom-loading">&nbsp;/&nbsp;</span>
      <span className="custom-loading">$72,500</span>
      <span className="custom-loading">&nbsp;/&nbsp;</span>
      <span className="custom-loading text-textSecondary font-medium">
        0.94%
      </span>
    </td>

    <td className="px-5 py-3 min-w-[20%] w-[20%] max-w-[20%]">
      <span className="custom-loading">279</span>
      <span className="custom-loading">$72,50072</span>
      <span className="custom-loading">$0.00</span>
      <span className="custom-loading">&nbsp;/&nbsp;</span>
      <span className="custom-loading text-textSecondary font-medium">
        100.00%
      </span>
    </td>

    <td className="px-5 py-3 min-w-[18%] w-[18%]">
      <div className="relative custom-loading max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
        <ButtonCmp
          className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
          icon={<Minus className="w-4 h-4" />}
        >
          <></>
        </ButtonCmp>
        <InputText
          inputName="targetProfit"
          className="border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center"
          value=""
          inputType="text"
          parentClassName="custom-loading max-w-full"
          onChangeFunc={() => {}}
        />
        <ButtonCmp
          className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
          icon={<Plus className="w-4 h-4" />}
        >
          <></>
        </ButtonCmp>
      </div>
    </td>
    <td className="px-5 py-3 min-w-[18%] w-[18%]">
      <InputText
        inputName="targetLoads"
        className="bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs"
        value=""
        inputType="text"
        parentClassName="custom-loading w-28"
        onChangeFunc={() => {}}
      />
    </td>
  </>
);

export default TargetSetLoadingRow;
