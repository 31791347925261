import React from 'react';

const TeamPerformanceLoadingRow = () => (
  <>
    <td className="px-5 py-4 max-w-44 w-44 min-w-44">
      <div className="flex items-center gap-2 ">
        <div className="table-profile-user-circle-xl custom-loading">TS</div>
        <div className="max-w-24 truncate custom-loading">Test User</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$20000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default TeamPerformanceLoadingRow;
