import { Image04, Inbox01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext } from 'react';
import check from 'src/assets/img/double-check.svg';
import JPG from 'src/assets/img/JPGIcon.png';
import { TIMEZONE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { getShortName } from 'src/utils/CommonFunctions';

import pdf from '../../assets/img/pdf-icon.webp';
import BadgeCmp from '../BadgeCmp';
import ButtonCmp from '../ButtonCmp';
import CommonModal from '../CommonModal';
import CustomPagination from '../CustomPagination';
import NotFoundUI from '../NotFoundUI';
// import TabButton from '../TabButton';

import NotificationSkeletonLoader from './NotificationSkeletonLoader';

interface NotificationProps {
  filterArr?: Array<{ value: string; name: string }>;
  params: any;
  handleOnTabButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  numberOfSkeletonRows?: number;
  notifications: Array<any>;
  handleNotificationClick: (notification: any) => void;
  handleNotificationStatus: any;
  totals: number;
  setParams: React.Dispatch<React.SetStateAction<any>>;
  notFoundTitle: string;
  notFoundDesc: string;
  setNotFoundTitlec?: React.Dispatch<React.SetStateAction<any>>;
  handleClose: () => void;
}

const NotificationCmp = ({
  // filterArr,
  params,
  // handleOnTabButtonClick,
  isLoading,
  numberOfSkeletonRows = 5,
  notifications,
  handleNotificationClick,
  handleNotificationStatus,
  totals,
  setParams,
  notFoundTitle,
  notFoundDesc,
  handleClose,
}: NotificationProps) => {
  const { appearance } = useContext(BasicContext);
  const { notificationsCount } = useContext(EntityCountsContext);

  const getFormattedDateTime = (date: any, convertTimezone = false) => {
    let momentDate = moment(date);

    if (convertTimezone) {
      momentDate = momentDate.utc(date).tz(appearance?.timezone ?? TIMEZONE);
    }

    return momentDate.fromNow();
  };

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  return (
    <>
      <CommonModal
        title={
          <div>
            Notifications
            <BadgeCmp style="pill" type="primary" mainClassName="ml-1.5">
              {notificationsCount < 10 && notificationsCount !== 0
                ? `0${notificationsCount}`
                : notificationsCount}
            </BadgeCmp>
          </div>
        }
        size={'sm:w-[460px] w-[90%] min-h-[80vh]'}
        handleClose={handleClose}
        headerIcon={<Inbox01 className="w-4 h-4" />}
        modalParentClass="!justify-end"
        headerIconClass="!w-8 !h-8 !rounded-md"
        modalHeaderTextClass="!gap-x-1.5"
        modalClassName="flex flex-col"
      >
        {/* {filterArr && filterArr.length > 0 && (
          <TabButton
            tabArray={filterArr}
            tabParentClassName="mt-4 px-4 border-b border-utilityGray200"
            parentClassName=" w-full whitespace-nowrap overflow-x-auto scrollbar-hide !rounded-none"
            className="w-1/3 border-0 border-b-2 !border-r-0 border-transparent !rounded-none"
            activeClassName="border-b !border-primary"
            isActive={params.currentTab}
            handleOnClick={handleOnTabButtonClick}
          />
        )} */}

        <ul className="flex-1 flex flex-col overflow-x-auto custom-scrollbar-v2">
          {isLoading &&
            Array(numberOfSkeletonRows)
              .fill(null)
              .map((_, index) => <NotificationSkeletonLoader key={index} />)}

          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification: any) => (
              <li
                className=" bg-white hover:bg-gray100 cursor-pointer px-6 [&:last-child>div]:border-b-0"
                key={notification.id}
                onClick={() => handleNotificationClick(notification)}
              >
                <div className="border-b border-utilityGray200 py-5 flex gap-x-4 relative items-start">
                  {notification.notifier_first_name || notification.image ? (
                    <div className="profile-icon-wrap">
                      {notification.image ? (
                        <img
                          src={notification.imageUrl + notification.image}
                          className="table-profile-icn-circle-xl"
                        />
                      ) : (
                        <label className="table-profile-customer-circle-xl">
                          {getShortName(
                            `${notification.notifier_first_name} ${notification.notifier_last_name}`
                          )}
                        </label>
                      )}
                      <p className="active-dot"></p>
                    </div>
                  ) : (
                    <div className="table-profile-empty-circle-xl">&nbsp;</div>
                  )}
                  <div className="flex-1">
                    <div className="flex gap-x-1">
                      <h6
                        className="text-sm text-grayLight600 font-normal flex-1"
                        dangerouslySetInnerHTML={{
                          __html: notification.message,
                        }}
                      ></h6>
                      {notification.isNew ? (
                        <p className="bg-primary w-2.5 h-2.5 rounded-full flex-none"></p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex justify-between gap-x-3 text-grayLight600 text-xs leading-[1.5] font-normal">
                      <p>
                        {moment
                          .utc(notification.createdAt)
                          .local()
                          .format('dddd hh:mm A')}
                      </p>
                      <p>
                        {getFormattedDateTime(notification.createdAt, true)}
                      </p>
                    </div>
                    {notification?.isFileAttachment ? (
                      <div className="rounded-lg border border-utilityGray200 p-3 flex gap-2 bg-white mt-3">
                        {notification?.additionalData.includes('pdf') ? (
                          <img src={pdf} className="w-9 h-9 object-contain" />
                        ) : notification?.additionalData.includes('JPG') ? (
                          <img src={JPG} className="w-9 h-9 object-contain" />
                        ) : (
                          <div className="w-10 h-10 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500">
                            <Image04 className="w-7 h-7 object-contain" />
                          </div>
                        )}
                        <div
                          className="text-textSecondary text-xs leading-[1.5] font-normal "
                          dangerouslySetInnerHTML={{
                            __html: notification?.additionalData,
                          }}
                        ></div>
                      </div>
                    ) : notification?.additionalData ? (
                      <div className="rounded-lg bg-utilityindigo25 p-3 rounded-tl-none mt-3">
                        <p
                          className="text-textSecondary text-xs leading-[1.5] font-normal "
                          dangerouslySetInnerHTML={{
                            __html: notification?.additionalData,
                          }}
                        ></p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {!isLoading && notifications && notifications.length === 0 && (
          <NotFoundUI
            title={notFoundTitle}
            desc={notFoundDesc}
            containerClassName="flex-1"
            testId="wal-Claims-dataNotFoundText"
          />
        )}
        <div className="p-4 flex justify-between border-t border-utilityGray200 sticky bg-white bottom-0">
          <ButtonCmp
            className="btn_secondary_black"
            icon={<img src={check} />}
            onClick={() => {
              if (notifications.length > 0) handleNotificationStatus(false);
            }}
            disabled={notifications && notifications.length == 0}
          >
            Mark as all read
          </ButtonCmp>
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={handlePagination}
            isShowArrowAlways={true}
            parentClassName="border-t-0 !p-0 !w-auto"
            isShowPageDetail={false}
          />
        </div>
      </CommonModal>
    </>
  );
};

export default NotificationCmp;
