import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditClaimExplanation from 'src/app/Claims&Disputes/Claims/EditClaimExplanation';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { listClaim } from 'src/services/ClaimService';

import CustomerDetailClaimsLoadingRaw from './CustomerDetailClaimsLoadingRaw';
import CustomerDetailClaimsRaw from './CustomerDetailClaimsRaw';

interface IProps {
  params: any;
  setParams: any;
  customerName: any;
}

const CustomerDetailClaimsTab = ({
  params,
  setParams,
  customerName,
}: IProps) => {
  const navigate = useNavigate();

  const [claimData, setClaimData] = useState<any[]>([]);
  const [claims, setClaims] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isCustomerClaimsLoading, setIsCustomerClaimsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const claimsApiController = new AbortController();
    const claimsApiSignal = claimsApiController.signal;

    setClaims([]);
    setIsCustomerClaimsLoading(true);
    listClaim(params, claimsApiSignal)
      .then((response: any) => {
        setClaims(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsCustomerClaimsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerClaimsLoading(false);
      });

    return () => {
      claimsApiController.abort();
    };
  }, [params]);

  const headCells = useMemo(
    () => [
      {
        id: 'claimID',
        name: 'Claim ID',
        sortable: false,
        visible: true,
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
        visible: true,
      },
      {
        id: 'orderID',
        name: 'Order ID',
        sortable: false,
        visible: true,
      },
      {
        id: 'createdAt',
        name: 'Claim Created',
        sortable: true,
        visible: true,
      },
      {
        id: 'claimAmount',
        name: 'Claim Amount',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  // const getClaim = (claimId: any) => {
  //   setIsLoading(true);
  //   getClaimData(claimId)
  //     .then((response: any) => {
  //       if (response && response.data) {
  //         const responseData = response.data;
  //         setClaimData(responseData);
  //       } else {
  //         navigate(ROUTES.HOME);
  //       }
  //     })
  //     .catch(console.error)
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  return (
    <>
      <TableCmp
        headCells={headCells}
        params={params}
        setParams={setParams}
        tableDataArr={claims}
        TableLoaderRowCmp={CustomerDetailClaimsLoadingRaw}
        TableRowCmp={CustomerDetailClaimsRaw}
        isTableRowClickable={true}
        tableRowCmpProps={{
          onRowClick: (data: any) => {
            setClaimData(data);

            if (data?.status === 'New') {
              setShowModal(true);
            } else {
              localStorage.setItem(
                TABLE_IDS.CUSTOMER_DETAILS,
                JSON.stringify('claims&disputes')
              );

              navigate(`${PATH.CLAIM_DETAILS}/${data?.id}`);
            }
          },
        }}
        isTableDataLoading={isCustomerClaimsLoading}
        numberOfSkeletonRows={7}
      />
      {!isCustomerClaimsLoading && !claims?.length && (
        <NotFoundUI
          title={`No ${customerName}'s claim found`}
          desc={`There are currently no claim for ${customerName}`}
          containerClassName="min-h-[160px] !h-auto"
        />
      )}
      <CustomPagination
        recordsPerPage={params.limit}
        totalRecords={total}
        currentPage={params.page}
        handlePagination={(page: number) => {
          setParams((old: any) => ({ ...old, page }));
        }}
      />
      {showModal && (
        <EditClaimExplanation
          handleClose={() => setShowModal(false)}
          claim={claimData}
          title="Open Claim"
          isEdit={false}
          isClaimAmountUpdatable={true}
        />
      )}
    </>
  );
};

export default CustomerDetailClaimsTab;
