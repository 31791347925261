import {
  File04,
  Ticket01,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CgClose } from 'react-icons/cg';
import WalToast from 'src/utils/WalToast';

import CommonModal from '../../components/CommonModal';
import InputText from '../../components/InputText/InputText';
import SelectBox from '../../components/SelectBox/SelectBox';
import { useAppDispatch } from '../../redux/hooks';
import { getUserQuotes } from '../../services/QuoteService';

import {
  IFeedbackFormData,
  IFeedbackKey,
  IFeedbackValidForm,
  FeedbackType,
} from './Feedback.interface';
import { createTicketAction } from './Feedback.slice';

const initFormData: IFeedbackFormData = {
  title: '',
  quoteId: [],
  description: '',
  expectedOutcome: '',
  currentOutcome: '',
  links: '',
  image: null,
  feedbackType: FeedbackType.QUOTE,
};
const initValidForm: IFeedbackValidForm = {
  description: true,
  quoteId: true,
  feedbackType: true,
};

const feedbackTypeOptions = [
  {
    value: FeedbackType.QUOTE,
    label: 'Quoting issue',
  },
  {
    value: FeedbackType.GENERAL,
    label: 'General',
  },
];

const FeedbackCreate = ({
  isOpen,
  getList,
  handleClose,
  headerIcon,
  headerTitle,
  headerIconClass,
}: any) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quoteLoading, setQuotingLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFeedbackFormData>(initFormData);
  const [validForm, setValidForm] = useState<IFeedbackValidForm>(initValidForm);
  const [quoteList, setQuoteList] = useState<any>([]);
  const [requiredFields, setRequiredFields] = useState<IFeedbackKey>([
    'description',
    'quoteId',
    'feedbackType',
  ]);

  const updateImage = (fileList: any) => {
    let files: any = formData.image || [];
    let invalidFile = false;
    Array.from(fileList).forEach((file: any) => {
      if (file.type.match('image')) {
        files.push(file);
      } else {
        invalidFile = true;
      }
    });

    if (invalidFile) {
      WalToast.error('Please select only image');
    }
    setFormData({ ...formData, image: files });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      updateImage(acceptedFiles);
    },
  });

  useEffect(() => {
    setFormData(initFormData);
    setValidForm(initValidForm);
    setQuotingLoading(true);

    if (isOpen) {
      getUserQuotes()
        .then((response: any) => {
          let data = response.data.map((value: any) => ({
            value: value.id,
            label: value.code || value.id,
          }));
          setQuoteList(data);
          setQuotingLoading(false);
        })
        .catch(console.log);
    }
  }, [isOpen]);

  useEffect(() => {
    const isValidFields = requiredFields.every(
      (requiredField) =>
        formData[requiredField] !== '' && formData[requiredField] !== null
    );
    setIsValid(isValidFields);
  }, [formData, requiredFields]);

  useEffect(() => {
    if (formData.feedbackType === FeedbackType.QUOTE) {
      setRequiredFields(['description', 'feedbackType', 'quoteId']);
    } else {
      setRequiredFields(['description', 'feedbackType']);
    }
  }, [formData]);

  const handleSelectChange = (name: any) => (event: any) => {
    if (name === 'quoteId') {
      event = event ? [event] : [];
      const selectedValues = event.map((option: any) => option.value);
      setFormData({ ...formData, [name]: event ? selectedValues : null });
    } else {
      setFormData({ ...formData, [name]: event ? event.value : null });
    }
  };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      description: true,
      quoteId: true,
    };

    if (formData.description === '' || formData.description === null) {
      validFormData.description = false;
      valid = false;
    }

    if (formData.feedbackType === FeedbackType.QUOTE && !formData.quoteId) {
      validFormData.quoteId = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (value) {
        if (key === 'image') {
          value.forEach((file: any) => appendFormData.append(key, file));
        } else {
          if (
            key !== 'quoteId' ||
            formData.feedbackType === FeedbackType.QUOTE
          ) {
            appendFormData.append(key, value);
          }
        }
      }
    });

    const result = await dispatch(createTicketAction(appendFormData));

    if (result.type === createTicketAction.fulfilled.toString()) {
      handleClose();
      WalToast.success('Feedback Added successfully');
      getList();
    }

    if (result.type === createTicketAction.rejected.toString()) {
      WalToast.error('Feedback have not been Added');
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const removeImage = (index: any) => {
    let files = formData.image;
    files.splice(index, 1);
    setFormData({ ...formData, image: files });
  };

  const headerIconF = headerIcon ?? <Ticket01 className="w-5 h-5" />;
  const iconClass = headerIconClass ?? 'bg-primary text-white';
  const modalTitle = headerTitle ?? 'Add New Ticket';

  return (
    <CommonModal
      handleClose={handleClose}
      headerIconClass={iconClass}
      title={modalTitle}
      headerIcon={headerIconF}
      size={'max-w-3xl'}
      primaryBtnText="submit"
      primaryBtnOnClick={handleSubmit}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      secondaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={!isValid || isLoading}
    >
      <form className="p-5 flex flex-col sm:gap-5 gap-3">
        <div className="flex sm:flex-row flex-col sm:-m-2.5">
          <SelectBox
            isClearable
            name="feedbackType"
            label="Feedback Type"
            className="form_control"
            classComp={`no-close-drawer`}
            labelClassName="form_label mb-1.5"
            options={feedbackTypeOptions}
            onChangeFunc={handleSelectChange('feedbackType')}
            value={
              formData.feedbackType
                ? feedbackTypeOptions.find(
                    (option: any) => option.value === formData.feedbackType
                  )
                : null
            }
            parentClassName="sm:p-2.5 pb-3 sm:w-2/4 w-full"
          />
          {formData.feedbackType === FeedbackType.QUOTE && (
            <SelectBox
              isClearable
              name="quoteId"
              label="Your Quote"
              className="form_control"
              placeholder="Select your quote"
              labelClassName="form_label mb-1.5"
              options={quoteList}
              isLoading={quoteLoading}
              onChangeFunc={handleSelectChange('quoteId')}
              // value={formData.quoteId ? quoteList.filter((quote: any) => quote.value === formData.quoteId) : null}
              value={quoteList?.filter((val: any) =>
                formData.quoteId?.includes(val.value)
              )}
              parentClassName="sm:p-2.5 sm:w-2/4 w-full"
            />
          )}
        </div>
        <div>
          <h6 className="form_label mb-2.5">Feedback Images</h6>
          <div className="flex flex-col gap-2.5">
            <label
              htmlFor="feedbackImage"
              className="rounded-xl border border-utilityGray200 bg-white px-6 py-4 text-center cursor-pointer"
              {...getRootProps()}
            >
              <input
                type="file"
                name="image"
                id="feedbackImage22"
                accept=".svg, .png, .jpg, .jpeg"
                multiple
                {...getInputProps()}
              />

              <div className="w-9 h-9 mb-3 mx-auto rounded-lg border border-utilityGray200 shadow-xs justify-center items-center flex">
                <UploadCloud02 className="w-4 h-4 " />
              </div>
              <p className="text-xs mb-1 text-grayLight600 font-medium ">
                <span className="text-primary font-semibold">
                  Click to upload
                </span>
                or drag and drop
              </p>
              <p className="text-[11px] text-grayLight600 font-medium">
                SVG, PNG or JPG. Max image size should be 1024KB
              </p>
            </label>
            {formData.image &&
              formData.image.map((file: any, i: any) => (
                <div key={i} className="w-full bg-inputBg rounded-md px-3 py-2">
                  <div className="flex items-center justify-between">
                    <div className="content flex gap-3 flex-1 items-center">
                      <img
                        className="w-16 h-16 border border-gray-300 rounded-md object-cover"
                        src={URL.createObjectURL(file)}
                        alt={formData.title}
                        title={formData.title}
                      />
                      <div className="cont">
                        <h2 className="text-xs text-black font-medium">
                          {file.name}
                        </h2>
                        <p className="text-grayText text-[11px] mt-2">
                          {Math.round(file.size / 1000)} KB
                        </p>
                      </div>
                    </div>
                    <label
                      className="cursor-pointer"
                      onClick={() => removeImage(i)}
                    >
                      <CgClose className="text-black" size={18} />
                    </label>
                  </div>
                </div>
              ))}
            <div className="w-full bg-white border border-gray-300 rounded-md px-3 py-2 hidden">
              <div className="w-full justify-start items-start gap-2 flex">
                <File04 className="w-5 h-5 relative" />
                <div className="w-full flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch h-10 pr-8 flex-col justify-start items-start flex">
                    <div className="self-stretch text-slate-700 text-sm font-medium font-['Inter'] leading-tight">
                      Dashboard prototype FINAL.fig
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      4.2 MB
                    </div>
                  </div>
                  <div className="self-stretch flex justify-start items-center gap-3">
                    <progress
                      className="[&::-webkit-progress-value]:transition-all  [&::-webkit-progress-bar]:transition-all flex-1 [&::-webkit-progress-bar]:rounded-lg transition-all [&::-webkit-progress-value]:rounded-lg rounded-lg progress progress-primary bg-utilityGray200 me-3"
                      value={35}
                      max="100"
                    ></progress>
                    <div className="text-slate-700 text-sm font-medium leading-tight">
                      100%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InputText
          asTextarea={'textarea'}
          rows={10}
          inputName="description"
          label="Feedback Description"
          labelClassName="mb-1.5"
          className="h-20"
          placeholder="Describe your feedback here, go into details about the issue, the desired outcomes or general comments."
          value={formData.description}
          onChangeFunc={handleInputChange}
          errorText={!validForm.description ? 'Description is required' : null}
        />
      </form>
    </CommonModal>
  );
};

export default memo(FeedbackCreate);
