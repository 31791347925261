import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  // useMemo,
  useState,
} from 'react';
import CarriersListLoading from 'src/app/Carriers/CarriersListLoading';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
// import TableCmp from 'src/components/TableCmp';
import { RECORDS_PER_PAGE } from 'src/constants/common';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { listCarrier } from 'src/services/CarrierService';

import Row from './Row';

// import Row from './Row';

export const filterArr: any = [
  {
    value: 'common',
    name: 'Common LTL',
  },
  // {
  //   value: 'private',
  //   name: 'Private LTL',
  // },
  // asked by client to hide private ltl temporary
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'common',
  provider: '',
};

const providerArr = [
  { label: 'Banyan', value: 'banyan' },
  { label: 'Freightcom', value: 'freightcom' },
  { label: 'Direct', value: 'direct' },
];

const QuotingTeamTable = () => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [carriers, setCarriers] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);

  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const headCells = useMemo(
    () => [
      {
        id: 'user',
        name: 'User',
        sortable: true,
        visible: true,
        rowClassName: 'min-w-60 w-60 max-w-60 cursor-pointer',
      },
      {
        id: 'name',
        name: 'Total Hits',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'scope',
        name: 'Total Quote Requests',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'provider',
        name: 'Total Quotes',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'rateCount',
        name: 'Total Orders',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'quoteCount',
        name: 'Avg. Time to Classify',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },

      {
        id: 'quoteCount1',
        name: 'Avg. Time to Quote',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'quoteCount2',
        name: 'Win/Loss',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const getListCarrier = (signal: any) => {
    setCarriers([]);
    setIsLoading(true);
    listCarrier(params, signal)
      .then(() => {
        setCarriers([]);
        setTotals(0);
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        setIsRefresh(false);
      });
  };

  useEffect(() => {
    const carrierApiController = new AbortController();
    const carrierApiSignal = carrierApiController.signal;
    getListCarrier(carrierApiSignal);

    return () => {
      carrierApiController.abort();
    };
  }, [isRefresh, params]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      setParams((old: any) => ({ ...old, page: 1 }));
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  useEffect(() => {
    if (carriers && carriers.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (entityCounts?.carrierCount && entityCounts?.carrierCount > 0) {
          const param = { type: 'carrier_created' };
          handleNotificationRead(param);

          const newCarriers = carriers.map((data) => ({
            ...data,
            hasUnreadNotification: false,
          }));

          setCarriers(newCarriers);
        }
      }, 5000);
    }
  }, [carriers, entityCounts, isNotificationLoading]);

  return (
    <>
      <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">
                {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                Team Performance
              </h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {totals} {totals > 1 ? 'Carriers' : 'Carrier'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Manage analytics of your customer quotes.
            </p>
          </div>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              inputName="searchQuote"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput"
              isClearable={true}
            />
          </div>
          <div className="table-statustbox">
            <SelectBox
              name="provider"
              id="provider"
              className="form_control"
              size="sm"
              placeholder="Filter by provider"
              isClearable={true}
              isSearchable={false}
              options={providerArr}
              onChangeFunc={(event: any) =>
                setParams((old) => ({
                  ...old,
                  ...{ provider: event?.value, page: 1 },
                }))
              }
              value={providerArr.filter(
                (val: any) => params.provider === val.value
              )}
            />
          </div>
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={RECORDS_PER_PAGE}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={RECORDS_PER_PAGE.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div
          data-test-id="userTest"
          className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
        >
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            {/* <TableCmp
              headCells={headCells}
              tableDataArr={carriers}
              TableLoaderRowCmp={<ClaimLoaderRow />}
              TableRowCmp={<h1>hello</h1>}
              params={params}
              setParams={setParams}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={params.limit}
              isTableRowClickable={true}
              // tableRowCmpProps={{
              //   handleActionType: 'carrier',
              // }}
            /> */}
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={carriers}
              TableLoaderRowCmp={CarriersListLoading}
              TableRowCmp={Row}
              tableRowCmpProps={{ setIsRefresh: setIsRefresh }}
              numberOfSkeletonRows={15}
              isTableDataLoading={isLoading}
              tableHeaderClass=""
              isTableRowClickable={false}
            />
          </div>
          {carriers.length <= 0 && !isLoading && (
            <NotFoundUI
              title="No Carriers found"
              desc="There are no data for carriers."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </div>

        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </div>
      </div>
    </>
  );
};

export default QuotingTeamTable;
