import { DotsVertical } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PATH } from 'src/constants/path';
import { onError } from 'src/utils/CommonFunctions';

import { DATE_FORMAT } from '../../constants/common';

const FeedbackRow = ({
  data,
  currentTimeZone,
  tooltipItems,
  setFeedbackId,
}: any) => {
  const navigate = useNavigate();

  return (
    <>
      <td className="px-5 py-4 w-[10%] min-w-[10%]">
        <span>{data.id}</span>
      </td>
      <td className="px-5 py-4 w-[14.33%] min-w-[14.33%]">
        <BadgeCmp
          style="modern"
          mainClassName="text-nowrap"
          type={
            data.status === 'pending'
              ? 'error'
              : data.status === 'solved'
              ? 'success'
              : data.status === 'ignored'
              ? 'primary'
              : data.status === 'inReview'
              ? 'pink'
              : 'warning'
          }
        >
          {data.status === 'solved'
            ? 'Solved'
            : data.status === 'inReview'
            ? 'In review'
            : data.status === 'pending'
            ? 'Pending'
            : 'Ignored'}
        </BadgeCmp>
        {/* <div className="flex justify-between">
                                    <div
                                        className={`flex p-0.5 px-1.5 border justify-center items-center border-gray300 rounded-full ${
                                            data.status === "solved"
                                                ? "bg-success50 border-success200 text-green"
                                                : data.status === "inReview"
                                                ? "bg-yellow-50 border-yellow-200 text-yellow-700"
                                                : data.status === "pending"
                                                ? "bg-danger50 border-danger200 text-danger"
                                                : "bg-primary50 border-primary300 text-primary"
                                        }`}
                                    >
                                        <div
                                            className={`h-2 w-2 rounded-full mr-1 ${
                                                data.status === "solved"
                                                    ? "bg-green500 "
                                                    : data.status === "inReview"
                                                    ? "bg-yellow300 "
                                                    : data.status === "pending"
                                                    ? "bg-red-500 "
                                                    : "bg-primary"
                                            }`}
                                        ></div>
                                        {data.status === "solved"
                                            ? "Solved"
                                            : data.status === "inReview"
                                            ? "In review"
                                            : data.status === "pending"
                                            ? "Pending"
                                            : "Ignored"}
                                    </div>
                                </div> */}
      </td>
      <td className="px-5 py-4 w-[14.33%] min-w-[14.33%]">
        <p className="text-grayLight900">
          {moment
            .utc(data.createdAt)
            .tz(currentTimeZone)
            .format(`${DATE_FORMAT}`)}
        </p>
        <div className="text-[11px]">
          {moment.utc(data.createdAt).tz(currentTimeZone).format('hh:mm A')}
        </div>
      </td>
      <td className="px-5 py-4 w-[14.33%] min-w-[14.33%]">
        {data.firstName} {data.lastName}
      </td>
      <td
        className={` px-5 py-4 w-[14.33%] min-w-[14.33%] ${
          data.quoteId && data.code ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.quoteId) {
            navigate(`${PATH.MY_QUOTE}/${data.quoteId}`);
          }
        }}
      >
        {/* <div className="justify-start items-start gap-1 flex">
                                    <div className="px-1 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center flex">
                                        <div className="text-center text-textSecondary text-[11px] font-medium leading-[14px]">{data.code}</div>
                                    </div>
                                    <div className="px-1 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center flex">
                                        <div className="text-center text-textSecondary text-[11px] font-medium leading-[14px]">{data.code}</div>
                                    </div>
                                </div> */}

        {data.quoteId && data.code ? (
          <div className="flex gap-1">
            <BadgeCmp
              style="modern"
              type="info"
              isHidePillDot
              mainClassName="text-nowrap"
            >
              {data.code}
            </BadgeCmp>
            {/* <BadgeCmp style="modern" type="info" isHidePillDot mainClassName="text-nowrap">
                                            {data.code}
                                        </BadgeCmp> */}
          </div>
        ) : (
          <BadgeCmp style="modern" type="info" mainClassName="text-nowrap">
            General
          </BadgeCmp>
        )}
      </td>
      <td className="px-5 py-4 w-[14.33%] min-w-[14.33%]">
        {data.description.length > 20 ? (
          <TooltipCmp message={data.description} parentClassName="w-80">
            <div className="text-xs cursor-pointer">{`${data.description.slice(
              0,
              20
            )}...`}</div>
          </TooltipCmp>
        ) : (
          <div className="text-xs">{data.description}</div>
        )}
      </td>
      <td className="px-5 py-4 w-[14.33%] min-w-[14.33%]">
        {data?.images && data.images.length > 0 ? (
          <div className="flex flex-wrap gap-3 max-w-xs">
            {data.images.map((image: string, i: number) => (
              <div className="table-img-icn" key={i}>
                <img
                  className="w-9 h-9 rounded-full object-contain border-gray-200 border-[1px]"
                  src={`${data.imageUrlPrefix}${image}`}
                  alt={data.title}
                  title={data.title}
                  onError={onError}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-left">-</div>
        )}
      </td>

      <td className="px-5 py-4 w-16 max-w-16 min-w-16">
        <div className="dropdown dropdown-bottom dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="p-0 text-grayText rounded-md transition-all border-none bg-transparent !outline-0"
          >
            <ActionTooltip
              message={tooltipItems}
              isOpenChildTopPosOnTop={true}
              type="light"
            >
              {/* {console.log('data.id :>> ', data.id)} */}
              <DotsVertical
                className="cursor-pointer w-5 h-5 "
                onClick={() => setFeedbackId(data.id)}
              />
            </ActionTooltip>
          </div>
        </div>
      </td>
    </>
  );
};

export default FeedbackRow;
