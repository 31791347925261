import axios from 'axios';
import { API } from 'src/constants/api';
import WalToast from 'src/utils/WalToast';

const BaseApiUrl = window.API_URL;

export const axiosInterceptor = axios.create({
  baseURL: BaseApiUrl,
});

axiosInterceptor.interceptors.request.use(
  (config: any) => {
    if (localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    if (localStorage.getItem('accessToken')) {
      config.headers.AccessToken = localStorage.getItem('accessToken');
    }

    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('accessToken');
      localStorage.clear();
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

axiosInterceptor.interceptors.response.use(
  (response: any) => (response.status === 200 ? response.data : response),
  (error: any) => {
    const errorCatch =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (
      !errorCatch &&
      error.code !== 'ERR_CANCELED' &&
      error?.config?.url !== API.CURRENCY_RATE.GET
    ) {
      WalToast.error('Unexpected error. Please try again later.', '');
    }

    return Promise.reject(error);
  }
);
