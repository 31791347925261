import {
  Calendar,
  CurrencyDollar,
  PackageCheck,
  Percent02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import TabButton from 'src/components/TabButton';
import { onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../../../assets/img/default-image.jpg';
import InputText from '../../../../components/InputText/InputText';
import { MARGIN_TYPE } from '../../../../constants/common';
import { updateRate } from '../../../../services/QuoteService';

export const DATE_FORMAT: string = 'MMM DD, YYYY';

const initFormData = {
  transitTime: 0,
  carrierQuoteId: '',
  projectedDeliveryDate: moment(),
  margin: 0,
  marginUnit: 0,
  additionalCharges: 0,
  finalCharge: 0,
  totalCharge: 0,
  marginType: MARGIN_TYPE.VALUE,
  services: [],
  currency: '',
};

const initValidForm = {
  carrierQuoteId: false,
  totalCharge: false,
};

// const initEditableFormData = {
//   carrierQuoteId: false,
//   transitTime: false,
//   projectedDeliveryDate: false,
//   marginUnit: false,
//   totalCharge: false,
// };

const initRequiredFields = ['carrierQuoteId', 'totalCharge'];
const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const QuoteDetail = ({
  handleClose,
  currency,
  carrierRate,
  setIsRefresh,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [isValid, setIsValid] = useState<any>({});
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  // const [editableFormData, setEditableFormData] =
  //   useState(initEditableFormData);

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';
      const updatedFormData = { ...formData };

      const total =
        parseFloat(updatedFormData?.totalCharge || 0) +
        parseFloat(updatedFormData?.additionalCharges);

      if (newType === '%') {
        const margin = parseFloat(updatedFormData?.margin || 0);
        updatedFormData.marginUnit =
          total > 0 && margin > 0 ? ((margin / total) * 100).toFixed(2) : 0;

        updatedFormData.finalCharge = (total + margin).toFixed(2);
      } else {
        const marginUnit = parseFloat(updatedFormData?.marginUnit || 0);

        updatedFormData.margin =
          total > 0 && marginUnit > 0
            ? ((total * marginUnit) / 100).toFixed(2)
            : 0;

        updatedFormData.finalCharge = (
          total + parseFloat(updatedFormData?.margin)
        ).toFixed(2);
      }

      setFormData(updatedFormData);

      return newType;
    });
  };
  useEffect(() => {
    setIsValid(Object.values(validForm).every((value) => value));
  }, [validForm]);

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]: any) => {
      if (initRequiredFields.includes(key)) {
        setValidForm({ ...validForm, [key]: value ? true : false });
      }
    });
  }, [formData]);

  useEffect(() => {
    let services: any = [];

    if (carrierRate?.services && carrierRate?.services?.length) {
      carrierRate.services.forEach((service: any) => {
        services.push({
          [service.serviceId.slug]: {
            name: service.serviceId?.name,
            value: service.value || 0,
            serviceId: service.serviceId?.id,
          },
        });
        // existingRequiredFields.push(service.serviceId.slug);
      });
    }

    setFormData({ ...formData, services: services, currency });
  }, [carrierRate]);

  const handleInputChange = (event: any, isService = false) => {
    const { target, type } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      ['totalCharge', 'transitTime', 'margin'].includes(name) &&
      !regex.test(value)
    ) {
      return;
    }

    // if (name === "carrierQuoteId" && !editableFormData.carrierQuoteId) {
    //     return;
    // }

    if (initRequiredFields.includes(name)) {
      setValidForm({ ...validForm, [name]: value ? true : false });
    }

    const existingFormData = {
      ...{
        margin: 0,
        marginUnit: 0,
        totalCharge: 0,
        services: formData.services || [],
      },
      ...formData,
    };

    if (!isService) {
      existingFormData[name] = value;
    }

    if (name === 'transitTime') {
      existingFormData.projectedDeliveryDate = moment()
        .clone()
        .add(value, 'days')
        .toDate();
    }

    if (['totalCharge', 'marginUnit', 'margin'].includes(name) || isService) {
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;

      existingFormData.services.forEach((quoteService: any, index: number) => {
        Object.entries(quoteService).forEach(([slug, service]: any) => {
          service.value = service.value || 0;
          additionalCharges +=
            name === slug ? parseValue : parseFloat(service.value);
          existingFormData.services[index][slug] = {
            ...service,
            value: name === slug ? value : parseFloat(service.value),
          };
        });
      });
      existingFormData.additionalCharges = additionalCharges.toFixed(2);
      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);
      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 ? parseFloat(((total / 100) * marginUnit).toFixed(2)) : 0;
      } else {
        marginUnit =
          total > 0 ? parseFloat(((100 * margin) / total).toFixed(2)) : 0;
      }
      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = value;
      } else if (name === 'margin') {
        existingFormData.margin = value;
      }
    }

    setFormData(existingFormData);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    updateRate(carrierRate.id, formData)
      .then(() => {
        setFormData(initFormData);
        handleClose();
        setIsRefresh(true);
        WalToast.success('Rate updated successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(console.error);
  };

  return (
    <>
      <CommonModal
        // title="Add a Quick Quote"
        // titleDesc={'Review rate and margin details to create an active quote.'}
        handleClose={() => handleClose(false)}
        headerIcon={<PackageCheck />}
        size={'max-w-[615px] overflow-unset'}
        isOverflow={false}
        modalClassName=""
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading || !isValid}
        primaryBtnOnClick={handleSubmit}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <>
          <div className="flex-1">
            <div className={`flex justify-between border-b px-5 py-3.5`}>
              <div className="flex gap-1">
                <img
                  src={
                    carrierRate?.image
                      ? carrierRate.imageUrl + carrierRate.image
                      : defaultImage
                  }
                  onError={onError}
                  className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                  alt=""
                />
                <h6 className="text-grayLight900 text-sm font-semibold">
                  {carrierRate?.name}
                </h6>
              </div>

              <div
                className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full flex-none `}
              >
                <XClose
                  className="text-gray400 hover:text-textSecondary"
                  onClick={() => handleClose(false)}
                />
              </div>
            </div>
            <div className="w-full p-5">
              <div className="w-full border-b pb-4 border-utilityGray200 mb-4">
                <div className="flex -m-1 ">
                  <InputText
                    label="Carrier quote ID"
                    labelClassName="mb-1.5 block text-textSecondary"
                    inputType="text"
                    inputName="carrierQuoteId"
                    className="gap-x-1.5"
                    value={formData.carrierQuoteId}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    parentClassName="p-1 mb-2 w-1/2"
                  />
                  <DateSelect
                    inputName="projectedDeliveryDate"
                    className={`form_control `}
                    label="Projected delivery"
                    labelClassName="block"
                    placeholder={DATE_FORMAT}
                    dateFormat="dd/MM/yyyy"
                    minDate={moment().toDate()}
                    value={
                      formData.projectedDeliveryDate
                        ? (() => {
                            const date = new Date(
                              formData.projectedDeliveryDate
                            );
                            const day = date.getDate();
                            const month = date.toLocaleString('default', {
                              month: 'long',
                            });
                            const year = date.getFullYear();

                            return `${day} ${month}, ${year} (${formData.transitTime} Business Days)`;
                          })()
                        : 'N/A'
                    }
                    selected={
                      formData && formData.projectedDeliveryDate
                        ? moment(formData.projectedDeliveryDate).toDate()
                        : null
                    }
                    errorText={''}
                    onChangeFunc={(date: any) => {
                      const daysDiff =
                        moment(moment(date?.value)).diff(moment(), 'days') + 1;

                      if (
                        daysDiff > 0 &&
                        initRequiredFields.includes('transitTime')
                      ) {
                        setValidForm({
                          ...validForm,
                          transitTime: daysDiff ? true : false,
                        });
                      }
                      setFormData({
                        ...formData,
                        projectedDeliveryDate: moment(date?.value),
                        transitTime: daysDiff,
                      });
                    }}
                    icon={<Calendar className="h-4 w-4 " />}
                    calendarIconClass="!text-primary"
                    parentClassName="p-1 w-1/2 fixed-width-datepicker right-side-datepicker"
                  />
                  {/* <InputText
                    label="Transit time"
                    labelClassName="mb-1.5 block text-textSecondary"
                    inputType={editableFormData.transitTime ? 'number' : 'text'}
                    inputName="transitTime"
                    value={
                      editableFormData.transitTime
                        ? formData.transitTime
                        : `Est. ${
                            formData ? formData.transitTime : 0
                          } Business Days.`
                    }
                    onChangeFunc={handleInputChange}
                    onBlur={() =>
                      setEditableFormData((old: any) => ({
                        ...old,
                        transitTime: false,
                      }))
                    }
                    onFocus={() =>
                      setEditableFormData((old: any) => ({
                        ...old,
                        transitTime: true,
                      }))
                    }
                    parentClassName="p-1 mb-2 w-1/2"
                  /> */}
                </div>
              </div>
              <div className="flex flex-wrap -mx-1 gap-y-3 mb-4 empty:hidden">
                <div className="px-1 sm:w-1/2 w-full">
                  <InputText
                    label="Base Quote"
                    labelClassName="mb-1.5 block text-textSecondary"
                    inputType="text"
                    inputName="totalCharge"
                    value={formData.totalCharge}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    parentClassName="mb-2"
                  />
                </div>
                {formData?.services?.length > 0 && (
                  <>
                    {formData?.services.map((nonQuoteService: any) =>
                      Object.entries(nonQuoteService).map(
                        ([slug, service]: any) => (
                          <div key={slug} className="px-1 sm:w-1/2 w-full">
                            <label className="form_label mb-1.5 flex gap-1">
                              {service.name}
                            </label>

                            <InputText
                              inputName={slug}
                              className="pl-8"
                              parentClassName="[&:not(:last-child)]:mb-4"
                              value={service.value}
                              icon={
                                <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-4 w-4" />
                              }
                              onChangeFunc={(e) => handleInputChange(e, true)}
                              onBlur={(e) => handleInputChange(e, true)}
                              onFocus={(e) => handleInputChange(e, true)}
                            />
                          </div>
                        )
                      )
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-wrap -mx-1 gap-y-1 border-t border-utilityGray200 pt-4">
                <div className="px-1 sm:w-1/2 w-full">
                  <label className="form_label block mb-1.5">Margin</label>
                  <div className="form_control p-0 relative !h-auto">
                    {activeMarginType === '$' ? (
                      <InputText
                        inputName="margin"
                        inputType="text"
                        placeholder="0"
                        className="!border-0 !h-auto !pl-8 !pr-20"
                        labelClassName="block mb-1.5"
                        value={formData?.margin ?? ''}
                        onChangeFunc={handleInputChange}
                        onBlur={handleInputChange}
                        onFocus={() => {
                          if (formData?.marginUnit === 0) {
                            setFormData({
                              ...formData,
                              ...{
                                marginUnit: '',
                              },
                            });
                            setIsValid(false);
                          }
                        }}
                        icon={
                          <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
                        }
                      />
                    ) : (
                      <InputText
                        inputName="marginUnit"
                        inputType="text"
                        placeholder="0"
                        className="!border-0 !h-auto !pl-8 !pr-20"
                        labelClassName="block mb-1.5"
                        value={formData?.marginUnit}
                        onChangeFunc={handleInputChange}
                        onBlur={handleInputChange}
                        onFocus={handleInputChange}
                        icon={
                          <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
                        }
                      />
                    )}
                    <TabButton
                      tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                      parentClassName="!bg-gray50 !p-[3px]"
                      className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                      activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                      tabArray={tabArray}
                      isActive={activeMarginType}
                      isTab={true}
                      handleOnClick={handleTabChange}
                    />
                  </div>

                  {/* <InputText
                    inputType="text"
                    inputName="margin"
                    value={formData.margin}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    className="pl-[40px]"
                    label="Margin"
                    labelClassName="block"
                    icon={
                      <div className="bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                        <CurrencyDollar className="w-4 h-4" />
                      </div>
                    }
                    parentClassName="sm:w-1/2 p-1"
                  />
                  <InputText
                    inputType="text"
                    inputName="marginUnit"
                    value={formData.marginUnit}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    className="pl-[40px]"
                    label="&nbsp;"
                    labelClassName="sm:block hidden"
                    icon={
                      <div className="bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                        <Percent02 className="w-4 h-4" />
                      </div>
                    }
                    parentClassName="sm:w-1/2 p-1"
                  /> */}

                  {/* <InputText
                  inputName="finalCharge"
                  className="pl-8"
                  parentClassName="flex-1"
                  value={formData.finalCharge}
                  label="Final Price"
                  labelClassName="block mb-1.5"
                  icon={
                    <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4" />
                    </div>
                  }
                /> */}
                </div>
                <InputText
                  inputType="text"
                  label="Total Price"
                  inputName="finalCharge"
                  placeholder="Final Price"
                  className="pl-8"
                  labelClassName="block mb-1.5"
                  parentClassName="px-1 sm:w-1/2 w-full"
                  value={formData?.finalCharge}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  icon={
                    <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className="flex sm:flex-row flex-col justify-between sticky bottom-0 p-5 border-t border-borderPrimary bg-white -mx-5">
            <ButtonCmp
              type="submit"
              className="btn_primary w-full"
              loading={isLoading}
              disabled={isLoading || !isValid}
              onClick={handleSubmit}
            >
              Save Changes
            </ButtonCmp>
          </div> */}
        </>
      </CommonModal>
    </>
  );
};

export default memo(QuoteDetail);
