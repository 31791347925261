import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ConfirmModal from 'src/components/ConfirmModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  FOOT,
  HANDLING_UNIT_NUMBER,
  KGS,
  LBS,
  MEASUREMENT,
} from 'src/constants/common';
import { listHandlingUnit } from 'src/services/CommonService';
import { createUpdateDimension } from 'src/services/QuoteService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import DimensionItems from './DimensionItems';

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
};

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
];

interface IFormData {
  id: number | null;
  handlingUnit: string | null;
  handlingUnitNo: number;
  weight: number;
  itemLength: number;
  width: number;
  height: number;
  totalWeight: number;
  freightClass: number;
  isCommodity: boolean;
  isStack: boolean;
  weightMeasurement: string;
  commodityId: string | null;
  commodityName: string | null;
  sub: string | null;
  description: string | null;
}

const initFormData: IFormData = {
  id: null,
  handlingUnit: '',
  handlingUnitNo: 0,
  weight: 0,
  itemLength: 0,
  width: 0,
  height: 0,
  totalWeight: 0,
  freightClass: 0,
  isCommodity: false,
  isStack: true,
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  commodityId: null,
  commodityName: null,
  sub: null,
  description: null,
};

const providerArr = [
  { label: 'Imperial (lbs)', value: MEASUREMENT.WEIGHT1.value },
  { label: 'Metric (kgs)', value: MEASUREMENT.WEIGHT2.value },
];

const initValidForm = {
  handlingUnit: {
    required: true,
    valid: true,
  },
  handlingUnitNo: {
    required: true,
    valid: true,
  },
  weight: {
    required: true,
    valid: true,
  },
  itemLength: {
    required: true,
    valid: true,
  },
  freightClass: {
    required: true,
    valid: true,
  },
  width: {
    required: true,
    valid: true,
  },
  height: {
    required: true,
    valid: true,
  },
  totalWeight: {
    required: true,
    valid: true,
  },
  isCommodity: {
    required: true,
    valid: true,
  },
  isStack: {
    required: true,
    valid: true,
  },
  commodityId: {
    required: true,
    valid: true,
  },
  commodityName: {
    required: true,
    valid: true,
  },
  sub: {
    required: true,
    valid: true,
  },
  description: {
    required: true,
    valid: true,
  },
};

const initAction = {
  tailgate: false,
};

interface IProps {
  setIsCreateQuoteLoading: any;
}

const DimensionSection = forwardRef(
  ({ setIsCreateQuoteLoading }: IProps, ref) => {
    const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
    const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
    const [validForm, setValidForm] = useState([initValidForm]);
    const [lengthMeasurement, setLengthMeasurement] = useState(
      MEASUREMENT.LENGTH1.value
    );
    const [weightMeasurement, setWeightMeasurement] = useState(
      MEASUREMENT.WEIGHT1.value
    );
    const [validateMeasurement, setValidateMeasurement] = useState(
      initValidateMeasurement
    );
    const [formData, setFormData] = useState<IFormData[]>([initFormData]);
    const [action, setAction] = useState(initAction);

    const [isTailgate, setIsTailgate] = useState<any>([]);
    // todo : this will get used in additional service change function
    console.log(setIsTailgate);

    const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
    const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);

    const handleServiceActionType = (actionKey: string) => {
      setAction((old) => ({ ...old, [actionKey]: true }));
    };

    useEffect(() => {
      const form = formData.map((formDataField: any) => {
        formDataField.weightMeasurement = weightMeasurement;

        return formDataField;
      });

      setFormData(form);
      const validateMeasurementValue = {
        weight:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.WEIGHT
            : LBS.WEIGHT,
        length:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.LENGTH
            : LBS.LENGTH,
        width:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.WIDTH
            : LBS.WIDTH,
        height:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.HEIGHT
            : LBS.HEIGHT,
      };
      setValidateMeasurement((old) => ({
        ...old,
        ...validateMeasurementValue,
      }));
    }, [weightMeasurement]);

    useEffect(() => {
      listHandlingUnit()
        .then((result: any) => {
          if (result.data && result.data.length) {
            const handlingUnitSelectBoxOptions = getSelectBoxOptions(
              result.data,
              'id',
              'name'
            );
            setHandlingUnitOptions(handlingUnitSelectBoxOptions);
            const newDataArray = [...formData];
            newDataArray[0].handlingUnit =
              handlingUnitSelectBoxOptions.length > 0
                ? handlingUnitSelectBoxOptions[0].value
                : '';
            setFormData(newDataArray);
          }
        })
        .finally(() => setIsHandlingUnitLoading(false))
        .catch(console.error);
    }, []);

    const isFormValid = () => {
      if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
        handleServiceActionType('tailgate');

        return false;
      }
      const validFormData: any = [];
      formData.forEach((form: any) => {
        const validation = {
          ...initValidForm,
          handlingUnit: {
            required: Boolean(form.handlingUnit),
            valid: Boolean(form.handlingUnit),
          },
          weight: {
            required: Boolean(form.weight),
            valid: Boolean(form.weight),
          },
          handlingUnitNo: {
            required: Boolean(form.handlingUnitNo),
            valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
          },
          itemLength: {
            required: Boolean(form.itemLength),
            valid: form.itemLength <= validateMeasurement.length,
          },
          width: {
            required: Boolean(form.width),
            valid: form.width <= validateMeasurement.width,
          },
          height: {
            required: Boolean(form.height),
            valid: form.height <= validateMeasurement.height,
          },
          freightClass: {
            required: Boolean(form.freightClass),
            valid: form.freightClass > 0,
          },
          commodityId: {
            required: form.isCommodity ? Boolean(form.commodityId) : true,
            valid: form.isCommodity ? Boolean(form.commodityId) : true,
          },
          sub: {
            required: form.isCommodity ? Boolean(form.sub) : true,
            valid: form.isCommodity ? Boolean(form.sub) : true,
          },
        };
        validFormData.push(validation);
      });
      setValidForm(validFormData);
      let valid = validFormData.every((form: any) =>
        requiredFields.every(
          (requiredField) =>
            form[requiredField].required && form[requiredField].valid
        )
      );

      return valid;
    };

    useEffect(() => {
      // let totalUnit = 0;
      let totalWeight: number | string = 0;
      let weightMeasurementValue = '';

      formData.forEach((form: any) => {
        // totalUnit += form.handlingUnitNo || 0;
        totalWeight += form.totalWeight;
        weightMeasurementValue = form.weightMeasurement;
      });

      totalWeight = totalWeight.toFixed();
      // setTotal((old: any) => ({ ...old, totalUnit, totalWeight }));

      const isTotalWeightOverBool =
        weightMeasurementValue === MEASUREMENT.WEIGHT1.value
          ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
          : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
      setIsTotalWeightOver(isTotalWeightOverBool);

      return;
      // todo : add calculation here from tailgate warning
      setIsItemLengthOver(isTotalWeightOverBool);
    }, [formData]);

    const handleSubmit = (quoteId: any) => {
      const formPayload = formData.map((formDataField: any) => {
        formDataField.quoteId = quoteId;

        return formDataField;
      });

      createUpdateDimension({ dimensions: formPayload })
        .then((result) => {
          if (result) {
            if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
              handleServiceActionType('tailgate');
            } else {
              console.log('Calling Submit Service Function');
              // todo : add code for additional services
              // submitService();
            }
          }
        })
        .catch((err) => {
          console.error(err);
          WalToast.error('Dimensions have not been updated', '');
        })
        .finally(() => {
          // TODO : temporary making loader false but after additional service integration, false loader when service call ends
          setIsCreateQuoteLoading(false);
        });
    };

    // Expose the childFunction to the parent
    useImperativeHandle(ref, () => ({
      checkIsDimensionFormValid: isFormValid,
      handleDimensionsSubmit: handleSubmit,
    }));

    const handleModalClose = useCallback(
      (status: boolean) => () => {
        setAction(initAction);

        if (status) {
          // todo : add code to call api
          // submitService();
        }
      },
      [action]
    );

    return (
      <div>
        <div className="w-full pb-4">
          <SelectBox
            name="measurement_unit"
            id="measurement_unit"
            label="Measurement Units"
            required={true}
            className="form_control "
            labelClassName="text-xs"
            size="sm"
            placeholder="Filter by provider"
            isClearable={true}
            isSearchable={false}
            options={providerArr}
            onChangeFunc={(event: any) => {
              const val = event?.value;

              if (val === MEASUREMENT.WEIGHT1.value) {
                setLengthMeasurement(MEASUREMENT.LENGTH1.value);
                setWeightMeasurement(MEASUREMENT.WEIGHT1.value);
              } else if (val === MEASUREMENT.WEIGHT2.value) {
                setLengthMeasurement(MEASUREMENT.LENGTH2.value);
                setWeightMeasurement(MEASUREMENT.WEIGHT2.value);
              }
            }}
            value={providerArr.filter(
              (val: any) => weightMeasurement === val.value
            )}
          />
        </div>
        {isHandlingUnitLoading ? (
          <div className="flex justify-center mt-4">
            <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
          </div>
        ) : (
          <DimensionItems
            formData={formData}
            validForm={validForm}
            initFormData={initFormData}
            initValidForm={initValidForm}
            handlingUnitOptions={handlingUnitOptions}
            lengthMeasurement={lengthMeasurement}
            weightMeasurement={weightMeasurement}
            setFormData={setFormData}
            isHandlingUnitLoading={isHandlingUnitLoading}
            setValidForm={setValidForm}
          />
        )}

        {action.tailgate && (
          <ConfirmModal
            title="Tailgate Over Dimensions"
            description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
              weightMeasurement === MEASUREMENT.WEIGHT1.value
                ? `${LBS.MAX_WEIGHT}lbs`
                : `${KGS.MAX_WEIGHT}kgs`
            }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
            button1="Yes, I am sure"
            button2="Modify Selection"
            handleClose={handleModalClose}
          />
        )}
      </div>
    );
  }
);

export default DimensionSection;
