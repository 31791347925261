import { Calendar, ReverseRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';

interface IProps {
  handleClose: any;
}

const RecurringOrderModal = ({ handleClose }: IProps) => {
  const repeatArr = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
  ];

  return (
    <CommonModal
      title="Create a Reoccuring Order"
      titleDesc="Managing orders with automated task recurrence"
      handleClose={() => handleClose(false)}
      headerIcon={<ReverseRight />}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText="Assign"
      secondaryBtnText="Cancel"
      primaryBtnOnClick={() => {}}
      isOverflow={false}
    >
      <div className="p-5 flex flex-col gap-y-4">
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Pickup Date
          </h6>
          <DateSelect
            inputName="carrier_booking_date"
            className="form_control"
            parentClassName="datepicker-w-auto one-month-datepicker flex-1"
            onChangeFunc={() => {}}
            placeholder="Select Date"
            dateFormat="dd/MM/yyyy"
            icon={<Calendar className="h-5 w-5" />}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Repeat
          </h6>
          <SelectBox
            name="customerId"
            id="customerId"
            placeholder="All Customers"
            parentClassName="flex-1"
            options={repeatArr}
            onChangeFunc={() => {}}
            value={'Weekly'}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Every
          </h6>
          <div className="form_control p-0 relative !h-auto flex-1">
            <InputText
              inputName="every"
              placeholder="nter Value"
              className="!border-0 !h-auto !pr-20"
              value={'1'}
              inputType="text"
              // onChangeFunc={handleFilter}
            />
            <div className="absolute right-0 top-0 h-full flex items-center px-3 border-l border-utilityGray200 text-textSecondary text-xs font-normal">
              Week(s)
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default RecurringOrderModal;
