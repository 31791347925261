import { yupResolver } from '@hookform/resolvers/yup';
import { Calendar, Clock } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { createOrUpdateAppointment } from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import { initAction } from '../../order.interface';

interface IPops {
  modalTitle: string;
  modalDesc: string;
  setAction: any;
  primaryBtnText: string;
  type: string;
  orderId: number;
}

const schema = yup.object().shape({
  appointmentDate: yup
    .date()
    .required('Appointment Date is required')
    .nullable(),
  appointmentTime: yup
    .string()
    .required('Appointment Time is required')
    .test('is-valid-time', 'Invalid time format. Expected HH:mm', (value) => {
      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

      return timeRegex.test(value);
    })
    .test(
      'is-not-past-time',
      'Appointment Time cannot be in the past',
      function (value) {
        const { appointmentDate } = this.parent;
        const currentDate = moment().startOf('day');
        const selectedDate = moment(appointmentDate).startOf('day');

        if (selectedDate.isSame(currentDate, 'day')) {
          const selectedTime = moment(value, 'HH:mm');
          const currentTime = moment();

          return selectedTime.isAfter(currentTime);
        }

        return true;
      }
    ),
});

const roundToNearest30Minutes = (date: any) => {
  const minutes = moment(date).minutes();
  const roundedMinutes = Math.round(minutes / 30) * 30;

  return moment(date).minutes(roundedMinutes).second(0);
};

const AppointmentModal = ({
  modalTitle,
  modalDesc,
  setAction,
  primaryBtnText,
  type,
  orderId,
}: IPops) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const headerIcon = <Calendar className="w-7 h-7" />;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      appointmentDate: moment().toDate(),
      appointmentTime: roundToNearest30Minutes(moment()).format('HH:mm'),
    },
  });

  const handleClose = () => {
    setAction(initAction);
  };

  const onSubmit = (formData: any) => {
    setIsLoading(true);
    const formattedData = {
      ...formData,
      appointmentDate: moment(formData.appointmentDate).format('YYYY-MM-DD'),
      type,
      orderId,
    };

    createOrUpdateAppointment(formattedData)
      .then((response) => {
        if (response.data) {
          console.log('appointment set properly', response.data);
          handleClose();
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CommonModal
      title={modalTitle}
      titleDesc={modalDesc}
      handleClose={handleClose}
      headerIcon={headerIcon}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText={primaryBtnText}
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      isOverflow={false}
      secondaryBtnOnClick={handleClose}
    >
      <div className="w-full p-5 flex flex-col gap-y-4 ">
        <div className="sm:-mx-1.5 flex flex-wrap ">
          <Controller
            name="appointmentDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateSelect
                inputName="appointmentDate"
                className="form_control"
                parentClassName="datepicker-w-auto one-month-datepicker sm:px-1.5 sm:w-1/2 w-full max-sm:mt-4"
                label="Select Date"
                selected={value}
                onChangeFunc={(e: any) => onChange(e?.value)}
                minDate={moment().toDate()}
                labelClassName="block"
                placeholder="Select Date"
                dateFormat="dd/MM/yyyy"
                errorText={errors.appointmentDate?.message}
                icon={<Calendar className="h-5 w-5" />}
                value={getDateWithSuffixFormat(getFormattedPickupDate(value))}
              />
            )}
          />

          <Controller
            name="appointmentTime"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateSelect
                inputName="appointmentTime"
                className="form_control"
                parentClassName="datepicker-w-auto one-month-datepicker sm:px-1.5 sm:w-1/2 w-full max-sm:mt-4"
                label="Select Time"
                selected={
                  value
                    ? roundToNearest30Minutes(moment(value, 'HH:mm')).toDate()
                    : roundToNearest30Minutes(moment()).toDate()
                }
                onChangeFunc={(date: any) => {
                  onChange(moment(date?.value).format('HH:mm'));
                }}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                labelClassName="block"
                placeholder="Select Appointment Time"
                errorText={errors.appointmentTime?.message}
                icon={<Clock className="h-5 w-5" />}
                value={value}
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default AppointmentModal;
