import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

interface IProps {
  formData: any;
  shipperOptions: any;
  validForm: any;
  handleSelectChange: any;
  selectFormData: any;
  handleActionType: any;
  saveShipperDetails: any;
  isShipperBtnDisable: any;
  handleAutocompleteChange: any;
  handleInputChange: any;
  shipperAddress: any;
  setShipperAddress: any;
  isShipperLoading: any;
  status: any;
}

const ShipperAddressSection = ({
  formData,
  shipperOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isShipperBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  shipperAddress,
  setShipperAddress,
  isShipperLoading,
  status,
}: IProps) => {
  const { mapService } = useContext(BasicContext);

  return (
    <div className="w-full">
      <div className="mb-5 relative">
        <SelectBox
          isClearable
          isSearchable
          isLoading={isShipperLoading}
          name="shipperId"
          label="Shipper Details"
          className={`form_control ${
            !validForm.shipperId
              ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
              : ''
          }`}
          labelClassName="form_label mb-1.5 block"
          classComp=""
          options={shipperOptions}
          onChangeFunc={handleSelectChange('shipperId')}
          value={shipperOptions.find(
            (val: any) => val.value === selectFormData.shipperId
          )}
          errorText={!validForm.shipperId ? 'Shipper is required' : null}
        />
        <div className="absolute right-0 top-0 flex">
          {selectFormData.shipperId && (
            <button
              onClick={handleActionType('shipper', 'delete')}
              type="button"
              className="text-xs flex mr-2"
            >
              <TooltipCmp message="Delete Shipper">
                {/* <BiSolidTrash size={20} /> */}
                <Trash01 className="h-4 w-4" />
              </TooltipCmp>
            </button>
          )}
          <button
            onClick={saveShipperDetails('shipper')}
            type="button"
            className={`text-primary700 text-xs flex font-semibold gap-2.5 ${
              isShipperBtnDisable && 'text-opacity-50'
            }`}
            disabled={isShipperBtnDisable}
          >
            {selectFormData.shipperId ? (
              <Edit05 className="w-4 h-4" />
            ) : (
              <Plus className="w-4 h-4" />
            )}
            {selectFormData.shipperId ? 'Edit' : 'Add'} New Shipper
          </button>
        </div>
      </div>
      <div className="mb-5">
        <InputText
          inputName="shipperCompanyName"
          placeholder="Enter shipper name"
          className="form_control"
          label="Shipper Name"
          labelClassName="mb-1.5 block"
          value={formData.shipperCompanyName}
          errorText={
            !validForm.shipperCompanyName ? 'Shipper Name is required' : null
          }
          onChangeFunc={handleInputChange}
        />
      </div>
      <div className="relative">
        {status &&
          (mapService === 'trimble_map' ? (
            <AutoCompleteTrimble
              inputName="shipperAddress"
              required
              placeholder="Enter shipper address"
              className={`form_control truncate ${
                !validForm.shipperAddress
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label="Select Address"
              labelClassName="mb-1.5 block"
              isClearable
              onChangeFunc={handleAutocompleteChange('shipperAddress')}
              onBlur={handleAutocompleteChange('shipperAddress')}
              onAddressInputChange={setShipperAddress}
              // errorText={!validForm.shipperAddress ? "Address is required" : null}
              value={shipperAddress}
            />
          ) : (
            <AutoCompleteGoogle
              inputName="shipperAddress"
              required
              placeholder="Enter shipper address"
              className={`form_control truncate ${
                !validForm.shipperAddress
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label="Select Address"
              labelClassName="mb-1.5 block"
              isClearable
              onChangeFunc={handleAutocompleteChange('shipperAddress')}
              onBlur={handleAutocompleteChange('shipperAddress')}
              onAddressInputChange={setShipperAddress}
              // errorText={!validForm.shipperAddress ? "Address is required" : null}
              value={shipperAddress}
            />
          ))}

        <button
          onClick={handleActionType(
            'shipper',
            formData.shipperAddress.fullAddress
              ? 'addressUpdate'
              : 'addressCreate'
          )}
          type="button"
          className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute right-0 top-0`}
          disabled={false}
        >
          {formData.shipperAddress.fullAddress ? 'Change' : 'Select'} Address
        </button>
      </div>
    </div>
  );
};

export default ShipperAddressSection;
