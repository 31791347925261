import React from 'react';

const ErrorMsg = ({ errorText, mainClassName }: any) => (
  <p
    className={`text-xs font-normal text-red-600 dark:text-red-500 mt-1 empty:hidden ${mainClassName}`}
  >
    {errorText}
  </p>
);

export default ErrorMsg;
