import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { calculateNiceMaximum } from 'src/utils/CommonFunctions';

import { salesOptions } from './OverView.interface';

const initSalesOptions = {
  options: salesOptions,
  series: [
    {
      name: 'Booked',
      data: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    },
    {
      name: 'Delivered',
      data: [150, 260, 350, 450, 550, 650, 750, 850, 950],
    },
  ],
};

interface IProps {
  totalGrossProfitChartData: any;
  ordersUpdateSocketTriggeredCount: any;
  isAllDataLoading: any;
  isSalesGraphLoading: any;
}

const TotalGrossProfitChart = ({
  totalGrossProfitChartData,
  ordersUpdateSocketTriggeredCount,
  isAllDataLoading,
  isSalesGraphLoading,
}: IProps) => {
  const [totalSalesOptions, setTotalSalesOptions] = useState(initSalesOptions);

  useEffect(() => {
    if (!totalGrossProfitChartData?.length) {
      const noDataOption = {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: -50,
        style: { color: undefined, fontSize: '16px', fontFamily: undefined },
      };

      setTotalSalesOptions({
        ...totalSalesOptions,
        series: [],
        options: { ...salesOptions, noData: noDataOption },
      });

      return;
    }
    console.log('totalGrossProfitChartData ', totalGrossProfitChartData);

    const namesArray = totalGrossProfitChartData.map(
      (item: any) => item.xValue
    );

    // const filteredData = totalGrossProfitChartData.filter(
    //   (item: any) => item.yValue !== 0 && item.yValue !== false
    // );

    const filteredData = totalGrossProfitChartData.filter(
      (item: any) =>
        (item.bookedGrossProfit !== 0 && item.bookedGrossProfit !== false) ||
        (item.deliveredGrossProfit !== 0 && item.deliveredGrossProfit !== false)
    );

    if (filteredData?.length > 0) {
      const valueArrays = totalGrossProfitChartData.reduce(
        (acc: any, item: any) => {
          acc[0].push(
            item.bookedGrossProfit > 0 ? item.bookedGrossProfit.toFixed(2) : 0
          );
          acc[1].push(
            item.deliveredGrossProfit > 0
              ? item.deliveredGrossProfit.toFixed(2)
              : 0
          );

          return acc;
        },
        [[], []]
      );

      const max = Math.max(
        ...filteredData.map((item: any) =>
          Math.max(item.bookedGrossProfit, item.deliveredGrossProfit)
        )
      );
      const tickAmount = 5;

      const series = [
        { name: 'Booked', data: valueArrays[0] },
        { name: 'Delivered', data: valueArrays[1] },
      ];

      const noDataConfig =
        filteredData?.length > 0
          ? {}
          : {
              text: '',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: -50,
              style: {
                color: undefined,
                fontSize: '16px',
                fontFamily: undefined,
              },
            };

      setTotalSalesOptions({
        options: {
          ...salesOptions,
          xaxis: {
            ...salesOptions.xaxis,
            categories: namesArray,
            labels: {
              formatter: function (value: any) {
                if (value?.split && value?.split(',')?.length > 1) {
                  // formatting x axis label for daily filter
                  return value?.split(',')?.[0];
                }

                return value;
              },
            },
          },
          yaxis: {
            min: 0,
            max: calculateNiceMaximum(max, tickAmount),
            tickAmount: tickAmount,
            labels: {
              formatter: function (value: any) {
                if (value >= 1000) {
                  return `${(value / 1000).toFixed(0)}k`;
                }

                return value;
              },
            },
          },
          noData: noDataConfig,
        },
        series: series,
      });
    } else {
      const series: any[] = [];
      const noDataConfig = {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: -50,
        style: {
          color: undefined,
          fontSize: '16px',
          fontFamily: undefined,
        },
      };

      setTotalSalesOptions({
        ...totalSalesOptions,
        series: series,
        options: {
          ...salesOptions,
          xaxis: {
            ...salesOptions.xaxis,
            categories: namesArray,
            labels: {
              formatter: function (value: any) {
                if (value?.split && value?.split(',')?.length > 1) {
                  // formatting x axis label for daily filter
                  return value?.split(',')?.[0];
                }

                return value;
              },
            },
          },
          noData: noDataConfig,
        },
      });
    }
  }, [totalGrossProfitChartData]);

  return (
    <div className="w-full h-full bg-white p-5 rounded-xl border border-utilityGray200 shadow flex flex-col">
      <div className="w-full justify-between xls:items-center items-start flex sm:flex-row flex-col mb-2.5 xxl:gap-4 gap-2 relative xs:mt-0 mt-5">
        <div className=" text-grayLight900 sm:text-lg text-base font-semibold gap-2 xs:justify-between xs:items-center xl:pr-4 mdm:flex-none flex-1">
          <p className="flex xs:flex-row flex-col xs:items-center justify-between">
            Total Gross Profit
          </p>
          <p className="text-grayLight600 text-sm font-normal">
            Organizational total gross profit over time
          </p>
        </div>
        <div className="mdm:justify-center justify-end items-center mdm:gap-5 gap-2 sms:order-none -order-1 flex mr-1 sms:w-auto w-full sm:relative absolute sm:top-0 sm:right-[unset] xs:top-1 right-0 -top-5">
          <div className="rounded-[10px] justify-end items-center gap-2 flex">
            <div className="w-2 h-2 rounded-[10px] bg-indigo-600" />
            <div className="text-textSecondary text-xs font-normal leading-none">
              Booked
            </div>
          </div>
          <div className="rounded-[10px] justify-end items-center gap-[9px] flex ">
            <div className="w-2 h-2 rounded-[10px] bg-indigo-100" />
            <div className="text-textSecondary text-xs font-normal leading-none">
              Delivered
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full h-full min-h-[310px]"
        id="sales-chart-container"
        key={`${totalSalesOptions.series[0]?.name}_${
          (ordersUpdateSocketTriggeredCount ? false : isAllDataLoading) ||
          (ordersUpdateSocketTriggeredCount ? false : isSalesGraphLoading)
            ? 'custom-loading'
            : 'loaded'
        }`}
      >
        <Chart
          options={
            (ordersUpdateSocketTriggeredCount ? false : isAllDataLoading) ||
            (ordersUpdateSocketTriggeredCount ? false : isSalesGraphLoading)
              ? initSalesOptions.options
              : totalSalesOptions.options
          }
          series={
            (ordersUpdateSocketTriggeredCount ? false : isAllDataLoading) ||
            (ordersUpdateSocketTriggeredCount ? false : isSalesGraphLoading)
              ? initSalesOptions.series
              : totalSalesOptions.series
          }
          className={`${
            (ordersUpdateSocketTriggeredCount ? false : isAllDataLoading) ||
            (ordersUpdateSocketTriggeredCount ? false : isSalesGraphLoading)
              ? 'custom-loading graph-loading'
              : ''
          }`}
          type="bar"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default TotalGrossProfitChart;
