import { CheckSquareBroken, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const CreditIncreaseRequestLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]">
      <div className="flex items-center gap-3">
        <div className="table-profile-customer-circle-xl custom-loading">
          WA
        </div>
        <div className="truncate  flex flex-col custom-loading">
          <TooltipCmp
            message={'Olivia Rhye'}
            childrenClassName="text-grayLight900 font-medium custom-loading"
          >
            <p className="truncate">Olivia Rhye</p>
          </TooltipCmp>
          <TooltipCmp message={'#WAL-DMIF-1'}>#WAL-DMIF-1</TooltipCmp>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]">
      <div className="flex flex-wrap gap-1">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          Approved
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]">
      <div className={`flex items-center gap-3 cursor-pointer`}>
        <div className="table-profile-wrap">
          <div className="table-profile-customer-circle-sm  custom-loading">
            WA
          </div>
        </div>
        <p className="truncate custom-loading">
          1000540217 canada inc (dba Urban Customz)
        </p>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[170px] w-[17.75%] max-w-[17.75%]">
      <span className="custom-loading">$20,000</span>
    </td>
    <td className="px-5 py-4 min-w-[220px] w-[17.75%] max-w-[17.75%]">
      <span className="custom-loading">$5,000</span>
    </td>
    <td className="px-5 py-4 min-w-[195px] w-[17.75%] max-w-[17.75%]">
      <span className="custom-loading">$25,000</span>
    </td>
    <td className="px-5 py-4 w-20 max-w-20 min-w-20">
      <div className="justify-center items-center gap-2 flex ">
        <div className="w-4 h-4 custom-loading">
          <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
        </div>
        <div className="w-4 h-4 custom-loading">
          <XClose className="w-4 h-4 text-fgErrorPrimary" />
        </div>
      </div>
    </td>
  </>
);
export default CreditIncreaseRequestLoaderRow;
