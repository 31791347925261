import {
  Calendar,
  Cube01,
  Cube03,
  InfoCircle,
  Package,
  Plus,
  Repeat01,
  Signal01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IFormData, initShipperAddress } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { CustomersAllList } from 'src/services/CustomerService';
import { getMapService } from 'src/services/IntegrationService';
import { createQuote } from 'src/services/QuoteService';
import {
  createShipper,
  listShippers,
  updateShipper,
} from 'src/services/ShipperService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import Address from '../../QuotingDashboard/AddressDetails/Address';
import {
  CLASSIFICATION_TYPES,
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initValidForm,
  scriptId,
} from '../../QuotingDashboard/AddressDetails/Address.interface';

import ConsigneeAddressSection from './ConsigneeAddressSection';
import DimensionSection from './DimensionSection';
import ShipperAddressSection from './ShipperAddressSection';

const serviceTypeArr = [
  {
    label: 'LTL',
    value: CLASSIFICATION_TYPES.LTL,
    icon: <Package className="w-4 h-4" />,
  },
  {
    label: 'FTL',
    value: CLASSIFICATION_TYPES.FTL,
    icon: <Cube01 className="w-4 h-4" />,
  },
  {
    label: 'Volume LTL',
    value: CLASSIFICATION_TYPES.VOLUME_LTL,
    icon: <Cube03 className="w-4 h-4" />,
  },
];

const urgencyTypeArr = [
  { label: 'Live', value: 'live', icon: <Signal01 className="w-4 h-4" /> },
  { label: 'QFR', value: 'qfr', icon: <Repeat01 className="w-4 h-4" /> },
];

interface IProps {
  selectedEmail: any;
}

const CreateQuoteFromMailSection = ({ selectedEmail }: IProps) => {
  const navigate = useNavigate();
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const dimensionComponentRef = useRef<any>(null);

  const [customers, setCustomers] = useState<any>([]);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);

  const [formData, setFormData] = useState<IFormData>({
    ...initFormData,
    urgency: null,
    classification: null,
  });

  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');

  const [validForm, setValidForm] = useState(initValidForm);

  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);

  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');

  // loaders
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [isCreateQuoteLoading, setIsCreateQuoteLoading] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [action, setAction] = useState(initAction);

  useEffect(() => {
    console.log('temp customers ', customers);
  }, []);

  useEffect(() => {
    if (selectedEmail?.extractDetails) {
      console.log(JSON.parse(selectedEmail?.extractDetails));
    }
  }, [selectedEmail]);

  window.initMap = () => {
    setStatus(true);
  };

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });

    if (formData.pickupDate !== '' || formData.pickupDate !== null) {
      checkPickupDate(formData.pickupDate);
    }
  }, []);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const getCustomer = () => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          result.data = result.data.filter((data: any) => !data.isDeleted);

          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, []);

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setIsConsigneeBtnDisable(false);
        setConsigneeAddress(consignee.fullAddress);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
      }
    }
    setFormData((old) => ({ ...old, ...newData }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({ ...validForm, consigneeAddress: true });
      }

      if (name === 'shipperId') {
        setValidForm({ ...validForm, shipperAddress: true });
      }
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (companyName === '') {
      WalToast.error(`Please enter ${type}'s company name`, '');

      return false;
    }

    if (address.fullAddress === '' || typeof address !== 'object') {
      WalToast.error(`Please enter ${type}'s address`, '');

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setShipperAddress(value);
      setValidForm({ ...validForm, shipperAddress: true });
      setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setConsigneeAddress(value);
      setIsConsigneeBtnDisable(false);
      setValidForm({ ...validForm, consigneeAddress: true });
    }
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((old: any) => ({ ...old, [name]: value }));
  };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
      classification: true,
      urgency: true,
    };

    if (formData.classification === '' || formData.classification === null) {
      validFormData.classification = false;
      valid = false;
    }

    if (formData.urgency === '' || formData.urgency === null) {
      validFormData.urgency = false;
      valid = false;
    }

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    if (formData.shipperAddress.fullAddress === undefined) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperAddress.fullAddress === '' ||
      formData.shipperAddress.fullAddress === null
    ) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    if (formData.consigneeAddress.fullAddress === undefined) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeAddress.fullAddress === '' ||
      formData.consigneeAddress.fullAddress === null
    ) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      const tomorrow = moment().add(1, 'days');
      setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let dimensionValidationResponse = false;

    dimensionValidationResponse =
      dimensionComponentRef.current.checkIsDimensionFormValid();

    if (!isFormValid() || !dimensionValidationResponse) {
      return true;
    }

    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    console.log('handleSubmit selectedEmail ', selectedEmail);

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,
      equipmentType: formData.equipmentTypeId,
      classification: formData.classification,
      urgency: formData.urgency,
      isFromQuoteRequest: true,
      emailContentId: selectedEmail?.emailBody?.id,
    };

    setIsCreateQuoteLoading(true);

    createQuote(data)
      .then(async (result: any) => {
        if (result) {
          if (data?.classification === 'ltl') {
            await dimensionComponentRef.current.handleDimensionsSubmit(
              result.data?.id
            );
          } else {
            navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
          }
        }
      })
      .catch((error: any) => {
        const errMsg =
          error?.response?.status === 409
            ? error?.response?.data?.message
            : 'Something went wrong! Please try again';
        WalToast.error(errMsg, '');
      });
  };

  return (
    <>
      <div className="w-[25%] border-l border-utilityGray200 flex flex-col h-full">
        <div className="p-4 border-b border-utilityGray200">
          <h6 className="text-grayLight900 text-base font-medium">
            Create New Quote
          </h6>
          <p className="text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5">
            Fill out the quote request details below to access quoting tools.
          </p>
        </div>

        <ul className="flex-1 px-4 py-6 flex flex-col gap-y-4 overflow-y-auto custom-scrollbar-v2">
          {/* Quote main fields section */}
          <li>
            <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
              Quote
            </h6>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-3">
              <div className="flex flex-wrap sm:-m-1">
                <SelectBox
                  name="serviceTypeGroup"
                  id="serviceTypeGroup"
                  className="form_control shadow"
                  size="sm"
                  options={serviceTypeArr}
                  onChangeFunc={handleSelectChange('classification')}
                  value={serviceTypeArr.find(
                    (st) => st.value === formData?.classification
                  )}
                  label="Service Type"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="text-xs sm:p-1 sm:w-1/2 w-full"
                  errorText={!validForm.classification ? true : false}
                  required
                />

                <SelectBox
                  name="urgencyTypeGroup"
                  id="urgencyTypeGroup"
                  className="form_control shadow"
                  size="sm"
                  options={urgencyTypeArr}
                  onChangeFunc={handleSelectChange('urgency')}
                  value={urgencyTypeArr.find(
                    (ut) => ut.value === formData?.urgency
                  )}
                  label="Urgency"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="text-xs sm:p-1 sm:w-1/2 w-full"
                  errorText={!validForm.urgency ? true : false}
                  required
                />
              </div>

              <SelectBox
                className="form_control shadow"
                labelClassName="form_label block mb-1.5"
                parentClassName="text-xs"
                isClearable
                name="customerId"
                required
                label="Customer"
                isSearchable={true}
                isLoading={isCustomerLoading}
                options={customerOptions}
                value={customerOptions.find(
                  (cusOptions: any) => cusOptions.value === formData.customerId
                )}
                onChangeFunc={handleSelectChange('customerId')}
                errorText={!validForm.customerId ? true : false}
              />

              <div>
                <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                  <div className="flex">
                    <label className="form_label mb-0">Pickup Date</label>
                    <span className="text-red-600 ms-[2px] leading-4">*</span>
                  </div>
                  <p
                    className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                  >
                    {pickup1pmError && (
                      <>
                        <span className="text-xs text-primary font-normal flex items-center">
                          {tomorrowDayName === 'Saturday'
                            ? `Set to Monday`
                            : 'Set to Tomorrow'}
                        </span>
                        <a
                          onClick={() => selectNextDay(true)}
                          className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                        >
                          Yes
                        </a>
                        &nbsp;
                        {'/'}
                        <a
                          onClick={() => selectNextDay(false)}
                          className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                        >
                          No
                        </a>
                        <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                          <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                        </TooltipCmp>
                      </>
                    )}
                  </p>
                </div>

                <DateSelect
                  inputName="pickupDate"
                  className={`form_control ${
                    !validForm.pickupDate.valid
                      ? 'border border-red-500 mb-1 border-solid'
                      : ''
                  }`}
                  placeholder={DATE_FORMAT}
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  selected={
                    formData.pickupDate
                      ? moment(formData.pickupDate).toDate()
                      : null
                  }
                  value={getDateWithSuffixFormat(
                    getFormattedPickupDate(formData.pickupDate)
                  )}
                  errorText={''}
                  onChangeFunc={handleDateChange}
                  icon={<Calendar className="h-5 w-5" />}
                  parentClassName="w-full"
                />
              </div>
            </div>
          </li>

          {/* Quote shipper details section */}
          <li>
            <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
              Shipper Details
            </h6>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-3">
              <ShipperAddressSection
                formData={formData}
                shipperOptions={shipperOptions}
                validForm={validForm}
                handleSelectChange={handleSelectChange}
                selectFormData={formData}
                handleActionType={handleActionType}
                saveShipperDetails={saveShipperDetails}
                isShipperBtnDisable={isShipperBtnDisable}
                handleAutocompleteChange={handleAutocompleteChange}
                handleInputChange={handleInputChange}
                shipperAddress={shipperAddress}
                setShipperAddress={setShipperAddress}
                isShipperLoading={isShipperLoading}
                status={status}
              />
            </div>
          </li>

          {/* Quote consignee details section */}
          <li>
            <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
              Consignee Details
            </h6>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-3">
              <ConsigneeAddressSection
                formData={formData}
                consigneeOptions={consigneeOptions}
                validForm={validForm}
                handleSelectChange={handleSelectChange}
                selectFormData={formData}
                handleActionType={handleActionType}
                saveShipperDetails={saveShipperDetails}
                isConsigneeBtnDisable={isConsigneeBtnDisable}
                handleAutocompleteChange={handleAutocompleteChange}
                handleInputChange={handleInputChange}
                consigneeAddress={consigneeAddress}
                setConsigneeAddress={setConsigneeAddress}
                isShipperLoading={isShipperLoading}
                status={status}
              />
            </div>
          </li>

          {/* Quote consignee details section */}
          <li>
            <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
              Dimensions
            </h6>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-3">
              <DimensionSection
                ref={dimensionComponentRef}
                setIsCreateQuoteLoading={setIsCreateQuoteLoading}
              />
            </div>
          </li>
        </ul>

        <div className="px-4 py-3 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200">
          <ButtonCmp
            icon={<Plus className="w-4 h-4" />}
            className="btn_primary w-full"
            onClick={(e: any) => handleSubmit(e)}
            loading={isCreateQuoteLoading}
            disabled={isCreateQuoteLoading}
          >
            Create Quote
          </ButtonCmp>
        </div>
      </div>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />
    </>
  );
};

export default CreateQuoteFromMailSection;
