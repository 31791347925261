import { ChevronDown, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
// import profile1 from 'src/assets/img/tick-icon.svg';
import TooltipCmp from 'src/components/TooltipCmp';
import { ROSE_ROCKET, STATUS } from 'src/constants/common';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getLabelByValue,
  getShortName,
} from 'src/utils/CommonFunctions';

interface QuoteDetailTableRowProps {
  data: any; // You might want to replace 'any' with the specific data type
  totalWeight: number;
  dimensions: { weightMeasurement: string }[];
  totalUnit: number;
  isDisplayDimensionsDetail: boolean;
  setIsDisplayDimensionsDetail: any;
  addressDetails: any;
}

const QuoteDetailTableRow: React.FC<QuoteDetailTableRowProps> = ({
  data,
  totalWeight,
  dimensions,
  totalUnit,
  addressDetails,
  isDisplayDimensionsDetail,
  setIsDisplayDimensionsDetail,
}) => (
  <>
    <td className="px-5 py-4 min-w-[18%] max-w-[1px] w-[18%]">
      <div className="flex items-center gap-3">
        {/* <img
          src={data.image ? data.imageUrl + data.image : profile1}
          className="table-profile-icn-circle-xl"
          alt="Profile"
        /> */}
        {data.image ? (
          <img
            src={`${data.imageUrl}${data.image}`}
            className="table-profile-icn-circle-xl"
            alt={data.name}
            title={data.name}
          />
        ) : (
          <div className="table-profile-user-circle-xl">
            {getShortName(`${data.firstName} ${data.lastName}`)}
          </div>
        )}
        <div className="truncate">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.firstName} {data.lastName}
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #{data.code ?? ''}
          </p>
        </div>
      </div>
    </td>
    {data.status === STATUS.WON && (
      <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
        <Link
          to={`${ROSE_ROCKET.QUOTE_URL}${data.rossRocketId}`}
          className="underline text-primary"
          target="_blank"
          // onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          //   e.preventDefault()
          // }
        >
          #{data.rossRocketPublicId}
        </Link>
      </td>
    )}
    <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
      <TooltipCmp
        message={data?.customerName?.length > 20 ? data.customerName : null}
        parentClassName="md:!p-3 !p-1.5 max-w-90"
      >
        <p className="truncate ">{data.customerName}</p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-[20%] max-w-[1px] w-[20%]">
      <TooltipCmp
        message={data.shipperFullAddress}
        parentClassName="md:!p-3 !p-1.5 max-w-90"
      >
        <p className="flex truncate max-w-[80%]">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate">
            {data.shipperFullAddress}
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp
        message={data.consigneeFullAddress}
        parentClassName="md:!p-3 !p-1.5 max-w-90"
      >
        <p className="flex truncate max-w-[80%]">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate">
            {data.consigneeFullAddress}
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span
        dangerouslySetInnerHTML={{
          __html: data.pickupDate
            ? getDateWithSuffixFormat(
                moment.utc(data.pickupDate).format('MMM Do - YYYY')
              )
            : '-',
        }}
      />
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      {totalWeight}{' '}
      {dimensions.length > 0 &&
        getLabelByValue(dimensions[0].weightMeasurement)?.toLowerCase()}
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">{totalUnit}</td>
    <td className="px-5 py-4 min-w-[65px] w-[65px] max-w-[65px]">
      {/* <a
        className="text-primary underline font-semibold cursor-pointer"
        onClick={handleActionType('quoteDetail', '')}
      >
        View Details
      </a> */}
      {addressDetails?.classification !== QUOTE_CLASSIFICATION.FTL && (
        <div
          className="flex w-fit rounded border border-borderPrimary shadow-xs p-[3px]"
          onClick={() =>
            setIsDisplayDimensionsDetail(!isDisplayDimensionsDetail)
          }
        >
          {isDisplayDimensionsDetail ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </div>
      )}
    </td>
  </>
);

export default QuoteDetailTableRow;
