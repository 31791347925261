import { Link01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { creditSafeOnboardingCompanyList } from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

import CreditSafeCompanyLoadingRow from './CreditSafeCompanyLoadingRow';
import CreditSafeCompanyRow from './CreditSafeCompanyRow';

interface IProps {
  linkCompany: any;
  customerId: any;
  handleClose: any;
  setIsRefresh?: any;
  setSelectedCreditSafeCompanyId: any;
  selectedCreditSafeCompanyId: any;
  setCreditSafeToken?: any;
  isCreditLimitDetailLoading: boolean;
}

export const initParams = {
  page: 1,
  pageSize: 10,
};

const LinkCompanyToCreditSafeModal = ({
  customerId,
  linkCompany,
  handleClose,
  setIsRefresh,
  setSelectedCreditSafeCompanyId,
  selectedCreditSafeCompanyId,
  setCreditSafeToken,
  isCreditLimitDetailLoading,
}: IProps) => {
  const [params, setParams] = useState(initParams);
  const [isCreditSafeCompanyListLoading, setIsCreditSafeCompanyListLoading] =
    useState(true);
  const [creditSafeCompanies, setCreditSafeCompanies] = useState<any>([]);
  const [creditSafeCompaniesTotal, setCreditSafeCompaniesTotal] = useState(0);

  useEffect(() => {
    setIsCreditSafeCompanyListLoading(true);
    setCreditSafeCompanies([]);

    creditSafeOnboardingCompanyList({
      ...params,
      customerId: customerId,
    })
      .then((response: any) => {
        setCreditSafeCompanies(response?.data);
        setCreditSafeCompaniesTotal(response?.total ?? 0);
        setCreditSafeToken(response?.token);
        setIsCreditSafeCompanyListLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        WalToast.error('Something went wrong while fetching task list');
      });
    console.log(setIsRefresh);
  }, [customerId, params]);

  const headCells = useMemo(
    () => [
      {
        id: 'id',
        name: 'Id',
      },
      {
        id: 'id2',
        name: 'Id2',
      },
      {
        id: 'country',
        name: 'Country',
      },
      {
        id: 'safeNo',
        name: 'Safe No',
      },
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'address',
        name: 'Address',
      },
      {
        id: 'status',
        name: 'Status',
      },
      {
        id: 'officeType',
        name: 'Office Type',
      },
      {
        id: 'type',
        name: 'Type',
      },
      {
        id: 'webSite',
        name: 'Website',
      },
      {
        id: 'regNo',
        name: 'Reg No',
      },
      {
        id: 'Headquarters',
        name: 'Headquarters',
      },
      {
        id: 'phoneNumbers',
        name: 'Phone Numbers',
      },
      {
        id: 'activityCode',
        name: 'Activity Code',
      },
      {
        id: 'significantItems',
        name: 'Significant Items',
      },
      {
        id: 'fileNo',
        name: 'File No',
      },
      {
        id: 'groupId',
        name: 'Group Id',
      },
    ],
    []
  );

  // const recordsPerPageArray = [
  //   { label: '10', value: 10 },
  //   { label: '25', value: 25 },
  //   { label: '50', value: 50 },
  //   { label: '100', value: 100 },
  // ];

  return (
    <CommonModal
      title={`Link Customer To CreditSafe (${creditSafeCompaniesTotal})`}
      titleDesc={'Find and link current customer to credit safe.'}
      handleClose={() => handleClose(false)}
      headerIcon={<Link01 />}
      size={'max-w-[1688px]'}
      isOverflow={true}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Link"
      primaryBtnOnClick={() => linkCompany(true)}
      primaryBtnDisabled={
        isCreditSafeCompanyListLoading ||
        !creditSafeCompanies?.length ||
        isCreditLimitDetailLoading
      }
      // primaryBtnLoading={}
      // secondaryBtnLoading={}
    >
      <div className="w-full p-2 flex flex-col">
        <div className="table-bottom-header">
          <div className="table-header-bottom-left"></div>

          {/* <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  pageSize: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.pageSize
              )}
            />
          </div> */}

          <TableCmp
            headCells={headCells}
            params={params}
            setParams={setParams}
            tableDataArr={creditSafeCompanies}
            TableRowCmp={CreditSafeCompanyRow}
            TableLoaderRowCmp={CreditSafeCompanyLoadingRow}
            tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
            isTableDataLoading={isCreditSafeCompanyListLoading}
            tableRowCmpProps={{
              selectedCreditSafeCompanyId: selectedCreditSafeCompanyId,
              setSelectedCreditSafeCompanyId: setSelectedCreditSafeCompanyId,
            }}
          />
        </div>
        {creditSafeCompanies?.length ? (
          <div className="w-full ">
            <CustomPagination
              recordsPerPage={params.pageSize}
              totalRecords={creditSafeCompaniesTotal}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old) => ({ ...old, page }));
              }}
            />
          </div>
        ) : null}
        {!creditSafeCompanies?.length && !isCreditSafeCompanyListLoading && (
          <NotFoundUI
            title="No Customer Found"
            desc="There are no data for Customer."
            containerClassName="min-h-[unset]"
          />
        )}
      </div>
    </CommonModal>
  );
};

export default LinkCompanyToCreditSafeModal;
