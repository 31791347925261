import React from 'react';

const ConfirmOrderDimensionRow = ({ data }: any) => (
  <>
    <td>{data?.handlingUnitName || ''}</td>
    <td>{data?.handlingUnitNo || ''}</td>
    <td>{data?.freightClass || ''}</td>
    <td>{`${data?.weight} (${data?.weightMeasurement})`}</td>
    <td>{`${data?.itemLength}L X ${data?.width}W X ${data?.height}H`}</td>
  </>
);

export default ConfirmOrderDimensionRow;
