import {
  ArrowRight,
  Minimize02,
  Phone,
  Route,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import avatar from '../../../assets/img/Avatar1.png';
import star from '../../../assets/img/Star.svg';

const filterArr = [
  {
    value: 'lane_history',
    name: 'Lane History',
  },
  {
    value: 'top_carriers',
    name: 'Top Carriers',
  },
  {
    value: 'key_cities',
    name: 'Key Cities',
  },
];

const radiusArr = [
  {
    value: 50,
    label: getFormattedNumber(50),
  },
  {
    value: 100,
    label: getFormattedNumber(100),
  },
  {
    value: 500,
    label: getFormattedNumber(500),
  },

  {
    value: 1000,
    label: getFormattedNumber(1000),
  },
  {
    value: 2500,
    label: getFormattedNumber(2500),
  },
  {
    value: 5000,
    label: getFormattedNumber(5000),
  },
  {
    value: 10000,
    label: getFormattedNumber(10000),
  },
];

const populationArr = [
  {
    value: 100,
    label: getFormattedNumber(100),
  },
  {
    value: 500,
    label: getFormattedNumber(500),
  },
  {
    value: 5000,
    label: getFormattedNumber(5000),
  },
  {
    value: 10000,
    label: getFormattedNumber(10000),
  },
  {
    value: 25000,
    label: getFormattedNumber(25000),
  },

  {
    value: 50000,
    label: getFormattedNumber(50000),
  },
];

const LaneOverview = ({
  routeFilters,
  setRouteFilters,
  handleClose,
  searchResult,
}: any) => {
  const [activeTab, setActiveTab] = useState('key_cities');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex items-start gap-2">
          <div className="bg-utilityGray100 rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
            <Route className="w-4 h-4" />
          </div>
          <div className="flex-1">
            <h6 className="text-grayLight900 text-base font-semibold">
              Lane Overview
            </h6>
            <p className="text-gray500 text-xs font-normal">
              Here is an overview of your lane details.
            </p>
          </div>
          <ButtonCmp className="!p-1.5 btn_secondary_black">
            <Minimize02
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={handleClose}
            />
          </ButtonCmp>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex-1">
            <p className="text-gray500 text-xs font-normal">From</p>
            <p className="text-textSecondary text-xs font-medium">
              {' '}
              {searchResult.from}
            </p>
          </div>
          <ButtonCmp className="lg:!px-[9px] !px-2 btn_secondary_black mx-3">
            <ArrowRight className="w-4 h-4" />
          </ButtonCmp>
          <div className="pl-3 flex-1">
            <p className="text-gray500 text-xs font-normal">To</p>
            <p className="text-textSecondary text-xs font-medium">
              {searchResult.to}
            </p>
          </div>
        </div>
      </div>
      <div className="my-4 px-4  flex flex-col gap-y-6 flex-1 overflow-y-auto  custom-scrollbar-v2 ">
        <ul className="flex flex-wrap">
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Mileage</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              {searchResult.distance}
            </p>
          </li>
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Duration</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              {searchResult.duration}
            </p>
          </li>
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Total cost</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              $5,234
            </p>
          </li>
          <li className="w-1/4">
            <h6 className="text-gray500 text-xs font-normal">Cost per mile</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              $20 <span className="text-gray500 text-[11px]">($26)</span>
            </p>
          </li>
        </ul>
        <div>
          <TabButton
            parentClassName="!bg-gray50 !w-full "
            className={`!rounded-md !border-0 [&>span]:text-gray400 w-1/3`}
            activeClassName=" !bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
            tabArray={filterArr}
            tabParentClassName="mb-5"
            handleOnClick={(e: any) => {
              setActiveTab(e?.target?.dataset?.value);
            }}
            isActive={activeTab}
            isTab={true}
          />
          {activeTab === 'lane_history' && (
            <ul className="flex flex-col gap-2.5">
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg cursor-pointer">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 ">
                      <p className="text-gray500 text-xs font-normal mt-[1px]">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>
                  <h6 className="text-primary text-xs font-medium underline">
                    #WAL-DMIF-1
                  </h6>
                  {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Weight:&nbsp;
                    <span className="font-medium text-textSecondary">
                      20lbs
                    </span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Margin:&nbsp;
                    <span className="font-medium text-textSecondary">$56</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Total Cost:&nbsp;
                    <span className="font-medium text-textSecondary">$752</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Price:&nbsp;
                    <span className="font-medium text-textSecondary">
                      $905.80
                    </span>
                  </div>
                </div>
              </li>
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg cursor-pointer">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 ">
                      <p className="text-gray500 text-xs font-normal mt-[1px]">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>
                  <h6 className="text-primary text-xs font-medium underline">
                    #WAL-DMIF-1
                  </h6>
                  {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Weight:&nbsp;
                    <span className="font-medium text-textSecondary">
                      20lbs
                    </span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Margin:&nbsp;
                    <span className="font-medium text-textSecondary">$56</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Total Cost:&nbsp;
                    <span className="font-medium text-textSecondary">$752</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Price:&nbsp;
                    <span className="font-medium text-textSecondary">
                      $905.80
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          )}
          {activeTab === 'top_carriers' && (
            <ul className="flex flex-col gap-2.5">
              <li className="rounded-md border border-utilityGray200 px-2 py-3">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                  </div>

                  <div className="rating rating-sm rating-half">
                    {/* <input
                type="radio"
                name="rating-10"
                className="rating-hidden"
              /> */}
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                      defaultChecked
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1 flex items-center gap-1">
                    <Phone className="w-3 h-3 text-gray500" />
                    <a href="tel:18004092269" className="text-gray500">
                      1-800-409-2269
                    </a>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Loads:&nbsp;
                    <span className="font-medium text-textSecondary">768</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Average Price:&nbsp;
                    <span className="font-medium text-textSecondary">$542</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Last Order:&nbsp;
                    <span className="font-medium text-textSecondary">
                      #WAL-DMIF-1
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          )}
          {activeTab === 'key_cities' && (
            <>
              {searchResult?.status === 'loading' ? (
                <p className="p-3 text-sm">{searchResult?.message}</p>
              ) : searchResult?.status === 'error' ? (
                <p className="text-red-500 p-3 text-sm">{searchResult.error}</p>
              ) : searchResult?.cities?.length > 0 ? (
                <>
                  <div className="flex flex-wrap -mx-1 mb-5">
                    <SelectBox
                      label="Search Radius"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="sm:w-1/2 w-full px-1"
                      name="radius"
                      size="sm"
                      options={radiusArr}
                      onChangeFunc={(event: any) => {
                        setRouteFilters((old: any) => ({
                          ...old,
                          radius: event?.value ?? '',
                        }));
                      }}
                      isSearchable={false}
                      value={radiusArr.find(
                        (val: any) => val.value === routeFilters.radius
                      )}
                    />
                    <SelectBox
                      label="Population Size"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="sm:w-1/2 w-full px-1"
                      name="population"
                      className=""
                      size="sm"
                      options={populationArr}
                      onChangeFunc={(event: any) => {
                        setRouteFilters((old: any) => ({
                          ...old,
                          population: event?.value ?? '',
                        }));
                      }}
                      isSearchable={false}
                      value={populationArr.find(
                        (val: any) => val.value === routeFilters.population
                      )}
                    />
                  </div>
                  <ul>
                    {searchResult.cities.map((city: any, index: number) => (
                      <li
                        key={index}
                        className="relative flex items-start gap-x-2 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden [&:last-child>div:after]:hidden"
                      >
                        <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[26px] after:absolute flex items-center justify-center after:bg-utilityGray200">
                          <div className="rounded-full shadow-xs px-1 py-0.5 border border-utilityGray200 text-grayLight900 text-xs min-w-[22px] text-center">
                            {++index}
                          </div>
                        </div>
                        <div className="flex-1">
                          <h6 className="text-primary text-xs leading-[1.5] font-semibold">
                            {city.fullName}
                          </h6>
                          <p className="text-grayLight600 text-xs leading-[1.5] font-normal">
                            {city.population !== undefined
                              ? ` Population: ${city.population.toLocaleString()}`
                              : ' Population: Unknown'}
                          </p>
                        </div>

                        <p className="text-right text-grayLight900 text-xs leading-[1.5] font-medium">
                          {Math.round(city.distance)}km
                        </p>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p className="p-3 text-sm">
                  No cities found within the specified radius.
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaneOverview;
