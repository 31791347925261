import moment from 'moment';
import React from 'react';

const ReqQuoteLoading = () => (
  <>
    <td className="px-5 py-4 min-w-64">
      <div className=" flex items-center ">
        <div className="table-profile-customer-circle-xl custom-loading">
          J D
        </div>

        <div className="pl-3  max-w-52">
          <h6 className="text-grayLight900 font-medium leading-tight custom-loading truncate">
            *****************
          </h6>
          <p className="text-grayLight600 font-normal leading-tight custom-loading truncate">
            #11111
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center custom-loading">
        <div className="table-profile-customer-circle-xl custom-loading">
          J D
        </div>

        <div className="pl-3 font-medium">NAME</div>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading"> {`Est. 5 days`} </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">
        {moment().format('MMM. Do - YYYY')}{' '}
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>

    <td className="px-5 py-4">
      <span className="text-textSecondary font-bold custom-loading">
        2000000000
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="text-textSecondary font-bold custom-loading">
        2000000000
      </span>
    </td>
  </>
);

export default ReqQuoteLoading;
