import { AlertCircle, CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ButtonCmp from '../ButtonCmp';

interface IProps {
  title: string;
  desc: string;
  isIcon?: boolean;
  type: 'success' | 'error' | 'info';
}

const ToastComp = ({ title, desc, type, isIcon = true }: IProps) => {
  const [textColor, setTextColor] = useState('');
  const [borderColor, setBorderColor] = useState('');

  useEffect(() => {
    if (type === 'success') {
      setTextColor('text-fgSuccessPrimary');
      setBorderColor('border-fgSuccessPrimary');
    } else if (type === 'error') {
      setTextColor('text-fgErrorPrimary');
      setBorderColor('border-fgErrorPrimary');
    } else if (type === 'info') {
      setTextColor('text-primary');
      setBorderColor('border-primary');
    }
  }, [type]);

  return (
    <div className="flex gap-4">
      {isIcon ? (
        <div>
          <div className="w-5 h-5 relative rounded-full">
            <div
              className={`w-7 h-7 left-[-4px] top-[-4px] absolute opacity-30 rounded-3xl border-2 ${borderColor}`}
            />
            <div
              className={`w-[38px] h-[38px] left-[-9px] top-[-9px] absolute opacity-10 rounded-3xl border-2 ${borderColor}`}
            />
            {type === 'success' ? (
              <CheckCircle className={`${textColor} h-[20px] w-[20px]`} />
            ) : (
              false
            )}
            {type === 'error' ? (
              <AlertCircle className={`${textColor} h-[20px] w-[20px]`} />
            ) : (
              false
            )}
            {type === 'info' ? (
              <AlertCircle className={`${textColor} h-[20px] w-[20px]`} />
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="">
        <h2 className="text-gray-900 text-sm font-semibold leading-tight">
          {' '}
          {title}
        </h2>
        <p className="text-slate-700 text-sm font-normal leading-tight">
          {desc}
        </p>
        <p className="mt-1 text-primary text-sm font-normal hidden">
          View Details:&nbsp;
          <Link to={'#'} className="underline">
            #WAL-CPU2-2
          </Link>
        </p>
        <div className="flex flex-wrap mt-2 !hidden">
          <a
            href="#"
            className="
          me-3 text-sm font-semibold"
          >
            Dismiss
          </a>
          <ButtonCmp
            type="submit"
            className="btn_secondary_black !p-0 !border-0 !shadow-none text-primary !text-sm"
          >
            Undo
          </ButtonCmp>
        </div>
      </div>
    </div>
  );
};

export default ToastComp;
