import {
  ArrowUpRight,
  Package,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

import avatar from '../../../assets/img/Avatar1.png';

const CustomerDetail = ({
  customer,
  orders,
  isPendingQuoteLoading,
  isLoading,
  isLatestOrdersLoading,
  customerQuote,
  quoteTotal,
  handleClose,
}: any) => {
  const filterArr = [
    {
      value: 'pending_quote_requests',
      name: 'Pending Quote Requests',
    },
    {
      value: 'order_history',
      name: 'Order History',
    },
  ];

  const [activeTab, setActiveTab] = useState('pending_quote_requests');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex justify-between items-start gap-2">
          <div className="">
            {customer?.image ? (
              <img
                src={customer?.imageUrl + customer?.image}
                className="w-10 h-10 rounded-lg border border-utilityBlack border-opacity-[0.08] flex-none"
              />
            ) : (
              <div className="w-10 h-10 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                {getShortName(`${customer?.name}`)}
              </div>
            )}
          </div>

          <ButtonCmp className="!p-1.5 btn_secondary_black">
            <ArrowUpRight
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={() => handleClose()}
            />
          </ButtonCmp>
        </div>
        <div className="mt-2">
          <div className="flex gap-x-2">
            <h6
              className={`text-grayLight900 text-sm font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {customer?.name ?? '16935 111 Ave NW'}
            </h6>
            <BadgeCmp
              style="modern"
              type="success"
              mainClassName={isLoading ? 'custom-loading' : ''}
            >
              {quoteTotal > 0
                ? `${quoteTotal} Quote Requests`
                : `${quoteTotal} Quote Request`}
            </BadgeCmp>
          </div>

          <p
            className={`text-gray500 text-xs font-normal mt-0.5 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {customer?.fullAddress ?? '2450 Edison Boulevard, Twinsburg, US'}
          </p>
        </div>
        <ul className="flex flex-wrap gap-y-4 mt-6">
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Sales Rep.
            </h6>
            {!isLoading ? (
              <>
                {customer?.orderCommissioneeFirstName &&
                customer?.orderCommissioneeLastName ? (
                  <div className="flex items-center gap-x-0.5">
                    {customer.orderCommissioneeImage ? (
                      <img
                        src={
                          customer.orderCommissioneeImageUrl +
                          customer.orderCommissioneeImage
                        }
                        onError={onError}
                        className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1]">
                        {getShortName(
                          `${customer.orderCommissioneeFirstName} ${customer.orderCommissioneeLastName}`
                        )}
                      </div>
                    )}

                    <p
                      className={`text-grayLight900 text-xs font-medium truncate${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {(
                        customer?.orderCommissioneeFirstName +
                        customer.orderCommissioneeLastName
                      ).length > 13 ? (
                        <TooltipCmp
                          message={`${customer?.orderCommissioneeFirstName} ${customer.orderCommissioneeLastName}`}
                          parentClassName=""
                        >
                          {`${customer?.orderCommissioneeFirstName} ${customer.orderCommissioneeLastName}`}
                        </TooltipCmp>
                      ) : (
                        `${customer?.orderCommissioneeFirstName} ${customer.orderCommissioneeLastName}`
                      )}
                    </p>
                  </div>
                ) : (
                  '-'
                )}
              </>
            ) : (
              <div className="flex items-center gap-x-0.5">
                <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1] custom-loading"></div>
                <p className="text-grayLight900 text-xs font-medium custom-loading">
                  respresentive
                </p>
              </div>
            )}
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Orders
            </h6>

            <p
              className={`text-grayLight900 text-xs font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {getFormattedNumber(customer?.totals?.totalOrders)}
            </p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Price
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              $
              {customer?.totals?.totalMargin
                ? getFormattedNumber(customer?.totals?.totalMargin)
                : 0}
            </p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Avg. Margin
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              $
              {customer?.totals?.avgOrderValue
                ? getFormattedNumber(customer?.totals?.avgOrderValue)
                : 0}
            </p>
          </li>
        </ul>
      </div>
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className={`!rounded-md !border-0 [&>span]:text-gray400 w-1/2`}
        activeClassName=" !bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
        tabArray={filterArr}
        tabParentClassName="mt-5 px-4"
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
        isActive={activeTab}
        isTab={true}
      />
      <div className="my-5 px-4 flex-1 overflow-y-auto  custom-scrollbar-v2 flex flex-col">
        {activeTab === 'pending_quote_requests' && (
          <ul className="flex flex-col gap-y-3 flex-1">
            <div className="text-grayLight600 text-sm font-medium leading-tight">
              Pending Quote Requests
            </div>
            {!isPendingQuoteLoading && customerQuote.length ? (
              <>
                {customerQuote.map((val: any, index: number) => (
                  <li
                    className="rounded-lg border border-utilityGray200 p-3"
                    key={index}
                  >
                    <div className="flex items-center gap-x-2.5 mb-4">
                      <div className="rounded-md border border-utilityGray200 bg-utilityGray100 p-2 relative">
                        <PackagePlus className="w-4 h-4 text-grayLight600" />
                        <div className="bg-primary rounded-full w-2 h-2 absolute -top-0.5 -right-0.5"></div>
                      </div>
                      <div className="flex-1">
                        <Link
                          to={'#'}
                          className="text-primary underline text-sm font-medium"
                        >
                          #{val?.code}
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-1.5">
                      <div className="flex justify-between gap-x-3 gap-y-1">
                        <p className="text-grayLight600 text-xs font-normal">
                          Customer
                        </p>
                        <div className="flex items-center gap-x-0.5">
                          <img
                            src={avatar}
                            className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                          />
                          <p className="text-textSecondary text-xs font-medium">
                            {val?.customerName}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between gap-x-3 gap-y-1 ">
                        <p className="text-grayLight600 text-xs font-normal">
                          Quote Request Time
                        </p>
                        <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: val?.createdAt
                                ? getDateWithSuffixFormat(
                                    getFormattedDate(
                                      val?.createdAt,
                                      `MMM Do , hh:mm A`,
                                      true,
                                      true
                                    )
                                  )
                                : '-',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <>
                {!customerQuote.length && !isPendingQuoteLoading ? (
                  <NotFoundUI
                    title="No Pending quote found"
                    desc=""
                    containerClassName="!my-4 h-full"
                  />
                ) : (
                  //   </div>
                  // </li>
                  <li className="rounded-lg border border-utilityGray200 p-3">
                    <div className="flex items-center gap-x-2.5 mb-4">
                      <div className="rounded-md border border-utilityGray200 bg-utilityGray100 p-2">
                        <span className="w-4 h-4 text-grayLight600 custom-loading" />
                      </div>
                      <div className="flex-1">
                        <Link
                          to={'#'}
                          className="text-primary underline text-sm font-medium"
                        >
                          <span className="custom-loading">#WAL-DMIF-1</span>
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-1.5">
                      <div className="flex justify-between gap-x-3 gap-y-1">
                        <p className="text-grayLight600 text-xs font-normal">
                          Customer
                        </p>
                        <div className="flex items-center gap-x-0.5 custom-loading image-loading">
                          <img
                            src={avatar}
                            className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none custom-loading"
                          />
                          <p className="text-textSecondary text-xs font-medium custom-loading">
                            <span className="custom-loading">
                              {' '}
                              Katherine Moss{' '}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between gap-x-3 gap-y-1 ">
                        <p className="text-grayLight600 text-xs font-normal ">
                          Quote Request Time
                        </p>
                        <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium ">
                          <span className="custom-loading">
                            Aug 23rd, 05:47 AM
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </>
            )}
          </ul>
        )}
        {activeTab === 'order_history' && (
          <ul className="flex flex-col gap-y-3 flex-1">
            <div className="text-grayLight600 text-sm font-medium leading-tight">
              Order History
            </div>
            {!isLatestOrdersLoading && orders.length ? (
              <>
                {orders.map((val: any, index: number) => (
                  <li
                    className="rounded-lg border border-utilityGray200 p-3"
                    key={index}
                  >
                    <div className="flex items-center gap-x-2.5 mb-4">
                      <div className="rounded-md border border-utilityGray200 bg-utilityGray100 p-2">
                        <Package className="w-4 h-4 text-grayLight600" />
                      </div>
                      <div className="flex-1">
                        <Link
                          to={'#'}
                          className="text-primary underline text-sm font-medium"
                        >
                          #{val?.fullId}
                        </Link>
                      </div>
                      {(val.orderStatus === 'new' ||
                        val.orderStatus === 'saved' ||
                        val.orderStatus === 'quoted' ||
                        val.orderStatus === 'spot-qq-requested' ||
                        val.orderStatus === 'spot-quote-requested' ||
                        val.orderStatus === 'pending' ||
                        val.orderStatus === 'draft-quick-quote' ||
                        val.orderStatus === 'quick-quoted' ||
                        val.orderStatus === 'reject' ||
                        val.orderStatus === 'archived' ||
                        val.orderStatus === 'expired') && (
                        <BadgeCmp style="modern" type="gray">
                          {val.orderStatus}
                        </BadgeCmp>
                      )}
                      {(val.orderStatus === 'no-quote' ||
                        val.orderStatus === 'no-quick-quote' ||
                        val.orderStatus === 'cancelled') && (
                        <BadgeCmp style="modern" type="pink">
                          {val.orderStatus}
                        </BadgeCmp>
                      )}
                      {(val.orderStatus === 'tender' ||
                        val.orderStatus === 'booked' ||
                        val.orderStatus === 'invoice-paid' ||
                        val.orderStatus === 'delivered') && (
                        <BadgeCmp style="modern" type="lightblue">
                          {val.orderStatus}
                        </BadgeCmp>
                      )}
                      {(val.orderStatus === 'invoice-created' ||
                        val.orderStatus === 'invoice-sent' ||
                        val.orderStatus === 'in-transit') && (
                        <BadgeCmp style="modern" type="error">
                          {val.orderStatus}
                        </BadgeCmp>
                      )}
                      {/* Tags */}
                      {val.orderStatus === 'dispatched' && (
                        <BadgeCmp style="modern" type="pink">
                          {val.orderStatus}
                        </BadgeCmp>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-1.5">
                      <div className="flex justify-between gap-x-3 gap-y-1">
                        <p className="text-grayLight600 text-xs font-normal">
                          Shipment
                        </p>
                        <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium">
                          ({val?.orderFromCity}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="9"
                            viewBox="0 0 11 9"
                            fill="none"
                          >
                            <path
                              d="M10.3536 4.68688C10.5488 4.49162 10.5488 4.17504 10.3536 3.97977L7.17157 0.797794C6.97631 0.602532 6.65973 0.602532 6.46447 0.797794C6.2692 0.993057 6.2692 1.30964 6.46447 1.5049L9.29289 4.33333L6.46447 7.16176C6.2692 7.35702 6.2692 7.6736 6.46447 7.86886C6.65973 8.06412 6.97631 8.06412 7.17157 7.86886L10.3536 4.68688ZM0 4.83333H10V3.83333H0V4.83333Z"
                              fill="#101828"
                            />
                          </svg>
                          {val?.orderToCity})
                        </div>
                      </div>
                      <div className="flex justify-between gap-x-3 gap-y-1 ">
                        <p className="text-grayLight600 text-xs font-normal">
                          Total Price
                        </p>
                        <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium">
                          $
                          {val?.combinedRevenue
                            ? getFormattedNumber(val?.combinedRevenue)
                            : 0}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ) : !orders.length && !isLatestOrdersLoading ? (
              <li>
                <NotFoundUI
                  title="No orders found"
                  desc=""
                  containerClassName="!my-4"
                />
              </li>
            ) : (
              <li className="rounded-lg border border-utilityGray200 p-3">
                <div className="flex items-center gap-x-2.5 mb-4">
                  <div className="rounded-md border border-utilityGray200 bg-utilityGray100 p-2">
                    <span
                      className={`w-4 h-4 text-grayLight600 custom-loading`}
                    />
                  </div>
                  <div className="flex-1">
                    <span className="text-primary underline text-sm font-medium custom-loading">
                      #WAL-DMIF-1
                    </span>
                  </div>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName="custom-loading"
                  >
                    Delivered
                  </BadgeCmp>

                  {/* IF IN TRANSIT */}
                  <BadgeCmp
                    style="modern"
                    type="error"
                    mainClassName="custom-loading"
                  >
                    In-transit
                  </BadgeCmp>
                </div>
                <div className="flex flex-col gap-y-1.5">
                  <div className="flex justify-between gap-x-3 gap-y-1">
                    <p className="text-grayLight600 text-xs font-normal">
                      Shipment
                    </p>
                    <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium custom-loading">
                      (Florida
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                      >
                        <path
                          d="M10.3536 4.68688C10.5488 4.49162 10.5488 4.17504 10.3536 3.97977L7.17157 0.797794C6.97631 0.602532 6.65973 0.602532 6.46447 0.797794C6.2692 0.993057 6.2692 1.30964 6.46447 1.5049L9.29289 4.33333L6.46447 7.16176C6.2692 7.35702 6.2692 7.6736 6.46447 7.86886C6.65973 8.06412 6.97631 8.06412 7.17157 7.86886L10.3536 4.68688ZM0 4.83333H10V3.83333H0V4.83333Z"
                          fill="#101828"
                        />
                      </svg>
                      Miami)
                    </div>
                  </div>
                  <div className="flex justify-between gap-x-3 gap-y-1 ">
                    <p className="text-grayLight600 text-xs font-normal">
                      Total Price
                    </p>
                    <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium custom-loading">
                      $905.80
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomerDetail;
