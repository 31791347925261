import { Edit05, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { usePhone } from 'src/utils/CommonFunctions';

const ContactTabOrderRaw = ({
  data,
  handleDeleteContacts,
  handleEditContacts,
}: any) => {
  const { getFormatPhoneWithPlus } = usePhone();

  return (
    <>
      <td className="px-5 py-4 w-[20%] min-w-[25%] max-w-72">
        <h6 className="truncate">{data?.contactFullName}</h6>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
        <span>{data?.contactRole}</span>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64  ">
        <span>{getFormatPhoneWithPlus(data?.contactPhone)}</span>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
        <p className="truncate">{data?.contactEmail}</p>
      </td>
      <td className="px-5 py-4 max-w-20 w-20 min-w-20">
        <div className="justify-center items-center gap-2 flex ">
          <Edit05
            className="w-4 h-3.5 relative cursor-pointer"
            onClick={() => handleEditContacts(data)}
          />
          <Trash01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => handleDeleteContacts(data)}
          />
        </div>
      </td>
    </>
  );
};

export default ContactTabOrderRaw;
