import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import Toggle from 'src/components/Toggle/Toggle';
import * as yup from 'yup';

import TruckFindingAiIcon from '../../../../../assets/img/truck-finding-ai-icon.svg';

const truckFindingAiSchema = yup.object().shape({
  enableTruckFinding: yup
    .string()
    .required('Please select truck finding ai for this load'),
  enableInboundTruckFinding: yup.boolean(),
  enableOutboundTruckFinding: yup.boolean(),
  outgoingCallStartAfter: yup
    .number()
    .nullable()
    .when('enableOutboundTruckFinding', (enableOutboundTruckFinding, schema) =>
      enableOutboundTruckFinding[0]
        ? schema.required('Start dispatching calls is required.')
        : schema.nullable()
    ),
  totalRates: yup.number().nullable(),
});

interface IProps {
  truckFindingAiDetail?: any;
  postCreatePayload: any;
  handleClose: () => void;
  handleModalSubmit?: (data: any) => void;
}

const TruckFindingAi = ({
  truckFindingAiDetail,
  postCreatePayload,
  handleClose,
  handleModalSubmit,
}: IProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(truckFindingAiSchema),
    defaultValues: {
      enableTruckFinding: truckFindingAiDetail?.enableTruckFinding || '',
      enableInboundTruckFinding:
        truckFindingAiDetail?.enableInboundTruckFinding || false,
      enableOutboundTruckFinding:
        truckFindingAiDetail?.enableOutboundTruckFinding || false,
      outgoingCallStartAfter:
        truckFindingAiDetail?.outgoingCallStartAfter || '',
      totalRates: truckFindingAiDetail?.totalRates || '',
    },
  });

  const enableTruckFinding = watch('enableTruckFinding');
  const enableOutboundTruckFinding = watch('enableOutboundTruckFinding');

  const onSubmit = (data: any) => {
    console.log('onSubmit data', data);
    const loadPostingData = { ...postCreatePayload, truckFindingAiData: data };

    console.log('onSubmit loadPostingData', loadPostingData);

    if (handleModalSubmit) {
      handleModalSubmit(loadPostingData);
    }
  };

  const [isTruckFindingAiUpdateLoading] = useState(false);
  const headerIcon = (
    <img src={TruckFindingAiIcon} alt="logo" className="sm:w-50 w-30" />
  );

  return (
    <CommonModal
      title={`Gather Rates with AI`}
      titleDesc={`Receive and calls with Kate, your AI truck finder`}
      handleClose={handleClose}
      size={'max-w-[600px]'}
      headerIcon={headerIcon}
      primaryBtnText="Confirm"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isTruckFindingAiUpdateLoading}
      primaryBtnDisabled={isTruckFindingAiUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isTruckFindingAiUpdateLoading}
    >
      <div className="px-6 py-5">
        <div className="w-full mb-4">
          <label className="form_label mb-1.5 block">
            Would you like to enable Truck Finding AI for this load?
          </label>
          <div className="w-full flex">
            <Controller
              key={`enable_truck_finding`}
              name="enableTruckFinding"
              control={control}
              render={({ field }) => (
                <>
                  <div className="w-[161px] rounded-lg border border-utilityGray200 p-3 flex items-center mr-2.5">
                    <Radio
                      {...field}
                      id={'yes'}
                      onChangeFunc={(e) => field.onChange(e.target.value)}
                      inputName={'enableTruckFinding'}
                      value={'yes'}
                      checked={field.value == 'yes'}
                    />
                    <label
                      htmlFor={'yes'}
                      className="form_label mb-0 block ml-2 font-medium text-xs cursor-pointer"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="w-[161px] rounded-lg border border-utilityGray200 p-3 flex items-center">
                    <Radio
                      {...field}
                      id={'no'}
                      onChangeFunc={(e) => field.onChange(e.target.value)}
                      inputName={'enableTruckFinding'}
                      value={'no'}
                      checked={field.value == 'no'}
                    />
                    <label
                      htmlFor={'no'}
                      className="form_label mb-0 block ml-2 font-medium text-xs cursor-pointer"
                    >
                      No
                    </label>
                  </div>
                </>
              )}
            />
          </div>
          <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5">
            {errors.enableTruckFinding && (
              <ErrorMsg errorText={errors.enableTruckFinding.message} />
            )}
          </div>
        </div>

        {enableTruckFinding == 'yes' && (
          <>
            <div className="w-full flex rounded-lg border border-utilityGray200 p-2 mb-4">
              <div className="w-full border-utilityGray200">
                <Controller
                  name="enableInboundTruckFinding"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <div className="flex justify-between gap-2">
                        <span className="text-sm fw-medium text-textSecondary">
                          Enable Inbound Truck Finding AI.
                        </span>
                        <Toggle
                          name="enableInboundTruckFinding"
                          labelClassName="mr-2"
                          isChecked={value}
                          onChange={onChange}
                        />
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <Controller
                name="totalRates"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="totalRates"
                    inputType="number"
                    label="How many total rates would you like to collect?"
                    labelClassName="mb-1.5 block"
                    placeholder="Enter Minutes"
                    className="form_control"
                    value={value ?? ''}
                    onChangeFunc={(e) =>
                      onChange(e.target.value ? parseInt(e.target.value) : null)
                    }
                    onBlur={(e) =>
                      onChange(e.target.value ? parseInt(e.target.value) : null)
                    }
                    disabled={false}
                    errorText={
                      errors.totalRates ? errors.totalRates.message : null
                    }
                  />
                )}
              />
            </div>
            <div className="w-full flex rounded-lg border border-utilityGray200 p-4 mb-4">
              <div className="w-full border-utilityGray200">
                <Controller
                  name="enableOutboundTruckFinding"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <div className="flex justify-between gap-2">
                        <span className="text-sm fw-medium text-textSecondary">
                          Enable Outbound Truck Finding AI.
                        </span>
                        <Toggle
                          name="enableOutboundTruckFinding"
                          labelClassName="mr-2"
                          isChecked={value}
                          onChange={onChange}
                        />
                      </div>
                    </>
                  )}
                />
                {enableOutboundTruckFinding && (
                  <>
                    <div className="border border-utilityGray200 my-3"></div>
                    <Controller
                      name="outgoingCallStartAfter"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="outgoingCallStartAfter"
                          inputType="number"
                          label="After how long should we start dispatching calls for this load?"
                          labelClassName="mb-1.5 block"
                          placeholder="Enter Minutes"
                          className="form_control"
                          value={value ?? ''}
                          onChangeFunc={(e) => {
                            onChange(
                              e.target.value ? parseInt(e.target.value) : null
                            );
                          }}
                          onBlur={(e) =>
                            onChange(
                              e.target.value ? parseInt(e.target.value) : null
                            )
                          }
                          disabled={false}
                          errorText={
                            errors.outgoingCallStartAfter
                              ? errors.outgoingCallStartAfter.message
                              : null
                          }
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default TruckFindingAi;
