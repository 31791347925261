import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './CreditIndexChartCss.scss';

interface IProps {
  customerCreditDetail: any;
}

const scoreObj: any = {
  A: 100,
  B: 80,
  C: 60,
  D: 40,
  E: 20,
};

const CreditIndexChart = ({ customerCreditDetail }: IProps) => {
  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '68%',
        },
        track: {
          background: '#fff',
          strokeWidth: '100%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        colorStops: [
          {
            offset: 0,
            color: '#E72730', // Start color
            opacity: 1,
          },
          {
            offset: 20,
            color: '#D3A333',
            opacity: 1,
          },
          {
            offset: 80,
            color: '#F3992D',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#4EC648', // End color
            opacity: 1,
          },
        ],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      dashArray: 3,
    },
  };

  return (
    <div className="max-w-sm mx-auto text-center relative">
      <ReactApexChart
        options={options as any}
        series={[scoreObj[customerCreditDetail?.creditValue] ?? 0]}
        // series={[
        //   ((customerCreditDetail?.providerValue -
        //     customerCreditDetail?.providerMinValue) /
        //     (customerCreditDetail?.providerMaxValue -
        //       customerCreditDetail?.providerMinValue)) *
        //     100,
        // ]}
        type="radialBar"
        height={270}
      />
      <div className="chart-labels text-[8px]">
        <div className=" text-gray-600 absolute bottom-[13%] left-[43px]">
          0
        </div>
        <div className=" text-gray-600 absolute bottom-[72%] left-[75px]">
          25
        </div>
        <div className=" text-gray-600 absolute bottom-[99%] left-[50%] -translate-x-1/2">
          50
        </div>
        <div className=" text-gray-600 absolute bottom-[72%] right-[75px]">
          75
        </div>
        <div className=" text-gray-600 absolute bottom-[13%] right-[35px]">
          100
        </div>
      </div>

      <div className="absolute w-full bottom-4">
        <h3 className="text-3xl m-0 text-grayLight900 ">
          {customerCreditDetail?.creditValue}
        </h3>
        <p className="text-base -mt-1 text-grayLight600 ">
          {customerCreditDetail?.creditDescription ?? 'low'}
        </p>
        <p className="text-[10px] text-grayLight600">
          Last Updated:{' '}
          {moment
            .utc(customerCreditDetail?.creditReportLastUpdated)
            .format('DD MMM, YYYY')}
        </p>
      </div>
    </div>
  );
};

export default CreditIndexChart;
