import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { BasicContext } from 'src/context/BasicContext';
import 'react-tippy/dist/tippy.css';

interface IProps {
  message?: any;
  children: any;
  type?: 'light' | 'dark';
  parentClassName?: string;
  followCursor?: boolean;
  isOpenChildTopPosOnTop?: boolean;
  openOnHover?: boolean;
  childrenClassName?: string;
  tooltipPosition?: 'right' | 'center' | 'left';
}

const ActionTooltip = ({
  message,
  children,
  type = 'dark',
  parentClassName = '',
  childrenClassName = '',
  isOpenChildTopPosOnTop,
  tooltipPosition = 'right',
  openOnHover = false,
}: IProps) => {
  const tooltipRef = useRef<any>(null);
  const [topPos, setTopPos] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    actionTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
    actionDelayTimeout,
    setActionDelayTimeout,
    setTooltipGlobalOpen,
  } = useContext(BasicContext);

  useEffect(() => {
    const handleScroll = () => {
      if (tooltipOpen) {
        setTooltipOpen(false);
        setActionTooltipGlobalOpen(false);
      }
    };

    const scrollableContainer = document.querySelector('.main__layout_section');
    scrollableContainer?.addEventListener('scroll', handleScroll);

    const allScrollableContainers =
      document.querySelectorAll('.overflow-x-auto');

    allScrollableContainers.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    return () => {
      scrollableContainer?.removeEventListener('scroll', handleScroll);
      allScrollableContainers.forEach((element) => {
        element?.removeEventListener('scroll', handleScroll);
      });
    };
  }, [tooltipOpen, message, children]);

  useEffect(() => {
    let handleOutsideClick: any;
    let handleEscapePress: any;

    if (tooltipOpen) {
      const tooltipChild = document.querySelector('.tooltipChild');

      handleOutsideClick = (event: any) => {
        if (tooltipChild && !tooltipChild?.contains(event.target)) {
          setTooltipOpen(false);
          setActionTooltipGlobalOpen(false);
        }
      };

      handleEscapePress = (event: any) => {
        if (tooltipOpen && event.key === 'Escape') {
          setTooltipOpen(false);
          setActionTooltipGlobalOpen(false);
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscapePress);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [tooltipOpen]);

  // This function used to render floating tooltip on bottom instead of top
  const handleToggleTooltip = (e: any, newValue: boolean) => {
    if (newValue) {
      setTooltipOpen(false);
      setActionTooltipGlobalOpen(false);
      setTooltipGlobalOpen(false);

      if (actionDelayTimeout) {
        clearTimeout(actionDelayTimeout);
      }
      setActionDelayTimeout(
        setTimeout(() => {
          const targetHeight =
            e.target.clientHeight && e.target.clientHeight !== 0
              ? e.target.clientHeight
              : 20;
          const extraPadding = 15;
          setActionTooltipGlobalOpen(false);

          setTimeout(() => {
            setTopPos(targetHeight + extraPadding);
            setTooltipOpen(newValue);
            setActionTooltipGlobalOpen(true);
            setTooltipGlobalOpen(false);
          }, 0);
        }, 200)
      );
    } else {
      setActionTooltipGlobalOpen(false);

      setTimeout(() => {
        setTooltipOpen(newValue);
      }, 0);
    }
  };

  useEffect(() => {
    if (!actionTooltipGlobalOpen) {
      setTooltipOpen(false);
    }
  }, [actionTooltipGlobalOpen]);

  return (
    <>
      {/* @ts-ignore */}
      <Tooltip
        ref={tooltipRef}
        html={
          <div
            style={{
              ...(isOpenChildTopPosOnTop
                ? { bottom: '0px' }
                : { top: `${topPos}px` }),
              ...(tooltipPosition === 'right'
                ? { transform: 'translateX(-80%)' }
                : {}),
              ...(tooltipPosition === 'center'
                ? { transform: 'translateX(-50%)' }
                : {}),
              ...(tooltipPosition === 'left'
                ? { transform: 'translateX(-20%)' }
                : {}),
            }}
            className={`absolute left-1/2 transform shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${parentClassName} ${
              type === 'light'
                ? 'bg-white border border-borderPrimary'
                : 'bg-black border border-black text-grayLight '
            }`}
          >
            {message}
          </div>
        }
        popperOptions={{
          modifiers: {
            addCustomClass: {
              enabled: true,
              fn: (data: any) => {
                data.instance.popper.classList.add('action-tooltip-custom');

                return data;
              },
            },
          },
        }}
        theme={type}
        duration={0}
        open={tooltipOpen}
        arrow={true}
        hideOnClick={false}
        trigger="manual"
      >
        <div
          className={`${childrenClassName} tooltipChild inline`}
          onClick={(e) => {
            if (!openOnHover) handleToggleTooltip(e, !tooltipOpen);
          }}
          onMouseEnter={(e) => {
            if (openOnHover) handleToggleTooltip(e, true);
          }}
          onMouseLeave={(e) => {
            if (openOnHover) handleToggleTooltip(e, false);
          }}
        >
          {children}
        </div>
      </Tooltip>
    </>
  );
};

export default ActionTooltip;
