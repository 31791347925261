/* eslint-disable max-lines-per-function */
import {
  SearchLg,
  XClose,
  Copy05,
  Edit05,
  Trash01,
  DotsVertical,
  RefreshCcw05,
  HelpCircle,
  Clock,
  RefreshCcw04,
  Plus,
  FileDownload03,
  RefreshCcw01,
  DownloadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy, debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PredictivePriceChart from 'src/app/QuotingHub/PredictivePricing/PredictivePriceChart';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import LiveTimer from 'src/components/LiveTimer';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPricingRate } from 'src/services/CommonService';
import { deleteLoadLinkPosting } from 'src/services/LoadLinkService';
// import { useRolePermission } from 'src/utils/CommonFunctions';

import doubleCheck from '../../../../../assets/img/double-check-success.svg';
import ButtonCmp from '../../../../../components/ButtonCmp';
import CheckBox from '../../../../../components/CheckBox';
import InputText from '../../../../../components/InputText/InputText';
import NotFoundUI from '../../../../../components/NotFoundUI';
import PageSectionLayout from '../../../../../components/PageSectionLayout';
import Header from '../../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import {
  CARRIERS,
  CURRENCY,
  QUOTE_CLASSIFICATION,
  ROSE_ROCKET,
  STATUS,
} from '../../../../../constants/common';
import { PATH } from '../../../../../constants/path';
import { ROUTES } from '../../../../../constants/routes';
import { AuthContext } from '../../../../../context/AuthContext';
import { BasicContext } from '../../../../../context/BasicContext';
import { listCarrier } from '../../../../../services/CarrierService';
import {
  createRate,
  getQuote,
  modifyQuote,
  spotQuoteRequest,
  createPDF,
  checkBol,
  // deleteActiveQuote,
} from '../../../../../services/QuoteService';
import {
  fetchJsFromCDN,
  getBadgeType,
} from '../../../../../utils/CommonFunctions';
import { downloadPdf } from '../../../../../utils/CommonFunctions';
import WalToast from '../../../../../utils/WalToast';
import AddQuickQuote from '../AddQuickQuote';
import AddQuoteDetail from '../AddQuoteDetail';
import FinalizeOrderModal from '../FinalizeOrderModal';
import GeneratePdfModal from '../GeneratePdfModal';
import LaneHistory from '../LaneHistory';
import PDF from '../PDF';
import PostToLoadBoard from '../PostToLoadBoard';
import MoveToActive from '../PostToLoadBoard/MoveToActive';
import PricingDetails from '../PricingDetails';
// import QuoteDetail from '../QuoteDetail';
import QuotedLoading from '../QuotedLoading';
import QuotedRow from '../QuotedRow';
import ReqQuotedRow from '../ReqQuotedRow';
import ReqQuoteLoading from '../ReqQuoteLoading';
import SpotQuoteConfirmation from '../SpotQuoteConfirmation';
import WithQuoteLoading from '../WithQuoteLoading';
import WithRateQuote from '../WithRateQuote';

import ConvertOrderModal from './ConvertOrderModal';
import QuoteDetailTable from './QuoteDetailTable';
import RefreshRatesModal from './RefreshRatesModal';

const initAction = {
  mode: '',
  pricing: false,
  model: false,
  pdf: false,
  closedReason: true,
  quoteDetail: false,
  addQuoteDetail: false,
  orderSuccessModel: false,
  pricingDetails: false,
  spotQuoteConfirmation: false,
  carrierOrderModel: false,
};

enum QuoteType {
  QUOTED = 'quoted',
  NON_QUOTED = 'non_quoted',
  WITH_RATE = 'with _rate',
  LOAD_BOARD = 'load_board',
  LANE_HISTORY = 'lane_history',
  PREDICTIVE_PRICING = 'predictive_pricing',
}

const QuoteDetailPage = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [isRateLoading, setIsRateLoading] = useState(true);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [services, setServices] = useState<any[]>([]);
  const [rates, setRates] = useState<any>({});
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [selectedCarrierRatesModel, setSelectedCarrierRatesModel] =
    useState<any>([]);
  const carrierRatesRef = useRef(carrierRates); // Ref to store latest carrier rates
  const [isModalOpen, setModalOpen] = useState(false);
  const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);
  // const [quotedListLoading, setQuotedListLoading] = useState(false);
  // const { hasRoleV2 } = useRolePermission();
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [isExpiredRefresh, setIsExpiredRefresh] = useState(false);
  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState({
    displayCarrierNames: false,
    displayDeliveryTime: false,
  });
  const [orderBookingSuccessToast, setOrderBookingSuccessToast] =
    useState(false);
  const [orderBookingErrorToast, setOrderBookingErrorToast] = useState(false);

  // const [quoteCarrierId] = useState<any>(null);

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language); // Set only one language at a time
  };

  const updateCarrierRates = (newCarrierRates: any) => {
    carrierRatesRef.current = newCarrierRates;
  };

  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [activeQuoteParams, setActiveQuoteParams] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [quoteType, setQuoteType] = useState<QuoteType>(QuoteType.WITH_RATE);
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [quotedList, setQuotedList] = useState<any[]>([]);
  const [nonQuotedList, setNonQuotedList] = useState<any[]>([]);
  const [carrierListArr, setCarrierListArr] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any>([]);
  const { currency, setCurrency } = useContext(BasicContext);
  const [search, setSearch] = useState('');
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
  const [isShowActionButtons, setIsShowActionButtons] = useState(false);
  const [selectedQuoteToGeneratePdfId, setSelectedQuoteToGeneratePdfId] =
    useState<any[]>([]);
  // load link
  const [loadLinkPostingDetail, setLoadLinkPostingDetail] = useState<
    any | undefined
  >(undefined);
  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [syncLiveData, setSyncLiveData] = useState<boolean>(false);
  const [isUpdateLoadPost, setIsUpdateLoadPost] = useState<boolean>(false);

  const [nonExpiredList, setNonExpiredList] = useState([]);
  const [isShowRefreshRateModal, setIsShowRefreshRateModal] = useState(false);
  const [isShowConvertOrderModal, setIsShowConvertOrderModal] = useState(false);
  const [intervalId, setIntervalId] = useState<any>();
  const [isShowGeneratePdfModal, setIsShowGeneratePdfModal] = useState(false);
  const sortDataByIsQuoted = (data: any) =>
    data.sort((a: any, b: any) => {
      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortActiveCarrierRates = (
    carrierRatesValue: any,
    isSortTrue = false
  ) => {
    const quotedCarrierRates: any = [];
    const nonExpiredQuotedList: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (
          carrierRate.carrierStatus !== STATUS.OPENED &&
          carrierRate.carrierQuoteId &&
          carrierRate.carrierStatus === addressDetails.status
        ) {
          quotedCarrierRates.push(carrierRate);
          if (
            moment(carrierRate.expirationDate).isSameOrAfter(
              moment().startOf('day')
            )
          )
            nonExpiredQuotedList.push(carrierRate);
        }
      });
    }

    if (!isSortTrue) {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    } else {
      // setTimeout(() => {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      // }, 400);
    }
    // setTimeout(() => {
    //   setQuotedListLoading(true);
    // }, 600);
  };

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    let openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);
    setNonQuotedList(invalidCarrierRates);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
      }, 400);
    }
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const quoteClassificationLTLArr = [
    {
      value: QuoteType.WITH_RATE,
      name: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      name: 'Request Spot Quote',
    },
  ];

  const quoteClassificationFTLArr = [
    {
      value: QuoteType.LOAD_BOARD,
      // name: 'Post to Load Board',
      name: (
        <div data-value={QuoteType.LOAD_BOARD}>
          Post to Load Board
          {loadLinkPostingDetail?.leadsCount > 0 && (
            <BadgeCmp
              type="primary"
              style="modern"
              dataValue={QuoteType.LOAD_BOARD}
              isHidePillDot
              mainClassName="bg-primary100 border-borderPrimary ml-2"
            >
              {loadLinkPostingDetail?.leadsCount}
            </BadgeCmp>
          )}
        </div>
      ),
    },
    {
      value: QuoteType.PREDICTIVE_PRICING,
      name: 'Predictive Pricing',
    },
  ];

  const handleAllColors = (quoteStatus: any) => {
    const status = quoteStatus;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  const handleSearchFilter = () => {
    const searchTermLower = search.toLowerCase();
    const matchedVal = carrierRates.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTermLower) ||
        (item.provider &&
          item.provider.toLowerCase().includes(searchTermLower)) ||
        (item.tags &&
          item.tags.some((tag: any) =>
            tag.toLowerCase().includes(searchTermLower)
          )) ||
        (item.carrierQuoteId &&
          item.carrierQuoteId.toLowerCase().includes(searchTermLower))
    );

    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (matchedVal?.length) {
      matchedVal.forEach((carrierRate: any) => {
        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }

    setNonQuotedList(invalidCarrierRates);
    setWithRateList(
      orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
    );
  };

  useEffect(() => {
    handleSearchFilter();
  }, [search]);

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;
              const { additionalService } = responseData;
              setIsCustomerDeleted(
                responseData?.addressDetails.isCustomerDeleted
              );
              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);
              }

              setLoadLinkPostingDetail(responseData?.loadLinkPostingDetail);

              if (responseData?.dimensions) {
                setDimensions(responseData.dimensions);
                let unit = 0;
                let weight = 0;
                responseData.dimensions.forEach((val: any) => {
                  unit += val.handlingUnitNo || 0;
                  weight += val.totalWeight;
                });
                setTotalWeight(weight);
                setTotalUnit(unit);
              }

              if (additionalService) {
                setServices(additionalService);
              }
              setIsLoading(false);

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                sortCarrierRates(responseData?.carrierRates);
                sortActiveCarrierRates(responseData?.carrierRates);
                setIsLoading(false);
                responseData?.carrierRates?.forEach((val: any) => {
                  setCarrierListArr((old) => [
                    ...old,
                    {
                      ...val,
                      id: val?.carrierId,
                    },
                  ]);
                  // setCarrierListArr((old) => [
                  //   ...old,
                  //   {
                  //     value: val?.carrierId,
                  //     label: val?.name,
                  //   },
                  // ]);
                });
              } else if (
                responseData?.addressDetails?.classification === 'ltl'
              ) {
                setIsGetRate(true);
              } else if (responseData?.addressDetails?.classification === 'ftl')
                handleAllColors(responseData?.addressDetails?.status);
            } else {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }
          })
          .finally(() => setIsRefresh(false))
          .catch(console.error);
      }
    } else {
      navigate(ROUTES.QUOTING_DASHBOARD);
    }
  }, [isRefresh]);

  const connectSocket = () => {
    if (!isSocketConnected) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      ).then(([io]: any) => {
        io.sails.url = window.SERVER_URL;
        io.socket.on('connect', function socketConnected() {
          io.socket.get(
            `/subscribe/${currentUser.id}`,
            function (data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
              setIsSocketConnected(true);
            }
          );
        });

        io.socket.on('disconnect', () => {
          setIsSocketConnected(false);
        });

        io.socket.on('user', function (data: any) {
          if (data.type === 'NEW_RATE') {
            if (data?.data && data?.data?.quoteId === parseInt(id)) {
              let newCarrierRates = [...carrierRatesRef.current, data.data];

              sortCarrierRates(newCarrierRates);
              setIsLoading(false);
            }
          } else if (data?.type === 'FINISH_LOADING') {
            carrierRateFetchStatus[data.carrier] = true;
            setCarrierRateFetchStatus((old: any) => ({
              ...old,
              [data.carrier]: true,
            }));

            // setIsFinish(true);
          } else if (data.type === 'ORDER_BOOKING_SUCCESS') {
            let carrierName = 'Carrier';

            if (data?.data && data?.data?.carrierDetails?.name) {
              carrierName = data?.data?.carrierDetails?.name;
            }

            if (!orderBookingSuccessToast) {
              WalToast.success(
                `Your Order with ${carrierName} was booked successfully.`
              );
              setOrderBookingSuccessToast(true);
            }

            setAction((old) => ({ ...old, carrierOrderModel: false }));

            if (data?.data?.quoteId == id) {
              setIsRefresh(true);
            }
          } else if (data.type === 'ORDER_BOOKING_ERROR') {
            if (!orderBookingErrorToast) {
              setAction((old) => ({ ...old, carrierOrderModel: false }));
              WalToast.error(
                data.insufficientBalance
                  ? "Can't book order due to insufficient balance"
                  : "Can't book order, some details missing"
              );
              setOrderBookingErrorToast(true);
            }
          }
        });
      });
    }
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
  }, []);

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      setModalOpen(true);

      let newCarrierRate: any = {};

      if (carrierRate) {
        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate.services.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
      }
      console.log('actionKey', actionKey);

      if (actionKey === 'model' && mode === 'create') {
        // Set the quote modal to open only when the conditions are met

        setQuoteModalOpen(true);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      if (actionKey === 'model' && mode === 'update') {
        setQuoteModalOpen(true);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      if (carrierRate) {
        setSelectedCarrierRates([newCarrierRate]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSubmit = () => {
    navigate(ROUTES.QUOTING_DASHBOARD);
  };

  useEffect(() => {
    console.log('action', action);
  }, [action]);

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];
          console.log('carrier', carriers);

          if (carriers?.length) {
            connectSocket();
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
            updateCarrierRates([]);
            setCarrierRates([]);
            setWithRateList([]);
            setNonQuotedList([]);

            if (isExpiredRefresh) {
              setWithRateList((old) =>
                old.filter((data) => {
                  if (data.expirationDate) {
                    return new Date(data.expirationDate) > new Date(); // Keep if not expired
                  } else {
                    return new Date(data.pickupDate) > new Date(); // Keep if not expired
                  }
                })
              );
              let expiredCarrierArr: any = [];

              withRateList.map((data) => {
                let slug = data.isBanyan
                  ? CARRIERS.BANYAN.SLUG
                  : data.isFreightcom
                  ? CARRIERS.FREIGHTCOM.SLUG
                  : data.slug;

                if (data.expirationDate) {
                  if (new Date(data.expirationDate) <= new Date())
                    expiredCarrierArr.push(slug);
                } else {
                  if (new Date(data.pickupDate) <= new Date())
                    expiredCarrierArr.push(slug);
                }
              });
              let expiredCarrierList = carriers.filter((data: any) =>
                expiredCarrierArr.includes(data.slug)
              );

              setCarrierList(expiredCarrierList || []);
            } else {
              setWithRateList([]);
              setCarrierList(carriers || []);
            }
          }
        })
        .finally(() => {
          // setIsLoading(false);
          // setIsRefresh(false);
        })
        .catch(console.log);
    } else {
      setIsFinish(true);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (addressDetails?.classification === QUOTE_CLASSIFICATION.FTL) {
      setQuoteType(QuoteType.LOAD_BOARD);
    }

    if (carrierRates?.length && !isExpiredRefresh) {
      sortCarrierRates(carrierRates, true);
    }
  }, [sortData, addressDetails]);

  useEffect(() => {
    if (carrierRates?.length) {
      sortActiveCarrierRates(carrierRates, true);
    }
  }, [activeQuoteParams, addressDetails]);

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    modifyQuote({ quoteId: quoteStatusId })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatusId;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', addressDetails?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          if (isCustomerDeleted || response.data?.classification === 'ftl') {
            navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          } else {
            navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  const updateSelectedCarrierRates = (allNonQuotedList: any) => {
    const filteredList = allNonQuotedList.filter(
      (carrierRate: any) =>
        !(carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail)
    );

    return filteredList;
  };

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: false,
        rowClassName: '',
      },
      // {
      //     id: "TransitTime",
      //     name: "Transit time",
      //     sortable: true,
      //     visible: true,
      // },
      {
        id: 'ProjectedDelivery',
        name: 'Projected Delivery',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'AdditionalServices',
        name: 'Additional Services',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'action',
        visible: [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
        name: (
          <div className="">
            <CheckBox
              checked={
                (allChecked ||
                  updateSelectedCarrierRates(nonQuotedList).length ===
                    selectedCarrierRates.length) &&
                selectedCarrierRates.length != 0
              }
              onChangeFunc={(e: any) => {
                setAllChecked(e.target.checked);

                if (e.target.checked) {
                  const filteredList =
                    updateSelectedCarrierRates(nonQuotedList);
                  setSelectedCarrierRates(filteredList);
                } else {
                  setSelectedCarrierRates([]);
                }
              }}
              parentClassName=""
            />
          </div>
        ),
        sortable: false,
        rowClassName: 'w-[5%]',
      },
    ],
    [allChecked, selectedCarrierRates, addressDetails]
  );

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [quoteType]);

  const handleExpiredRefreshRates = () => {
    let isAnyExpired = withRateList.some((data) => {
      if (data.expirationDate) {
        return moment(data.expirationDate).isBefore(moment().startOf('day')); // Expired
      } else {
        return moment(data.pickupDate).isBefore(moment().startOf('day')); // Expired
      }
    });

    if (isAnyExpired) {
      if (new Date(addressDetails.pickupDate) < new Date()) {
        setIsShowRefreshRateModal(true);
      } else {
        setIsGetRate(true);
      }
      setIsExpiredRefresh(true);
    } else {
      WalToast.error('No Expired rates found');
    }
  };
  useEffect(() => {}, [isExpiredRefresh]);

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const WithRatesHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
      },
      {
        id: 'expiry',
        name: 'Expiry',
      },
      {
        id: 'transitTime',
        name: 'Transit time',
      },
      // {
      //   id: 'tags',
      //   name: 'Delivery',
      //   sortable: true,
      //   rowClassName: 'max-w-48',
      // },

      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
      },

      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
      },
      {
        id: 'finalCharge',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ],
    [addressDetails, isLoading]
  );
  const QuotedHeadCells = useMemo(() => {
    const baseHeadCells = [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'expiry',
        name: 'Expiry',
        visible: true,
      },
      {
        id: 'transittime',
        name: 'Transit time',
        visible: true,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        visible: true,
      },
      {
        id: `${currency === CURRENCY.CAD ? 'totalCostCAD' : 'totalCostUSD'}`,
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      // {
      //   id: 'totalCharge',
      //   name: 'Base Quote',
      //   sortable: true,
      //   visible: true,
      // },
      // {
      //   id: 'additionalChargesUSD',
      //   name: 'Additional Services',
      //   sortable: true,
      //   visible: true,
      // },

      {
        id: 'finalCharge',
        name: 'Total Sell Price',
        sortable: true,
        visible: true,
      },

      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ];

    return baseHeadCells;
  }, [quotedList, isLoading, addressDetails]); // Dependency array for useMemo

  const tabOptionsArr = [
    {
      value: QuoteType.QUOTED,
      label: 'Quoted',
    },
    {
      value: QuoteType.WITH_RATE,
      label: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      label: 'Request Spot Quote',
    },
  ];

  const requestSpotQuote = (
    sentEmailFrom?: any,
    isDoNotAskChecked: boolean = false
  ) => {
    const quoteIds = selectedCarrierRates.map((item: any) => item.id);
    const data: any = {
      doNotAskSpotQuote: isDoNotAskChecked,
      sentEmailFrom: sentEmailFrom,
      ids: quoteIds,
    };
    setSubmitLoading(true);
    spotQuoteRequest(data)
      .then(() => {
        setSubmitLoading(false);
        setSelectedCarrierRates([]);
        setAllChecked(false);
        setAction(initAction);

        if (isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
        WalToast.success('Spot quote request sent successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(() => {
        setSelectedCarrierRates([]);
        setSubmitLoading(false);
        setAction(initAction);
      });
  };

  const handleModalClose = useCallback(
    (
        status: boolean,
        modalName?: any,
        sentEmailFrom?: any,
        isDoNotAskChecked: boolean = false
      ) =>
      () => {
        if (modalName) {
          setAction({ ...initAction, [modalName]: status });
        }

        if (!status) {
          setAction(initAction);
        }

        if (status) {
          if (action.spotQuoteConfirmation) {
            requestSpotQuote(isDoNotAskChecked, sentEmailFrom);
          }
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
        } else if (action.addQuoteDetail) {
          setSelectedCarrierRates([]);
        }
      },
    [action]
  );

  const handleQuoteClick = (carrierRate: any) => {
    // if (
    //   (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
    //   quoteType === QuoteType.NON_QUOTED
    // ) {
    //   handleActionType('spotQuoteConfirmation', 'view');
    // }
    if (
      (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
      quoteType === QuoteType.NON_QUOTED
    ) {
      return;
    }

    if (![STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status)) {
      return;
    }

    if (quoteType === QuoteType.NON_QUOTED) {
      handleActionType('spotQuoteConfirmation', 'view')();
    }

    if (quoteType === QuoteType.WITH_RATE) {
      handleActionType('model', 'create', carrierRate)();
    }

    let newCarrierRate: any = {};

    if (currency === CURRENCY.USD) {
      newCarrierRate = { ...carrierRate };
      newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
      newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
      newCarrierRate.margin = carrierRate.marginUSD;
      newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

      if (newCarrierRate.services.length) {
        newCarrierRate.services = newCarrierRate.services.map((item: any) => ({
          ...item,
          value: item.valueUSD,
        }));
      }
    } else {
      newCarrierRate = { ...carrierRate };
    }

    if (
      selectedCarrierRates.filter((rate: any) => rate.id === carrierRate.id)
        .length
    ) {
      newCarrierRate = {};
      setSelectedCarrierRates((old: any) =>
        old.filter((item: any) => item.id !== carrierRate.id)
      );
    } else {
      setSelectedCarrierRates((old: any) => [...old, newCarrierRate]);
    }
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  // useEffect(() => {
  //   if (carrierRates?.length) {
  //     setIsGetRate(false);
  //   }
  // }, [carrierRates]);

  // useEffect(() => {
  //   if (isSocketConnected && carrierList?.length && isGetRate) {
  //     carrierList.forEach((element: any) => {
  //       setCarrierRateFetchStatus((old: any) => ({
  //         ...old,
  //         [element.slug]: false,
  //       }));

  //       let formData = quote;
  //       formData.carrierId = element.id;
  //       createRate(formData)
  //         .then()
  //         .finally()
  //         .catch((error: any) => {
  //           console.error(error);
  //           WalToast.error(
  //             error?.response?.data?.message ||
  //               'Carrier rates have not been created',
  //             ''
  //           );
  //         });
  //     });
  //   }
  // }, [isSocketConnected]);

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      setIsRateLoading(true);

      const ratePromises = carrierList.map((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));
        let formData = quote;
        formData.carrierId = element.id;
        formData.pickupDate = addressDetails.pickupDate;

        return createRate(formData).catch((error: any) => {
          console.error(error);
          WalToast.error(
            error?.response?.data?.message ||
              'Carrier rates have not been created',
            ''
          );
        });
      });

      Promise.all(ratePromises)
        .then(() => {
          setIsRateLoading(false);
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
          setIsRateLoading(false);
        });
    }
  }, [isSocketConnected, carrierList]);

  useEffect(() => {
    let allFetched: boolean = true;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setCarrierList([]);
      setIsFinish(true);
      setIsExpiredRefresh(false);
      setIsGetRate(false);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  const fetchBol = () => {
    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      checkBol(id)
        .then((response: any) => {
          setAddressDetails({ ...addressDetails, ...response?.data });

          // Clear the interval if the condition is met
          if (response?.data?.bolUrl) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
        });
    }
  };

  // Start the interval in a useEffect hook and clear it when component unmounts
  useEffect(() => {
    let intervalIdd: any = '';

    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      intervalIdd = setInterval(() => {
        if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
          fetchBol();
        }
      }, 180000);
      setIntervalId(intervalIdd);
    }

    // Clear interval on component unmount or when addressDetails change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [addressDetails.status, addressDetails.bolUrl]);

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      <TabButton
        tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
        parentClassName="w-full currency-tab quoting-currency"
        childrenClassName="test"
        activeClassName="text-grayLight900 bg-utilityGray100"
        className=""
        isTooltip={true}
        tabArray={filterArr}
        handleOnClick={(e: any) => {
          setCurrency(e.target.dataset.value);
        }}
        isActive={currency}
      />
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {}}
        containerClassName="relative"
      >
        <ButtonCmp
          className="lg:!py-[9px] !py-2 lg:!px-[9px] !px-2 btn-outline-primary"
          onClick={() => setIsShowActionButtons(!isShowActionButtons)}
        >
          Quote Actions
        </ButtonCmp>

        <ul
          className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
            isShowActionButtons ? '' : 'hidden'
          } `}
        >
          <li
            className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
            onClick={modifyQuoteStatus(addressDetails.id)}
          >
            <Edit05 className="w-4 h-4" />
            {addressDetails.status === STATUS.OPENED
              ? 'Modify Quote'
              : 'Duplicate Quote'}
          </li>
          {/* <li
            className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
            onClick={handleSubmit}
          >
            <XClose className="w-4 h-4" />
            Close quote
          </li> */}

          {!!addressDetails.carrierOrderUrl &&
            addressDetails?.status === STATUS.WON && (
              <li
                className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
                onClick={() => window.open(addressDetails.carrierOrderUrl)}
              >
                Open Carrier Portal
              </li>
            )}

          {addressDetails.status === STATUS.WON && (
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() =>
                window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId)
              }
            >
              View Order
            </li>
          )}
          {addressDetails.status === STATUS.WON && (
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => {
                if (addressDetails.bolUrl) {
                  downloadPdf(
                    addressDetails.bolUrl,
                    `${addressDetails.code}-BOL.${
                      addressDetails.bolUrl.split('.').reverse()[0]
                    }`
                  );
                }
              }}
            >
              {addressDetails.bolUrl ? (
                <>
                  <DownloadCloud02 className="w-4 h-4" />
                  Download BOL
                </>
              ) : (
                <>
                  <RefreshCcw01 className="w-4 h-4" />
                  Fetching BOL
                </>
              )}
            </li>
          )}
        </ul>
      </OutsideClickHandler>
      {!!addressDetails.isOrderBookingInProgress && (
        <ButtonCmp
          className="btn-outline-primary text-grayLight600"
          btnSpinnerClass="text-primary"
          loading
        >
          Processing Order Booking
        </ButtonCmp>
      )}
      {addressDetails.isBookOrderEnable &&
        addressDetails.status === STATUS.ACTIVE && (
          <ButtonCmp
            className="btn-outline-primary"
            onClick={() => setIsShowConvertOrderModal(true)}
          >
            Convert to order
          </ButtonCmp>
        )}
      <ButtonCmp
        className="btn_secondary_black lg:!px-[9px] !px-2"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp>
      {/* 
      {!!addressDetails.carrierOrderUrl &&
        addressDetails.status === STATUS.WON && (
          <ButtonCmp
            className="btn-outline-primary"
            onClick={() => window.open(addressDetails.carrierOrderUrl)}
          >
            Open Carrier Portal
          </ButtonCmp>
        )} */}
      {/* 
      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() =>
            window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId)
          }
        >
          View Order
        </ButtonCmp>
      )} */}
      {/* 
      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() => {
            if (addressDetails.bolUrl) {
              downloadPdf(
                addressDetails.bolUrl,
                `${addressDetails.code}-BOL.${
                  addressDetails.bolUrl.split('.').reverse()[0]
                }`
              );
            }
          }}
          disabled={!addressDetails.bolUrl}
          loading={!addressDetails.bolUrl}
        >
          {addressDetails.bolUrl ? (
            <>
              <DownloadCloud02 className="w-4 h-4" />
              Download BOL
            </>
          ) : (
            'Fetching BOL'
          )}
        </ButtonCmp>
      )} */}

      {/* {!isLoading && (
        <ButtonCmp
          type="submit"
          className="btn_primary xs:!text-xs !text-[11px] sm:!px-3 !px-2"
          onClick={modifyQuoteStatus(addressDetails.id)}
        >
          {addressDetails.status === STATUS.OPENED
            ? 'Modify Quote'
            : 'Duplicate Quote'}
        </ButtonCmp>
      )} */}

      {/* {addressDetails.status === STATUS.ACTIVE ||
      addressDetails.status === STATUS.WON ||
      addressDetails.status === STATUS.EXPIRED ||
      addressDetails.status === STATUS.LOST ? (
        <>
          <ButtonCmp
            type="button"
            className="btn_secondary_black mdm:flex-none md:flex-1 sms:flex-none flex-1 xs:!text-xs !text-[11px] sm:!px-3 !px-2"
            onClick={handleActionType('pdf')}
          >
            View Generated PDFs
          </ButtonCmp>
        </>
      ) : (
        !isLoading && <></>
      )} */}

      {/* <ButtonCmp
        type="button"
        className="btn_secondary_black !p-2 flex-none"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp> */}
    </div>
  );

  const handleLoadPostingDelete = (status: boolean) => {
    if (status) {
      // deleteLoadLinkPosting
      setIsLoadLinkPostingDeleteLoading(true);
      deleteLoadLinkPosting(loadLinkPostingDetail?.id)
        .then(() => {
          WalToast.success(
            `Load link posting was removed from WALHQ & Load Link`
          );
          setIsRefresh(true);
        })
        .catch((e) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
    }
  };

  const ActiveArray: any = [
    {
      value: 'active',
      name: 'Active Quotes',
    },
  ];

  const handleQuoteModalClose = () => {
    setQuoteModalOpen(false);
  };

  const handleGeneratePdf = () => {
    setIsPdfGenerating(true);
    createPDF(id, {
      selectedOption: selectedDisplayOptions,
      quoteCarrierId: selectedQuoteToGeneratePdfId,
      pdfLanguage: selectedLanguage,
      isPdf: false,
      currency: currency,
    })
      .then((response: any) => {
        // setIsRefresh(true);
        setIsPdfGenerating(false);
        setIsShowGeneratePdfModal(false);
        setSelectedLanguage('english');
        setSelectedDisplayOptions({
          displayCarrierNames: false,
          displayDeliveryTime: false,
        });
        setSelectedQuoteToGeneratePdfId([]);
        const { data } = response;

        if (data) {
          const url = `${data.url}${data.file}`;

          setTimeout(() => {
            downloadPdf(url, `${data.name} - ${data.file}`);
          }, 500);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  };

  const [isPostCreateLoading, setIsPostCreateLoading] = useState(false);
  const [validatePostToLoadBoardForm, setValidatePostToLoadBoardForm] =
    useState(false);

  useEffect(() => {
    if (!isPostCreateLoading) {
      setValidatePostToLoadBoardForm(false);
    }
  }, [isPostCreateLoading]);

  const handlePostToLoadBoardSubmit = () => {
    setValidatePostToLoadBoardForm(true);
    console.log('submit form', validatePostToLoadBoardForm);
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: #${addressDetails.code ?? ''}`}
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              isLoading ? (
                <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                  <span className="rounded-full p-1 bg-warning500"></span>
                  <span className="text-xs">Opened </span>
                </div>
              ) : (
                <BadgeCmp
                  style="modern"
                  type={
                    addressDetails.status === 'Won'
                      ? 'success'
                      : addressDetails.status === 'Active'
                      ? 'lightblue'
                      : addressDetails.status === 'Opened'
                      ? 'primary'
                      : addressDetails.status === 'Expired' ||
                        addressDetails.status === 'Lost'
                      ? 'error'
                      : 'success'
                  }
                >
                  {addressDetails.status === 'Opened'
                    ? 'Open'
                    : addressDetails.status === 'Active'
                    ? 'Quoted'
                    : addressDetails.status}
                </BadgeCmp>
              )
            }
            desc="View carrier rates and create a quote for your shipment."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        <QuoteDetailTable
          dimensions={dimensions}
          services={services}
          isLoading={isLoading}
          handleActionType={handleActionType}
          addressDetails={addressDetails}
          totalWeight={totalWeight}
          totalUnit={totalUnit}
        />

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3 border-b border-utilityGray200">
            <TabButton
              className=""
              tabArray={ActiveArray}
              isActive={'active'}
              activeClassName="!bg-utilityGray100"
              // parentClassName="w-[2.5vh] "
              // tabParentClassName="w-[6.5vh] justify-end"
              // handleOnClick={() => setQuoteType(QuoteType.QUOTED)}
            />

            <div className="gap-3 flex flex-wrap whitespace-nowrap">
              <TooltipCmp message={'View PDF History'}>
                <ButtonCmp
                  type="submit"
                  disabled={selectedCarrierRates.length <= -1}
                  className="btn_secondary_black lg:px-[9px] px-2"
                  onClick={handleActionType('pdf')}
                >
                  <FileDownload03 className="w-4 h-4" />
                </ButtonCmp>
              </TooltipCmp>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={() => setIsShowGeneratePdfModal(true)}
                disabled={quotedList?.length <= 0}
              >
                Generate Quote PDF
              </ButtonCmp>
              <ButtonCmp
                type="button"
                className="btn_secondary_black mdm:flex-none flex-1 xls:min-w-[180px] mdm:min-w-[auto] sm:w-auto md:min-w-[calc(50%_-_8px)] sm:min-w-[auto] min-w-[calc(50%_-_8px)] "
                onClick={handleActionType(
                  'model',
                  'Quick_quote',
                  addressDetails
                )}
                icon={<Plus className="w-4 h-4" />}
              >
                Add Quick Quote
              </ButtonCmp>
            </div>
          </div>

          <div className="w-full border-t border-gray100 flex-1 flex flex-col">
            <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between table-without-pagination">
              <TableCmp
                headCells={QuotedHeadCells}
                tableDataArr={quotedList}
                TableLoaderRowCmp={QuotedLoading}
                TableRowCmp={QuotedRow}
                params={activeQuoteParams}
                setParams={setActiveQuoteParams}
                tableRowCmpProps={{
                  handleActionType: handleActionType,
                  currency: currency,
                  addressDetails: addressDetails,
                  isFinish: isFinish,
                  setQuotedList: setQuotedList,
                }}
                getRowClass={(data: any) =>
                  data.carrierStatus === STATUS.WON ? '!bg-green20' : ''
                }
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={5}
                isTableRowClickable={false}
              />
            </div>
            {quotedList.length <= 0 && !isLoading && (
              <NotFoundUI
                iconClassName="hidden"
                contentClassName="before:!bg-none"
                title={
                  <>
                    <BadgeCmp
                      style="pill"
                      type={getBadgeType(addressDetails?.createdAt)?.type}
                      mainClassName="rounded-md"
                    >
                      <div className="flex gap-1">
                        <Clock className="w-4 h-4" />
                        <LiveTimer startDate={addressDetails?.createdAt} />
                      </div>
                    </BadgeCmp>
                    {/* <div>No Active Quotes</div> */}
                  </>
                }
                desc="Push a carrier rate below or add a quick quote to quote your customer and get a proof of quote."
                containerClassName=" !h-auto !my-[30px]"
              />
            )}
          </div>
        </div>
        {action.model && action.mode === 'Quick_quote' && (
          <AddQuickQuote
            quoteId={id}
            currency={currency}
            serviceList={services}
            handleClose={handleDrawerClose}
            carrierListArr={carrierListArr}
            setIsRefresh={setIsRefresh}
          />
        )}
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1">
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left ">
              {(addressDetails?.classification === QUOTE_CLASSIFICATION.FTL ||
                addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL) && (
                <TabButton
                  className="table-tab max-xxl:!px-3"
                  activeClassName="!bg-utilityGray100"
                  tabArray={
                    addressDetails?.classification === QUOTE_CLASSIFICATION.FTL
                      ? quoteClassificationFTLArr
                      : quoteClassificationLTLArr
                  }
                  parentClassName="table-tabs "
                  isActive={quoteType}
                  handleOnClick={(e: any) => {
                    setQuoteType(e?.target?.dataset?.value);
                  }}
                />
              )}
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {/* {addressDetails?.classification !==
                  QUOTE_CLASSIFICATION.FTL && (
                  <>
                    <div
                      className={`lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2 cursor-pointer rounded-l-md text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.WITH_RATE
                          ? 'bg-utilityGray100'
                          : ''
                      } ${
                        addressDetails.status !== STATUS.OPENED
                          ? ''
                          : 'rounded-l-md'
                      } ${window.MODE === 'production' ? 'rounded-r-md' : ''}`}
                      onClick={() => setQuoteType(QuoteType.WITH_RATE)}
                    >
                      Direct Carrier Rates
                    </div>

                    {window.MODE !== 'production' && (
                      <div
                        className={`lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                          quoteType === QuoteType.NON_QUOTED
                            ? 'bg-utilityGray100'
                            : ''
                        }`}
                        onClick={() => setQuoteType(QuoteType.NON_QUOTED)}
                      >
                        Request Spot Quote
                      </div>
                    )}
                  </>
                )}

                {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2 cursor-pointer rounded-bl-md rounded-tl-md border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.LOAD_BOARD
                          ? 'bg-utilityGray100'
                          : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.LOAD_BOARD)}
                    >
                      Post to Load Board{' '}
                      {loadLinkPostingDetail?.leadsCount && (
                        <BadgeCmp
                          style="pill"
                          type="primary"
                          mainClassName="ml-1"
                        >
                          {loadLinkPostingDetail?.leadsCount}
                        </BadgeCmp>
                      )}
                    </div>
                  )} */}

                {/* {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.LANE_HISTORY ? 'bg-utilityGray100' : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.LANE_HISTORY)}
                    >
                      Lane History
                    </div>
                  )} */}

                {/* {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.PREDICTIVE_PRICING
                          ? 'bg-utilityGray100'
                          : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.PREDICTIVE_PRICING)}
                    >
                      Predictive Pricing
                    </div>
                  )} */}
              </div>
            </div>
            {quoteType === QuoteType.LOAD_BOARD &&
              (isUpdateLoadPost || !loadLinkPostingDetail) && (
                <ButtonCmp
                  className="btn-outline-primary"
                  loading={isPostCreateLoading}
                  disabled={isPostCreateLoading}
                  onClick={() => handlePostToLoadBoardSubmit()}
                >
                  Post to Load Board
                </ButtonCmp>
              )}
            {quoteType !== QuoteType.LOAD_BOARD && (
              <InputText
                inputName="searchQuote"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                isClearable
                onChangeFunc={(e: any) => {
                  setSearch(e.target.value);
                }}
                parentClassName="table-searchInput"
              />
            )}
            <SelectBox
              name="role"
              placeholder="Carrier Rates"
              parentClassName="sm:hidden block w-full"
              labelClassName="form_label"
              value={tabOptionsArr.find((val: any) => val.value === quoteType)}
              onChangeFunc={(event: any) => setQuoteType(event.value)}
              options={tabOptionsArr}
            />
            {quoteType === QuoteType.WITH_RATE && (
              <>
                {/* <ButtonCmp
                  type="submit"
                  disabled={selectedCarrierRates.length <= 0}
                  className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                  onClick={handleActionType('model', 'create')}
                >
                  Select Rates
                </ButtonCmp> */}
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={handleExpiredRefreshRates}
                  disabled={!isAllCarrierRateFetched && isExpiredRefresh}
                  loading={!isAllCarrierRateFetched && isExpiredRefresh}
                >
                  {isAllCarrierRateFetched && (
                    <RefreshCcw04 className="w-4 h-4 text-primary" />
                  )}
                  Fetching Expired Rates
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => {
                    if (new Date(addressDetails.pickupDate) < new Date()) {
                      setIsShowRefreshRateModal(true);
                    } else {
                      setIsGetRate(true);
                    }
                  }}
                  disabled={!isAllCarrierRateFetched && !isExpiredRefresh}
                  loading={!isAllCarrierRateFetched && !isExpiredRefresh}
                >
                  {isAllCarrierRateFetched && (
                    <RefreshCcw01 className="w-4 h-4 text-primary" />
                  )}
                  Fetching Rates
                </ButtonCmp>
              </>
            )}
            {quoteType === QuoteType.NON_QUOTED && (
              <ButtonCmp
                type="submit"
                disabled={selectedCarrierRates.length <= 0}
                className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                onClick={() =>
                  handleActionType('spotQuoteConfirmation', 'view')()
                }
              >
                Request Spot
              </ButtonCmp>
            )}
            {quoteType === QuoteType.LOAD_BOARD && loadLinkPostingDetail && (
              <>
                <div className="text-grayLight900 text-xs font-normal flex items-center">
                  Posted on :&nbsp;
                  <img src={doubleCheck} className="mr-0.5" />
                  <span className="font-semibold text-fgSuccessPrimary">
                    {moment(loadLinkPostingDetail?.createdAt).format(
                      'MMM D, YYYY h:mm A'
                    )}
                  </span>
                </div>

                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setIsUpdateLoadPost(true)}
                  disabled={isUpdateLoadPost}
                >
                  <Edit05 className="w-4 h-4" />
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() =>
                    setIsShowLoadLinkPostingDeleteConfirmation(true)
                  }
                  disabled={isLoadLinkPostingDeleteLoading}
                  loading={isLoadLinkPostingDeleteLoading}
                >
                  <Trash01 className="w-4 h-4" />
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setSyncLiveData(true)}
                  disabled={syncLiveData}
                  loading={syncLiveData}
                >
                  {!syncLiveData && <RefreshCcw04 className="w-4 h-4" />}
                </ButtonCmp>
              </>
            )}
          </div>

          {quoteType === QuoteType.NON_QUOTED && (
            <div className="h-full lg:min-h-[122px] w-full  border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={ReqQuoteHeadCells}
                  tableDataArr={nonQuotedList}
                  TableLoaderRowCmp={ReqQuoteLoading}
                  TableRowCmp={ReqQuotedRow}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    currentUser: currentUser,
                    requestSpotQuote: requestSpotQuote,
                    selectedCarrierRates: selectedCarrierRates,
                    allChecked: allChecked,
                    setAllChecked: setAllChecked,
                    addressDetails: addressDetails,
                  }}
                  isTableDataLoading={isLoading || !isFinish}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={8}
                  isTableRowClickable={true}
                  onRowClick={handleQuoteClick}
                />
              </div>
              {nonQuotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          )}

          {quoteType === QuoteType.WITH_RATE && (
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={withRateList}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={WithRateQuote}
                  params={sortData}
                  setParams={setSortData}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    selectedCarrierRates: selectedCarrierRates,
                  }}
                  onRowClick={handleQuoteClick}
                  isTableDataLoading={!isFinish || isLoading || isRateLoading}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                  tableBodyRowClass={``}
                  getRowClass={(data: any) => {
                    let isNonQuotedServicesExist = false;

                    if (data?.services.some((form: any) => !form.isQuoted)) {
                      isNonQuotedServicesExist = true;
                    }

                    return isNonQuotedServicesExist
                      ? 'bg-utilityGray100 hover:bg-utilityGray200'
                      : '';
                  }}
                />
              </div>
              {withRateList.length <= 0 &&
                isFinish &&
                !isLoading &&
                !isRateLoading && (
                  <NotFoundUI
                    title="No Quote Found"
                    desc="There are no rates for current quote"
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  />
                )}
            </div>
          )}

          {quoteType === QuoteType.LOAD_BOARD && (
            <PostToLoadBoard
              loadLinkPostingDetail={loadLinkPostingDetail}
              addressDetails={addressDetails}
              syncLiveData={syncLiveData}
              validatePostToLoadBoardForm={validatePostToLoadBoardForm}
              setIsPostCreateLoading={setIsPostCreateLoading}
              setSyncLiveData={setSyncLiveData}
              setLoadLinkPostingDetail={setLoadLinkPostingDetail}
              isUpdateLoadPost={isUpdateLoadPost}
              setIsUpdateLoadPost={setIsUpdateLoadPost}
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
            />
          )}

          {quoteType === QuoteType.LANE_HISTORY && <LaneHistory />}

          {quoteType === QuoteType.PREDICTIVE_PRICING && (
            <PredictivePriceChart id={id} />
          )}
        </div>
      </PageSectionLayout>

      {isModalOpen && (
        <PDF
          isOpen={action.pdf}
          id={id}
          handleClose={handleDrawerClose}
          addressDetails={addressDetails}
        />
      )}

      {/* <QuoteDetail
        isOpen={action.quoteDetail}
        handleClose={handleModalClose(false)}
        quote={{ addressDetails, dimensions, services, totalUnit, totalWeight }}
      /> */}

      <PricingDetails
        isOpen={action.pricingDetails}
        handleClose={handleDrawerClose}
        quote={{ addressDetails, dimensions, services }}
        carrierRate={selectedCarrierRates[0]}
      />

      {isQuoteModalOpen && (
        <MoveToActive
          quoteID={id}
          action={action}
          handleClose={handleQuoteModalClose}
          selectedCarrierRates={selectedCarrierRatesModel}
          currency={currency}
          setIsRefresh={setIsRefresh}
        />
      )}
      {action.addQuoteDetail && (
        <AddQuoteDetail
          handleClose={handleDrawerClose}
          carrierRate={selectedCarrierRates[0]}
          currency={currency}
          setIsRefresh={setIsRefresh}
        />
      )}

      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRates={selectedCarrierRates}
          dimensions={dimensions}
          addressDetails={addressDetails}
          loading={submitLoading}
        />
      )}

      {isShowLoadLinkPostingDeleteConfirmation && (
        <CommonModal
          title="Remove Load Link Posting"
          titleDesc="This action will remove load link posting from WAL HQ and Load Link."
          handleClose={() =>
            isLoadLinkPostingDeleteLoading
              ? false
              : handleLoadPostingDelete(false)
          }
          secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleLoadPostingDelete(true)}
          primaryBtnLoading={isLoadLinkPostingDeleteLoading}
          primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {action.carrierOrderModel && (
        <FinalizeOrderModal
          handleClose={handleModalClose}
          carrierRate={selectedCarrierRates[0]}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          dimensions={dimensions}
          currency={currency}
          connectSocket={connectSocket}
        />
      )}
      {isShowRefreshRateModal && (
        <RefreshRatesModal
          handleClose={() => setIsShowRefreshRateModal(false)}
          handleSubmit={(date: any) => {
            setIsShowRefreshRateModal(false);
            setAddressDetails((prevState: any) => ({
              ...prevState,
              pickupDate: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            }));
            setIsGetRate(true);
          }}
        />
      )}
      {isShowConvertOrderModal && (
        <ConvertOrderModal
          handleClose={() => setIsShowConvertOrderModal(false)}
          quotedList={nonExpiredList}
          currency={currency}
          handleActionType={handleActionType}
        />
      )}
      {isShowGeneratePdfModal && (
        <GeneratePdfModal
          handleClose={() => setIsShowGeneratePdfModal(false)}
          quotedList={quotedList}
          currency={currency}
          selectedDisplayOptions={selectedDisplayOptions}
          setSelectedDisplayOptions={setSelectedDisplayOptions}
          selectedLanguage={selectedLanguage}
          handleLanguageSelect={handleLanguageSelect}
          selectedQuoteToGeneratePdfId={selectedQuoteToGeneratePdfId}
          setSelectedQuoteToGeneratePdfId={setSelectedQuoteToGeneratePdfId}
          handleGeneratePdf={handleGeneratePdf}
          isPdfGenerating={isPdfGenerating}
        />
      )}
    </>
  );
};

export default QuoteDetailPage;
