import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditDisputeExplanation from 'src/app/Claims&Disputes/Disputes/EditDisputeExplanation';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { listDispute } from 'src/services/DisputeService';

import CustomerDetailDisputesLoadingRaw from './CustomerDetailDisputesLoadingRaw';
import CustomerDetailDisputesRaw from './CustomerDetailDisputesRaw';

interface IProps {
  params: any;
  setParams: any;
  customerName: any;
}

const CustomerDetailDisputesTab = ({
  params,
  setParams,
  customerName,
}: IProps) => {
  const navigate = useNavigate();

  const [disputeData, setDisputeData] = useState<any[]>([]);
  const [disputes, setDisputes] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isCustomerDisputeLoading, setIsCustomerDisputeLoading] =
    useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const disputesApiController = new AbortController();
    const disputesApiSignal = disputesApiController.signal;

    setDisputes([]);
    setIsCustomerDisputeLoading(true);
    listDispute(params, disputesApiSignal)
      .then((response: any) => {
        setDisputes(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsCustomerDisputeLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerDisputeLoading(false);
      });

    return () => {
      disputesApiController.abort();
    };
  }, [params]);

  const headCells = useMemo(
    () => [
      {
        id: 'disputeId',
        name: 'Dispute ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
        visible: true,
        rowClassName: 'text-xs ',
      },
      {
        id: 'orderId',
        name: 'Order ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },

      {
        id: 'createdAt',
        name: 'Dispute Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'carrier',
        name: 'Carrier',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'disputeAmount',
        name: 'Dispute Amount',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'timer',
        name: 'Timer',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
    ],
    []
  );

  return (
    <>
      <TableCmp
        headCells={headCells}
        params={params}
        setParams={setParams}
        tableDataArr={disputes}
        TableLoaderRowCmp={CustomerDetailDisputesLoadingRaw}
        TableRowCmp={CustomerDetailDisputesRaw}
        isTableRowClickable={true}
        tableRowCmpProps={{
          onRowClick: (data: any) => {
            setDisputeData(data);

            if (data?.status === 'New') {
              setShowModal(true);
            } else {
              localStorage.setItem(
                TABLE_IDS.CUSTOMER_DETAILS,
                JSON.stringify('claims&disputes')
              );

              navigate(`${PATH.DISPUTE_DETAILS}/${data?.id}`);
            }
          },
        }}
        isTableDataLoading={isCustomerDisputeLoading}
        numberOfSkeletonRows={7}
      />
      {!isCustomerDisputeLoading && !disputes?.length && (
        <NotFoundUI
          title={`No ${customerName}'s dispute found`}
          desc={`There are currently no dispute for ${customerName}`}
          containerClassName="min-h-[160px] !h-auto"
        />
      )}
      <CustomPagination
        recordsPerPage={params.limit}
        totalRecords={total}
        currentPage={params.page}
        handlePagination={(page: number) => {
          setParams((old: any) => ({ ...old, page }));
        }}
      />
      {showModal && (
        <EditDisputeExplanation
          handleClose={() => setShowModal(false)}
          dispute={disputeData}
          title={'Edit Dispute Description'}
          isEdit={true}
        />
      )}
    </>
  );
};

export default CustomerDetailDisputesTab;
