import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarCheck01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CheckBox from 'src/components/CheckBox';
import DateSelect from 'src/components/DateSelect/DateSelect';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  createdLoadLinkPosting,
  getLoadLinkVehicleAttribute,
  getLoadLinkVehicleSize,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import TruckFindingAi from './TruckFindingAi';

const weightTypeOptions = [
  {
    label: 'kg',
    value: 'kg',
  },
  {
    label: 'lb',
    value: 'lb',
  },
];
const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

const schema = yup.object().shape({
  availabilityDate: yup.date().required('Availability date is required.'),
  vehicleSize: yup.string().required('Vehicle size is required.'),
  vehicleType: yup.array().min(1, 'Vehicle type is required.').required(),
  vehicleAttribute: yup.array(),
  sourceRadius: yup.number().required('Source radius is required.'),
  destinationRadius: yup.number().required('Destination radius is required.'),
  linearFootage: yup.number().required('Linear footage is required.'),
  totalWeight: yup.number().required('Total Weight is required.'),
  weightType: yup.string().required('Weight type is required.'),
  comments: yup.string(),
  commodity: yup.string(),
  budgetCost: yup.number().nullable(),
});

interface IProps {
  addressDetails: any;
  validatePostToLoadBoardForm: any;
  setIsPostCreateLoading: any;
  setIsRefresh: any;
  loadLinkPostingDetail?: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
}

const CreateLoadPostingFrom = ({
  addressDetails,
  validatePostToLoadBoardForm,
  setIsPostCreateLoading,
  setIsRefresh,
  loadLinkPostingDetail,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
}: IProps) => {
  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  const [isVehicleSizeLoading, setIsVehicleSizeLoading] = useState(true);
  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<any[]>([]);

  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);
  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);

  const [postCreatePayload, setPostCreatePayload] = useState({});
  const [vehicleTypeFullNames, setVehicleTypeFullNames] = useState<any[]>([]);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      availabilityDate: loadLinkPostingDetail?.availabilityDate
        ? new Date(loadLinkPostingDetail?.availabilityDate)
        : new Date(),
      vehicleSize: loadLinkPostingDetail?.vehicleSize || '',
      vehicleType: loadLinkPostingDetail?.vehicleType
        ? loadLinkPostingDetail?.vehicleType?.split(',') || []
        : addressDetails?.equipmentType?.split(',') || [],
      vehicleAttribute: loadLinkPostingDetail?.vehicleAttribute
        ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
        : [],
      destinationRadius: loadLinkPostingDetail?.destinationRadius || 200,
      sourceRadius: loadLinkPostingDetail?.sourceRadius || 200,
      comments: loadLinkPostingDetail?.comments || '',
      commodity: loadLinkPostingDetail?.commodity || '',
      linearFootage: loadLinkPostingDetail?.linearFootage || null,
      totalWeight: loadLinkPostingDetail?.totalWeight || null,
      weightType: loadLinkPostingDetail?.weightType || 'kg',
      budgetCost: loadLinkPostingDetail?.budgetCost || null,
    },
  });

  useEffect(() => {
    console.log('vehicleType', watch('vehicleType'));
  }, [watch('vehicleType')]);

  const onSubmit = (data: any) => {
    const formDataPayload = {
      quoteId: addressDetails?.id,
      availabilityDate: moment.utc(data?.availabilityDate).format('YYYY-MM-DD'),
      destinationRadius: data?.destinationRadius,
      sourceRadius: data?.sourceRadius,
      vehicleSize: data?.vehicleSize,
      vehicleType: data?.vehicleType?.toString(),
      vehicleTypeFullNames: vehicleTypeFullNames.toString(),
      vehicleAttribute: data?.vehicleAttribute?.toString(),
      comments: data?.comments,
      sourceState: addressDetails?.shipperStateCode,
      sourceCity: addressDetails?.shipperCity,
      destinationState: addressDetails?.consigneeStateCode,
      destinationCity: addressDetails?.consigneeCity,
      linearFootage: data?.linearFootage,
      totalWeight: data?.totalWeight,
      weightType: data?.weightType,
      commodity: data?.commodity,
      budgetCost: data?.budgetCost,
      vehicleTypes: vehicleTypeOptions,
      postId: null,
    };

    if (
      isUpdateLoadPost &&
      loadLinkPostingDetail &&
      loadLinkPostingDetail?.id
    ) {
      formDataPayload.postId = loadLinkPostingDetail?.id;
    }
    setPostCreatePayload(formDataPayload);
    setIsShowTruckFindingAiModal(true);
  };

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    console.log('onTruckFindingAiModalSubmit formDataPayload', formDataPayload);

    setIsShowTruckFindingAiModal(false);
    setIsPostCreateLoading(true);
    createdLoadLinkPosting(formDataPayload)
      .then((response: any) => {
        WalToast.success(response?.message);
        setIsUpdateLoadPost(false);
        setIsRefresh(true);
      })
      .finally(() => {
        setIsPostCreateLoading(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
        setValue(
          'vehicleType',
          loadLinkPostingDetail?.vehicleType
            ? loadLinkPostingDetail?.vehicleType?.split(',') || []
            : addressDetails?.equipmentType?.split(',') || []
        );
        const vehicleType = loadLinkPostingDetail?.vehicleType
          ? loadLinkPostingDetail?.vehicleType?.split(',') || []
          : addressDetails?.equipmentType?.split(',') || [];
        const vehicleTypeFNames = updatedListVehicleTypes
          .filter((type: any) => vehicleType.includes(type.value))
          .map((type: any) => type.label);
        setVehicleTypeFullNames(vehicleTypeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Type Error ', e);
      })
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleSizeOptions = () => {
    setIsVehicleSizeLoading(true);

    getLoadLinkVehicleSize()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleSizeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Size Error ', e);
      })
      .finally(() => {
        setIsVehicleSizeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    getLoadLinkVehicleAttribute()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleAttrOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Attr Error ', e);
      })
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  const handleCommodityChange = (vehicleType: string) => {
    const commodity = getValues('commodity');
    if (commodity) return;

    switch (vehicleType) {
      case 'V':
      case 'K':
        console.log(1);
        setValue('commodity', 'Dry Freight');
        break;
      case 'F':
      case 'S':
      case 'I':
      case 'N':
        console.log(2);
        setValue('commodity', 'Construction Equipment (Tarp)');
        break;
      case 'R':
        console.log(3);
        setValue('commodity', 'Produce (Fresh)');
        break;
      case 'L':
      case 'D':
        console.log(4);
        setValue('commodity', 'Machinery');
        break;
      case 'default':
        break;
    }
  };

  useEffect(() => {
    getVehicleTypeOptions();
    getVehicleSizeOptions();
    getVehicleAttributeOptions();
  }, []);

  useEffect(() => {
    console.log(
      'Load Posting Form validatePostToLoadBoardForm',
      validatePostToLoadBoardForm
    );

    if (validatePostToLoadBoardForm) {
      console.log('submit');
      handleSubmit(onSubmit)();
    }
  }, [validatePostToLoadBoardForm]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-5 py-3">
        <div className="rounded-lg border border-utilityGray200 p-4 ">
          <div className="sm:-mx-1.5 flex flex-wrap gap-y-4">
            {/* first row */}
            <Controller
              name="availabilityDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateSelect
                  inputName="availabilityDate"
                  label="Availability date"
                  labelClassName="block"
                  className={`form_control`}
                  parentClassName="sm:px-1.5 sm:w-1/6 one-month-datepicker"
                  placeholder="Availability date"
                  dateFormat="MMMM do, yyyy"
                  icon={<CalendarCheck01 className="h-5 w-5" />}
                  dateSelectClassName="truncate !pr-9"
                  required={true}
                  selected={value}
                  value={getDateWithSuffixFormat(getFormattedPickupDate(value))}
                  onChangeFunc={(event: any) => {
                    onChange(event?.value);
                  }}
                  errorText={
                    errors.availabilityDate
                      ? errors.availabilityDate.message
                      : null
                  }
                />
              )}
            />
            <Controller
              name="linearFootage"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="linearFootage"
                  inputType="number"
                  label="Linear Footage"
                  labelClassName="mb-1.5 block"
                  parentClassName="sm:px-1.5 sm:w-1/6"
                  className="form_control"
                  value={value}
                  required={true}
                  onChangeFunc={(e) => onChange(parseFloat(e.target.value))}
                  onBlur={(e) => onChange(parseFloat(e.target.value))}
                  disabled={false}
                  errorText={
                    errors.linearFootage ? errors.linearFootage.message : null
                  }
                />
              )}
            />
            <Controller
              name="vehicleSize"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="vehicleSize"
                  placeholder="Select Vehicle Size"
                  label="Vehicle Size"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="sm:px-1.5 sm:w-1/6"
                  id="vehicleSize"
                  className="form_control shadow"
                  size="sm"
                  options={vehicleSizeOptions}
                  isClearable={true}
                  isLoading={isVehicleSizeLoading}
                  onChangeFunc={(event: any) => onChange(event?.value ?? '')}
                  value={vehicleSizeOptions.filter(
                    (val: any) => value === val.value
                  )}
                  required={true}
                  errorText={
                    errors.vehicleSize ? errors.vehicleSize.message : null
                  }
                />
              )}
            />
            <Controller
              name="commodity"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="commodity"
                  placeholder="Enter Commodity"
                  label="Commodity"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="sm:px-1.5 sm:w-1/6"
                  id="commodity"
                  className="form_control"
                  value={value}
                  onChangeFunc={(e) => onChange(e.target.value)}
                  onBlur={(e) => onChange(e.target.value)}
                  required={false}
                />
              )}
            />
            <div className="sm:px-1.5 sm:w-1/6">
              <label className="form_label mb-1.5 block">
                Total Weight<span className="text-red-600 leading-4">*</span>
              </label>
              <div className="form_control p-0 relative !h-auto">
                <Controller
                  name="totalWeight"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      inputName="totalWeight"
                      inputType="number"
                      labelClassName="mb-1.5 block"
                      parentClassName=""
                      className="!border-0 !h-auto !pr-20"
                      value={value}
                      required={true}
                      onChangeFunc={(e) => onChange(parseInt(e.target.value))}
                      onBlur={(e) => onChange(parseInt(e.target.value))}
                      disabled={false}
                    />
                  )}
                />
                <Controller
                  name="weightType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectBox
                      name="weightType"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="absolute right-0 top-0 w-[60px] dropdown-within-input"
                      id="weightType"
                      classComp="rounded-none rounded-r-lg"
                      size="sm"
                      options={weightTypeOptions}
                      onChangeFunc={(event: any) =>
                        onChange(event?.value ?? '')
                      }
                      value={weightTypeOptions.filter(
                        (val: any) => value === val.value
                      )}
                    />
                  )}
                />
              </div>
              <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                {errors.totalWeight && (
                  <ErrorMsg errorText={errors.totalWeight.message} />
                )}
              </div>
            </div>

            <Controller
              name="budgetCost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="budgetCost"
                  inputType="number"
                  label="Budget Cost"
                  labelClassName="mb-1.5 block"
                  parentClassName="sm:px-1.5 sm:w-1/6"
                  className="form_control"
                  value={value || ''}
                  onChangeFunc={(e) => onChange(parseFloat(e.target.value))}
                  onBlur={(e) => onChange(parseFloat(e.target.value))}
                  disabled={false}
                  errorText={
                    errors.budgetCost ? errors.budgetCost.message : null
                  }
                />
              )}
            />

            {/* second row */}

            <div className="sm:px-1.5 sm:w-1/2">
              <label className="form_label mb-1.5 block">Source</label>
              <div className="rounded-xl border border-utilityGray200 p-4">
                <div className="sm:-mx-1.5 flex flex-wrap gap-y-4">
                  <InputText
                    inputName="sourceState"
                    label="State"
                    labelClassName="mb-1.5 block"
                    parentClassName="sm:px-1.5 sm:w-1/2"
                    className="form_control"
                    value={addressDetails?.shipperStateCode}
                    disabled={true}
                    required={true}
                  />
                  <InputText
                    inputName="sourceCity"
                    label="City"
                    labelClassName="mb-1.5 block"
                    parentClassName="sm:px-1.5 sm:w-1/2"
                    className="form_control"
                    value={addressDetails?.shipperCity}
                    disabled={true}
                    required={true}
                  />
                  <div className="sm:px-1.5 w-full">
                    <label className="form_label mb-1.5 block">Radius</label>
                    <div className="w-full flex rounded-lg border border-utilityGray200 bg-gray25 p-2">
                      {radiusOptions.map((radius: any, index: number) => (
                        <Controller
                          key={`source_radius_${index}`}
                          name="sourceRadius"
                          control={control}
                          render={({ field }) => (
                            <div className="w-1/4 flex items-center">
                              <Radio
                                {...field}
                                id={`sourceRadius_${index}`}
                                onChangeFunc={(e) =>
                                  field.onChange(e.target.value)
                                }
                                inputName={'sourceRadius'}
                                value={radius.value}
                                checked={field.value == radius.value}
                              />
                              <label
                                htmlFor={`sourceRadius_${index}`}
                                className="text-textSecondary text-sm block ml-2 font-medium cursor-pointer"
                              >
                                {radius.label}
                              </label>
                            </div>
                          )}
                        />
                      ))}
                    </div>
                    <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                      {errors.sourceRadius && (
                        <ErrorMsg errorText={errors.sourceRadius.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:px-1.5 sm:w-1/2">
              <label className="form_label mb-1.5 block">Destination</label>
              <div className="rounded-xl border border-utilityGray200 p-4">
                <div className="sm:-mx-1.5 flex flex-wrap gap-y-4">
                  <InputText
                    inputName="destinationState"
                    label="Destination State"
                    labelClassName="mb-1.5 block"
                    parentClassName="sm:px-1.5 sm:w-1/2"
                    className="form_control"
                    value={addressDetails?.consigneeStateCode}
                    disabled={true}
                    required={true}
                  />
                  <InputText
                    inputName="destinationCity"
                    label="Destination City"
                    labelClassName="mb-1.5 block"
                    parentClassName="sm:px-1.5 sm:w-1/2"
                    className="form_control"
                    value={addressDetails?.consigneeCity}
                    disabled={true}
                    required={true}
                  />

                  <div className="sm:px-1.5 w-full">
                    <label className="form_label mb-1.5 block">Radius</label>
                    <div className="w-full flex rounded-lg border border-utilityGray200 bg-gray25 p-2">
                      {radiusOptions.map((radius: any, index: number) => (
                        <Controller
                          key={`destination_city_${index}`}
                          name="destinationRadius"
                          control={control}
                          render={({ field }) => (
                            <div className="w-1/4 flex items-center">
                              <Radio
                                {...field}
                                id={`destinationRadius_${index}`}
                                onChangeFunc={(e) =>
                                  field.onChange(e.target.value)
                                }
                                inputName={'destinationRadius'}
                                value={radius.value}
                                checked={field.value == radius.value}
                              />
                              <label
                                htmlFor={`destinationRadius_${index}`}
                                className="text-textSecondary text-sm block ml-2 font-medium cursor-pointer"
                              >
                                {radius.label}
                              </label>
                            </div>
                          )}
                        />
                      ))}
                    </div>
                    <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5">
                      {errors.destinationRadius && (
                        <ErrorMsg
                          errorText={errors.destinationRadius.message}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* third row */}
            <div className={`sm:px-1.5 w-full `}>
              <label className="form_label mb-1.5 block">
                Equipment Type
                <span className="text-red-600 leading-4 text-xs">*</span>
              </label>

              <div className="flex flex-wrap rounded-xl border border-utilityGray200 px-3 py-4 gap-y-4">
                {vehicleTypeOptions.map((vehicleType: any, index: number) => (
                  <Controller
                    key={`vehicle_type_${index}`}
                    name="vehicleType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="w-1/5 flex items-center gap-x-2">
                        <CheckBox
                          id={`vehicle_type_${vehicleType.value}`}
                          name="vehicleType"
                          checked={value.includes(vehicleType.value)}
                          parentClassName={`${
                            isVehicleTypeLoading ? 'custom-loading' : ''
                          }`}
                          value={vehicleType.value}
                          onChangeFunc={(event: any) => {
                            const checked = event.target.checked;
                            let vehicleTypes = value ? [...value] : [];
                            let vehicleTypeFNames = vehicleTypeFullNames
                              ? [...vehicleTypeFullNames]
                              : [];

                            if (checked) {
                              vehicleTypes.push(vehicleType.value);
                              vehicleTypeFNames.push(vehicleType.label);
                              handleCommodityChange(vehicleType.value);
                            } else {
                              vehicleTypes = vehicleTypes.filter(
                                (item) => item !== vehicleType.value
                              );
                              vehicleTypeFNames = vehicleTypeFullNames.filter(
                                (item) => item !== vehicleType.value
                              );
                            }
                            onChange(vehicleTypes);
                            setVehicleTypeFullNames(vehicleTypeFNames);
                          }}
                        />
                        <label
                          htmlFor={`vehicle_type_${vehicleType.value}`}
                          className={`text-textSecondary text-sm font-medium cursor-pointer ${
                            isVehicleTypeLoading ? 'custom-loading' : ''
                          }`}
                        >
                          {vehicleType.label}
                        </label>
                      </div>
                    )}
                  />
                ))}
              </div>
              <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                {errors.vehicleType && (
                  <ErrorMsg errorText={errors.vehicleType.message} />
                )}
              </div>
            </div>
            {/* fourth row */}
            <div className={`sm:px-1.5 w-full `}>
              <label className="form_label mb-1.5 block">
                Additional Services
              </label>
              <div className="flex flex-wrap rounded-xl border border-utilityGray200 px-3 py-4 gap-y-4 ">
                {vehicleAttrOptions.map(
                  (vehicleAttribute: any, index: number) => (
                    <Controller
                      key={`vehicle_attribute_${index}`}
                      name="vehicleAttribute"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="w-1/5 flex items-center gap-x-2 ">
                          <CheckBox
                            id={vehicleAttribute.value}
                            name="vehicleAttribute"
                            classes=""
                            parentClassName={`${
                              isVehicleAttrLoading ? 'custom-loading' : ''
                            }`}
                            value={vehicleAttribute.value}
                            checked={value?.includes(vehicleAttribute.value)}
                            onChangeFunc={(event: any) => {
                              const checked = event.target.checked;
                              let vehicleAttributes = value ? [...value] : [];

                              if (checked) {
                                vehicleAttributes.push(vehicleAttribute.value);
                              } else {
                                vehicleAttributes = vehicleAttributes.filter(
                                  (item) => item !== vehicleAttribute.value
                                );
                              }
                              onChange(vehicleAttributes);
                            }}
                          />
                          <label
                            htmlFor={vehicleAttribute.value}
                            className="text-textSecondary text-sm font-medium  cursor-pointer "
                          >
                            {vehicleAttribute.label}
                          </label>
                        </div>
                      )}
                    />
                  )
                )}
              </div>
            </div>

            {/* fifth row */}
            <Controller
              name="comments"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="comments"
                  label="Comments"
                  placeholder={'Add your additional comments'}
                  labelClassName="mb-1.5 block"
                  asTextarea="textarea"
                  parentClassName="sm:px-1.5 w-full  "
                  className="h-[76px]"
                  value={value}
                  onChangeFunc={(e) => onChange(e?.target?.value)}
                  errorText={errors.comments ? errors.comments.message : null}
                />
              )}
            />
          </div>
        </div>
      </form>

      {isShowTruckFindingAiModal && (
        <TruckFindingAi
          postCreatePayload={postCreatePayload}
          truckFindingAiDetail={loadLinkPostingDetail?.truckFindingAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}
    </>
  );
};

export default CreateLoadPostingFrom;
