import {
  SearchLg,
  CheckSquareBroken,
  DownloadCloud02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import CustomPagination from 'src/components/CustomPagination';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import {
  exportSalesRepReport,
  removeConflict,
  removeFromCommissioned,
  resolveConflict,
  salesRepConflictsList,
  salesRepOrderList,
} from 'src/services/CommissionReportService';
import WalToast from 'src/utils/WalToast';

import ConflictsCommissionReportLoadingRow from './ConflictsCommissionReportLoadingRow';
import ConflictsCommissionReportRow from './ConflictsCommissionReportRow';
import SalesRepCommissionReportLoadingRow from './SalesRepCommissionReportLoadingRow';
import SalesRepCommissionReportRow from './SalesRepCommissionReportRow';
import UserCommissionReportLoadingRow from './UserCommissionReportLoadingRow';
import UserCommissionReportRow from './UserCommissionReportRow';

export const ordersTimeFilterArray: any = [
  {
    value: 'commissioned',
    name: 'Commissioned Orders',
  },
  {
    value: 'claims',
    name: 'Active Claims',
  },
  {
    value: 'disputes',
    name: 'Active Disputes',
  },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'orderCreatedAt',
  page: 1,
  limit: 25,
  filterType: 'commissioned',
};

const initAction = {
  type: '',
  orderId: '',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const SalesRepCommissionReport = () => {
  const navigate = useNavigate();
  const { reportMonth, reportYear } = useContext(BasicContext);
  const { reportId, id } = useParams();

  const [params, setParams] = useState(initParams);
  const [sortData, setSortData] = useState({
    sortField: 'createdAt',
    sortType: 'desc',
  });
  const [action, setAction] = useState(initAction);
  const [activeTab, setActiveTab] = useState('commissioned');
  const [filteredConflictList, setFilteredConflictList] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSalesRepLoading, setIsSalesRepLoading] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [name, setName] = useState('');
  const [salesRep, setSalesRep] = useState<any>([]);
  const [conflictOrders, setConflictOrders] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [totalOrders, setTotalOrders] = useState(0);
  // const [selectedOrder, setSelectedOrder] = useState('');
  const [isConflictLoading, setIsConflictLoading] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState({
    status: false,
    orderId: '',
  });

  const searchDebounce = useCallback(
    debounce(
      (search: string) =>
        setParams((old: any) => ({ ...old, ...{ search, page: 1 } })),
      700
    ),
    []
  );

  const fetchSalesRepConflictsList = () => {
    setIsSalesRepLoading(true);
    salesRepConflictsList({ reportId, salesRepId: id })
      .then((response: any) => {
        setConflictOrders(response.conflictOrders ?? []);
        setFilteredConflictList(response.conflictOrders ?? []);
        setSalesRep(response.salesRep ? [response.salesRep] : []);
        setName(response.salesRep?.name ?? '');
      })
      .catch((error) => {
        console.error('Error fetching conflicts list:', error);
      })
      .finally(() => {
        setIsSalesRepLoading(false);
      });
  };

  const fetchSalesRepOrderList = () => {
    setIsOrdersLoading(true);
    salesRepOrderList({ ...params, reportId, salesRepId: id })
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotalOrders(response.total ?? 0);
      })
      .catch((error) => {
        console.error('Error fetching order list:', error);
      })
      .finally(() => {
        setIsOrdersLoading(false);
      });
  };

  const headCells = useMemo(() => {
    const cells = [
      {
        id: 'fullID',
        name: 'Order ID',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[1px] min-w-[12.82%]',
      },
      {
        id: 'orderStatus',
        name: 'Order Status',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[12.82%] min-w-[12.82%]',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        rowClassName: 'w-[17.78%] max-w-[17.78%] min-w-[17.78%]',
      },

      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[12.82%] min-w-[12.82%]',
      },
      {
        id: 'combinedRevenue',
        name: 'Total Revenue',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[12.82%] min-w-[12.82%]',
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[12.82%] min-w-[12.82%]',
      },
      {
        id:
          activeTab === 'commissioned'
            ? 'customerName'
            : activeTab === 'claims'
            ? 'claimID'
            : 'disputeID',
        name:
          activeTab === 'commissioned'
            ? 'Customer'
            : activeTab === 'claims'
            ? 'Claim ID'
            : 'Dispute ID',
        sortable: true,
        rowClassName: 'w-[12.82%] max-w-[1px] min-w-[12.82%]',
      },
      {
        id: 'action',
        name: '',
        rowClassName: 'w-[84px] max-w-[84px] min-w-[84px]',
      },
    ];

    return cells;
  }, [activeTab]);

  const CommissionUserHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'commissionPeriod',
        name: 'Commission Period',
      },
      {
        id: 'totalmargin',
        name: 'Total Margin',
      },
      {
        id: 'totalMarginPercentage',
        name: 'Avg. Margin Percentage',
      },
      {
        id: 'AvgMarginPerLoad',
        name: 'Avg. Margin Per Load',
      },
      {
        id: 'numberOfLoadsProjected',
        name: 'Loads Delivered',
      },
    ],
    []
  );

  const WarningHeadCells = useMemo(
    () => [
      // {
      //   id: 'erroricon',
      //   name: '',
      //   rowClassName: '',
      // },
      {
        id: 'fullID',
        name: 'Order ID',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'orderStatus',
        name: 'Status',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'combinedRevenue',
        name: 'Total Revenue',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'year',
        name: 'Commission Period',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  // Call functions on component mount
  useEffect(() => {
    fetchSalesRepConflictsList();
  }, []);

  useEffect(() => {
    fetchSalesRepOrderList();
  }, [params]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedCustomerList = filteredConflictList.sort((a: any, b: any) => {
      if (sortField === 'name') {
        const nameA = a.name.toUpperCase(); // to ensure case-insensitive comparison
        const nameB = b.name.toUpperCase(); // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA.localeCompare(nameB);
        }

        return nameB.localeCompare(nameA);
      } else if (sortField === 'createdAt') {
        if (sortType === 'asc') {
          return moment(a.createdAt).diff(moment(b.createdAt));
        }

        return moment(b.createdAt).diff(moment(a.createdAt));
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }
      }

      return 0;
    });
    setFilteredConflictList([...sortedCustomerList]);
  };

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status && action.type === 'remove') {
        setIsConflictLoading(true);

        removeConflict({ orderId: action.orderId })
          .then(() => {
            setIsConflictLoading(false);
            WalToast.success('Conflict removed successfully');
            fetchSalesRepConflictsList();
            fetchSalesRepOrderList();
          })
          .catch(() => WalToast.error('Conflict not removed', ''));
      }

      if (status && action.type === 'resolve') {
        setIsConflictLoading(true);

        resolveConflict({ orderId: action.orderId })
          .then(() => {
            setIsConflictLoading(false);
            WalToast.success('Conflict resolve successfully');

            fetchSalesRepConflictsList();
            fetchSalesRepOrderList();
          })
          .catch(() => WalToast.error('Conflict not resolve', ''));
      }
      setAction(initAction);
    },
    [action]
  );

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const downloadExcel = async () => {
    try {
      const response = (await exportSalesRepReport({
        reportId: reportId,
        salesRepId: id,
      })) as any;

      if (!response) {
        throw new Error('No data received');
      }

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Commission_report_${name}_${reportMonth}_${reportYear}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      // setError('Failed to download the report. Please try again.');
    } finally {
      // setIsLoading(false);
    }
  };

  const handleDeleteModalOpen = useCallback(
    (status: boolean) => () => {
      if (status) {
        removeFromCommissioned({ id: isShowDeleteModal?.orderId }).then(() => {
          fetchSalesRepOrderList();
          fetchSalesRepConflictsList();
          setIsShowDeleteModal({ status: false, orderId: '' });
        });
      } else {
        setIsShowDeleteModal({ status: false, orderId: '' });
      }
    },
    [isShowDeleteModal]
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="View and manage your commission reports here."
            rightSideContent={
              <div className="flex gap-x-3">
                <ButtonCmp
                  className=" btn-outline-primary"
                  onClick={() => downloadExcel()}
                >
                  <DownloadCloud02 className="w-4 h-4 text-primary cursor-pointer" />
                  Export
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  type="submit"
                  onClick={() => {
                    navigate(`${PATH.COMMISSION_REPORT}/${reportId}`);
                  }}
                >
                  <XClose className="w-4 h-4" />
                </ButtonCmp>
              </div>
            }
          />
        }
      >
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={CommissionUserHeadCells}
                tableDataArr={salesRep}
                TableLoaderRowCmp={UserCommissionReportLoadingRow}
                TableRowCmp={UserCommissionReportRow}
                isTableDataLoading={isSalesRepLoading}
                numberOfSkeletonRows={1}
              />
            </div>
          </div>
        </div>
        {filteredConflictList?.length > 0 && (
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col">
            <div className="max-h-[220px] w-full flex-1 flex flex-col">
              <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={WarningHeadCells}
                  tableDataArr={filteredConflictList}
                  TableLoaderRowCmp={ConflictsCommissionReportLoadingRow}
                  TableRowCmp={ConflictsCommissionReportRow}
                  tableRowCmpProps={{
                    setAction: setAction,
                  }}
                  isTableDataLoading={isSalesRepLoading}
                  params={sortData}
                  setParams={setSortData}
                />
              </div>
              {!conflictOrders.length && !isSalesRepLoading && (
                <NotFoundUI
                  title="No Conflicts Found"
                  desc="There are no data for conflicts."
                  containerClassName="!h-auto !my-[32px]"
                />
              )}
            </div>
          </div>
        )}
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex-col flex-1 flex">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  Commission Order Breakdown
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isOrdersLoading ? 'custom-loading' : ''}
                >
                  {totalOrders} {totalOrders > 1 ? 'orders' : 'order'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View the complete record of Commissioned orders for{' '}
                {salesRep[0]?.name}.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <TabButton
                className="table-tab max-xxl:!px-3"
                tabArray={ordersTimeFilterArray}
                parentClassName="table-tabs"
                isActive={activeTab}
                handleOnClick={(e: any) => {
                  setIsOrdersLoading(true);
                  setActiveTab(e?.target?.dataset?.value);
                  setParams((old) => ({
                    ...old,
                    filterType: e?.target?.dataset?.value,
                    page: 1,
                  }));
                }}
              />
            </div>
            <InputText
              inputName="searchOrder"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={searchValue}
              inputType="text"
              isClearable={true}
              parentClassName="table-searchInput"
              onChangeFunc={(e) => {
                setSearchValue(e.target.value);
                searchDebounce(e.target.value);
              }}
            />

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className=" lg:min-h-[122px] w-full border-t border-gray100 h-full flex flex-col flex-1"
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={orders}
                TableLoaderRowCmp={SalesRepCommissionReportLoadingRow}
                TableRowCmp={SalesRepCommissionReportRow}
                tableRowCmpProps={{
                  setIsShowDeleteModal: setIsShowDeleteModal,
                  activeTab: activeTab,
                }}
                params={params}
                setParams={setParams}
                isTableDataLoading={isOrdersLoading}
                numberOfSkeletonRows={params.limit}
              />
            </div>
            {!orders.length && !isOrdersLoading && (
              <NotFoundUI
                title="No Order Found"
                desc="There are no data for order."
                containerClassName="!h-[auto] flex-1 !my-[32px]"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totalOrders}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>

      {action.type === 'remove' && (
        <DeleteModal
          moduleName={action.orderId}
          handleClose={handleModalClose}
        />
      )}

      {action.type === 'resolve' && (
        <CommonModal
          title={
            <span className="line-clamp-2 word-break">
              Resolve Conflict {action.orderId}
            </span>
          }
          size="max-w-[400px]"
          titleDesc={`Are you sure you want to resolve ${action.orderId} conflict ? This action can't be undone.`}
          // handleClose={() =>
          //   isDeleteLoading ? false : handleModalClose(false)()
          // }
          handleClose={() =>
            isConflictLoading ? false : setAction(initAction)
          }
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
          headerIcon={<CheckSquareBroken />}
          secondaryBtnOnClick={() => setAction(initAction)}
          secondaryBtnDisabled={isConflictLoading}
          dangerBtnText="Resolve"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={handleModalClose(true)}
          primaryBtnDisabled={isConflictLoading}
          primaryBtnLoading={isConflictLoading}
        >
          &nbsp;
        </CommonModal>
      )}

      {isShowDeleteModal?.status && (
        <DeleteModal
          moduleName="this order from Commissioned Orders"
          handleClose={handleDeleteModalOpen}
        />
      )}
    </>
  );
};

export default SalesRepCommissionReport;
