import { Minus, Plus } from '@untitled-ui/icons-react//build/cjs';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import {
  getFormattedNumber,
  getProgressClass,
} from 'src/utils/CommonFunctions';

import {
  ICustomerData,
  ICustomerFooterRowProps,
  ITotalCustomerTargetNumbers,
} from './Target.interface';

const TargetSetFooterRow = ({
  data,
  miscellaneousData,
  setMiscellaneousData,
  totalData,
  setTotalData,
  setTargetData,
}: ICustomerFooterRowProps) => {
  const [isUpdatingValue, setIsUpdatingValue] = useState<string>('');
  const [targetProfit, setTargetProfit] = useState<number>(0);
  const [targetLoads, setTargetLoads] = useState<number>(0);
  // const [percentageChange, setPercentageChange] = useState<number>(2.5);
  const percentageChange = 2.5;

  // useEffect(() => {
  //   setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
  //     ...old,
  //     targetProfit: targetProfit,
  //   }));
  // }, [targetProfit]);

  // useEffect(() => {
  //   setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
  //     ...old,
  //     targetLoads: targetLoads,
  //   }));
  // }, [targetLoads]);

  useEffect(() => {
    if (data.isMiscellaneous) {
      setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
        ...old,
        targetProfit: data.targetProfit || data.prevMonthTargetProfit || 0,
        targetLoads: data.targetLoads || data.prevMonthTargetLoads || 0,
      }));
    }
  }, []);

  useEffect(() => {
    setTargetProfit(data.targetProfit || data.prevMonthTargetProfit || 0);
    setTargetLoads(data.targetLoads || data.prevMonthTargetLoads || 0);
  }, [data]);

  const updateOtherValue = (
    key: 'targetProfit' | 'targetLoads',
    updatedValue = 0,
    isInputChange = false
  ) => {
    setIsUpdatingValue('');

    if (data.isMiscellaneous) {
      if (key === 'targetProfit') {
        if (updatedValue === 0 && isInputChange) {
          updatedValue = targetProfit;
        }
        setTotalData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetProfit: old.targetProfit - data.targetProfit + updatedValue,
        }));

        setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetProfit: updatedValue,
        }));
      } else {
        if (updatedValue === 0 && isInputChange) {
          updatedValue = targetLoads;
        }
        setTotalData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetLoads: old.targetLoads - data.targetLoads + updatedValue,
        }));

        setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetLoads: updatedValue,
        }));
      }
    } else {
      if (key === 'targetProfit') {
        if (updatedValue === 0 && isInputChange) {
          updatedValue = targetProfit;
        }
        let prevTargetProfit = totalData.targetProfit;

        let percentageIncrease =
          ((updatedValue - prevTargetProfit) / prevTargetProfit) * 100;

        console.log('percentageIncrease', percentageIncrease);

        let loadMultiplier = 1 + percentageIncrease / 100;
        loadMultiplier = Math.ceil(loadMultiplier * 10) / 10;

        console.log('loadMultiplier', loadMultiplier);

        setTotalData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetProfit: updatedValue,
        }));

        setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetProfit: Math.round(old.targetProfit * loadMultiplier),
        }));

        setTargetData((prevTargets: ICustomerData[]) => {
          let newTargets = prevTargets.map((old: ICustomerData) => {
            if (old.targetProfit) {
              old.targetProfit = Math.round(old.targetProfit * loadMultiplier);
            }

            return old;
          });

          const miscellaneousDataCount = Math.round(
            miscellaneousData?.targetProfit * loadMultiplier
          );

          let currentTotal =
            newTargets.reduce((sum, target) => sum + target.targetProfit, 0) +
            miscellaneousDataCount;

          // console.log('currentTotal', currentTotal);
          // console.log('updatedValue', updatedValue);

          if (currentTotal < updatedValue) {
            let difference = updatedValue - currentTotal;

            const minLoadTarget = newTargets.reduce((min, target) =>
              target.targetProfit < min.targetProfit ? target : min
            );

            if (miscellaneousDataCount <= minLoadTarget.targetProfit) {
              setMiscellaneousData((old) => ({
                ...old,
                targetProfit: old.targetProfit + difference,
              }));
            } else {
              if (minLoadTarget) {
                minLoadTarget.targetProfit += difference;
              }
            }
          } else if (currentTotal > updatedValue) {
            let difference = currentTotal - updatedValue;

            const maxLoadTarget = newTargets.reduce((max, target) =>
              target.targetProfit > max.targetProfit ? target : max
            );

            if (miscellaneousDataCount >= maxLoadTarget.targetProfit) {
              setMiscellaneousData((old) => ({
                ...old,
                targetProfit: Math.max(old.targetProfit - difference, 0),
              }));
            } else {
              if (maxLoadTarget) {
                maxLoadTarget.targetProfit -= difference;
              }
            }
          }

          return newTargets;
        });

        //old one
        // setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
        //   ...old,
        //   targetProfit: Math.max(
        //     Math.floor(old.targetProfit * (1 + percentageIncrease / 100)),
        //     0
        //   ),
        // }));

        // setTargetData((prevTargets: ICustomerData[]) => {
        //   const newTargets = prevTargets.map((old: ICustomerData) => {
        //     if (old.targetProfit) {
        //       old.targetProfit = Math.max(
        //         Math.floor(old.targetProfit * (1 + percentageIncrease / 100)),
        //         0
        //       );
        //     }

        //     return old;
        //   });

        //   return newTargets;
        // });
      } else {
        if (updatedValue === 0 && isInputChange) {
          updatedValue = targetLoads;
        }
        let prevTargetLoads = totalData.targetLoads;

        let percentageIncrease =
          ((updatedValue - prevTargetLoads) / prevTargetLoads) * 100;

        console.log('percentageIncrease', percentageIncrease);

        let loadMultiplier = 1 + percentageIncrease / 100;
        loadMultiplier = Math.ceil(loadMultiplier * 10) / 10;

        console.log('loadMultiplier', loadMultiplier);

        setTotalData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetLoads: updatedValue,
        }));

        setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
          ...old,
          targetLoads: Math.round(old.targetLoads * loadMultiplier),
        }));

        setTargetData((prevTargets: ICustomerData[]) => {
          let newTargets = prevTargets.map((old: ICustomerData) => {
            if (old.targetLoads) {
              old.targetLoads = Math.round(old.targetLoads * loadMultiplier);
              // console.log('old.targetLoads', old.targetLoads);
            }

            return old;
          });

          const miscellaneousDataCount = Math.round(
            miscellaneousData?.targetLoads * loadMultiplier
          );

          let currentTotal =
            newTargets.reduce((sum, target) => sum + target.targetLoads, 0) +
            miscellaneousDataCount;

          // console.log('currentTotal', currentTotal);
          // console.log('updatedValue', updatedValue);

          if (currentTotal < updatedValue) {
            let difference = updatedValue - currentTotal;

            const minLoadTarget = newTargets.reduce((min, target) =>
              target.targetLoads < min.targetLoads ? target : min
            );

            if (miscellaneousDataCount <= minLoadTarget.targetLoads) {
              setMiscellaneousData((old) => ({
                ...old,
                targetLoads: old.targetLoads + difference,
              }));
            } else {
              if (minLoadTarget) {
                minLoadTarget.targetLoads += difference;
              }
            }
          } else if (currentTotal > updatedValue) {
            let difference = currentTotal - updatedValue;

            const maxLoadTarget = newTargets.reduce((max, target) =>
              target.targetLoads > max.targetLoads ? target : max
            );

            if (miscellaneousDataCount >= maxLoadTarget.targetLoads) {
              setMiscellaneousData((old) => ({
                ...old,
                targetLoads: Math.max(old.targetLoads - difference, 0),
              }));
            } else {
              if (maxLoadTarget) {
                maxLoadTarget.targetLoads -= difference;
              }
            }
          }

          return newTargets;
        });

        //old one

        // setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
        //   ...old,
        //   targetLoads: parseInt(
        //     Math.max(
        //       Math.ceil(old.targetLoads * (1 + percentageIncrease / 100)),
        //       0
        //     ).toString()
        //   ),
        // }));

        // setTargetData((prevTargets: ICustomerData[]) => {
        //   const newTargets = prevTargets.map((old: ICustomerData) => {
        //     if (old.targetProfit) {
        //       old.targetLoads = parseInt(
        //         Math.max(
        //           Math.ceil(old.targetLoads * (1 + percentageIncrease / 100)),
        //           0
        //         ).toString()
        //       );
        //     }

        //     return old;
        //   });

        //   console.log('newTargets', newTargets);

        //   return newTargets;
        // });
      }
    }
  };

  const handleProfitDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetProfit(Math.floor(targetProfit * decreaseFactor));
    updateOtherValue('targetProfit', Math.floor(targetProfit * decreaseFactor));
  };

  const handleProfitIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetProfit(
      Math.ceil(targetProfit === 0 ? 1 : targetProfit * increaseFactor)
    );
    updateOtherValue(
      'targetProfit',
      Math.ceil(targetProfit === 0 ? 1 : targetProfit * increaseFactor)
    );
  };

  const handleLoadsDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetLoads(Math.floor(targetLoads * decreaseFactor));
    updateOtherValue('targetLoads', Math.floor(targetLoads * decreaseFactor));
  };

  const handleLoadsIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetLoads(
      Math.ceil(targetLoads === 0 ? 1 : targetLoads * increaseFactor)
    );

    updateOtherValue(
      'targetLoads',
      Math.ceil(targetLoads === 0 ? 1 : targetLoads * increaseFactor)
    );
  };

  // const handlePlusDoubleClick = () => {
  //   setPercentageChange((prev) => prev + 2.5);
  // };

  // const handleMinusDoubleClick = () => {
  //   setPercentageChange((prev) => Math.max(2.5, prev - 2.5));
  // };

  return (
    <>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
        <span className="h-[32.5px] flex items-center">{data.name}</span>
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 rounded-bl-xl min-w-[14rem] w-[14rem] max-w-[14rem]">
        <span>-</span>
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[20%] w-[20%] max-w-[20%]">
        <span>
          $
          {getFormattedNumber(
            data.prevMonthCompletedProfit,
            false,
            false,
            true
          )}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span>
          ${getFormattedNumber(data.prevMonthTargetProfit, false, false, true)}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span
          className={`text-textSecondary ${getProgressClass(
            data.prevMonthCompletedProfitPercentage,
            false
          )}`}
        >
          {data.prevMonthCompletedProfitPercentage}%
        </span>
      </td>

      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[20%] w-[20%] max-w-[20%]">
        <span>{data.prevMonthCompletedLoads}</span>
        <span>&nbsp;/&nbsp;</span>
        <span>{data.prevMonthTargetLoads}</span>
        <span>&nbsp;/&nbsp;</span>
        <span
          className={`text-textSecondary ${getProgressClass(
            data.prevMonthCompletedLoadsPercentage,
            false
          )}`}
        >
          {data.prevMonthCompletedLoadsPercentage}%
        </span>
      </td>

      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[18%] w-[18%]">
        <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
          <ButtonCmp
            className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
            onClick={handleProfitDecrease}
            // onDoubleClick={handleMinusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`-${percentageChange}%`}
                </div>
              }
            >
              <Minus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
          <InputText
            inputName="targetProfit"
            className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
              isUpdatingValue ? '' : ''
            }`}
            value={
              (isUpdatingValue === 'targetProfit'
                ? targetProfit
                : `$${getFormattedNumber(targetProfit)}`) || ''
            }
            inputType={isUpdatingValue === 'targetProfit' ? 'number' : 'text'}
            placeholder=""
            onKeyPress={(event: any) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChangeFunc={(e: any) =>
              setTargetProfit(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
            parentClassName="max-w-full"
            onBlur={() => updateOtherValue('targetProfit', undefined, true)}
            onFocus={() => setIsUpdatingValue('targetProfit')}
          />

          <ButtonCmp
            className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
            onClick={handleProfitIncrease}
            // onDoubleClick={handlePlusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`+${percentageChange}%`}
                </div>
              }
            >
              <Plus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
        </div>
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[18%] w-[18%]">
        <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
          <ButtonCmp
            className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
            onClick={handleLoadsDecrease}
            // onDoubleClick={handleMinusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`-${percentageChange}%`}
                </div>
              }
            >
              <Minus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
          <InputText
            inputName="targetLoads"
            // className="bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs"
            className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
              isUpdatingValue ? '' : ''
            }`}
            value={
              isUpdatingValue === 'targetLoads'
                ? targetLoads || ''
                : targetLoads || 0
            }
            // value={targetLoads || ''}
            inputType="number"
            parentClassName="max-w-full"
            placeholder=""
            onKeyPress={(event: any) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            // onChangeFunc={(e: any) =>
            //   setTargetLoads(parseInt(e.target.value))
            // }
            onChangeFunc={(e: any) =>
              setTargetLoads(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
            onBlur={() => updateOtherValue('targetLoads', undefined, true)}
            onFocus={() => setIsUpdatingValue('targetLoads')}
          />
          <ButtonCmp
            className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
            onClick={handleLoadsIncrease}
            // onDoubleClick={handlePlusDoubleClick}
          >
            {/* @ts-ignore */}
            <Tooltip
              delay={300}
              arrow={true}
              html={
                <div
                  style={{ bottom: `0px` }}
                  className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                >
                  {`+${percentageChange}%`}
                </div>
              }
            >
              <Plus className="w-4 h-4" />
            </Tooltip>
          </ButtonCmp>
        </div>
      </td>
    </>
  );
};

export default TargetSetFooterRow;
