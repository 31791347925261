import React, { useEffect, useRef } from 'react';

function OutsideClickHandler({
  onOutsideClick,
  containerClassName,
  children,
}: any) {
  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div className={containerClassName} ref={ref}>
      {children}
    </div>
  );
}

export default OutsideClickHandler;
