import moment from 'moment';

export enum SortBy {
  UserName = 'userName',
  TargetLoads = 'targetLoads',
  TargetProfit = 'targetProfit',
  CompletedLoads = 'completedLoads',
  CompletedProfit = 'completedProfit',
  CompletedProfitPercentage = 'completedProfitPercentage',
  CompletedLoadsPercentage = 'completedLoadsPercentage',
  TargetCustomersCount = 'targetCustomersCount',
  CustomersCount = 'customersCount',
}

export enum SortByForCustomer {
  CustomerName = 'customerName',
  TargetProfit = 'targetProfit',
  TargetLoads = 'targetLoads',
  CompletedLoads = 'completedLoads',
  CompletedProfit = 'completedProfit',
  CompletedLoadsPercentage = 'completedLoadsPercentage',
  CompletedProfitPercentage = 'completedProfitPercentage',
  PrevMonthTargetProfit = 'prevMonthTargetProfit',
  PrevMonthTargetLoads = 'prevMonthTargetLoads',
  PrevMonthCompletedLoads = 'prevMonthCompletedLoads',
  PrevMonthCompletedProfit = 'prevMonthCompletedProfit',
  PrevMonthCompletedLoadsPercentage = 'prevMonthCompletedLoadsPercentage',
  PrevMonthCompletedProfitPercentage = 'prevMonthCompletedProfitPercentage',
  Top = 'top12MonthProfit',
  Tags = 'tags',
}

export interface IRowProps {
  data: ITargetData;
  targetMonth?: String;
}
export interface IFooterRowProps {
  data: ITotalTargetNumbers;
}
export interface ICustomerFooterRowProps {
  data: ITotalCustomerTargetNumbers;
  totalData: ITotalCustomerTargetNumbers;
  miscellaneousData: ITotalCustomerTargetNumbers;
  setTotalData: React.Dispatch<
    React.SetStateAction<ITotalCustomerTargetNumbers>
  >;
  setTargetData: React.Dispatch<React.SetStateAction<ICustomerData[]>>;
  setMiscellaneousData: React.Dispatch<
    React.SetStateAction<ITotalCustomerTargetNumbers>
  >;
}
export interface ITotalCustomerTargetNumbers {
  name?: String;
  isMiscellaneous?: boolean;
  prevMonthTargetProfit: number;
  prevMonthCompletedProfitPercentage: number;
  prevMonthTargetLoads: number;
  prevMonthCompletedLoadsPercentage: number;
  targetProfit: number;
  targetLoads: number;
  completedLoads?: number;
  completedProfit?: number;
  completedLoadsPercentage?: number;
  completedProfitPercentage?: number;
  prevMonthCompletedLoads?: number;
  prevMonthCompletedProfit?: number;
}
export interface ITotalTargetNumbers {
  targetProfit: number;
  targetLoads: number;
  completedLoads: number;
  completedProfit: number;
  completedLoadsPercentage: number;
  completedProfitPercentage: number;
  customersCount?: number;
}

export interface IGetTargetDataParams {
  page?: number;
  limit?: number;
  search?: string;
  filterCustomerId?: string;
  filterUserId?: string;
  targetMonth?: string;
  sortField?: SortBy;
  sortType?: 'asc' | 'desc';
}

export interface ITargetDataResponse {
  page: number;
  limit: number;
  total: number;
  data: ITargetData[];
}

export interface ITargetData {
  userId: string;
  image?: string | null;
  imageUrl?: string | null;
  userName: string;
  targetMonth: string;
  targetProfit: number;
  targetLoads: number;
  completedLoads: number;
  completedProfit: number;
  completedLoadsPercentage: number;
  completedProfitPercentage: number;
  targetCustomersCount: number;
  customersCount: number;
}

export interface ISetSalesTargetsRequest {
  userId: string;
  targetMonth: string;
  targets: any[];
  miscellaneousTargetProfit?: string | number;
  miscellaneousTargetLoads?: string | number;
}

export interface ICustomerTarget {
  customerId: number;
  targetLoads: number;
  targetProfit: number;
  targetMonth: string;
}

export interface ISetSalesTargetsResponse {
  success: boolean;
  message: string;
  data?: any;
}

export interface ICustomerTargetRowProps {
  data: ICustomerData;
  targetData: ICustomerData[];
  handleInputChange: (
    customerId: any,
    field: string,
    value: any,
    otherData: any
  ) => void;
  updatedTargets: ICustomerTarget[];
  setTargetData: React.Dispatch<React.SetStateAction<ICustomerData[]>>;
  setTotalData: React.Dispatch<
    React.SetStateAction<ITotalCustomerTargetNumbers>
  >;
}

export interface IGetCustomerTargetDataParams {
  page?: number;
  limit?: number;
  search?: string;
  userId?: any;
  tag?: string;
  onlyTarget?: boolean;
  targetMonth: string;
  sortField?: SortByForCustomer;
  sortType?: 'asc' | 'desc';
}

export interface ICustomerData {
  id: number;
  customerId: string;
  customerName: string;
  image?: string | null;
  imageUrl?: string | null;
  targetProfit: number;
  targetLoads: number;
  completedLoads: number;
  completedProfit: number;
  completedLoadsPercentage: number;
  completedProfitPercentage: number;
  prevMonthTargetProfit: number;
  prevMonthTargetLoads: number;
  prevMonthCompletedLoads: number;
  prevMonthCompletedProfit: number;
  prevMonthCompletedLoadsPercentage: number;
  prevMonthCompletedProfitPercentage: number;
  tags?: string;
}

export interface ITargetSetTotalRowTableProps {
  totalData: ITotalCustomerTargetNumbers;
  dateRange: any;
  isEdit: boolean;
  isLoading: boolean;
  userDetails: any;
}

export const initParamsForTargetSet: IGetCustomerTargetDataParams = {
  search: '',
  // sortField: SortByForCustomer.TargetProfit,
  // sortType: 'desc',
  tag: '',
  page: 1,
  targetMonth: moment().format('YYYY-MM'),
};

export const headCellsForCustomerTargets = [
  {
    id: SortByForCustomer.CustomerName,
    name: 'Customers',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.Top,
    name: 'Tags',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.CompletedProfit,
    name: 'Current Margin',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.TargetProfit,
    name: 'Target Margin',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.CompletedProfitPercentage,
    name: 'Margin Comp.',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.CompletedLoads,
    name: 'Current Loads',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.TargetLoads,
    name: 'Target Loads',
    sortable: true,
    rowClassName: '',
  },
  {
    id: SortByForCustomer.CompletedLoadsPercentage,
    name: 'Load Comp.',
    sortable: true,
    rowClassName: ' ',
  },
];
