import {
  ArrowDownWideNarrow,
  CheckCheck,
  RefreshCcwDot,
  StepForward,
  WrapText,
} from 'lucide-react';
import { useEditor } from 'novel';
import { getPrevText } from 'novel/utils';
import React, { useEffect, useState } from 'react';

import { CommandGroup, CommandItem, CommandSeparator } from '../ui/command';

const ContinueValue = 'continue';
const options = [
  {
    value: 'improve',
    label: 'Improve writing',
    icon: RefreshCcwDot,
  },

  {
    value: 'fix',
    label: 'Fix grammar',
    icon: CheckCheck,
  },
  {
    value: 'shorter',
    label: 'Make shorter',
    icon: ArrowDownWideNarrow,
  },
  {
    value: 'longer',
    label: 'Make longer',
    icon: WrapText,
  },
];

interface AISelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
  inputValue: any;
}

const AISelectorCommands = ({
  onSelect,
  inputValue,
}: AISelectorCommandsProps) => {
  const { editor }: any = useEditor();
  const [filterOptions, setFilterOptions] = useState<any>([]);
  useEffect(() => {
    setFilterOptions(
      options.filter((option) => option.value.includes(inputValue))
    );
  }, [inputValue]);

  return (
    <>
      <CommandGroup heading="Edit or review selection">
        {filterOptions.map((option: any) => (
          <CommandItem
            onClick={() => {
              // Explicitly define 'value' as string
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(
                slice.content
              );
              onSelect(text, option?.value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-purple-500" />
            {option.label}
          </CommandItem>
        ))}
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup heading="Use AI to do more">
        {ContinueValue.includes(inputValue) && (
          <CommandItem
            onClick={() => {
              const pos = editor.state.selection.from;

              const text = getPrevText(editor, pos);
              onSelect(text, 'continue');
            }}
            value="continue"
            className="gap-2 px-4"
          >
            <StepForward className="h-4 w-4 text-purple-500" />
            Continue writing
          </CommandItem>
        )}
      </CommandGroup>
    </>
  );
};

export default AISelectorCommands;
