import {
  Calendar,
  Check,
  ChevronDown,
  CurrencyDollar,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import { DATE_FORMAT, MARGIN_TYPE } from 'src/constants/common';
import { CarrierList } from 'src/services/CarrierService';
import { quickQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const initRequiredFields = [
  'carrierId',
  'totalCharge',
  'finalCharge',
  'projectedDeliveryDate',
];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const initValidForm = {
  carrierId: false,
  projectedDeliveryDate: false,
  totalCharge: false,
  finalCharge: false,
};

const AddQuickQuote = ({
  carrierListArr,
  quoteId,
  currency,
  serviceList,
  handleClose,
  setIsRefresh,
}: any) => {
  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);
  const filterRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({});
  // const [requiredFields] = useState(initRequiredFields);

  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [isValid, setIsValid] = useState<any>(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [isShowCarrierList, setIsShowCarrierList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [carriers, setCarriers] = useState([]);
  const [filteredCarrierList, setFilteredCarrierList] =
    useState<any>(carrierListArr);
  useEffect(() => {
    setIsValid(
      Object.values(validForm).every((value) => {
        console.log('value::', value);

        return value;
      })
    );
    console.log('validForm', validForm);
  }, [validForm]);

  const getShowFilteredCarrierList = (search: string, originalList: any) => {
    console.log('search', search);
    const filteredList = originalList.filter((originalData: any) => {
      console.log('originalData', originalData?.name);

      return originalData?.name
        ?.toLowerCase()
        .includes(search.toLowerCase().trim());
    });

    return [...filteredList] as any;
  };

  useEffect(() => {
    if (isShowCarrierList && searchValue !== '') {
      const filteredList = getShowFilteredCarrierList(searchValue, carriers);
      setFilteredCarrierList(filteredList);
    } else {
      setFilteredCarrierList(carrierListArr);
    }
  }, [isShowCarrierList, searchValue]);

  useEffect(() => {
    console.log('isValid', isValid);
  }, [isValid]);

  useEffect(() => {
    console.log('formData', formData, '  ', isValid, ' ', validForm);
  }, [formData, isValid]);

  useEffect(() => {
    let services: any = [];

    const serviceListArr = Object.values(serviceList);
    console.log('serviceList', serviceListArr, '   ', serviceListArr?.length);

    if (serviceListArr && serviceListArr?.length) {
      console.log('serviceListfdgdfgd', serviceList);
      serviceListArr.forEach((service: any) => {
        console.log('service', service);
        services.push({
          [service.slug]: {
            name: service.serviceName,
            value: 0,
            serviceId: service.id,
          },
        });
        // existingRequiredFields.push(service.serviceId.slug);
      });
    }

    setFormData({ ...formData, services: services, currency });
  }, [serviceList]);

  useEffect(() => {
    CarrierList()
      .then((response: any) => {
        setCarriers(response.data);
        // setFilteredCarrierList(response.data);
      })
      .catch(console.error);
  }, []);

  const handleSubmit = async () => {
    setIsPrimaryBtnClicked(true);

    try {
      formData.quoteId = quoteId;
      formData.currency = currency;
      quickQuote(formData)
        .then(() => {
          setFormData({});
          handleClose();
          setIsRefresh(true);
          WalToast.success('Quick quote added successfully', '');
        })
        .finally(() => setIsPrimaryBtnClicked(false))
        .catch(console.error);
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]) => {
      if (initRequiredFields.includes(key)) {
        setValidForm((prevValidForm: any) => ({
          ...prevValidForm,
          [key]: value ? true : false,
        }));
      }
    });
  }, [formData]);

  const handleInputChange = (event: any, isService = false) => {
    const { target, type } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      ['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) &&
      !regex.test(value)
    ) {
      return;
    }

    // if (name === "carrierQuoteId" && !editableFormData.carrierQuoteId) {
    //     return;
    // }

    if (initRequiredFields.includes(name)) {
      setIsValid({ ...isValid, [name]: value ? true : false });
    }

    const existingFormData: any = { ...formData };

    if (!isService) {
      existingFormData[name] = value;
    }

    if (name === 'transitTime') {
      existingFormData.projectedDeliveryDate = moment()
        .clone()
        .add(value, 'days')
        .toDate();
    }

    if (['totalCharge', 'marginUnit', 'margin'].includes(name) || isService) {
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;
      existingFormData.services.forEach((quoteService: any, index: number) => {
        Object.entries(quoteService).forEach(([slug, service]: any) => {
          service.value = service.value || 0;
          additionalCharges +=
            name === slug ? parseValue : parseFloat(service.value);
          existingFormData.services[index][slug] = {
            ...service,
            value: name === slug ? value : parseFloat(service.value),
          };
        });
      });

      existingFormData.additionalCharges = additionalCharges.toFixed(2);
      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 ? parseFloat(((total / 100) * marginUnit).toFixed(2)) : 0;
      } else {
        marginUnit =
          total > 0 ? parseFloat(((100 * margin) / total).toFixed(2)) : 0;
      }
      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = value;
      } else if (name === 'margin') {
        existingFormData.margin = value;
      }
    }

    setFormData(existingFormData);
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';
      const updatedFormData = { ...formData };

      const total =
        parseFloat(updatedFormData?.totalCharge || 0) +
        parseFloat(updatedFormData?.additionalCharges);

      if (newType === '%') {
        const margin = parseFloat(updatedFormData?.margin || 0);
        updatedFormData.marginUnit =
          total > 0 && margin > 0 ? ((margin / total) * 100).toFixed(2) : 0;

        updatedFormData.finalCharge = (total + margin).toFixed(2);
      } else {
        const marginUnit = parseFloat(updatedFormData?.marginUnit || 0);

        updatedFormData.margin =
          total > 0 && marginUnit > 0
            ? ((total * marginUnit) / 100).toFixed(2)
            : 0;

        updatedFormData.finalCharge = (
          total + parseFloat(updatedFormData?.margin)
        ).toFixed(2);
      }

      setFormData(updatedFormData);

      return newType;
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowCarrierList &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowCarrierList(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowCarrierList && event.key === 'Escape') {
        setIsShowCarrierList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowCarrierList]);

  return (
    <CommonModal
      title="Add a Quick Quote"
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose(false)}
      headerIcon={<PackageCheck />}
      size={'max-w-[615px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={!isValid || isPrimaryBtnClicked}
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={() => handleClose(false)}
    >
      <div className="w-full p-5">
        {/* Warning message */}

        {/* Carrier Info */}
        <div className="w-full border-b pb-4 border-utilityGray200 mb-4">
          <div className="flex -m-1 ">
            <div className="p-1 w-full mb-4 relative">
              <InputText
                label="Carrier Rates"
                labelClassName="block mb-1.5"
                inputName="searchOrder"
                placeholder="Carrier Rates"
                className="placeholder:text-secondaryTxtColor"
                parentClassName=""
                searchIconClass={`!right-8`}
                icon={
                  <ChevronDown className="absolute top-1/2 -translate-y-1/2 right-2 text-grayText h-5 w-5" />
                }
                value={carrierName}
                onChangeFunc={(e: any) => {
                  // setFormData({
                  //   ...formData,
                  //   carrierId: e.target.value,
                  // });
                  setSearchValue(e.target.value);
                  setCarrierName(e?.target?.value);
                }}
                onFocus={() => {
                  setIsShowCarrierList(true);
                }}
                // errorText={errors?.orderID?.message ?? null}
                isClearable={true}
              />
              {isShowCarrierList && (
                <div
                  id="dropdownDelay"
                  ref={filterRef}
                  className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
                >
                  <ul
                    className="mt-1.5 mb-[9px] max-h-[180px] overflow-y-auto custom-scrollbar-v2"
                    aria-labelledby="dropdownDelayButton"
                  >
                    {filteredCarrierList?.length > 0 ? (
                      filteredCarrierList
                        .slice(0, 1000)
                        .map((carrier: any, index: number) => (
                          <>
                            <li
                              key={index}
                              className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s] ${
                                carrier.id === formData?.carrierId
                                  ? 'bg-gray50'
                                  : ''
                              }`}
                              onClick={() => {
                                console.log('carrier.id', carrier.id);
                                setFormData({
                                  ...formData,
                                  carrierId: carrier?.id,
                                });
                                setCarrierName(carrier?.name);
                                setSearchValue('');
                                // setShowOrdersSearchValue();
                                setIsShowCarrierList(false);
                              }}
                            >
                              <div className="flex justify-between items-center align-middle">
                                {' '}
                                <div className="flex justify-center items-center">
                                  {carrier.image ? (
                                    <img
                                      src={`${carrier.imageUrl}${carrier.image}`}
                                      className="table-profile-icn-circle-xl !w-6 !h-6 rounded-full"
                                      alt={carrier.name}
                                      title={carrier.name}
                                      onError={onError}
                                    />
                                  ) : (
                                    <div className="table-profile-customer-circle-xl !w-6 !h-6 text-xs rounded-full">
                                      {getShortName(carrier.name)}
                                    </div>
                                  )}
                                  <span className="font-medium mx-2">
                                    {carrier?.name}{' '}
                                  </span>
                                </div>
                                {carrier.id === formData?.carrierId ? (
                                  <Check className="w-4 h-4 ml-2 text-primary" />
                                ) : (
                                  ''
                                )}
                              </div>
                            </li>
                          </>
                        ))
                    ) : (
                      <li
                        className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                      >
                        {' '}
                        <span className="font-semibold mr-2 items-center justify-center flex">
                          No Orders found
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="p-1 w-1/2">
              <label className="form_label block mb-1.5">Carrier</label>
              <SelectBox
                name="carrierId"
                placeholder="Carrier Rates"
                parentClassName=" block w-full"
                labelClassName="form_label"
                value={carrierListArr.find(
                  (val: any) => val.value === formData?.carrierId
                )}
                onChangeFunc={(e: any) =>
                  setFormData({
                    ...formData,
                    carrierId: e?.value,
                  })
                }
                options={carrierListArr}
              />
            </div> */}
          </div>
          <div className="flex">
            <div className="p-1 w-1/2">
              <InputText
                label="Carrier quote ID"
                labelClassName="mb-1.5 block text-textSecondary"
                inputType="text"
                inputName="carrierQuoteId"
                value={formData.carrierQuoteId}
                onChangeFunc={handleInputChange}
                onBlur={handleInputChange}
                parentClassName="mb-2"
              />
            </div>
            <div className="p-1 w-1/2">
              <DateSelect
                inputName="projectedDeliveryDate"
                className={`form_control `}
                label="Projected delivery"
                labelClassName="block"
                placeholder={DATE_FORMAT}
                dateFormat="dd/MM/yyyy"
                minDate={moment().toDate()}
                selected={
                  formData && formData.projectedDeliveryDate
                    ? moment(formData.projectedDeliveryDate).toDate()
                    : null
                }
                errorText={''}
                value={getDateWithSuffixFormat(
                  getFormattedPickupDate(formData.projectedDeliveryDate)
                )}
                onChangeFunc={(date: any) => {
                  setFormData({
                    ...formData,
                    projectedDeliveryDate: moment(date?.value),
                  });
                }}
                icon={<Calendar className="h-4 w-4 " />}
                calendarIconClass="!text-primary"
                parentClassName="fixed-width-datepicker right-side-datepicker"
              />
            </div>
          </div>
        </div>

        <div
          className={`flex flex-wrap -mx-1 gap-y-3 mb-4 empty:hidden ${
            formData?.services?.length > 10
              ? 'h-96 overflow-y-scroll custom-scrollbar-v2 '
              : ''
          }`}
        >
          <div className="px-1 sm:w-1/2 w-full">
            <label className="form_label block mb-1.5">Base Quote</label>
            <InputText
              inputType="text"
              inputName="totalCharge"
              className="pl-8"
              parentClassName=""
              value={formData?.totalCharge}
              onChangeFunc={handleInputChange}
              onBlur={handleInputChange}
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
            />
          </div>
          {formData?.services?.map((nonQuoteService: any) =>
            Object.entries(nonQuoteService).map(([slug, service]: any) => (
              <>
                {console.log('slug', slug)}
                <div key={slug} className="px-1 sm:w-1/2 w-full">
                  <InputText
                    inputName={slug}
                    className="pl-8"
                    parentClassName="[&:not(:last-child)]:mb-4"
                    value={service.value}
                    label={service.name}
                    labelClassName="block mb-1.5"
                    icon={
                      <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-4 w-4" />
                    }
                    onChangeFunc={(e) => handleInputChange(e, true)}
                    onBlur={(e) => handleInputChange(e, true)}
                    onFocus={(e) => handleInputChange(e, true)}
                  />
                </div>
              </>
            ))
          )}
        </div>

        <div className="flex flex-wrap -mx-1 gap-y-1 border-t border-utilityGray200 pt-4">
          <div className="px-1 sm:w-1/2 w-full">
            <label className="form_label block mb-1.5">Margin</label>
            <div className="form_control p-0 relative !h-auto">
              {activeMarginType === '$' ? (
                <InputText
                  inputName="margin"
                  inputType="text"
                  placeholder="0"
                  className="!border-0 !h-auto !pl-8 !pr-20"
                  labelClassName="block mb-1.5"
                  value={formData?.margin}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={() => {}}
                  icon={
                    <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
                  }
                />
              ) : (
                <InputText
                  inputName="marginUnit"
                  inputType="text"
                  placeholder="0"
                  className="!border-0 !h-auto !pl-8 !pr-20"
                  labelClassName="block mb-1.5"
                  value={formData?.marginUnit}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={() => {}}
                  icon={
                    <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
                  }
                />
              )}

              <TabButton
                tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                parentClassName="!bg-gray50 !p-[3px]"
                className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                tabArray={tabArray}
                isActive={activeMarginType}
                isTab={true}
                handleOnClick={handleTabChange}
              />
            </div>
          </div>

          <InputText
            inputType="text"
            label="Total Price"
            inputName="finalCharge"
            placeholder="Final Price"
            className="pl-8"
            labelClassName="block mb-1.5"
            parentClassName="px-1 sm:w-1/2 w-full"
            value={formData?.finalCharge}
            onChangeFunc={handleInputChange}
            onBlur={handleInputChange}
            icon={
              <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
            }
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default AddQuickQuote;
