import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import { BasicContext } from 'src/context/BasicContext';

import {
  IMapAddress,
  initAction,
  // initShipperAddress,
  scriptId,
} from '../../order.interface';

import ShipperAndConsigneeAddressModal from './ShipperAndConsigneeAddressModal';

interface IProps {
  action: any;
  setAction: any;
}

const AddressDetails = ({ action, setAction }: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [shipperAddress, setShipperAddress] = useState<any>(null);
  const [consigneeAddress, setConsigneeAddress] = useState<any>(null);
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
  });

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value?.fullAddress);
      setValue('shipper.shipperAddress', value);
      setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      setConsigneeAddress(value?.fullAddress);
      setValue('consignee.consigneeAddress', value);
      setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value?.type === 'blur') {
      // if (value.target.value) {
      //   setIsValidAddress((old) => ({ ...old, [name]: false }));
      // }

      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setValue('shipper.shipperAddress', value);

        return;
      }
      setIsValidAddress((old) => ({ ...old, [name]: true }));
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setValue('consignee.consigneeAddress', value);

        return;
      }
      setIsValidAddress((old) => ({ ...old, [name]: true }));
    }

    if (name === 'consigneeAddress') {
      setValue('consignee.consigneeAddress', value);
      setConsigneeAddress(value?.fullAddress);
    }

    if (name === 'shipperAddress') {
      setValue('shipper.shipperAddress', value);
      setShipperAddress(value?.fullAddress);
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  return (
    <>
      <h5 className="text-grayLight900 text-xl font-semibold">
        Address Details
      </h5>
      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />
      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="shipper"
        title="Shipper"
        onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
        onAddressInputChange={setShipperAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={shipperAddress}
        isValidAddress={isValidAddress}
      />

      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="consignee"
        title="Consignee"
        onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
        onAddressInputChange={setConsigneeAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={consigneeAddress}
        isValidAddress={isValidAddress}
      />
    </>
  );
};

export default AddressDetails;
