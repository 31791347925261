import { ClockFastForward } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import Confirmation from 'src/components/Confirmation';

const ExpiredPage = () => (
  <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
    <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
      <div className="bg-gray50 h-full">
        <Confirmation
          title="Link Expired!"
          Icon={<ClockFastForward className="w-7 h-7 text-fgErrorPrimary" />}
          desc="Oops! This URL is not valid anymore."
          containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
          // buttonText="Return to Homepage"
          // handleActionType={() => (window.location.href = '/')}
        />
      </div>
    </div>
  </div>
);

export default ExpiredPage;
