import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import FIG from 'src/assets/img/fig-icon.svg';
import ImageIcon from 'src/assets/img/image-icon.svg';
import MP4 from 'src/assets/img/mp4-icon.svg';
import pdf from 'src/assets/img/pdf-icon.svg';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  convertToGBMBKB,
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const FileUploadRow = ({
  data,
  setIsDeleteFile,
  setIsDeleteID,
  onRowClick,
}: any) => (
  <>
    <td
      className="px-5 py-4 min-w-64 w-64 max-w-64 cursor-pointer"
      onClick={() => {
        onRowClick(data?.fileUrl + data?.file)();
      }}
    >
      <div className="flex items-center gap-3 cursor-pointer">
        <div className="w-8 h-8 flex-none">
          {data.fileName && data.fileName.toLowerCase().endsWith('.pdf') ? (
            <img src={pdf} className="w-full h-full object-contain" />
          ) : data.fileName.toLowerCase().endsWith('.mp4') ? (
            <img src={MP4} className="w-full h-full object-contain" />
          ) : data.fileName.toLowerCase().endsWith('.fig') ? (
            <img src={FIG} className="w-full h-full object-contain" />
          ) : data?.file ? (
            <img
              src={data?.fileUrl + data?.file}
              className="w-full h-full object-contain"
            />
          ) : (
            <img src={ImageIcon} className="w-full h-full object-contain" />
          )}
        </div>

        <div className="truncate">
          <h6 className="text-grayLight900 font-medium truncate">
            <TooltipCmp
              message={
                data.fileName && data.fileName.length > 24
                  ? data.fileName
                  : null
              }
            >
              {data.fileName}
            </TooltipCmp>
          </h6>
          <span className="font-normal text-grayLight600">
            {convertToGBMBKB(data.fileSize)}
          </span>
        </div>
      </div>
    </td>
    <td
      className="px-5 py-4 text-xs max-w-64 min-w-[25%] w-[25%] cursor-pointer"
      onClick={() => {
        onRowClick(data?.fileUrl + data?.file)();
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: data.createdAt
            ? getDateWithSuffixFormat(
                getFormattedDate(data.createdAt, `MMM Do - YYYY`, true, true)
              )
            : '-',
        }}
      />
    </td>
    <td
      className="px-5 py-4 max-w-64 min-w-[25%] w-[25%] cursor-pointer"
      onClick={() => {
        onRowClick(data?.fileUrl + data?.file)();
      }}
    >
      <div className="flex items-center gap-3">
        <div className="flex-none">
          {data.uploadedBy.imageUrl ? (
            <img
              src={data.uploadedBy.imageUrl + data.uploadedBy.image}
              className="table-profile-icn-circle-sm"
            />
          ) : (
            <label className="table-profile-customer-circle-sm">
              {getShortName(
                `${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
              )}
            </label>
          )}
        </div>
        <div>
          <TooltipCmp
            message={
              data.uploadedBy &&
              `${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
                .length > 24
                ? `${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
                : ''
            }
          >
            <p className="w-32 truncate text-grayLight900 font-medium">
              {data.uploadedBy.firstName} {data.uploadedBy.lastName}
            </p>
          </TooltipCmp>
          <span className="font-normal">
            {data?.uploadedBy?.roleDetails?.displayName}
          </span>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 max-w-16 w-16 min-w-16">
      <div className="flex justify-end gap-2">
        <Trash01
          className="w-4 h-4 cursor-pointer"
          onClick={() => {
            setIsDeleteFile(true);
            setIsDeleteID(data.id);
          }}
        />
      </div>
    </td>
  </>
);

export default FileUploadRow;
