import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { approveCreditIncreaseRequest } from 'src/services/CreditIncreaseRequestService';
import WalToast from 'src/utils/WalToast';

interface IProps {
  handleClose: any;
  creditIncreaseRequestData: any;
}

const CreditIncreaseApproveModal = ({
  handleClose,
  creditIncreaseRequestData,
}: IProps) => {
  const [requestedFinalCreditLimit, setRequestedFinalCreditLimit] =
    useState<any>(creditIncreaseRequestData?.requestedTotalCredit);
  const [isCreditRequestApproveLoading, setIsCreditRequestApproveLoading] =
    useState(false);

  const handleCreditIncreaseRequestApproveAction = () => {
    setIsCreditRequestApproveLoading(true);
    approveCreditIncreaseRequest({
      creditIncreaseRequestId: creditIncreaseRequestData?.id,
      approvedTotalCreditLimit: requestedFinalCreditLimit,
    })
      .then(() => {
        handleClose(true);
      })
      .finally(() => {
        setIsCreditRequestApproveLoading(false);
      })
      .catch((err: any) => {
        WalToast.error(
          'Something went wrong approving credit increase request.'
        );
        console.log(err);
      });
  };

  return (
    <CommonModal
      title={'Approve Credit Request'}
      titleDesc={'Provide a final approved credit limit.'}
      handleClose={() =>
        isCreditRequestApproveLoading ? false : handleClose(false)
      }
      headerIcon={<Check />}
      size={'max-w-[688px]'}
      isOverflow={true}
      modalClassName=""
      primaryBtnText="Approve"
      secondaryBtnText="Cancel"
      primaryBtnLoading={isCreditRequestApproveLoading}
      primaryBtnDisabled={isCreditRequestApproveLoading}
      secondaryBtnDisabled={isCreditRequestApproveLoading}
      primaryBtnOnClick={handleCreditIncreaseRequestApproveAction}
    >
      <div className="w-full p-5 mb-1.5">
        <InputText
          label="Requested Credit Limit"
          placeholder="Enter new credit limit"
          parentClassName="mb-2.5"
          value={requestedFinalCreditLimit}
          onChangeFunc={(e) => setRequestedFinalCreditLimit(e?.target?.value)}
          inputType="number"
        />
      </div>
    </CommonModal>
  );
};

export default CreditIncreaseApproveModal;
