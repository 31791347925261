/* eslint-disable max-lines-per-function */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import ReactQuill from 'react-quill';
import WalToast from 'src/utils/WalToast';

import 'react-quill/dist/quill.snow.css';
import defaultImage from '../../assets/img/default-image.jpg';
import ButtonCmp from '../../components/ButtonCmp';
import CustomPagination from '../../components/CustomPagination';
import CustomRowPerPage from '../../components/CustomRowPerPage';
import DeleteModal from '../../components/DeleteModal';
import Drawer from '../../components/Drawer';
import InputText from '../../components/InputText/InputText';
import TabsCmp from '../../components/TabsCmp';
import { CARRIERS } from '../../constants/common';
import {
  createCarrierContact,
  deleteCarrierContact,
  listCarrierContact,
  updateCarrierContact,
} from '../../services/CarrierContactService';
import {
  deleteCarrier,
  listCarrier,
  updateCarrier,
  updateCarrierImage,
  updateCarrierLimitation,
} from '../../services/CarrierService';
import {
  getShortName,
  onError,
  usePhone,
  useRolePermission,
} from '../../utils/CommonFunctions';

import CarrierServicePrice from './CarrierServicePrice';

const initFormData = {
  carrierId: null,
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactTitle: '',
};
const initValidForm = {
  carrierId: true,
  contactName: true,
  contactEmail: true,
  contactPhone: true,
  contactTitle: true,
  isEmailValid: true,
};
const initAction = {
  mode: null,
  carrier: false,
  sub_carrier: false,
};
const initPhones = '';
const requiredFields = [
  'carrierId',
  'contactName',
  'contactEmail',
  'contactTitle',
];

const initParams = {
  search: '',
  sortDirection: 'DESC',
  sortBy: 'quoteCount',
  page: 1,
  limit: 10,
};

const initFormDataCarrier = {
  name: '',
  phone: '',
  email: '',
  hoursOfOperation: '',
  image: null,
};
const initValidFormCarrier = {
  name: true,
  phone: true,
  email: true,
  isEmailValid: true,
  hoursOfOperation: true,
};
const requiredFieldsCarrier = ['name', 'phone', 'email', 'hoursOfOperation'];

const ContactDetails = ({
  isOpen,
  carrier,
  handleClose,
  setIsRefreshMain,
  activeTab,
  currency,
}: any) => {
  const { hasPermission } = useRolePermission();
  const { getFormatPhone, setFormatPhone } = usePhone();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [carrierContacts, setCarrierContacts] = useState([]);
  const [carrierContact, setCarrierContact] = useState<any>({});
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [action, setAction] = useState(initAction);
  const [isRefresh, setIsRefresh] = useState(false);
  const [phones, setPhones] = useState([initPhones]);
  const [limitation, setLimitation] = useState('');

  const [subCarriers, setSubCarriers] = useState([]);
  const [params, setParams] = useState(initParams);
  const [totals, setTotals] = useState(0);

  const [subCarrier, setSubCarrier] = useState<any>({});
  const [isValidCarrier, setIsValidCarrier] = useState(false);
  const [formDataCarrier, setFormDataCarrier] =
    useState<any>(initFormDataCarrier);
  const [validFormCarrier, setValidFormCarrier] =
    useState(initValidFormCarrier);
  const [displayImage, setDisplayImage] = useState<any>(null);
  const [ignoreClose, setIgnoreClose] = useState<boolean>(false);

  const [spotQuoteEmail, setSpotQuoteEmail] = useState(
    carrier.spotQuoteEmail || ''
  );
  const [isSpotEmailValid, setIsSpotEmailValid] = useState(true);

  const handleFormClose = () => {
    setAction(initAction);
    setCarrierContact({});
    setSubCarrier({});
  };

  useEffect(() => {
    if (isOpen) {
      // setIsLoading(true);
      setFormData({ ...formData, carrierId: carrier.id });

      if (
        carrier.slug === CARRIERS.FREIGHTCOM.SLUG ||
        carrier.slug === CARRIERS.BANYAN.SLUG
      ) {
        listCarrier({
          ...params,
          provider:
            carrier.slug === CARRIERS.FREIGHTCOM.SLUG
              ? 'freightcom'
              : carrier.slug === CARRIERS.BANYAN.SLUG
              ? 'banyan'
              : '',
        })
          .then((response: any) => {
            setSubCarriers(response.data);
            setTotals(response.total);
          })
          .finally(() => {
            setIsLoading(false);
            setIsRefresh(false);
          })
          .catch(console.log);
      }

      listCarrierContact(carrier.id)
        .then((response) => setCarrierContacts(response.data))
        .finally(() => {
          setIsLoading(false);
          setIsRefresh(false);
          handleFormClose();
        })
        .catch(console.log);
    } else {
      setFormData(initFormData);
      setValidForm(initValidForm);
      setAction(initAction);
      setPhones([initPhones]);
      setCarrierContacts([]);

      if (
        carrier.slug === CARRIERS.FREIGHTCOM.SLUG ||
        carrier.slug === CARRIERS.BANYAN.SLUG
      ) {
        setFormDataCarrier(initFormDataCarrier);
        setValidFormCarrier(initValidFormCarrier);
        setDisplayImage(null);
        setSubCarriers([]);
      }
    }
    setLimitation(carrier.limitation);
  }, [isOpen, isRefresh, params]);

  useEffect(() => {
    const isFieldsValid = requiredFields.every(
      (requiredField) =>
        formData[requiredField] !== '' && formData[requiredField] !== null
    );
    setIsValid(isFieldsValid);
  }, [formData]);

  useEffect(() => {
    if (Object.keys(carrierContact).length) {
      const contactPhone = carrierContact.contactPhone;
      setFormData({
        ...formData,
        contactName: carrierContact.contactName || '',
        contactEmail: carrierContact.contactEmail || '',
        contactPhone: contactPhone || '',
        contactTitle: carrierContact.contactTitle || '',
      });

      if (contactPhone) {
        const phonesSplit = contactPhone.split(',');
        setPhones(phonesSplit);
      }
    } else {
      setFormData({
        ...formData,
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactTitle: '',
      });
      setPhones([initPhones]);
    }
  }, [carrierContact]);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      carrierId: true,
      contactName: true,
      contactEmail: true,
      contactPhone: true,
      contactTitle: true,
      isEmailValid: true,
    };
    const email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;

    if (formData.carrierId === '' || formData.carrierId === null) {
      validFormData.carrierId = false;
      valid = false;
    }

    if (formData.contactName === '' || formData.contactName === null) {
      validFormData.contactName = false;
      valid = false;
    }

    if (formData.contactEmail === '' || formData.contactEmail === null) {
      validFormData.contactEmail = false;
      valid = false;
    }

    if (formData.contactEmail && !email.test(formData.contactEmail)) {
      validFormData.isEmailValid = false;
      valid = false;
    }

    if (formData.contactTitle === '' || formData.contactTitle === null) {
      validFormData.contactTitle = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  useEffect(() => {
    setFormData({ ...formData, contactPhone: phones ? phones.join(',') : '' });
  }, [phones]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setIsLoading(true);

    if (action.mode === 'create') {
      createCarrierContact(formData)
        .then(() => {
          setIsRefresh(true);
          WalToast.success('Carrier contact created successfully');
        })
        .finally(() => setIsLoading(false))
        .catch(() => WalToast.error('Carrier contact not created'));
    } else if (action.mode === 'update') {
      updateCarrierContact(carrierContact.id, formData)
        .then(() => {
          setIsRefresh(true);
          WalToast.success('Carrier contact updated successfully');
        })
        .finally(() => setIsLoading(false))
        .catch(() => WalToast.error('Carrier contact not updated'));
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'spotQuoteEmail') {
      setSpotQuoteEmail(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const spotEmailTest = () => {
    const email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;

    if (spotQuoteEmail === '' || spotQuoteEmail === null) {
      setIsSpotEmailValid(false);
    } else if (!email.test(spotQuoteEmail)) {
      setIsSpotEmailValid(false);
    } else {
      setIsSpotEmailValid(true);
    }
  };

  useEffect(() => {
    if (spotQuoteEmail !== '') {
      spotEmailTest();
    }
  }, [spotQuoteEmail]);

  const handlePhoneChange = (index: number) => (event: any) => {
    const { value } = event.target;
    // const regex = /^\+?[0-9]*$/;
    const regex = /^\+?[0-9]{0,12}$/;

    if (regex.test(value)) {
      setPhones((old) => {
        const newPhones = [...old];
        newPhones[index] = value;

        return newPhones;
      });
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null, carrierContactData: any = {}) =>
    () => {
      setIgnoreClose(true);

      if (actionKey === 'sub_carrier') {
        setSubCarrier(carrierContactData);
      } else if (actionKey === 'carrier') {
        setFormData({ ...formData, carrierId: carrier.id });
        setCarrierContact(carrierContactData);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setIgnoreClose(true);

      if (status) {
        deleteCarrierContact(carrierContact.id)
          .then(() => {
            setIsRefresh(true);
            WalToast.success('Carrier contact deleted successfully');
          })
          .catch(() => WalToast.error('Carrier contact not deleted'));
      }
    },
    [carrierContact]
  );

  const addMore = () => {
    setPhones((old) => [...old, initPhones]);
  };

  const removeMore = (index: number) => () => {
    setPhones((old) => {
      const newArray = [...old];
      newArray.splice(index, 1);

      return newArray;
    });
  };

  const handleImage =
    (id: any = null) =>
    (event: any) => {
      const file = event.target.files[0];
      setDisplayImage(URL.createObjectURL(file));
      setFormDataCarrier({ ...formDataCarrier, [event.target.name]: file });

      setIsLoading(true);
      const appendFormData: any = new FormData();
      appendFormData.append('image', file);
      let carrierId = id || subCarrier.id;
      updateCarrierImage(carrierId, appendFormData)
        .then(() => {
          // handleClose();
          setDisplayImage(null);

          if (id) {
            handleClose();
            setIsRefreshMain();
          }
          setIsRefresh(true);
          WalToast.success('Carrier image updated successfully');
        })
        .finally(() => setIsLoading(false))
        .catch(() => WalToast.error('Carrier image not updated'));
    };

  const getTitle = () => (
    <>
      <div className="flex justify-center items-center w-24 h-24 rounded-lg border border-border300">
        <label htmlFor="fileSelect" className="cursor-pointer">
          {carrier.image ? (
            <img
              src={`${carrier.imageUrl}${carrier.image}`}
              className="w-full object-contain p-1 rounded-md h-24"
              alt={carrier.name}
              title={carrier.name}
            />
          ) : (
            carrier.name && getShortName(carrier.name)
          )}
        </label>
        <input
          type="file"
          name="image"
          id="fileSelect"
          accept="image/*"
          onChange={handleImage(carrier.id)}
          className="hidden"
        />
      </div>
      <span className="ms-4">
        {carrier.name}{' '}
        {(carrier.slug === CARRIERS.FREIGHTCOM.SLUG ||
          carrier.slug === CARRIERS.BANYAN.SLUG) &&
          `(${totals} carriers)`}
      </span>
    </>
  );

  const getPhones = (phonesString: any) => {
    if (phonesString) {
      phonesString = phonesString.split(',');

      return (
        <>
          {phonesString.map((phone: string, index: number) => (
            <div key={index} className="py-1">
              {getFormatPhone(phone)}
            </div>
          ))}
        </>
      );
    }

    return '-';
  };

  const headCells = useMemo(
    () => [
      {
        id: 'contact',
        name: 'Contact',
      },
      {
        id: 'phone',
        name: 'Phone number',
      },
      {
        id: 'email',
        name: 'Email',
      },
      {
        id: 'title',
        name: 'Title',
      },
      {
        id: 'action',
      },
    ],
    []
  );

  const headSubCarrierCells = useMemo(
    () => [
      {
        id: '',
        name: '',
      },
      {
        id: 'image',
        name: 'Image',
      },
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'phone',
        name: 'Phone number',
      },
      {
        id: 'email',
        name: 'Email',
      },
      {
        id: 'action',
      },
    ],
    []
  );

  const handleSpotEmailSubmit =
    (isSpotQuote = false) =>
    (event: any) => {
      event.preventDefault();
      setIsLoading(true);
      const data: any = {};
      let successMsg = '';
      let errorMsg = '';

      if (isSpotQuote) {
        data.spotQuoteEmail = spotQuoteEmail;
        successMsg = 'Carrier spot quote email updated successfully';
        errorMsg = 'Carrier spot quote email not updated';
      } else {
        data.limitation = limitation;
        successMsg = 'Carrier limitation updated successfully';
        errorMsg = 'Carrier limitation not updated';
      }
      updateCarrierLimitation(carrier.id, data)
        .then(() => {
          setIsRefreshMain();
          handleClose();
          setIsRefresh(true);
          WalToast.success(successMsg);
        })
        .finally(() => setIsLoading(false))
        .catch(() => WalToast.error(errorMsg));
    };

  /**** sub carriers *****/
  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const handleLimit = (limit: number) => {
    setParams((old) => ({ ...old, limit }));
  };

  const handleModalCloseCarrier = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        deleteCarrier(subCarrier.id)
          .then(() => {
            setIsRefresh(true);
            WalToast.success('Carrier deleted successfully');
          })
          .catch(() => WalToast.error('Carrier not deleted'));
      }
    },
    [subCarrier]
  );

  useEffect(() => {
    if (Object.keys(subCarrier).length) {
      setFormDataCarrier({
        ...formDataCarrier,
        name: subCarrier.name || '',
        phone: subCarrier.phone || '',
        email: subCarrier.email || '',
        hoursOfOperation: subCarrier.hoursOfOperation || '',
      });
    } else {
      setFormDataCarrier({
        ...formDataCarrier,
        name: '',
        phone: '',
        email: '',
        hoursOfOperation: '',
      });
    }
  }, [subCarrier]);

  const handleInputChangeCarrier = (event: any) => {
    const value = event.target.value;
    const regex = /^\+?[0-9]*$/;

    if (event.target.name === 'contactPhone') {
      if (regex.test(value)) {
        setFormDataCarrier({
          ...formDataCarrier,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setFormDataCarrier({
        ...formDataCarrier,
        [event.target.name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    const isFieldsValid = requiredFieldsCarrier.every(
      (requiredField) =>
        formDataCarrier[requiredField] !== '' &&
        formDataCarrier[requiredField] !== null
    );
    setIsValidCarrier(isFieldsValid);
  }, [formDataCarrier]);

  const validCarrier = () => {
    let valid = true;
    const validFormData = {
      name: true,
      phone: true,
      email: true,
      isEmailValid: true,
      hoursOfOperation: true,
    };
    const email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;

    if (formDataCarrier.name === '' || formDataCarrier.name === null) {
      validFormData.name = false;
      valid = false;
    }

    if (formDataCarrier.phone === '' || formDataCarrier.phone === null) {
      validFormData.phone = false;
      valid = false;
    }

    if (formDataCarrier.email === '' || formDataCarrier.email === null) {
      validFormData.email = false;
      valid = false;
    }

    if (formDataCarrier.email && !email.test(formDataCarrier.email)) {
      validFormData.isEmailValid = false;
      valid = false;
    }

    if (
      formDataCarrier.hoursOfOperation === '' ||
      formDataCarrier.hoursOfOperation === null
    ) {
      validFormData.hoursOfOperation = false;
      valid = false;
    }

    setValidFormCarrier({ ...validFormCarrier, ...validFormData });

    return valid;
  };

  const handleSubmitCarrier = async (event: any) => {
    event.preventDefault();

    if (!validCarrier()) {
      return true;
    }
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formDataCarrier).forEach((data) => {
      const [key, value] = data;
      appendFormData.append(key, value);
    });
    updateCarrier(subCarrier.id, appendFormData)
      .then(() => {
        setIsRefresh(true);
        WalToast.success('Carrier updated successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(() => WalToast.error('Carrier not updated'));
  };

  return (
    <>
      {isLoading && (
        <div
          role="status"
          className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-[#000] bg-opacity-50 w-full h-full flex justify-center items-center"
        >
          <svg
            aria-hidden="true"
            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        title={getTitle()}
        classDrawer="lg:max-w-5xl sm:max-w-xl max-w-[250px] "
        drawerHeight="h-[calc(100vh - 124px)]"
        ignoreClose={ignoreClose}
        setIgnoreClose={setIgnoreClose}
      >
        <TabsCmp>
          {carrier &&
          Object.keys(carrier).length > 0 &&
          [CARRIERS.FREIGHTCOM.SLUG, CARRIERS.BANYAN.SLUG].includes(
            carrier?.slug
          ) ? (
            <TabsCmp.ItemCmp
              isActive={activeTab === 'sub_carrier'}
              key={'sub_carrier'}
              title={'Sub Carrier'}
              className="lg:min-w-[180px] lg:after:min-w-[180px] leading-5 h-auto"
            >
              <div className="main__layout_height_new pt-8">
                <section className="min-h-[inherit] flex flex-col justify-between">
                  <div className="contain">
                    <div className="overflow-x-auto">
                      {action.sub_carrier && action.mode === 'update' && (
                        <form className="h-full" onSubmit={handleSubmitCarrier}>
                          <div className="flex flex-col justify-between h-full">
                            <div className="flex flex-wrap mt-5">
                              <div className="w-full">
                                <div className="flex flex-wrap lg-mx-3 mt-5">
                                  <div className="w-1/5 px-3">
                                    <p className="text-black text-md mb-2">
                                      Carrier image
                                    </p>
                                    {displayImage ? (
                                      <label
                                        htmlFor="fileSelect2"
                                        className=" cursor-pointer px-1"
                                      >
                                        <img
                                          className="w-full h-36  object-contain rounded-md px-1"
                                          src={displayImage}
                                          alt={formDataCarrier.name}
                                          title={formDataCarrier.name}
                                        />
                                      </label>
                                    ) : subCarrier.image ? (
                                      <label
                                        htmlFor="fileSelect2"
                                        className=" cursor-pointer"
                                      >
                                        <img
                                          className="w-full h-36 object-contain rounded-md"
                                          src={
                                            subCarrier.imageUrl +
                                            subCarrier.image
                                          }
                                          alt={subCarrier.name}
                                          title={subCarrier.name}
                                          onError={onError}
                                        />
                                      </label>
                                    ) : formDataCarrier.name ? (
                                      <label
                                        htmlFor="fileSelect2"
                                        className="w-full h-36 cursor-pointer  text-5xl font-medium bg-primary rounded-xl text-white flex items-center justify-center uppercase"
                                      >
                                        {getShortName(formDataCarrier.name)}
                                      </label>
                                    ) : (
                                      <label
                                        htmlFor="fileSelect2"
                                        className="cursor-pointer"
                                      >
                                        <img
                                          className="w-full h-36 object-contain rounded-md"
                                          src={defaultImage}
                                          alt={formDataCarrier.name}
                                          title={formDataCarrier.name}
                                        />
                                      </label>
                                    )}
                                    <input
                                      type="file"
                                      name="image"
                                      id="fileSelect2"
                                      accept="image/*"
                                      onChange={handleImage(null)}
                                      className="hidden"
                                    />
                                    {/* <input type="file" name="image" id="fileSelect2" accept="image/*" onChange={handleImage(null)} className="hidden" /> */}
                                  </div>
                                  <div className="w-4/5 px-3">
                                    <div className="mb-4">
                                      <InputText
                                        inputName="name"
                                        label="Name"
                                        placeholder="Enter name"
                                        value={formDataCarrier.name}
                                        onChangeFunc={handleInputChangeCarrier}
                                        errorText={
                                          !validFormCarrier.name
                                            ? 'Name is required'
                                            : null
                                        }
                                        disabled={true}
                                        className={'cursor-not-allowed'}
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <InputText
                                        inputName="phone"
                                        label="Phone"
                                        placeholder="Enter phone"
                                        value={setFormatPhone(
                                          formDataCarrier.phone
                                        )}
                                        onChangeFunc={handleInputChangeCarrier}
                                        errorText={
                                          !validFormCarrier.phone
                                            ? 'Phone is required'
                                            : null
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <InputText
                                    inputType="email"
                                    inputName="email"
                                    label="Email"
                                    placeholder="Enter email"
                                    value={formDataCarrier.email}
                                    onChangeFunc={handleInputChangeCarrier}
                                    errorText={
                                      !validFormCarrier.email
                                        ? 'Email is required'
                                        : !validFormCarrier.isEmailValid
                                        ? 'Must be a valid email address'
                                        : null
                                    }
                                  />
                                </div>
                                <div className="mb-4">
                                  <InputText
                                    inputName="hoursOfOperation"
                                    label="Hours of operation"
                                    placeholder="Enter hours of operation"
                                    value={formDataCarrier.hoursOfOperation}
                                    onChangeFunc={handleInputChangeCarrier}
                                    errorText={
                                      !validFormCarrier.hoursOfOperation
                                        ? 'Hours of operation is required'
                                        : null
                                    }
                                  />
                                </div>
                              </div>
                              <div className="w-1/2 px-3">
                                <button
                                  type="submit"
                                  className={`py-2.5 rounded-full text-md text-white w-full ${
                                    isValidCarrier
                                      ? ' btn_primary '
                                      : ' bg-secondary bg-opacity-50 '
                                  }`}
                                  disabled={!isValidCarrier || isLoading}
                                >
                                  Save changes
                                </button>
                              </div>
                              <div className="w-1/2 px-3">
                                <button
                                  type="reset"
                                  className="w-full outline-1 outline-primary outline bg-white py-2.5 transition-all ease delay-100 rounded-full text-primary hover:bg-primary hover:text-white"
                                  disabled={isLoading}
                                  onClick={handleFormClose}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                      <div className="overflow-y-auto">
                        <table className="w-full text-sm text-left text-gray-500 mt-5">
                          <thead className="text-sm font-light text-grayText">
                            <tr className="border-b border-gray-200">
                              {headSubCarrierCells.map((head, index) => (
                                <th
                                  key={index}
                                  className="text-sm font-medium p-3"
                                >
                                  {head.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {subCarriers.length ? (
                              subCarriers.map((subCarrierVal: any, index) => (
                                <tr
                                  key={index}
                                  className="hover:row_active transition-all delay-100 odd:bg-neutral-100"
                                >
                                  <td className="p-3 text-base text-black font-normal">
                                    {params.limit * (params.page - 1) +
                                      (index + 1)}
                                  </td>
                                  <td className="p-3 text-base text-black font-normal">
                                    {subCarrierVal.image ? (
                                      <img
                                        className="w-20 h-20 rounded-md border object-contain px-1"
                                        src={
                                          subCarrierVal.imageUrl +
                                          subCarrierVal.image
                                        }
                                        alt={subCarrierVal.name}
                                        title={subCarrierVal.name}
                                        onError={onError}
                                      />
                                    ) : (
                                      <img
                                        className="w-20 h-20 rounded-md border object-contain px-1"
                                        src={defaultImage}
                                        alt={carrier.name}
                                        title={carrier.name}
                                      />
                                    )}
                                  </td>
                                  <td className="p-3 text-base text-black font-normal">
                                    {subCarrierVal.name}
                                  </td>
                                  <td className="p-3 text-base text-black font-normal">
                                    {getPhones(subCarrierVal.phone)}
                                  </td>
                                  <td className="p-3 text-base text-black font-normal">
                                    {subCarrierVal.email}
                                  </td>
                                  <td>
                                    {(hasPermission('carrier_update') ||
                                      hasPermission('carrier_delete')) && (
                                      <>
                                        <div className="dropdown dropdown-bottom dropdown-end">
                                          <div
                                            tabIndex={0}
                                            role="button"
                                            className="btn p-0 w-10 h-10 min-h-10 text-grayText rounded-md hover:bg-primary hover:bg-opacity-10 hover:text-primary transition-all ms-3"
                                          >
                                            <HiDotsHorizontal className="cursor-pointer w-6 h-6" />
                                          </div>
                                          <ul
                                            tabIndex={0}
                                            className="dropdown-content z-10 menu p-2 mt-1 shadow bg-base-100 rounded-box w-30"
                                          >
                                            <li>
                                              <ButtonCmp
                                                className="btn-ghost h-[34px] min-h-[34px] pb-0 pt-1"
                                                onClick={handleActionType(
                                                  'sub_carrier',
                                                  'update',
                                                  subCarrierVal
                                                )}
                                                disabled={
                                                  !hasPermission(
                                                    'carrier_update'
                                                  )
                                                }
                                              >
                                                Edit
                                              </ButtonCmp>
                                            </li>
                                            <li>
                                              <ButtonCmp
                                                className="btn-ghost h-[34px] min-h-[34px] pb-0 pt-1 text-red-500"
                                                onClick={handleActionType(
                                                  'sub_carrier',
                                                  'delete',
                                                  subCarrierVal
                                                )}
                                                disabled={
                                                  !hasPermission(
                                                    'carrier_delete'
                                                  )
                                                }
                                              >
                                                Remove
                                              </ButtonCmp>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="transition-all delay-100 odd:bg-neutral-200">
                                <td
                                  className="p-3 text-base text-black text-center  font-normal"
                                  colSpan={headSubCarrierCells.length}
                                >
                                  {isLoading
                                    ? 'Please wait...'
                                    : 'Data not found'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>
                    {subCarriers.length > 0 && (
                      <div className="flex justify-between">
                        <div className="mb-4">
                          <CustomRowPerPage
                            limit={params.limit}
                            handleLimit={handleLimit}
                          />
                        </div>
                        <div className="text-right mb-4">
                          <CustomPagination
                            recordsPerPage={params.limit}
                            totalRecords={totals}
                            currentPage={params.page}
                            handlePagination={handlePagination}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </div>
              {action.sub_carrier && action.mode === 'delete' && (
                <DeleteModal handleClose={handleModalCloseCarrier} />
              )}
            </TabsCmp.ItemCmp>
          ) : (
            <></>
          )}
          <TabsCmp.ItemCmp
            isActive={activeTab === 'contact'}
            key={'contact'}
            title={'Contact'}
            className="lg:min-w-[180px] lg:after:min-w-[180px] leading-5 h-auto"
          >
            <div className="relative overflow-x-auto pt-8">
              {hasPermission('carrier_create') && (
                <div className="text-right">
                  <button
                    type="button"
                    className="btn_primary px-10"
                    onClick={handleActionType('carrier', 'create')}
                  >
                    Add contact
                  </button>
                </div>
              )}
              {(action.mode === 'create' || action.mode === 'update') && (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-wrap mt-5">
                      <div className="w-1/2 px-3">
                        <div className="mb-4">
                          <InputText
                            inputName="contactName"
                            label="Name"
                            placeholder="Enter name"
                            value={formData.contactName}
                            onChangeFunc={handleInputChange}
                            errorText={
                              !validForm.contactName ? 'Name is required' : null
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <InputText
                            inputType="email"
                            inputName="contactEmail"
                            label="Email"
                            placeholder="Enter email"
                            value={setFormatPhone(formData.contactEmail)}
                            onChangeFunc={handleInputChange}
                            errorText={
                              !validForm.contactEmail
                                ? 'Email is required'
                                : !validForm.isEmailValid
                                ? 'Must be a valid email address'
                                : null
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <InputText
                            inputName="contactTitle"
                            label="Title"
                            placeholder="Enter title"
                            value={formData.contactTitle}
                            onChangeFunc={handleInputChange}
                            errorText={
                              !validForm.contactTitle
                                ? 'Title is required'
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="w-1/2 px-3">
                        {phones.map((phone: any, index) => (
                          <div
                            key={index}
                            className="flex flex-wrap items-center"
                          >
                            <div className="w-11/12 px-1">
                              <div className="mb-4">
                                <InputText
                                  inputName="contactPhone"
                                  label={`Phone ${index + 1}`}
                                  placeholder="Enter phone"
                                  value={setFormatPhone(phone)}
                                  onChangeFunc={handlePhoneChange(index)}
                                  errorText={
                                    !validForm.contactPhone
                                      ? 'Phone is required'
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className="w-1/12 px-1 mt-3">
                              {index ? (
                                <button
                                  type="button"
                                  className="flex justify-center items-center p-3 bg-red-700 hover:bg-red-800 text-white text-sm rounded-full font-bold"
                                  onClick={removeMore(index)}
                                >
                                  <FaMinus />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="flex justify-center items-center p-3 bg-primary hover:bg-primary700 text-white text-sm rounded-full font-bold"
                                  onClick={addMore}
                                >
                                  <FaPlus />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="w-1/2 px-3">
                        <button
                          type="submit"
                          className={`py-2.5 rounded-full text-md text-white w-full ${
                            isValid
                              ? ' btn_primary '
                              : ' bg-secondary bg-opacity-50 '
                          }`}
                          disabled={!isValid || isLoading}
                        >
                          Save changes
                        </button>
                      </div>
                      <div className="w-1/2 px-3">
                        <button
                          type="reset"
                          className="w-full outline-1 outline-primary outline bg-white py-2.5 transition-all ease delay-100 rounded-full text-primary hover:bg-primary hover:text-white"
                          disabled={isLoading}
                          onClick={handleFormClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              <div className="overflow-y-auto">
                <table className="w-full text-sm text-left text-gray-500 mt-5">
                  <thead className="text-sm font-light text-grayText">
                    <tr className="border-b border-gray-200">
                      {headCells.map((head, index) => (
                        <th key={index} className="text-sm font-medium p-3">
                          {head.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {carrierContacts.length ? (
                      carrierContacts.map((carrierContactVal: any, index) => (
                        <tr
                          key={index}
                          className="hover:row_active transition-all delay-100 odd:bg-neutral-100"
                        >
                          <td className="p-3 text-base text-black font-normal">
                            {carrierContactVal.contactName}
                          </td>
                          <td className="p-3 text-base text-black font-normal">
                            {getPhones(carrierContactVal.contactPhone)}
                          </td>
                          <td className="p-3 text-base text-black font-normal">
                            {carrierContactVal.contactEmail}
                          </td>
                          <td className="p-3 text-base text-black font-normal">
                            {carrierContactVal.contactTitle}
                          </td>
                          <td>
                            {(hasPermission('carrier_update') ||
                              hasPermission('carrier_delete')) && (
                              <div className="dropdown dropdown-bottom dropdown-end">
                                <div
                                  tabIndex={0}
                                  role="button"
                                  className="btn p-0 w-10 h-10 min-h-10 text-grayText rounded-md hover:bg-primary hover:bg-opacity-10 hover:text-primary transition-all ms-3"
                                >
                                  <HiDotsHorizontal className="cursor-pointer w-6 h-6" />
                                </div>
                                <ul
                                  tabIndex={0}
                                  className="dropdown-content z-10 menu p-2 mt-1 shadow bg-base-100 rounded-box w-30"
                                >
                                  <li>
                                    <ButtonCmp
                                      className="btn-ghost h-[34px] min-h-[34px] pb-0 pt-1"
                                      onClick={handleActionType(
                                        'sub_carrier',
                                        'update',
                                        subCarrier
                                      )}
                                      disabled={
                                        !hasPermission('carrier_update')
                                      }
                                    >
                                      Edit
                                    </ButtonCmp>
                                  </li>
                                  <li>
                                    <ButtonCmp
                                      className="btn-ghost h-[34px] min-h-[34px] pb-0 pt-1 text-red-500"
                                      onClick={handleActionType(
                                        'sub_carrier',
                                        'delete',
                                        subCarrier
                                      )}
                                      disabled={
                                        !hasPermission('carrier_delete')
                                      }
                                    >
                                      Remove
                                    </ButtonCmp>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="transition-all delay-100 odd:bg-neutral-200">
                        <td
                          className="p-3 text-base text-black text-center  font-normal"
                          colSpan={headCells.length}
                        >
                          {isLoading ? 'Please wait...' : 'Data not found'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {action.carrier && action.mode === 'delete' && (
              <DeleteModal handleClose={handleModalClose} />
            )}
          </TabsCmp.ItemCmp>
          <TabsCmp.ItemCmp
            isActive={activeTab === 'limitations'}
            key={'limitations'}
            title={'Limitations'}
            className="lg:min-w-[180px] lg:after:min-w-[180px] leading-5 h-auto"
          >
            <div className="relative overflow-x-auto pt-8">
              <form className="h-full" onSubmit={handleSpotEmailSubmit(false)}>
                <div className="flex flex-col justify-between h-full">
                  {hasPermission('carrier_create') ? (
                    <>
                      <div className="w-full">
                        <p className="text-black text-md mb-2">
                          {carrier.name} Limitations
                        </p>
                        <ReactQuill
                          theme="snow"
                          value={limitation || carrier.limitation}
                          onChange={setLimitation}
                          placeholder={'Enter details here'}
                        />
                      </div>
                      <button
                        type="submit"
                        className={`py-2.5 rounded-full text-md text-white w-full text-center mt-5 btn_primary`}
                      >
                        Save changes
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="text-black text-md mb-3 font-bold">
                        {carrier.name} Limitations
                      </p>
                      <ReactQuill
                        theme="snow"
                        value={carrier.limitation}
                        placeholder={'Enter details here'}
                        readOnly
                        modules={{ toolbar: false }}
                      />
                    </>
                  )}
                </div>
              </form>
            </div>
          </TabsCmp.ItemCmp>
          <TabsCmp.ItemCmp
            isActive={activeTab === 'servicePrice'}
            key={'servicePrice'}
            title={'Additional Services Pricing'}
            className="lg:min-w-[180px] lg:after:min-w-[180px] leading-5 h-auto"
          >
            <CarrierServicePrice
              carrier={carrier}
              currency={currency}
              setIgnoreClose={setIgnoreClose}
            />
          </TabsCmp.ItemCmp>
          <TabsCmp.ItemCmp
            isActive={activeTab === 'spot_quote'}
            key={'spot_quote'}
            title={'Spot Quote Email'}
            className="lg:min-w-[180px] lg:after:min-w-[180px] leading-5 h-auto"
          >
            <div className="relative overflow-x-auto pt-8">
              <form className="h-full" onSubmit={handleSpotEmailSubmit(true)}>
                <div className="flex flex-col justify-between h-full">
                  {hasPermission('carrier_create') ? (
                    <>
                      <div className="w-full">
                        <div className="mb-4">
                          <InputText
                            inputType="email"
                            inputName="spotQuoteEmail"
                            label="Spot Quote Email"
                            placeholder="Enter email"
                            value={spotQuoteEmail}
                            onChangeFunc={handleInputChange}
                            errorText={
                              !isSpotEmailValid
                                ? 'Must be a valid email address'
                                : null
                            }
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className={`bg-primary w-full mt-3 px-8 text-center py-3 rounded-full text-sm font-semibold text-white
                                                    ${
                                                      isLoading
                                                        ? ' bg-opacity-50 '
                                                        : spotQuoteEmail ===
                                                            '' ||
                                                          !isSpotEmailValid
                                                        ? 'bg-opacity-50 cursor-not-allowed '
                                                        : ' hover:bg-primary700 cursor-pointer '
                                                    }`}
                        disabled={!isSpotEmailValid || spotQuoteEmail === ''}
                      >
                        Save changes
                      </button>
                    </>
                  ) : (
                    <div className="mb-4">
                      <InputText
                        inputType="email"
                        inputName="spotQuoteEmail"
                        label="Spot Quote Email"
                        placeholder="Enter email"
                        value={carrier.spotQuoteEmail}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </TabsCmp.ItemCmp>
        </TabsCmp>
      </Drawer>
    </>
  );
};

export default memo(ContactDetails);
