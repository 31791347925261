import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

// TODO : CLEAN UP
// export const listUnassignedOrderGroupByCustomerByUploadedFileId = async (
//   params: any,
//   signal?: any
// ) => {
//   const response = await axiosInterceptor.get(
//     API.ORDERS.GET_UNASSIGNED_ORDERS_BY_FILE,
//     { params, signal }
//   );

//   return response;
// };

export const orderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.LIST, {
    params,
    signal,
  });

  return response;
};

export const getOrders = async (params: any) => {
  const response = await axiosInterceptor.get(`${API.ORDERS.GET_ORDERS}`, {
    params,
  });

  return response;
};

export const createOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.CREATE}`, params);

  return response;
};

export const updateOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.UPDATE}`, params);

  return response;
};

export const getRecentOrders = async () => {
  const response = await axiosInterceptor.get(`${API.ORDERS.GET_RECENT_ORDER}`);

  return response;
};

export const getOrder = async (params: any) => {
  const response = await axiosInterceptor.get(
    `${API.ORDERS.GET_ORDER_DETAILS}`,
    { params }
  );

  return response;
};

// For create appointment

export const createOrUpdateAppointment = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.APPOINTMENT.CREATE_OR_UPDATE}`,
    params
  );

  return response;
};

export const deleteAppointment = async (params: any) => {
  const response = await axiosInterceptor.delete(
    `${API.ORDERS.APPOINTMENT.DELETE}`,
    params
  );

  return response;
};
