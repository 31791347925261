import React from 'react';
import { getFormattedDate, getShortName } from 'src/utils/CommonFunctions';

interface IProps {
  internalChat: any;
  currentUser: any;
  getFormattedDateTime: any;
  messageRefs: any;
}

const InternalChatCard = ({
  internalChat,
  currentUser,
  getFormattedDateTime,
  messageRefs,
}: IProps) => (
  <>
    {internalChat.special ? (
      <li className="flex items-center my-4" key={internalChat.id}>
        <span className="h-[1px] bg-utilityGray200 w-full"></span>
        <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
          {getFormattedDate(
            internalChat.createdAt,
            'MMMM Do, YYYY',
            false,
            true
          )}
        </p>
        <span className="h-[1px] bg-utilityGray200 w-full"></span>
      </li>
    ) : internalChat.sender.id === currentUser.currentUser.id ? (
      <li className="flex justify-end gap-3 " key={internalChat.id}>
        <div className="xl:max-w-[305px] max-w-[230px] ">
          {internalChat?.showSenderAndDate && (
            <div className="flex justify-end gap-2 items-center w-full mb-1.5 mt-4 ">
              <p className="text-grayLight600 font-normal text-xs">
                {getFormattedDateTime(internalChat.createdAt, true)}
              </p>
            </div>
          )}

          <div className="rounded-lg rounded-tr-none xl:p-2.5 p-2 font-normal text-xs bg-primary50 text-grayLight900 mb-0.5">
            {internalChat.content}
          </div>
        </div>
      </li>
    ) : (
      <li
        className="flex gap-3 max-w-[305px]"
        ref={(el) => (messageRefs.current[internalChat.id] = el)}
        data-message-id={internalChat.id}
      >
        {internalChat?.showSenderAndDate && (
          <>
            <div className="flex gap-2">
              {internalChat?.isUnread ? (
                <p className="bg-primary500 w-2 h-2 rounded flex-none mt-7"></p>
              ) : (
                ''
              )}
              <div className="w-7 h-7 flex-none relative mt-4">
                {internalChat.sender.imageUrl ? (
                  <img
                    src={
                      internalChat.sender.imageUrl + internalChat.sender.image
                    }
                    className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary"
                  />
                ) : (
                  <div className="w-7 h-7 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none  mr-2 text-xs">
                    {getShortName(
                      `${internalChat.sender?.firstName} ${internalChat.sender?.lastName}`
                    )}
                  </div>
                )}
                <span
                  className={`border border-white rounded-full ${
                    internalChat?.sender?.user_status === 'online'
                      ? 'bg-successSecondary'
                      : 'bg-danger500'
                  }  w-2 h-2 absolute bottom-0 right-0 user-status-${
                    internalChat?.sender?.id
                  }`}
                  title={internalChat?.sender?.user_status}
                ></span>
              </div>
            </div>
          </>
        )}
        <div>
          {internalChat?.showSenderAndDate && (
            <div className="flex items-center w-full mb-1.5 mt-4">
              <p className="text-grayLight600 font-normal text-xs">
                {internalChat.sender.firstName} {internalChat.sender.lastName}
                ,&nbsp;
              </p>
              <p className="text-grayLight600 font-normal text-xs">
                {getFormattedDateTime(internalChat.createdAt, true)}
              </p>
            </div>
          )}

          <div
            className={`rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 text-grayLight900 font-normal text-xs mb-0.5 bg-utilityGray100 ${
              internalChat?.showSenderAndDate
                ? ''
                : internalChat?.isUnread
                ? 'ml-14'
                : 'ml-10'
            }`}
          >
            {internalChat.content}
          </div>
        </div>
      </li>
    )}
  </>
);

export default InternalChatCard;
