import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationCmp from 'src/components/NotificationCmp';
import { AuthContext } from 'src/context/AuthContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import {
  markAsReadAllNotification,
  readNewNotificationStatus,
} from 'src/services/NotificationService';
import { generateTargetUrl } from 'src/utils/CommonFunctions';

let filterArr = [
  {
    value: 'viewall',
    name: 'View all',
  },
  {
    value: 'claims',
    name: 'Claims',
  },
  {
    value: 'disputes',
    name: 'Disputes',
  },
];

export const initParams = {
  currentTab: 'viewall',
  page: 1,
  limit: 5,
  type: ['claim', 'dispute'],
};

interface IProps {
  handleClose: any;
}

const Notification = ({ handleClose }: IProps) => {
  const navigate = useNavigate();

  const [notFoundTitle, setNotFoundTitlec] = useState('No notification found');
  const [notFoundDesc, setNotFoundDesc] = useState(
    'There are no notification found'
  );
  const notificationTimeoutRef = useRef<any>(null);

  const { currentUser } = useContext(AuthContext);
  const { reloadCounts } = useContext(EntityCountsContext);
  const {
    notificationParam,
    setNotificationParams,
    notifications,
    setNotifications,
    notificationTotals,
    isNotificationLoading,
    fetchNotfications,
  } = useContext(NotificationsContext);

  useEffect(() => {
    fetchNotfications();
  }, [notificationParam]);

  const handleNotificationStatus = (
    isSingle: boolean,
    notificationId: number | null = null
  ) => {
    let param: any = { type: notificationParam.type, userId: currentUser.id };

    if (isSingle) {
      param = { ...param, notificationId: notificationId };
    }

    markAsReadAllNotification(param)
      .then((response: any) => {
        if (response.message && !isSingle) {
          fetchNotfications();
        }
      })
      .catch(console.log)
      .finally(() => {
        reloadCounts();
      });
  };

  const handleNotificationClick = (notification: any) => {
    const targetUrl = generateTargetUrl(notification);

    if (targetUrl) {
      navigate(targetUrl);
    }

    if (
      ![
        'carrier_created',
        'team_created',
        'team_member_created',
        'customer_sync_created',
        'order_created',
        'load_insurance_created',
        'feedback_create',
        'credit_increase_request_created',
      ].includes(notification?.subType)
    ) {
      handleNotificationStatus(true, notification.id);
    }
  };

  const handleOnTabButtonClick = (e: any) => {
    e.stopPropagation();
    let type = ['claim', 'dispute'];

    if (e?.currentTarget?.dataset?.value === 'viewall') {
      type = ['claim', 'dispute'];
      setNotFoundDesc('There are no notification found');
    } else if (e?.currentTarget?.dataset?.value === 'claims') {
      type = ['claim'];
      setNotFoundDesc('There are no notification found for the claims');
    } else if (e?.currentTarget?.dataset?.value === 'disputes') {
      type = ['dispute'];
      setNotFoundDesc('There are no notification found for the disputes');
    }

    setNotificationParams((old: any) => ({
      ...old,
      ...{
        currentTab:
          e?.currentTarget?.dataset?.value ?? e?.target?.dataset?.value,
        type,
      },
    }));
  };

  useEffect(() => {
    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }

    notificationTimeoutRef.current = setTimeout(() => {
      const notificationIds = notifications
        .filter((notification: any) => notification.isNew)
        .map((notification: any) => notification.id);

      if (notificationIds.length > 0) {
        readNewNotificationStatus({ notificationId: notificationIds })
          .then((response) => {
            if (response) {
              const newNotifications = notifications.map((data: any) => ({
                ...data,
                isNew: false,
              }));
              setNotifications(newNotifications);
            }
          })
          .catch(console.error);
      }
    }, 2000);

    return () => {
      clearTimeout(notificationTimeoutRef.current);
    };
  }, [notifications]);

  return (
    <>
      <NotificationCmp
        filterArr={filterArr}
        handleNotificationClick={handleNotificationClick}
        handleNotificationStatus={handleNotificationStatus}
        handleOnTabButtonClick={handleOnTabButtonClick}
        isLoading={isNotificationLoading}
        notifications={notifications}
        notFoundDesc={notFoundDesc}
        notFoundTitle={notFoundTitle}
        numberOfSkeletonRows={5}
        params={notificationParam}
        setParams={setNotificationParams}
        setNotFoundTitlec={setNotFoundTitlec}
        totals={notificationTotals}
        handleClose={handleClose}
      />
    </>
  );
};

export default Notification;
