// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchSm, XClose } from '@untitled-ui/icons-react/build/cjs';
// import mapboxgl from 'mapbox-gl';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { scriptId } from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address.interface';
import { BasicContext } from 'src/context/BasicContext';

import AutoCompleteGoogle from '../AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from '../AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from '../ButtonCmp';
import InputText from '../InputText/InputText';

const initValidForm = {
  from: true,
  to: true,
};

const SearchBox = ({ hasSearch, onSearch }: any) => {
  const { mapService, googleKey } = useContext(BasicContext);
  const filterRef: any = useRef(null);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [radius, setRadius] = useState(1500);

  const [isDisplayFromSuggestion, setIsDisplayFromSuggestion] = useState(false);
  const [isDisplayToSuggestion, setIsDisplayToSuggestion] = useState(false);
  const [isShowMapFilterModal, setIsShowMapFilterModal] = useState(false);
  const [validForm, setValidForm] = useState(initValidForm);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      from: true,
      to: true,
    };

    if (from === '' || from === null) {
      validFormData.from = false;
      valid = false;
    }

    if (to === '' || to === null) {
      validFormData.to = false;
      valid = false;
    }
    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid()) onSearch(from, to, radius);
  };

  useEffect(() => {
    let timeOutId: any;
    let handleOutsideClick: any;
    let handleEscapePress: any;

    if (isShowMapFilterModal) {
      setTimeout(() => {
        handleOutsideClick = (event: any) => {
          if (
            isShowMapFilterModal &&
            filterRef.current &&
            !filterRef.current.contains(event.target)
          ) {
            setIsShowMapFilterModal(false);
          }
        };

        handleEscapePress = (event: any) => {
          if (isShowMapFilterModal && event.key === 'Escape') {
            setIsShowMapFilterModal(false);
          }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscapePress);
      }, 0);
    }

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowMapFilterModal]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    let suggestionBox: any = document.querySelector(`suggestion-box`);

    if (suggestionBox && !suggestionBox.contains(event.targte)) {
      setIsDisplayFromSuggestion(false);
      setIsDisplayToSuggestion(false);
    }
  };

  useEffect(() => {
    if (isDisplayFromSuggestion) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
  }, [isDisplayFromSuggestion, isDisplayToSuggestion]);

  useEffect(() => {
    console.log('from :>> ', from, ' ', fromAddress, ' ', toAddress);
  }, [from]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className=" absolute top-[50px] w-full px-4"
      >
        <div className="max-w-[1000px] mx-auto shadow-lg px-6 py-4 border border-borderPrimary rounded-full bg-white flex items-center gap-x-4 relative">
          <div className="flex-1 relative">
            <label className="text-grayLight900 text-sm font-semibold">
              From
            </label>
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="from"
                  placeholder="Search Origin"
                  // dropdownWrapClass="!top-[unset] bottom-[65%]"
                  className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.from ? 'border border-red-500 border-solid' : ''
                  }`}
                  onChangeFunc={(e: any) => {
                    setFrom(e?.fullAddress ?? '');
                    setFromAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setFrom(e);
                  }}
                  isClearable
                  onAddressInputChange={(e: any) => {
                    setFrom(e ?? '');
                  }}
                  errorText={
                    !validForm.from ? 'from address is required' : null
                  }
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={from}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="from"
                  placeholder="Enter Origin"
                  className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.from ? 'border border-red-500 border-solid' : ''
                  }`}
                  onChangeFunc={(e: any) => {
                    setFrom(e?.fullAddress ?? '');
                    setFromAddress(e);
                  }}
                  onBlur={(e: any) => setFrom(e.target?.value)}
                  isClearable
                  onAddressInputChange={(e: any) => {
                    setFrom(e ?? '');
                    setFromAddress(e);
                  }}
                  errorText={
                    !validForm.from ? 'from address is required' : null
                  }
                  value={from}
                />
              ))}
          </div>
          <div className="border-l border-utilityGray200 flex-1 pl-4 relative">
            <label className="text-grayLight900 text-sm font-semibold">
              To
            </label>
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="to"
                  placeholder="Search Destination"
                  // dropdownWrapClass="!top-[unset] bottom-[65%]"
                  className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0`}
                  onChangeFunc={(e: any) => {
                    setTo(e?.fullAddress ?? '');
                    setToAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setTo(e);
                  }}
                  isClearable
                  onAddressInputChange={(e: any) => {
                    setTo(e ?? '');
                  }}
                  errorText={!validForm.to ? 'to address is required' : null}
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={to}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="to"
                  placeholder="Enter Destination"
                  className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.from ? 'border border-red-500 border-solid' : ''
                  }`}
                  onChangeFunc={(e: any) => {
                    setTo(e?.fullAddress ?? '');
                    setFromAddress(e);
                  }}
                  onBlur={(e: any) => setTo(e.target?.value)}
                  isClearable
                  onAddressInputChange={(e: any) => {
                    setTo(e ?? '');
                    setToAddress(e);
                  }}
                  errorText={!validForm.to ? 'to address is required' : null}
                  value={to}
                />
              ))}
          </div>

          <ButtonCmp
            className="btn_primary rounded-full lg:!px-[9px] !px-2"
            type="submit"
            onClick={handleSubmit}
          >
            {hasSearch ? (
              <XClose className="w-4 h-4" />
            ) : (
              <SearchSm className="w-4 h-4" />
            )}
          </ButtonCmp>
          <div className="flex items-center hidden">
            <InputText
              inputName="radius"
              inputType="number"
              value={radius}
              onChangeFunc={(e) =>
                setRadius(Math.max(1, parseInt(e.target.value)))
              }
              placeholder="Km"
              min="1"
            />
            {/* 
       
          <label htmlFor="radius" style={{ marginLeft: '5px', fontSize: '14px' }}>
            km
          </label> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default SearchBox;
