import React from 'react';

const CommissionReportLoadingRow = () => (
  <>
    <td className="px-5 py-4 min-w-[50px] w-[8%]">
      <h6 className=" font-semibold rounded p-1.5 border border-utilityGray200 w-fit text-cyan text-center min-w-[42px] custom-loading">
        <p className="">Sept</p>
        <p className="font-normal text-[10px] leading-3">2024</p>
      </h6>
    </td>

    <td className="px-5 py-4 min-w-[120px] w-[11.5%]">
      <span className="custom-loading">
        Jul 1<sup>st</sup> - 2024
      </span>
    </td>
    <td className="px-5 py-4 min-w-[120px] w-[11.5%]">
      <span className="custom-loading">
        Jul 31<sup>st</sup> - 2024
      </span>
    </td>
    <td className="px-5 py-4 min-w-[120px] w-[11.5%]">
      <span className="custom-loading">
        Jul 31<sup>st</sup> - 2024
      </span>
    </td>
    <td className="px-5 py-4 min-w-[135px] w-[11.5%]">
      <span className="custom-loading">100</span>
    </td>
    <td className="px-5 py-4 min-w-[95px] w-[11.5%]">
      <span className="custom-loading">$270.96</span>
    </td>
    <td className="px-5 py-4 min-w-[106px] w-[11.5%]">
      <span className="custom-loading">$270.96</span>
    </td>
    <td className="px-5 py-4 min-w-[115px] w-[11.5%]">
      <span className="custom-loading">1778</span>
    </td>
    <td className="px-5 py-4 min-w-[130px] w-[11.5%]">
      <span className="custom-loading">17.89%</span>
    </td>
  </>
);

export default CommissionReportLoadingRow;
