import React, { useEffect, useState } from 'react';
import CreditIndexChart from 'src/app/Customers/CustomerDetailCreditTab/CreditIndexChart';

const CustomerCreditInfo = ({
  creditLimitDetail,
  isCreditLimitDetailLoading,
}: any) => {
  console.log('creditLimitDetail', creditLimitDetail);
  const [customerCreditDetail, setCustomerCreditDetail] = useState<any>();
  useEffect(() => {
    let creditLimit = {
      creditValue: creditLimitDetail?.common_value,
      creditDescription: creditLimitDetail?.common_description,
    };

    setCustomerCreditDetail(creditLimit);
  }, [creditLimitDetail]);

  return (
    <div className=" w-full">
      <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
        Current Credit Rating
      </h6>
      <ul className="flex xls:flex-col flex-row gap-y-1 flex-wrap pb-4 border-b border-utilityGray200">
        <li className="text-xs w-full flex justify-between border border-utilityGray200">
          <CreditIndexChart customerCreditDetail={customerCreditDetail} />
        </li>
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1">
            Common Description
          </p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.common_description ?? '-'}
            </span>
          </div>
        </li>
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1">Common Value</p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.common_value ?? '-'}
            </span>
          </div>
        </li>
      </ul>
      <h6 className="text-grayLight900 text-sm font-medium my-1.5">
        Credit Limit
      </h6>
      <ul className="flex xls:flex-col flex-row gap-y-1 flex-wrap pb-2 border-b border-utilityGray200">
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1">Currency</p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.currency ?? '-'}
            </span>
          </div>
        </li>
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1"> Value</p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.credit_value ?? '-'}
            </span>
          </div>
        </li>
      </ul>
      <h6 className="text-grayLight900 text-sm font-medium my-1.5">
        Provider Value
      </h6>
      <ul className="flex xls:flex-col flex-row gap-y-1 flex-wrap pb-2 border-b border-utilityGray200">
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1">Value</p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.provider_value ?? '-'}
            </span>
          </div>
        </li>
        <li className="text-xs w-full flex justify-between">
          <p className="text-grayLight600 font-normal mb-1">
            {' '}
            Provider Description
          </p>
          <div
            className={`flex items-center ${
              isCreditLimitDetailLoading ? 'custom-loading' : ''
            }`}
          >
            <span className="text-grayLight900 font-medium">
              {creditLimitDetail?.provider_description ?? '-'}
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default CustomerCreditInfo;
