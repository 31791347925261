import { Dotpoints01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';

const NextStepsModal = ({ handleClose }: any) => {
  console.log('object :>> ');

  return (
    <CommonModal
      title={'Next Steps'}
      titleDesc={
        'Ensure following tasks are revised to complete customer onboarding process.'
      }
      handleClose={() => handleClose()}
      headerIcon={<Dotpoints01 />}
      size={'max-w-[688px]'}
      isOverflow={true}
    >
      <div className="px-5 max-h-[396px]">
        <ul className="py-5 flex flex-col gap-y-3">
          <li className="rounded-xl border border-utilityGray200 bg-white p-4 flex gap-x-3">
            <div className="rounded-md border border-borderPrimary w-8 h-8 flex items-center justify-center text-gray500 text-xs font-semibold flex-none">
              1
            </div>
            <div>
              <h6 className="text-grayLight900 text-sm font-medium">
                Lorem Ipsum
              </h6>
              <p className="text-grayLight600 text-xs font-normal whitespace-normal">
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </li>
          <li className="rounded-xl border border-utilityGray200 bg-white p-4 flex gap-x-3">
            <div className="rounded-md border border-borderPrimary w-8 h-8 flex items-center justify-center text-gray500 text-xs font-semibold flex-none">
              2
            </div>
            <div>
              <h6 className="text-grayLight900 text-sm font-medium">
                Lorem Ipsum
              </h6>
              <p className="text-grayLight600 text-xs font-normal whitespace-normal">
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys standard dummy text
              </p>
            </div>
          </li>
          <li className="rounded-xl border border-utilityGray200 bg-white p-4 flex gap-x-3">
            <div className="rounded-md border border-borderPrimary w-8 h-8 flex items-center justify-center text-gray500 text-xs font-semibold flex-none">
              3
            </div>
            <div>
              <h6 className="text-grayLight900 text-sm font-medium">
                Lorem Ipsum
              </h6>
              <p className="text-grayLight600 text-xs font-normal whitespace-normal">
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys standard dummy text
              </p>
            </div>
          </li>
          <li className="rounded-xl border border-utilityGray200 bg-white p-4 flex gap-x-3">
            <div className="rounded-md border border-borderPrimary w-8 h-8 flex items-center justify-center text-gray500 text-xs font-semibold flex-none">
              4
            </div>
            <div>
              <h6 className="text-grayLight900 text-sm font-medium">
                Lorem Ipsum
              </h6>
              <p className="text-grayLight600 text-xs font-normal whitespace-normal">
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys standard dummy text
              </p>
            </div>
          </li>
          <li className="rounded-xl border border-utilityGray200 bg-white p-4 flex gap-x-3">
            <div className="rounded-md border border-borderPrimary w-8 h-8 flex items-center justify-center text-gray500 text-xs font-semibold flex-none">
              5
            </div>
            <div>
              <h6 className="text-grayLight900 text-sm font-medium">
                Lorem Ipsum
              </h6>
              <p className="text-grayLight600 text-xs font-normal whitespace-normal">
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys standard dummy text
              </p>
            </div>
          </li>
        </ul>
      </div>
    </CommonModal>
  );
};
export default NextStepsModal;
