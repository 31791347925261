/* eslint-disable max-lines-per-function */
import {
  Calculator,
  CurrencyDollar,
  Percent01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
// import PageSectionLayout from 'src/components/PageSectionLayout';
// import Header from 'src/components/PageSectionLayout/Header/Header';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';

import { predictivePriceOptions } from './PredictivePrice.interface';

const PredictivePriceChart = ({ id }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    getPredictivePrice(id)
      .then((response: any) => {
        if (response && response.data) {
          setPredictivePriceData(response?.data ?? []);
          setSeries(response?.data?.graphData?.series ?? []);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div id="chart" className="flex p-5">
        <Chart
          options={predictivePriceOptions}
          series={series}
          type="line"
          height={'100%'}
          className={` rounded-xl border border-utilityGray200 p-5 w-full flex-1 mr-5 ${
            isLoading ? 'custom-loading graph-loading' : ''
          }`}
        />

        <div className="w-[496px] rounded-xl border border-utilityGray200 px-5 py-4">
          <div className="flex -mx-1.5">
            <div className="px-1.5 w-1/2">
              <TooltipCmp
                message={`${predictivePriceData?.from}`}
                parentClassName="md:!p-3 !p-1.5 max-w-90"
              >
                <div
                  className={`rounded-lg border border-utilityGray200 bg-gray50 p-2 text-grayLight900 text-sm font-medium truncate ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600">From: </span>
                  {predictivePriceData?.from}
                </div>
              </TooltipCmp>
            </div>
            <div className="px-1.5 w-1/2">
              <TooltipCmp
                message={`${predictivePriceData?.to}`}
                parentClassName="md:!p-4 !p-1.5 max-w-90"
              >
                <div
                  className={`rounded-lg border border-utilityGray200 bg-gray50 p-2 text-grayLight900 text-sm font-medium truncate ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600">To: </span>
                  {predictivePriceData?.to}
                </div>
              </TooltipCmp>
            </div>
          </div>
          <h6 className="text-grayLight600 text-sm font-normal mt-4 mb-2">
            Target buy rate
          </h6>
          <div className="flex gap-x-5 items-center">
            <h6
              className={`text-grayLight900 text-3xl font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              ${predictivePriceData?.total}
            </h6>
            <div className="flex-1">
              <p className="text-grayLight600 text-sm font-normal mb-1">
                Standard Deviation
              </p>
              <div className="progress-range-container">
                <div
                  className="progress-range-bar"
                  style={{
                    width: `${predictivePriceData?.confidenceLevel}%`,
                  }}
                >
                  <div className="progress-range-thumb progress-range-thumb-invisible left-[100%]">
                    <div className="progress-tooltip absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                      {predictivePriceData?.confidenceLevel}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-4 mt-4">
            <div>
              <h6 className="text-textSecondary text-sm font-semibold mb-1.5">
                Markup
              </h6>
              <div className="flex gap-1">
                <InputText
                  inputType="text"
                  inputName="markup"
                  value={'17'}
                  className="pl-[40px]"
                  parentClassName="w-[100px]"
                  icon={
                    <div className="border-r border-borderPrimary absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <Percent01 className="w-4 h-4 text-grayLight600" />
                    </div>
                  }
                />
                <InputText
                  inputType="text"
                  inputName="markup"
                  value={'203'}
                  className="pl-[40px]"
                  parentClassName="w-[136px]"
                  icon={
                    <div className="border-r border-borderPrimary absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4 text-grayLight600" />
                    </div>
                  }
                />
              </div>
            </div>
            <div>
              <h6 className="text-textSecondary text-sm font-semibold mb-1.5">
                Target Sell Rate
              </h6>

              <InputText
                inputType="text"
                inputName="markup"
                value={'1399'}
                className="pl-[40px] pr-8"
                icon={
                  <>
                    <div className="border-r border-borderPrimary absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4 text-grayLight600" />
                    </div>
                    <Calculator className="w-4 h-4 absolute mr-2 ml-3 text-gray400 right-0" />
                  </>
                }
              />
            </div>
          </div>
          <div className="mt-4 rounded-[10px] border border-utilityBrand200 bg-primary50 p-3 text-xs text-grayLight600 font-normal">
            <span className="font-semibold">17%</span> is the markup based on
            the following rule
          </div>
          <ButtonCmp
            className="btn_primary mt-4 w-full"
            icon={<Plus className="w-4 h-4" />}
          >
            Add Quick Quote
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default PredictivePriceChart;
