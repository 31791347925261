import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

interface IProps {
  formData: any;
  consigneeOptions: any;
  validForm: any;
  handleSelectChange: any;
  selectFormData: any;
  handleActionType: any;
  saveShipperDetails: any;
  isConsigneeBtnDisable: any;
  handleAutocompleteChange: any;
  handleInputChange: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  isShipperLoading: any;
  status: any;
}

const ConsigneeAddressSection = ({
  formData,
  consigneeOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isConsigneeBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  consigneeAddress,
  setConsigneeAddress,
  isShipperLoading,
  status,
}: IProps) => {
  const { mapService } = useContext(BasicContext);

  return (
    <div className="w-full">
      <div className="mb-5 relative">
        <SelectBox
          isClearable
          isSearchable
          name="consigneeId"
          className={`form_control ${
            !validForm.consigneeId
              ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
              : ''
          }`}
          labelClassName="form_label mb-1.5 block"
          classComp=""
          label="Consignee Details"
          isLoading={isShipperLoading}
          options={consigneeOptions}
          value={consigneeOptions.find(
            (val: any) => val.value === selectFormData.consigneeId
          )}
          onChangeFunc={handleSelectChange('consigneeId')}
          menuPlaceOption={'top'}
        />
        <div className="absolute right-0 top-0 flex">
          {selectFormData.consigneeId && (
            <button
              onClick={handleActionType('consignee', 'delete')}
              type="button"
              className="text-xs flex mr-2"
            >
              <TooltipCmp message="Delete Consignee">
                <Trash01 className="h-4 w-4" />
              </TooltipCmp>
            </button>
          )}
          <button
            onClick={saveShipperDetails('consignee')}
            type="button"
            className={`text-primary700 text-xs flex font-semibold gap-2 ${
              isConsigneeBtnDisable && 'text-opacity-50'
            }`}
            disabled={isConsigneeBtnDisable}
          >
            {selectFormData.consigneeId ? (
              <Edit05 className="w-4 h-4" />
            ) : (
              <Plus className="w-4 h-4" />
            )}
            {selectFormData.consigneeId ? 'Edit' : 'Add'} New Consignee
          </button>
        </div>
      </div>
      <div className="mb-5">
        <InputText
          inputName="consigneeCompanyName"
          placeholder="Enter company name"
          className="form_control"
          label="Company name"
          labelClassName="mb-1.5 block"
          value={formData.consigneeCompanyName}
          errorText={
            !validForm.consigneeCompanyName ? 'Company name is required' : null
          }
          onChangeFunc={handleInputChange}
        />
      </div>
      <div className="relative">
        {status &&
          (mapService === 'trimble_map' ? (
            <AutoCompleteTrimble
              inputName="consigneeAddress"
              required
              placeholder="Enter address"
              dropdownWrapClass="!top-[unset] bottom-[65%]"
              className={`form_control truncate ${
                !validForm.consigneeAddress
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label="Address"
              labelClassName="mb-1.5 block"
              onChangeFunc={handleAutocompleteChange('consigneeAddress')}
              onBlur={handleAutocompleteChange('consigneeAddress')}
              isClearable
              onAddressInputChange={setConsigneeAddress}
              value={consigneeAddress}
            />
          ) : (
            <AutoCompleteGoogle
              inputName="consigneeAddress"
              required
              placeholder="Enter address"
              className={`form_control truncate ${
                !validForm.consigneeAddress
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label="Address"
              listClassName="showAddressOnTop"
              labelClassName="mb-1.5 block"
              onChangeFunc={handleAutocompleteChange('consigneeAddress')}
              onBlur={handleAutocompleteChange('consigneeAddress')}
              isClearable
              onAddressInputChange={setConsigneeAddress}
              value={consigneeAddress}
            />
          ))}
        <button
          onClick={handleActionType(
            'consignee',
            formData.consigneeAddress.fullAddress
              ? 'addressUpdate'
              : 'addressCreate'
          )}
          type="button"
          className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute right-0 top-0`}
          disabled={false}
        >
          {formData.consigneeAddress.fullAddress ? 'Change' : 'Select'} Address
        </button>
      </div>
    </div>
  );
};

export default ConsigneeAddressSection;
