import { Clock } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
// import { useRolePermission } from 'src/utils/CommonFunctions';

const DisputesLoaderRow = () => (
  // const { hasRoleV2 } = useRolePermission();

  <>
    <td className="px-5 py-4 min-w-44 w-44 max-w-44 ">
      <div className="flex items-center custom-loading">
        <div className="w-8 h-8 rounded-full uppercase  bg-utilityGray100 text-primary flex-none relative">
          <div className="w-full h-full rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
            GC
          </div>
        </div>
        <div className="pl-3 text-xs font-medium max-w-28">
          <div className="text-grayLight900 font-medium leading-tight truncate">
            Gal Cohen
          </div>
          <div className="text-grayLight600 font-normal leading-tight truncate">
            #DIS-EPL1-24
          </div>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 text-xs max-w-32 w-32 min-w-32 ">
      <BadgeCmp style="modern" type={'success'} mainClassName="custom-loading">
        Resolved
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-[11%] min-w-[11%] w-[11%]">
      <div className="text-grayLight600 font-normal leading-tight truncate custom-loading">
        #WAL-EPL1-10
      </div>
    </td>
    <td className="px-5 py-4 min-w-36 w-36 max-w-36">
      <span className="custom-loading">MMM Do - YYYY</span>
    </td>

    <td
      data-test-id="wal-QuotingDashboard-totalWeight"
      className="px-5 py-4  min-w-[150px] w-[150px] max-w-[150px]"
    >
      <TooltipCmp
        message={'TEST SOLUTIONS J D ( TEST-JD Express ) -888-878-9121'}
        parentClassName="md:!p-3 !p-1.5 max-w-90"
      >
        <p className="truncate custom-loading">
          TEST SOLUTIONS J D ( TEST-JD Express ) -888-878-9121
        </p>
      </TooltipCmp>
    </td>
    <td
      data-test-id="wal-QuotingDashboard-totalUnits"
      className="px-5 py-4  min-w-36 w-36 max-w-36"
    >
      <span className="custom-loading">MMM Do - YYYY</span>
    </td>
    <td className={`px-5 py-4 min-w-[10%] max-w-[10%] w-[10%] `}>
      <span className="custom-loading">$4321.55</span>
    </td>
    <td className={`px-5 py-4 min-w-[10%] max-w-[10%] w-[10%] `}>
      <span className="custom-loading">$4321.55</span>
    </td>

    <td className="px-5 py-4 min-w-[9%] max-w-[9%] w-[9%]">
      <BadgeCmp
        style="pill"
        type="success"
        mainClassName="rounded-md custom-loading"
      >
        <div className="flex items-center gap-1">
          <Clock className={`w-3 h-3 mt-[1px] text-success500`} />
          10:10:10
        </div>
      </BadgeCmp>
    </td>

    {/* {hasRoleV2('admin') && (
        <td className="px-5 py-4 w-16 max-w-16 min-w-16">
          <div className="py-2">
            <div className="flex items-center custom-loading">
              <TooltipCmp
                message={'Resolve Claim'}
                parentClassName="md:!p-3 !p-1.5 max-w-90"
              >
                <CheckSquareBroken
                  className={`w-4 h-4 relative mr-3 text-primary`}
                />
              </TooltipCmp>
            </div>
          </div>
        </td>
      )} */}
  </>
);

export default DisputesLoaderRow;
