import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';

const ActionItemsLoadingRow = () => (
  <>
    <td className="px-5 py-4 min-w-64 w-64 max-w-64">
      <div className="flex gap-x-3 items-center custom-loading">
        <CheckBox classes="" onChangeFunc={() => {}} />
        <p className="truncate  flex items-center text-sm">new task</p>
      </div>
    </td>
    <td className="px-5 py-4 max-w-64  min-w-[18%] w-[18%] relative">
      <div className="flex gap-x-1 custom-loading">
        <BadgeCmp style="modern" type="red">
          In Progress
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 max-w-64 min-w-[20%] w-[20%] relative">
      <div className="flex items-center gap-3 cursor-pointer h-8">
        <div>
          {/* IF NO IMAGE FOUND THEN BELOW CODE WILL BE USED */}
          <div className="w-[18px] h-[18px] rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white custom-loading">
            WA
          </div>
        </div>
        <div className="custom-loading">
          <p className="text-grayLight900 font-medium">Kate Morrison</p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 text-xs min-w-44 max-w-44  relative">
      <span className="custom-loading">18 Jun, 2024</span>
    </td>

    <td className="px-5 py-4 max-w-28 w-28 min-w-28">
      <div className="flex justify-end custom-loading">
        <Trash01 className="w-4 h-4" />
      </div>
    </td>
  </>
);

export default ActionItemsLoadingRow;
