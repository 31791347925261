import React from 'react';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';

const CreditSafeCompanyRow = ({
  data,
  selectedCreditSafeCompanyId,
  setSelectedCreditSafeCompanyId,
}: any) => (
  <>
    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px] flex gap-2">
      <CheckBox
        classes=""
        onChangeFunc={() => setSelectedCreditSafeCompanyId(data?.id)}
        checked={data?.id === selectedCreditSafeCompanyId}
      />
      {data?.id}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.id2}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.country}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.safeNo}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.name}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.address?.simpleValue}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.status}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.officeType}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.type}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.website?.toString()}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.regNo}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <TooltipCmp message={'have more fields, need some sort of popup design'}>
        {data?.headquarters?.name}
      </TooltipCmp>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.phoneNumbers?.toString()}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.activityCode}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.significantItems}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.fileNo}
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      {data?.groupId}
    </td>
  </>
);

export default CreditSafeCompanyRow;
