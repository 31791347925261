import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getQuoteEmail = async (filter: string) => {
  const response = await axiosInterceptor.get(
    `${API.QUOTE_EMAIL.GET}${filter}`
  );

  return response;
};

export const replyEmail = async (payload: string) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.SEND_REPLY,
    payload
  );

  return response;
};

export const setEmailRead = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.SET_EMAIL_READ,
    payload
  );

  return response;
};

export const getQuoteEmailDetails = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.FETCH_EMAIL_DETAILS,
    payload
  );

  return response;
};

export const getIsActiveToken = async () => {
  const response = await axiosInterceptor.get(
    API.QUOTE_EMAIL.GET_OUTLOOK_INTEGRATION
  );

  return response;
};

export const setIsActiveToken = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.INTEGRATE_OUTLOOK,
    data
  );

  return response;
};

export const deleteQuoteEmail = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.DELETE_QUOTE_EMAIL,
    data
  );

  return response;
};

export const removeFromQuote = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE_EMAIL.REMOVE_FROM_QUOTE}`,
    payload
  );

  return response;
};

export const draftQuoteEmail = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE_EMAIL.DRAFT_EMAIL,
    payload
  );

  return response;
};
