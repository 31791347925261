import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { code, integrationCode } from '../../services/AuthService';

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentUser } = useContext(AuthContext);

  const handleCallback = (message: string) => {
    window.opener.postMessage(message, window.location.origin);
  };

  useEffect(() => {
    let data = {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
      verifier: localStorage.getItem('verifier'),
    } as any;

    if (localStorage.getItem('integration_verifier')) {
      if (
        data.code &&
        data.state &&
        localStorage.getItem('integration_verifier')
      ) {
        data = {
          ...data,
          verifier: localStorage.getItem('integration_verifier'),
          attachedId: currentUser?.userId || null,
        };
        integrationCode(data)
          .then(() => handleCallback('loginSuccess'))
          .catch(() => handleCallback('loginFailure'))
          .finally(() => localStorage.removeItem('integration_verifier'));
      } else {
        navigate(ROUTES.HOME);
      }
    } else {
      if (data.code && data.state && data.verifier) {
        code(data)
          .then(() => handleCallback('loginSuccess'))
          .catch(() => handleCallback('loginFailure'))
          .finally(() => localStorage.removeItem('verifier'));
      } else {
        navigate(ROUTES.HOME);
      }
    }
  }, []);

  return <Loader />;
};

export default Callback;
