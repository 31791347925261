import {
  FilterLines,
  Plus,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import _, { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { getPricingRate } from 'src/services/CommonService';
import { orderList } from 'src/services/OrderService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  getDateRange,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../assets/img/Avatar7.png';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';

import CreateOrder from './createOrder';
import FilterModal from './FilterModal';
import OrderLoaderRow from './OrderLoaderRow';
import OrderRow from './OrderRow';

export const ordersTimeFilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];

const initParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'orderCreateTime',
  page: 1,
  limit: 25,
  team: 'all',
  member: 'all',
};

const recordsPerPageArr = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Orders = () => {
  const { hasRoleV2 } = useRolePermission();
  const { currency, setCurrency, appearance } = useContext(BasicContext);
  const [lastSyncTime, setLastSyncTime] = useState<string>('');
  const [isOrdersListLoading, setIsOrdersListLoading] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);
  const [params, setParams] = useState(initParams);
  const [searchValue, setSearchValue] = useState('');
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const { lastSyncDateTimeActual } = useContext(BasicContext);
  const [rates, setRates] = useState<any>({});
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('');
  const [timeFilter, settimeFilter] = useState('weekly');
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const checkMode =
    window.MODE === 'production' || window.MODE === 'staging' ? false : true;

  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    setParams((old: any) => ({
      ...old,
      ...{ startDate: startDate, endDate: endDate },
    }));
  }, [startDate, endDate]);

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const searchDebounce = useCallback(
    debounce(
      (search: string) =>
        setParams((old: any) => ({ ...old, ...{ search, page: 1 } })),
      700
    ),
    []
  );

  useEffect(() => {
    if (timeFilter !== '') {
      if (
        timeFilter === 'weekly' ||
        timeFilter === 'monthly' ||
        timeFilter === 'yearly'
      ) {
        setSelectedTimeRange(timeFilter);
      } else {
        setSelectedTimeRange('custom');
      }
      const { start, end } = getDateRange(timeFilter);
      setStartDate(start);
      setEndDate(end);
      // setParams((old: any) => ({ ...old, ...{ startDate: start, endDate: end } }));
    }
  }, [timeFilter]);

  useEffect(() => {
    const orderListApiController = new AbortController();
    const orderListApiSignal = orderListApiController.signal;

    setIsOrdersListLoading(true);
    setOrders([]);

    params.startDate =
      selectedTimeRange === 'custom'
        ? moment(params.startDate).local().format('YYYY-MM-DD')
        : moment(params.startDate).format('YYYY-MM-DD');
    params.endDate =
      selectedTimeRange === 'custom'
        ? moment(params.endDate).local().format('YYYY-MM-DD')
        : moment(params.endDate).format('YYYY-MM-DD');

    orderList(params, orderListApiSignal)
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsOrdersListLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersListLoading(false);
      });

    return () => {
      orderListApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    if (lastSyncDateTimeActual) {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const parsedDate = moment
        .utc(lastSyncDateTimeActual)
        .tz(appearance?.timezone ?? browserTimezone);
      const formattedDate = parsedDate.isSame(moment(), 'day')
        ? `Today, ${parsedDate.format('hh:mm A')}`
        : parsedDate.format('MMMM Do, hh:mm A');
      setLastSyncTime(formattedDate);
    }
  }, [lastSyncDateTimeActual]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setUserList(updatedListAllMembers);
    }
  }, [params.team, teamListOptions]);

  const onClearFilter = () => {
    setParams((prev: any) => ({ ...prev, team: 'all', member: 'all' }));
    setFilterCount(0);
  };

  const headCells = useMemo(
    () => [
      {
        id: 'orderCommissionees',
        name: 'User',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Status',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'customerName',
        name: 'Customer',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'orderFromOrgName',
        name: 'Address',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'orderCreateTime',
        name: 'Order Created',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Revenue',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Cost',
        rowClassName: '',
        sortable: true,
      },

      {
        id: 'combinedMarginPercentage',
        name: 'Margin %',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        rowClassName: '',
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (orders && orders.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (entityCounts?.orderCount && entityCounts?.orderCount > 0) {
          const param = { type: 'order_created' };
          handleNotificationRead(param);

          const newOrders = orders.map((order) => ({
            ...order,
            hasUnreadNotification: false,
          }));

          setOrders(newOrders);
        }
      }, 5000);
    }
  }, [orders, entityCounts, isNotificationLoading]);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Orders"
            desc="View and manage orders, synced from RoseRocket."
            rightClassName="sms:!flex-row !flex-col"
            topContent={
              lastSyncTime && (
                <div className="sms:hidden flex flex-row xxl:gap-4 gap-3 justify-end sm:items-center">
                  <div className="flex gap-1.5 items-center mdm:justify-end ">
                    <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                    <span className="text-xs text-textSecondary">
                      Last sync: {lastSyncTime}
                    </span>
                  </div>
                </div>
              )
            }
            rightSideContent={
              <>
                {lastSyncTime && (
                  <div className="flex flex-row xxl:gap-4 gap-3 justify-between sm:items-center">
                    <div className="sms:flex hidden gap-1.5 items-center mdm:justify-end ">
                      <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                      <span className="text-xs text-textSecondary">
                        Last sync: {lastSyncTime}
                      </span>
                    </div>
                  </div>
                )}
              </>
            }
            isShowNotificationBox
          />
        }
      >
        <div className=" min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">
                  {hasRoleV2('admin') || hasRoleV2('manager') ? 'All ' : 'My '}
                  Orders
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isOrdersListLoading ? 'custom-loading' : ''}
                >
                  {total} {total <= 1 ? 'Order' : 'Orders'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage orders, synced from RoseRocket.
              </p>
            </div>
            <TabButton
              tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
              parentClassName="w-full currency-tab order-currency-tab"
              childrenClassName="test"
              activeClassName="text-grayLight900 bg-utilityGray100"
              className=""
              tabArray={filterArr}
              isTooltip={true}
              handleOnClick={(e: any) => {
                setCurrency(e.target.dataset.value);
              }}
              isActive={currency}
            />
            {checkMode && (
              <ButtonCmp
                type="button"
                className="btn-outline-primary"
                onClick={() => {
                  setIsCreateOrder(true);
                }}
                icon={<Plus className="text-primary w-4 h-4" />}
              >
                Create New Order
              </ButtonCmp>
            )}
          </div>
          <div className="table-bottom-header table-tab-wrap">
            <div className="table-header-bottom-left">
              <TabButton
                className="table-tab max-xxl:!px-3"
                activeClassName="!bg-utilityGray100"
                tabArray={ordersTimeFilterArray}
                parentClassName="table-tabs"
                isActive={timeFilter}
                handleOnClick={(e: any) => {
                  settimeFilter(e.target.dataset.value);
                  setParams((old: any) => ({
                    ...old,
                    ...{ page: 1 },
                  }));
                }}
              />
            </div>
            <InputText
              inputName="searchOrder"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={searchValue}
              inputType="text"
              isClearable={true}
              parentClassName="table-searchInput"
              onChangeFunc={(e) => {
                setSearchValue(e.target.value);
                searchDebounce(e.target.value);
              }}
            />
            {(hasRoleV2('admin') || hasRoleV2('manager')) && (
              <>
                <div className="table-selectbox xlm:block hidden">
                  <SelectBox
                    name="teamListGroup"
                    id="teamListGroup"
                    className="form_control"
                    size="sm"
                    placeholder="All Teams"
                    noOptionMessage="No Teams Found"
                    isClearable={true}
                    isSearchable={true}
                    options={teamListOptions}
                    onChangeFunc={(event: any) =>
                      setParams((old: any) => ({
                        ...old,
                        ...{
                          team: event?.value ?? 'all',
                          member: 'all',
                          page: 1,
                        },
                      }))
                    }
                    value={teamListOptions.filter(
                      (val: any) => params.team === val.value
                    )}
                  />
                </div>
                <div className="table-selectbox xlm:block hidden">
                  <SelectBox
                    name="userListGroup"
                    id="userListGroup"
                    size="sm"
                    placeholder="All Users"
                    noOptionMessage="No Users Found"
                    isSearchable={true}
                    className="form_control"
                    isClearable={true}
                    options={userList}
                    onChangeFunc={(event: any) => {
                      setParams((old: any) => ({
                        ...old,
                        ...{ member: event?.value ?? 'all', page: 1 },
                      }));
                    }}
                    value={userList.filter(
                      (user: any) => params.member === user.value
                    )}
                  />
                </div>
                <ButtonCmp
                  type="submit"
                  className={`btn_secondary_black table-filter-btn xlm:hidden ${
                    filterCount > 0
                      ? 'border-borderSecondary hover:border-borderSecondary'
                      : ''
                  }`}
                  onClick={(e) => {
                    if (
                      e?.target?.id !== 'closeBtn' &&
                      e?.target?.parentElement?.id !== 'closeBtn'
                    )
                      setIsDisplayFilterModal(true);
                  }}
                  icon={<FilterLines className=" w-4 h-4" />}
                  iconSide="left"
                >
                  Filters
                  {filterCount > 0 ? (
                    <>
                      <p className="filter-btn-selected">{filterCount}</p>
                      <XClose
                        className="w-4 h-4 text-primary700 mt-[1px]"
                        id="closeBtn"
                        onClick={(e: any) => {
                          e.preventDefault();
                          onClearFilter();
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </ButtonCmp>
              </>
            )}
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArr}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArr.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div className="h-full w-full border-t border-gray100">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={orders}
                TableLoaderRowCmp={OrderLoaderRow}
                TableRowCmp={OrderRow}
                isTableDataLoading={isOrdersListLoading}
                numberOfSkeletonRows={15}
              />
            </div>
            {!orders.length && !isOrdersListLoading && (
              <NotFoundUI
                title="No Orders Found"
                desc="There are no data for orders."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={total}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old: any) => ({ ...old, page }));
              }}
            />
          </div>
        </div>
        {isDisplayFilterModal && (
          <FilterModal
            handleClose={() => setIsDisplayFilterModal(false)}
            params={params}
            setCurrency={setCurrency}
            currency={currency}
            setFilterCount={setFilterCount}
            teamListOptions={teamListOptions}
            rates={rates}
            setParams={setParams}
            userList={userList}
          />
        )}
      </PageSectionLayout>
      {isCreateOrder && <CreateOrder setIsCreateOrder={setIsCreateOrder} />}
    </>
  );
};
export default Orders;
