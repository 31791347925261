import { CreditCard01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { updateOnboarding } from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

const CreditLimitModal = ({ customerId, handleClose }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initialCreditLimit, setInitialCreditLimit] = useState('');

  const handleSubmit = () => {
    if (!initialCreditLimit) {
      setIsError(true);

      return;
    } else setIsError(true);
    setIsLoading(true);
    updateOnboarding({
      id: customerId,
      status: 'Approved',
      initialCreditLimit: initialCreditLimit,
    })
      .then(() => {
        setIsLoading(true);
        handleClose();
      })
      .catch(() => WalToast.error('something went wrong please try again'));

    console.log('handleSubmit');
  };

  return (
    <CommonModal
      title={' Initial Credit Limit'}
      titleDesc={'View and manage your Initial Credit limit.'}
      handleClose={() => handleClose(false)}
      headerIcon={<CreditCard01 />}
      size={'max-w-[688px]'}
      modalClassName=""
      primaryBtnOnClick={() => handleSubmit()}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Save"
      isOverflow={false}
    >
      <div className="p-5">
        <div className="flex gap-x-2 rounded-md border border-utilityGray200 p-3">
          <InputText
            label="Set Initial Credit Limit"
            inputName=""
            placeholder="Set Initial Credit Limit"
            className="placeholder:text-secondaryTxtColor"
            parentClassName="w-full"
            value={initialCreditLimit}
            onChangeFunc={(e: any) => {
              setInitialCreditLimit(e?.target?.value);
            }}
            errorText={
              isError && !initialCreditLimit
                ? 'Initial Credit Limit is required'
                : ''
            }
            isClearable={true}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default CreditLimitModal;
