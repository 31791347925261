import React, { useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import CheckBox from 'src/components/CheckBox';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';

const AdditionalServices = () => {
  const [activeTab, setActiveTab] = useState('pickup');

  const additionalServiceArr: any = [
    {
      value: 'pickup',
      name: 'Pickup',
    },
    {
      value: 'delivery',
      name: 'Delivery',
    },
    {
      value: 'other',
      name: 'Other',
    },
  ];

  return (
    <>
      <h6 className="text-textSecondary text-sm font-medium mb-1.5">
        Are there any additional services for this load?
      </h6>
      <AccordionCmp
        title={
          <div className="flex flex-wrap w-full justify-between items-center">
            <div>
              <h3 className="text-grayLight900 font-semibold text-base">
                Select Additional Services
              </h3>
              <p className="text-xs text-grayLight600">
                Please select any additional services that are needed for this
                shipment.
              </p>
            </div>
            <TabButton
              className=""
              tabArray={additionalServiceArr}
              activeClassName="!bg-utilityGray100"
              isActive={activeTab}
              handleOnClick={(e: any) => {
                setActiveTab(e?.target?.dataset?.value);
              }}
            />
          </div>
        }
        className="shadow-sm !p-0 overflow-hidden !cursor-default"
        contentClassName="!p-0"
        contentParentClass="!mt-0 !pt-0 !border-t-0"
        accordionHeaderWrap="px-4 py-3 cursor-pointer"
      >
        <div className="flex flex-wrap">
          {activeTab === 'pickup' && (
            <div className=" md:py-3.5 md:px-4 py-3 px-2 relative w-1/3 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:top-0 before:left-0 before:absolute">
              <div className="flex cursor-pointer">
                <CheckBox
                  // id={`service_${val.id}`}
                  onChangeFunc={() => {}}
                  // name={val.slug}
                  // value={val.id}
                  checked={true}
                  parentClassName="mt-0.5"
                />
                <div className="flex leading-4">
                  <TooltipCmp
                    message={'description'}
                    parentClassName="max-w-80 md:!p-3 !p-1.5"
                  >
                    <label
                      // htmlFor={`service_${val.id}`}
                      className="md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5"
                    >
                      Tailgate pickup
                    </label>
                  </TooltipCmp>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'delivery' && (
            <div className=" md:py-3.5 md:px-4 py-3 px-2 relative w-1/3 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:top-0 before:left-0 before:absolute">
              <div className="flex cursor-pointer">
                <CheckBox
                  onChangeFunc={() => {}}
                  checked={true}
                  parentClassName="mt-0.5"
                />
                <div className="flex leading-4">
                  <TooltipCmp
                    message={'description'}
                    parentClassName="max-w-80 md:!p-3 !p-1.5"
                  >
                    <label className="md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5">
                      Tailgate delivery
                    </label>
                  </TooltipCmp>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'other' && (
            <div className=" md:py-3.5 md:px-4 py-3 px-2 relative w-1/3 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:top-0 before:left-0 before:absolute">
              <div className="flex cursor-pointer">
                <CheckBox
                  onChangeFunc={() => {}}
                  checked={true}
                  parentClassName="mt-0.5"
                />
                <div className="flex leading-4">
                  <TooltipCmp
                    message={'description'}
                    parentClassName="max-w-80 md:!p-3 !p-1.5"
                  >
                    <label className="md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5">
                      Protect from freeze
                    </label>
                  </TooltipCmp>
                </div>
              </div>
            </div>
          )}
        </div>
      </AccordionCmp>
    </>
  );
};

export default AdditionalServices;
