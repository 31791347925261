import React from 'react';
import { getFormattedNumber, getShortName } from 'src/utils/CommonFunctions';

const MonthlyCommissionReportRow = ({ data }: any) => {
  console.log('commission report row data');

  return (
    <>
      <td className="px-5 py-4 min-w-60 w-60 max-w-60">
        {data?.name?.split(',').length === 1 && data?.name ? (
          <div className="flex items-center">
            {data?.userImage ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data?.userImageUrl + data?.userImage}
                alt={data?.name}
                title={data?.name}
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(`${data?.name}`)}
              </div>
            )}
            <div className="pl-2 max-w-32">
              <h6 className="text-grayLight900 font-semibold leading-5 truncate">{`${data?.name} `}</h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                {data?.jobRole}
              </p>
            </div>
          </div>
        ) : data?.name?.split(',').length > 1 ? (
          <div className="max-w-32">
            <h6 className="text-grayLight900 font-semibold leading-5 truncate">
              {data?.name?.split(',').length} Sales Reps.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              {data?.jobRole}
            </p>
          </div>
        ) : data?.name ? (
          <div className="flex items-center">
            <div className="table-profile-customer-circle-xl">
              {getShortName(data?.name)}
            </div>
            <div className="pl-2 max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1">
                <span className="inline-block max-w-32 truncate">
                  {data?.name}
                </span>
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                {data?.jobRole}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="table-profile-empty-circle-xl"></div>
            <div className="pl-2 max-w-32 text-xs font-medium">
              <p>Unassigned</p>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                {data?.jobRole}
              </p>
            </div>
          </div>
        )}
      </td>

      <td className="px-5 py-4 ">
        <span>${getFormattedNumber(data?.totalMargin)}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>${getFormattedNumber(data?.averageMargin?.toFixed(2))}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data?.loadsDelivered}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data?.averageMarginPercentage?.toFixed(2)}%</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data?.activeClaims}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data?.activeDisputes}</span>
      </td>
      <td className="px-5 py-4 ">
        <div className="text-primary font-medium">{data?.conflicts}</div>
      </td>
    </>
  );
};

export default MonthlyCommissionReportRow;
