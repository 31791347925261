import {
  Calendar,
  XClose,
  SwitchVertical01,
  InfoCircle,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';

import ButtonCmp from '../../../../components/ButtonCmp';
import DateSelect from '../../../../components/DateSelect/DateSelect';
import DeleteModal from '../../../../components/DeleteModal';
import PageSectionLayout from '../../../../components/PageSectionLayout';
import Header from '../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../components/StepBar/StepBar';
import { DATE_FORMAT, STATUS, TIME_FORMAT } from '../../../../constants/common';
import { PATH } from '../../../../constants/path';
import { ROUTES } from '../../../../constants/routes';
import { CustomersAllList } from '../../../../services/CustomerService';
import {
  createQuote,
  getQuote,
  updateQuote,
} from '../../../../services/QuoteService';
import {
  createShipper,
  deleteShippers,
  listShippers,
  updateShipper,
} from '../../../../services/ShipperService';
import {
  getDateWithSuffixFormat,
  getSelectBoxOptions,
} from '../../../../utils/CommonFunctions';
import WalToast from '../../../../utils/WalToast';
import ConsigneeAddress from '../../../QuotingHub/Common/ConsigneeAddress';
import ShipperAddress from '../../../QuotingHub/Common/ShipperAddress';
import QuoteEmailDetailView from '../QuoteEmailDetails/QuoteEmailDetailView';
import { STEPS } from '../SmartQuote.interface';

import Address from './Address';
import {
  classOptions,
  IFormData,
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initSelectFormData,
  initSelectQuoteFormData,
  initShipperAddress,
  initValidForm,
  scriptId,
  // urgencyOptions,
} from './Address.interface';
// eslint-disable-next-line
const AddressDetailsForm = () => {
  const { id } = useParams<{ id: any }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);

  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const [customers, setCustomers] = useState<any>([]);
  const [formData, setFormData] = useState<IFormData>(initFormData);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [selectQuoteFormData, setSelectQuoteFormData] = useState<any>(
    initSelectQuoteFormData
  );
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [action, setAction] = useState(initAction);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isSwapAddress, setIsSwapAddress] = useState(true);
  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
  });
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [validForm, setValidForm] = useState(initValidForm);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });

    if (formData.pickupDate !== '' || formData.pickupDate !== null) {
      if (
        !isCustomerLoading &&
        !isShipperLoading &&
        id &&
        formData.customerId
      ) {
        checkPickupDate(formData.pickupDate);
      } else if (!id) {
        checkPickupDate(formData.pickupDate);
      }
    }

    if (localStorage.getItem('isModifyQuoteFromQuote')) {
      setPageTitle(
        `Modify Quote: # ${localStorage.getItem('modifyQuoteCode')}`
      );
    } else {
      setPageTitle('New Quote');
    }
    getVehicleTypeOptions();
  }, []);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const getFormattedPickupDate = (date: any) => {
    const momentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDate.isSame(today, 'day')) {
      return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(tomorrow, 'day')) {
      return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
    } else {
      return momentDate.format('MMMM Do, YYYY');
    }
  };

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;
    if (newData[name]) setIsSwapAddress(false);

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
        setIsSwapAddress(true);
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setIsConsigneeBtnDisable(false);
        setConsigneeAddress(consignee.fullAddress);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
        setIsSwapAddress(true);
      }
    }
    setFormData((old) => ({ ...old, ...newData }));
    setSelectFormData((old: any) => ({ ...old, [name]: event }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({ ...validForm, consigneeAddress: true });
      }

      if (name === 'shipperId') {
        setValidForm({ ...validForm, shipperAddress: true });
      }
    }
  };

  const handleSelectQuoteClassChange = (name: any) => (event: any) => {
    setSelectQuoteFormData((old: any) => ({ ...old, [name]: event.value }));
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, shipperAddress: true });
      setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsConsigneeBtnDisable(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, consigneeAddress: true });
    }
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const getCustomer = () => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          if (formData.customerId) {
            result.data = result.data.filter(
              (data: any) => !data.isDeleted || data.id === formData.customerId
            );
          } else if (!id) {
            result.data = result.data.filter((data: any) => !data.isDeleted);
          }
          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (value !== null) setIsSwapAddress(false);
    setFormData((old: any) => ({ ...old, [name]: value }));
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
    };

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    if (formData.shipperAddress.fullAddress === undefined) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperAddress.fullAddress === '' ||
      formData.shipperAddress.fullAddress === null
    ) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    if (formData.consigneeAddress.fullAddress === undefined) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeAddress.fullAddress === '' ||
      formData.consigneeAddress.fullAddress === null
    ) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,
      equipmentTypeId: formData.equipmentTypeId?.join(','),
      classification: selectQuoteFormData?.classification,
      isFromQuoteRequest: id ? true : false,
    };

    if (window.MODE !== 'production' && selectQuoteFormData?.emailContentId) {
      data.emailContentId = selectQuoteFormData?.emailContentId;
    }

    if (window.MODE !== 'production' && selectQuoteFormData?.urgency) {
      data.urgency = selectQuoteFormData?.urgency;
    }

    if (id) {
      updateQuote(id, data)
        .then((result: any) => {
          if (result) {
            if (
              searchParams.get('_r') !== null ||
              result?.data?.classification === 'ftl'
            ) {
              navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
            } else {
              navigate(`${PATH.DIMENSIONS}/${result.data.id}`);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    } else {
      createQuote(data)
        .then((result: any) => {
          if (result) {
            if (data?.classification === 'ltl') {
              navigate(`${PATH.DIMENSIONS}/${result.data.id}`);
            } else {
              navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (companyName === '') {
      WalToast.error(`Please enter ${type}'s company name`, '');

      return false;
    }

    if (address.fullAddress === '' || typeof address !== 'object') {
      WalToast.error(`Please enter ${type}'s address`, '');

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, [isCustomerDeleted]);

  useEffect(() => {
    if (formData?.shipperAddress?.fullAddress) {
      setShipperAddress(formData.shipperAddress.fullAddress);
    }

    if (formData?.consigneeAddress?.fullAddress) {
      setConsigneeAddress(formData.consigneeAddress.fullAddress);
    }
  }, [formData]);

  useEffect(() => {
    if (!isCustomerLoading && !isShipperLoading && id) {
      setIsLoading(true);
      getQuote(id)
        .then((response: any) => {
          if (response && response.data) {
            const { addressDetails } = response.data;
            const pickupDateInLocal = moment
              .utc(addressDetails.pickupDate)
              .local()
              .format('YYYY-MM-DD');

            if (
              addressDetails.status !== STATUS.PENDING &&
              addressDetails.status !== STATUS.QUOTE_REQUEST
            ) {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }

            const currentFormData: any = {
              customerId: addressDetails.isCustomerDeleted
                ? ''
                : addressDetails.customerId,
              // equipmentTypeId: addressDetails.equipmentType,
              // pickupDate: addressDetails.pickupDate,
              pickupDate: pickupDateInLocal,
              shipperId: addressDetails.shipperId,
              shipperCompanyName: addressDetails.shipperCompanyName,
              consigneeId: addressDetails.consigneeId,
              consigneeCompanyName: addressDetails.consigneeCompanyName,
              equipmentTypeId: addressDetails?.equipmentType?.split(','),
              shipperAddress: {
                fullAddress: addressDetails.shipperFullAddress,
                address1: addressDetails.shipperAddress1,
                city: addressDetails.shipperCity,
                state: addressDetails.shipperState,
                country: addressDetails.shipperCountry,
                postal: addressDetails.shipperPostal,
                latitude: addressDetails.shipperLatitude,
                longitude: addressDetails.shipperLongitude,
                stateCode: addressDetails.shipperStateCode,
                countryCode: addressDetails.shipperCountryCode,
              },
              consigneeAddress: {
                fullAddress: addressDetails.consigneeFullAddress,
                address1: addressDetails.consigneeAddress1,
                city: addressDetails.consigneeCity,
                state: addressDetails.consigneeState,
                country: addressDetails.consigneeCountry,
                postal: addressDetails.consigneePostal,
                latitude: addressDetails.consigneeLatitude,
                longitude: addressDetails.consigneeLongitude,
                stateCode: addressDetails.consigneeStateCode,
                countryCode: addressDetails.consigneeCountryCode,
              },
            };
            setFormData(currentFormData);
            setShipperAddress(addressDetails.shipperFullAddress);
            setConsigneeAddress(addressDetails.consigneeFullAddress);
            setIsCustomerDeleted(addressDetails.isCustomerDeleted);

            setSelectQuoteFormData((old: any) => ({
              ...old,
              classification: addressDetails?.classification,
              urgency: addressDetails?.urgency,
              emailContentId: addressDetails?.emailContentId,
            }));

            const newData: any = {};

            if (currentFormData.customerId) {
              const customer = customers.find(
                (customerOption: any) =>
                  customerOption.id === currentFormData.customerId
              );
              const customerOption = getSelectBoxOptions(
                customer,
                'id',
                'name'
              );
              newData.customerId = customerOption;
            }

            if (currentFormData.shipperId) {
              newData.shipperId = shipperOptions.find(
                (shipperOption: any) =>
                  shipperOption.value === currentFormData.shipperId
              );
              setIsShipperBtnDisable(false);
            }

            if (currentFormData.consigneeId) {
              newData.consigneeId = consigneeOptions.find(
                (consigneeOption: any) =>
                  consigneeOption.value === currentFormData.consigneeId
              );
              setIsConsigneeBtnDisable(false);
            }

            if (addressDetails.equipmentType) {
              // newData.equipmentTypeId = equipmentTypeOptions.find(
              //   (equipment: any) =>
              //     equipment.value
              // );
              newData.equipmentTypeId =
                addressDetails?.equipmentType?.split(',');
            }

            setSelectFormData((old: any) => ({ ...old, ...newData }));
            checkPickupDate(addressDetails.pickupDate);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch((e: any) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      if (!id) {
        setIsLoading(false);
      }
      setFormData((old) => ({ ...old, pickupDate: moment().toISOString() }));
    }
  }, [id, isCustomerLoading, isShipperLoading]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
      setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
      setIsValidAddress((old) => ({ ...old, consigneeAddress: true }));
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? formData.shipperId
          : formData.consigneeId;

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                }
                setFormData((old) => ({ ...old, ...newData }));
                setSelectFormData((old: any) => ({ ...old, [name]: null }));
                getShipper();
                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const swapAddress = () => {
    setConsigneeAddress(shipperAddress);
    setShipperAddress(consigneeAddress);
    setFormData((old) => ({
      ...old,
      consigneeAddress: formData.shipperAddress,
      shipperAddress: formData.consigneeAddress,
      shipperCompanyName: formData.consigneeCompanyName,
      consigneeCompanyName: formData.shipperCompanyName,
      shipperId: null,
      consigneeId: null,
    }));
    setSelectFormData((old: any) => ({
      ...old,
      shipperId: null,
      consigneeId: null,
    }));
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      if (tomorrowDayName === 'saturday') {
        const tomorrow = moment().add(1, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else {
        const tomorrow = moment().add(3, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      }

      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  useEffect(() => {
    if (searchParams.get('messageId')) {
      setSelectQuoteFormData((old: any) => ({
        ...old,
        emailContentId: searchParams.get('messageId')?.toLowerCase(),
      }));
    }

    if (searchParams.get('class')) {
      setSelectQuoteFormData((old: any) => ({
        ...old,
        classification: searchParams.get('class')?.toLowerCase() || 'ltl',
      }));
    }

    if (searchParams.get('urgency')) {
      setSelectQuoteFormData((old: any) => ({
        ...old,
        urgency: searchParams.get('urgency')?.toLowerCase() || 'live',
      }));
    }
  }, [searchParams.get('class'), searchParams.get('urgency')]);

  return (
    <>
      <PageSectionLayout
        contentClassName="pb-[20px] !gap-0"
        header={
          <Header
            title={pageTitle}
            desc="Create a new quote by filling out the shipment details below."
            rightSideContent={
              <div className="flex gap-5">
                <StepBar steps={STEPS} activeStep={1}></StepBar>
                {localStorage.getItem('isModifyQuoteFromQuote') && (
                  <Link
                    to={`${PATH.MY_QUOTE}/${localStorage.getItem(
                      'isModifyQuoteFromQuote'
                    )}`}
                    className="btn_secondary_black !p-2 flex flex-none justify-center items-center"
                  >
                    <XClose className="w-4 h-4" />
                  </Link>
                )}
              </div>
            }
          />
        }
      >
        <div className="w-full justify-between mdm:items-start sm:items-start items-start flex mdm:flex-row flex-col gap-3 mb-4">
          <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 mdm:w-fit w-full mdm:order-[0] order-2 ">
            {selectQuoteFormData?.classification && (
              <div className="table-statustbox">
                <SelectBox
                  name="classification"
                  required
                  label="Service Type"
                  className={`form_control`}
                  labelClassName="form_label mb-1.5 block"
                  options={classOptions}
                  value={
                    selectQuoteFormData?.classification
                      ? classOptions.filter(
                          (val: any) =>
                            selectQuoteFormData?.classification === val.value
                        )
                      : 'ltl'
                  }
                  onChangeFunc={handleSelectQuoteClassChange('classification')}
                  parentClassName=" single-select-x-scroll"
                />
              </div>
            )}
            <div className="table-selectbox xlm:!w-72">
              <SelectBox
                isClearable
                name="customerId"
                required
                label="Customer"
                className={`form_control`}
                labelClassName="form_label mb-1.5 block"
                isSearchable={true}
                isLoading={isCustomerLoading}
                options={customerOptions}
                value={selectFormData.customerId}
                onChangeFunc={handleSelectChange('customerId')}
                errorText={!validForm.customerId ? true : false}
                parentClassName=" single-select-x-scroll"
              />
            </div>
            {selectQuoteFormData?.classification === 'ftl' && (
              <div className="table-selectbox xlm:!w-72">
                <SelectBox
                  isClearable
                  name="equipmentTypeId"
                  label="Equipment Type"
                  labelClassName="form_label mb-1.5 block"
                  options={equipmentTypeOptions}
                  // value={selectFormData.equipmentTypeId}
                  value={equipmentTypeOptions.filter((item) =>
                    selectFormData.equipmentTypeId?.includes(item.value)
                  )}
                  isLoading={isEquipmentTypeLoading}
                  isMultipleSelection={true}
                  classComp={`${
                    !validForm.customerId
                      ? 'border border-red-500 mb-1 rounded-[10px] border-solid'
                      : ''
                  }`}
                  onChangeFunc={(event: any) => {
                    const newValue = event.map((e: any) => e.value);
                    setFormData((old) => ({
                      ...old,
                      equipmentTypeId: newValue,
                    }));
                    setSelectFormData((old: any) => ({
                      ...old,
                      equipmentTypeId: newValue,
                    }));
                  }}
                  // onChangeFunc={handleSelectChange('equipmentTypeId')}
                  parentClassName=""
                />
              </div>
            )}
            <div className="xl:w-[276px] mdm:w-64 sm:w-fit w-full mdm:min-w-[unset] min-w-[calc(50%_-_6px)]">
              <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                <div className="flex">
                  <label className="form_label mb-0">Pickup Date</label>
                  <span className="text-red-600 ms-[2px] leading-4">*</span>
                </div>
                <p
                  className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                >
                  {pickup1pmError && (
                    <>
                      <span className="text-xs text-primary font-normal flex items-center">
                        {tomorrowDayName === 'Saturday'
                          ? `Set to Monday`
                          : 'Set to Tomorrow'}
                      </span>
                      <a
                        onClick={() => selectNextDay(true)}
                        className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                      >
                        Yes
                      </a>
                      &nbsp;
                      {'/'}
                      <a
                        onClick={() => selectNextDay(false)}
                        className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                      >
                        No
                      </a>
                      <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                        <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                      </TooltipCmp>
                    </>
                  )}
                </p>
              </div>
              <DateSelect
                inputName="pickupDate"
                className={`form_control ${
                  !validForm.pickupDate.valid
                    ? 'border border-red-500 mb-1 border-solid'
                    : ''
                }`}
                placeholder={DATE_FORMAT}
                dateFormat="dd/MM/yyyy"
                minDate={moment().toDate()}
                selected={
                  formData.pickupDate
                    ? moment(formData.pickupDate).toDate()
                    : null
                }
                value={getDateWithSuffixFormat(
                  getFormattedPickupDate(formData.pickupDate)
                )}
                errorText={''}
                onChangeFunc={handleDateChange}
                icon={<Calendar className="h-5 w-5" />}
                parentClassName="w-full"
              />
            </div>
          </div>
          <div className=" md:gap-3 gap-2 flex flex-wrap items-start mdm:w-fit w-full md:mt-[21px] flex-none">
            <ButtonCmp
              onClick={() => navigate(-1)}
              className="btn_secondary_black xxl:min-w-[156px] xlm:min-w-28 min-w-20 text-center mdm:flex-none flex-1"
            >
              <div className="px-4 text-textSecondary text-xs font-semibold">
                Back
              </div>
            </ButtonCmp>

            <ButtonCmp
              type="submit"
              className="btn_primary xxl:min-w-[156px] xlm:min-w-28 min-w-20 mdm:flex-none flex-1"
              onClick={(e: any) => handleSubmit(e)}
              disabled={isLoading}
            >
              Next
            </ButtonCmp>
          </div>
        </div>

        <ShipperAddress
          formData={formData}
          shipperOptions={shipperOptions}
          validForm={validForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isShipperBtnDisable={isShipperBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          setShipperAddress={setShipperAddress}
          handleInputChange={handleInputChange}
          shipperAddress={shipperAddress}
          isValidAddress={isValidAddress}
          isLoading={isLoading}
          isShipperLoading={isShipperLoading}
          status={status}
        />

        <div className="text-center my-4">
          <ButtonCmp
            className="btn_secondary_black !p-2"
            onClick={swapAddress}
            disabled={
              localStorage.getItem('isModifyQuoteFromQuote')
                ? false
                : isSwapAddress
            }
          >
            <SwitchVertical01 className="text-text-grayLight900 w-4 h-4" />
          </ButtonCmp>
        </div>

        <ConsigneeAddress
          formData={formData}
          consigneeOptions={consigneeOptions}
          validForm={validForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isConsigneeBtnDisable={isConsigneeBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          consigneeAddress={consigneeAddress}
          setConsigneeAddress={setConsigneeAddress}
          isLoading={isLoading}
          isShipperLoading={isShipperLoading}
          isValidAddress={isValidAddress}
          status={status}
        />
        {selectQuoteFormData?.emailContentId && (
          <QuoteEmailDetailView
            emailContentId={selectQuoteFormData?.emailContentId}
          />
        )}
      </PageSectionLayout>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? formData.consigneeCompanyName
              : formData.shipperCompanyName
          } From Wal HQ `}
        />
      )}
    </>
  );
};

export default AddressDetailsForm;
