import { throttle, unionBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { AuthContext } from 'src/context/AuthContext';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import {
  deleteQuoteEmail,
  getIsActiveToken,
  getQuoteEmail,
  setEmailRead,
} from 'src/services/QuoteEmailService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import ConnectionModal from './ConnectionModal';
import CreateQuoteFromMailSection from './CreateQuoteFromMailSection';
import MessageDetailSection from './MessageDetailSection';
import MessageListSection from './MessageListSection';
import NotFoundUI from './NotFoundUI';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 15,
  isQuoteEmail: true,
};

const QuotingRequest = () => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [params, setParams] = useState(initParams);
  const [isOpenQuoteRequest, setIsOpenQuoteRequest] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<any[]>([]);
  const [totalEmails, setTotalEmails] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const scrollContainerRef = useRef<any>(null);
  const scrollPositionRef = useRef<number>(0);
  const [search, setSearch] = useState('');
  const [isEmailToggle, setIsEmailToggle] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);

  const [deletingEmails, setDeletingEmails] = useState<{
    [key: string]: boolean;
  }>({});

  const getQuoteEmails = async (isDeleteId = null) => {
    try {
      let filter = ``;

      if (loadMore) {
        if (scrollContainerRef.current) {
          console.log('object');
          setTimeout(() => {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollHeight + 500;
          }, 200);
        }
      }

      if (isDeleteId === null) {
        setLoading(true);
      }

      if (params?.page) {
        filter += `?page=${params?.page}&limit=${params.limit}`;
      }

      if (params?.search) {
        filter += `&search=${params?.search}`;
      }

      if (params?.isQuoteEmail) {
        filter += `&isQuoteEmail=true`;
      }
      const quoteEmails = (await getQuoteEmail(filter)) as any;
      let emailFilterData = quoteEmails?.data?.filter(
        (v: any) => v?.isConvertedToQuote === 0
      );
      setUser(quoteEmails?.user || null);
      setLoading(false);
      setLoadMore(false);
      setIsEmailToggle(false);
      setInitialLoading(false);

      emailFilterData = emailFilterData?.map((email: any) => {
        const parseEmailThreads = email?.thread?.map((threadObj: any) => ({
          ...threadObj,
          emailBody: JSON.parse(threadObj?.emailBody),
        }));

        // Parse main email body
        const parsedEmail = {
          ...email,
          emailBody: JSON.parse(email?.emailBody),
          thread: parseEmailThreads,
        };

        return parsedEmail;
      });
      const deletedIndex = emailData.findIndex(
        (x: any) => x.id === isDeleteId || false
      );

      if (params?.page === 1) {
        setEmailData(emailFilterData);

        if (isEmailToggle) {
          setSelectedEmail(emailFilterData?.[0] || {});
        }
      } else {
        if (isDeleteId) {
          setEmailData((prevItems: any) => {
            const combinedData = unionBy(prevItems, emailFilterData, 'id');
            const filteredData = combinedData.filter(
              (item: any) => item.id !== isDeleteId
            );

            return filteredData;
          });
        } else {
          setEmailData((prevItems: any) =>
            unionBy(prevItems, emailFilterData, 'id')
          );
        }
      }
      setTotalEmails(quoteEmails?.total || 0);
      setIsLoadingMore(false);

      if (!selectedEmail?.id) {
        setSelectedEmail(emailData?.[0] || emailFilterData?.[0]);
      } else if (isDeleteId) {
        setTimeout(() => {
          const selectedEmailData =
            emailData?.[deletedIndex + 1] || emailData?.[deletedIndex - 1];
          console.log(
            '🚀 ~ setTimeout ~ selectedEmailData:',
            selectedEmailData
          );
          setSelectedEmail(selectedEmailData);
        }, 200);
      }

      // Restore scroll position after new data is loaded
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollPositionRef.current;
        }
      }, 0);
    } catch (e: any) {
      console.log(e);
      setInitialLoading(false);
      setIsLoadingMore(false);
      setIsEmailToggle(false);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  const makeEmailRead = async (messageId: string) => {
    try {
      (await setEmailRead({ messageId: messageId })) as any;
    } catch (e: any) {
      console.log(e);
    }
  };

  const fetchTokenStatus = async () => {
    try {
      setLoading(true);
      const response = await getIsActiveToken();
      setIsActive(response.data?.isActive);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching token status:', error);
      setIsActive(false);
      setLoading(false);
    }
  };

  const handleNewEmail = useCallback((newEmail: any) => {
    setEmailData((prevEmails: any) => {
      // Check if an email with the same messageId already exists
      const emailExists = prevEmails.some(
        (email: any) => email.messageId === newEmail?.newEmailContent?.messageId
      );

      if (!emailExists) {
        setTotalEmails((prevTotal: any) => prevTotal + 1);

        return [{ ...newEmail?.newEmailContent }, ...prevEmails];
      }

      return prevEmails;
    });
  }, []);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on?.on) {
      socketIO.removeAllListeners();
      socketIO.on.on('newEmail', function (newEmail: any) {
        const userIdToCompare = currentUser?.attachedUser
          ? currentUser?.attachedUser
          : currentUser?.userId;

        if (userIdToCompare === newEmail?.newEmailContent?.userId) {
          handleNewEmail(newEmail);
        }
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Listen for new emails
          io.socket.on('newEmail', (newEmail: any) => {
            const userIdToCompare = currentUser?.attachedUser
              ? currentUser.attachedUser
              : currentUser?.userId;

            if (userIdToCompare === newEmail?.newEmailContent?.userId) {
              handleNewEmail(newEmail);
            }
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('newEmail');
          };
        })
        .catch((error) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, [currentUser?.id]);

  useEffect(() => {
    fetchTokenStatus();
  }, []);

  useEffect(() => {
    if (isActive) {
      getQuoteEmails();
    }
  }, [params, isActive]);

  const handleSelectedEmail = (emailDetails: any) => {
    setSelectedEmail(emailDetails);
    setIsEmailClicked(true);

    if (!emailDetails?.isRead) {
      makeEmailRead(emailDetails?.messageId);
      const emailDataCopy = emailData.map((email: any) => {
        if (email?.messageId === emailDetails?.messageId) {
          email.isRead = 1;
        }

        return email;
      });
      setEmailData(emailDataCopy);
    }
  };

  const handleScroll = useCallback(
    throttle(() => {
      if (!scrollContainerRef.current || isLoadingMore || loading) return;
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;

      // Save the current scroll position
      scrollPositionRef.current = scrollTop;

      // Check if we've reached the bottom and need to load more
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        totalEmails > emailData?.length &&
        !isLoadingMore
      ) {
        setIsLoadingMore(true);
        setParams((old) => ({
          ...old,
          page: old.page + 1,
        }));
      }
    }, 200),
    [isLoadingMore, loading, emailData?.length, totalEmails]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current as any;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const clearSearch = useCallback(() => {
    setSearch('');
    setParams((old) => ({ ...old, search: '', page: 1 }));
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [search, params?.isQuoteEmail]);

  const handleDeleteQuote = async (item: any) => {
    setDeletingEmails((prev) => ({ ...prev, [item.id]: true }));
    deleteQuoteEmail({ emailId: item.id })
      .then(() => {
        getQuoteEmails(item.id);
      })
      .catch((err) => {
        console.error('Error deleting email:', err);
      })
      .finally(() => {
        setDeletingEmails((prev) => ({ ...prev, [item.id]: false }));
      });
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quote Requests"
          desc="Create, view and manage your customer quotes."
          isShowNotificationBox
        />
      }
      contentClassName="h-[calc(100vh_-_97px)]"
    >
      {isActive ? (
        <>
          <div className="h-full w-full relative bg-white rounded-xl border border-gray-200 flex flex-wrap shadow">
            <MessageListSection
              initialLoading={initialLoading}
              loading={loading}
              params={params}
              isLoadingMore={isLoadingMore}
              search={search}
              setIsEmailToggle={setIsEmailToggle}
              setParams={setParams}
              setSearch={setSearch}
              isEmailToggle={isEmailToggle}
              totalEmails={totalEmails}
              userEmail={user?.email}
              deletingEmails={deletingEmails}
              emailData={emailData}
              handleDeleteQuote={handleDeleteQuote}
              handleSelectedEmail={handleSelectedEmail}
              loadMore={loadMore}
              scrollContainerRef={scrollContainerRef}
              selectedEmail={selectedEmail}
            />

            <MessageDetailSection
              isEmailClicked={isEmailClicked}
              initialLoading={initialLoading}
              isLoadingMore={isLoadingMore}
              loadMore={loadMore}
              loading={loading}
              emailData={emailData}
              clearSearch={clearSearch}
              params={params}
              setParams={setParams}
              selectedEmail={selectedEmail}
              setIsEmailClicked={setIsEmailClicked}
            />

            {selectedEmail && (
              <CreateQuoteFromMailSection selectedEmail={selectedEmail} />
            )}
          </div>
          {isOpenQuoteRequest && (
            <ConnectionModal handleClose={() => setIsOpenQuoteRequest(false)} />
          )}
        </>
      ) : (
        <NotFoundUI />
      )}
    </PageSectionLayout>
  );
};

export default QuotingRequest;
