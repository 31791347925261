import { Minus, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
} from 'src/utils/CommonFunctions';

import {
  ICustomerData,
  ICustomerTargetRowProps,
  ITotalCustomerTargetNumbers,
} from './Target.interface';

const TargetSetRow = ({
  data,
  // handleInputChange,
  targetData,
  setTargetData,
  setTotalData,
}: ICustomerTargetRowProps) => {
  const [isUpdatingValue, setIsUpdatingValue] = useState<string>('');
  const [targetProfit, setTargetProfit] = useState<number>(0);
  const [targetLoads, setTargetLoads] = useState<number>(0);
  // const [percentageChange, setPercentageChange] = useState<number>(2.5);
  const percentageChange = 2.5;

  useEffect(() => {
    setTargetProfit(data.targetProfit);
    setTargetLoads(data.targetLoads);
  }, [targetData, data]);

  const newSegmentArray = data?.tags
    ?.split(',')
    ?.map((item: string) => item.trim());

  const updateOtherValue = (
    key: 'targetProfit' | 'targetLoads',
    updatedValue = 0,
    isInputChange = false
  ) => {
    setIsUpdatingValue('');

    if (key === 'targetProfit') {
      if (updatedValue === 0 && isInputChange) {
        updatedValue = targetProfit;
      }
      setTotalData((old: ITotalCustomerTargetNumbers) => ({
        ...old,
        targetProfit: old.targetProfit - data.targetProfit + updatedValue,
      }));
      setTargetData((prevTargets: ICustomerData[]) => {
        const existingTargetIndex = prevTargets.findIndex(
          (target: any) => target.customerId === data.customerId
        );

        if (existingTargetIndex !== -1) {
          const newTargets = [...prevTargets];
          newTargets[existingTargetIndex] = {
            ...newTargets[existingTargetIndex],
            targetProfit: updatedValue,
          };

          return newTargets;
        } else {
          return [
            ...prevTargets,
            {
              ...data,
              targetProfit: updatedValue,
            },
          ];
        }
      });
    } else {
      if (updatedValue === 0 && isInputChange) {
        updatedValue = targetLoads;
      }

      setTotalData((old: ITotalCustomerTargetNumbers) => ({
        ...old,
        targetLoads: old.targetLoads - data.targetLoads + updatedValue,
      }));

      setTargetData((prevTargets: ICustomerData[]) => {
        const existingTargetIndex = prevTargets.findIndex(
          (target: any) => target.id === data.id
        );

        if (existingTargetIndex !== -1) {
          const newTargets = [...prevTargets];
          newTargets[existingTargetIndex] = {
            ...newTargets[existingTargetIndex],
            targetLoads: updatedValue,
          };

          return newTargets;
        } else {
          return [
            ...prevTargets,
            {
              ...data,
              targetLoads: updatedValue,
            },
          ];
        }
      });
    }
  };

  const handleProfitDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetProfit(Math.floor(targetProfit * decreaseFactor));
    updateOtherValue('targetProfit', Math.floor(targetProfit * decreaseFactor));
  };

  const handleProfitIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetProfit(
      Math.ceil(targetProfit === 0 ? 1 : targetProfit * increaseFactor)
    );
    updateOtherValue(
      'targetProfit',
      Math.ceil(targetProfit === 0 ? 1 : targetProfit * increaseFactor)
    );
    // handleInputChange(
    //   data.id,
    //   'targetProfit',
    //   Math.ceil(targetProfit === 0 ? 1 : targetProfit * increaseFactor),
    //   {
    //     targetLoads: getFieldValue(data.id, 'targetLoads', data.targetLoads),
    //   }
    // );
  };

  const handleLoadsDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetLoads(Math.floor(targetLoads * decreaseFactor));
    // handleInputChange(
    //   data.id,
    //   'targetLoads',
    //   Math.floor(targetLoads * decreaseFactor),
    //   {
    //     targetProfit: getFieldValue(data.id, 'targetProfit', data.targetProfit),
    //   }
    // );
    updateOtherValue('targetLoads', Math.floor(targetLoads * decreaseFactor));
  };

  const handleLoadsIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetLoads(
      Math.ceil(targetLoads === 0 ? 1 : targetLoads * increaseFactor)
    );
    // handleInputChange(
    //   data.id,
    //   'targetLoads',
    //   Math.ceil(targetLoads === 0 ? 1 : targetLoads * increaseFactor),
    //   {
    //     targetProfit: getFieldValue(data.id, 'targetProfit', data.targetProfit),
    //   }
    // );
    // console.log('load Increase');
    updateOtherValue(
      'targetLoads',
      Math.ceil(targetLoads === 0 ? 1 : targetLoads * increaseFactor)
    );
  };

  // const handlePlusDoubleClick = () => {
  //   setPercentageChange((prev) => prev + 2.5);
  // };

  // const handleMinusDoubleClick = () => {
  //   setPercentageChange((prev) => Math.max(2.5, prev - 2.5));
  // };

  const tdClass = data.id !== 0 ? 'px-5 py-[14px]' : '';

  return (
    <>
      <td className={`${tdClass} min-w-[13rem] w-[13rem] max-w-[13rem]`}>
        {data.id !== 0 && (
          <div className="flex items-center gap-3">
            <div className="flex-none">
              {data.image ? (
                <img
                  className="table-profile-icn-circle-xl"
                  src={data.imageUrl + data.image}
                  alt=""
                  title=""
                />
              ) : (
                <div className="table-profile-customer-circle-xl">
                  {getShortName(`${data.customerName}`)}
                </div>
              )}
            </div>
            <div className="w-32">
              <h6 className="text-grayLight900 font-semibold leading-5 truncate">
                {data.customerName.length > 20 ? (
                  <>
                    {/* @ts-ignore */}
                    <Tooltip
                      delay={300}
                      arrow={true}
                      html={
                        <div
                          style={{ bottom: `0px` }}
                          className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                        >
                          {data.customerName}
                        </div>
                      }
                    >
                      {data.customerName}
                    </Tooltip>
                  </>
                ) : (
                  data.customerName
                )}
              </h6>
            </div>
          </div>
        )}
      </td>
      <td className={`${tdClass} min-w-[14rem] w-[14rem] max-w-[14rem]`}>
        {data.id !== 0 && (
          <div className="flex flex-wrap gap-1">
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TEST) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-yellow300"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.TEST}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TOP) && (
              <BadgeCmp
                style="modern"
                mainClassName=""
                badgeDotColor="bg-[#b2ac17]"
              >
                {CUSTOMER_SEGMENT_TYPES.TOP}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.DORMANT) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-utilityPink600"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.DORMANT}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.PROSPECT) && (
              <BadgeCmp style="modern" type="lightblue" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.PROSPECT}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.NEW) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-fgSuccessPrimary"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.NEW}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.ACTIVE) && (
              <BadgeCmp style="modern" type="primary" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.ACTIVE}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.INACTIVE) && (
              <BadgeCmp style="modern" type="error" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.INACTIVE}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.AT_RISK) && (
              <BadgeCmp style="modern" type="gray" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.AT_RISK}
              </BadgeCmp>
            )}
          </div>
        )}
      </td>
      {data.id !== 0 && (
        <td className={`${tdClass} min-w-[20%] w-[20%] max-w-[20%]`}>
          {/* @ts-ignore */}
          <Tooltip
            delay={300}
            arrow={true}
            html={
              <div
                style={{ bottom: `0px` }}
                className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
              >
                Current / Target / Completion
              </div>
            }
          >
            <span className="cursor-pointer">
              $
              {getFormattedNumber(
                data.prevMonthCompletedProfit,
                false,
                false,
                true
              )}
            </span>
            <span>&nbsp;/&nbsp;</span>

            <span className="cursor-pointer">
              $
              {getFormattedNumber(
                data.prevMonthTargetProfit,
                false,
                false,
                true
              )}
            </span>
            <span>&nbsp;/&nbsp;</span>

            <span
              className={`text-textSecondary cursor-pointer ${getProgressClass(
                data.prevMonthCompletedProfitPercentage,
                false
              )}`}
            >
              {data.prevMonthCompletedProfitPercentage}%
            </span>
          </Tooltip>
        </td>
      )}
      {data.id !== 0 && (
        <td className={`${tdClass} min-w-[20%] w-[20%] max-w-[20%]`}>
          {/* @ts-ignore */}
          <Tooltip
            delay={300}
            arrow={true}
            html={
              <div
                style={{ bottom: `0px` }}
                className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
              >
                Actual / Target / Completion
              </div>
            }
          >
            <span>{data.prevMonthCompletedLoads}</span>{' '}
            <span>&nbsp;/&nbsp;</span>
            <span>{data.prevMonthTargetLoads}</span>
            <span>&nbsp;/&nbsp;</span>
            <span
              className={`text-textSecondary ${getProgressClass(
                data.prevMonthCompletedLoadsPercentage,
                false
              )}`}
            >
              {data.prevMonthCompletedLoadsPercentage}%
            </span>
          </Tooltip>
        </td>
      )}

      <td className={`${tdClass} min-w-[18%] w-[18%]`}>
        {data.id !== 0 && (
          <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
            <ButtonCmp
              className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
              onClick={handleProfitDecrease}
              // onDoubleClick={handleMinusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`-${percentageChange}%`}
                  </div>
                }
              >
                <Minus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>

            <InputText
              inputName="targetProfit"
              className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
                isUpdatingValue ? '' : ''
              }`}
              value={
                (isUpdatingValue === 'targetProfit'
                  ? targetProfit
                  : `$${getFormattedNumber(targetProfit)}`) || ''
              }
              inputType={isUpdatingValue === 'targetProfit' ? 'number' : 'text'}
              placeholder=""
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChangeFunc={(e: any) => {
                setTargetProfit(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                );
                // handleInputChange(
                //   data.id,
                //   'targetProfit',
                //   isNaN(parseInt(e.target.value))
                //     ? 0
                //     : parseInt(e.target.value),
                //   {
                //     targetLoads: getFieldValue(
                //       data.id,
                //       'targetLoads',
                //       data.targetLoads
                //     ),
                //   }
                // );
              }}
              parentClassName="max-w-full"
              onBlur={() => {
                setIsUpdatingValue('');
                updateOtherValue('targetProfit', undefined, true);
                // handleInputChange(data.id, 'targetProfit', targetProfit, {
                //   targetLoads: getFieldValue(
                //     data.id,
                //     'targetLoads',
                //     data.targetLoads
                //   ),
                // });
              }}
              onFocus={() => setIsUpdatingValue('targetProfit')}
            />

            <ButtonCmp
              className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
              onClick={handleProfitIncrease}
              // onDoubleClick={handlePlusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`+${percentageChange}%`}
                  </div>
                }
              >
                <Plus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
          </div>
        )}
      </td>
      <td className={`${tdClass} min-w-[18%] w-[18%]`}>
        {data.id !== 0 && (
          <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
            <ButtonCmp
              className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
              onClick={handleLoadsDecrease}
              // onDoubleClick={handleMinusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`-${percentageChange}%`}
                  </div>
                }
              >
                <Minus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>

            <InputText
              inputName="targetLoads"
              className="border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center"
              value={
                isUpdatingValue === 'targetLoads'
                  ? targetLoads || ''
                  : targetLoads || 0
              }
              // value={
              //   isUpdatingValue === 'targetLoads' ? targetLoads : targetLoads || 0
              // }
              inputType="number"
              placeholder=""
              parentClassName="max-w-full"
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChangeFunc={(e: any) => {
                setTargetLoads(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                );
                // handleInputChange(
                //   data.id,
                //   'targetLoads',
                //   isNaN(parseInt(e.target.value))
                //     ? 0
                //     : parseInt(e.target.value),
                //   {
                //     targetProfit: getFieldValue(
                //       data.id,
                //       'targetProfit',
                //       data.targetProfit
                //     ),
                //   }
                // );
              }}
              onBlur={() => {
                setIsUpdatingValue('');
                updateOtherValue('targetLoads', undefined, true);

                // handleInputChange(data.id, 'targetLoads', targetLoads, {
                //   targetProfit: getFieldValue(
                //     data.id,
                //     'targetProfit',
                //     data.targetProfit
                //   ),
                // });
              }}
              onFocus={() => setIsUpdatingValue('targetLoads')}
            />

            <ButtonCmp
              className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
              onClick={handleLoadsIncrease}
              // onDoubleClick={handlePlusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`+${percentageChange}%`}
                  </div>
                }
              >
                <Plus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
          </div>
        )}
      </td>
    </>
  );
};

export default TargetSetRow;
