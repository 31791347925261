import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const LaneHistoryLoading = () => (
  <>
    <td className="px-5 py-4 max-w-56 w-56 min-w-56">
      <div className="flex items-center gap-3">
        {/* <img
            src=""
            className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
          /> */}
        <div className="table-profile-customer-circle-xl custom-loading">
          TT
        </div>
        <div className="custom-loading">
          <TooltipCmp message={'name'} parentClassName="">
            <span className="truncate block max-w-36 text-grayLight900 font-semibold ">
              Canadian Logistic Express
            </span>
          </TooltipCmp>
          <TooltipCmp message={'name'} parentClassName="">
            <a className="truncate max-w-36 font-medium text-primary underline cursor-pointer ">
              #WAL-DMIF-1
            </a>
          </TooltipCmp>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 max-w-56 w-56 min-w-56">
      <TooltipCmp message={name} parentClassName="md:!p-3 !p-1.5 max-w-90">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate max-w-48">
            Torrance, CA, USA
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp message={name} parentClassName="md:!p-3 !p-1.5 max-w-90">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate max-w-48">
            Toronto, ON, Canada
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">04</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">20 lbs</span>
    </td>

    <td className="px-5 py-4 min-w-44 w-44 max-w-44">
      <span className="custom-loading">Jan 6, 2022</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$ 752.09</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$ 905.80</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">$ 56</span>
    </td>
    <td className="px-5 py-4 max-w-16 w-16 min-w-16">
      <div className="w-4 h-4 custom-loading">
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </div>
    </td>
  </>
);
export default LaneHistoryLoading;
