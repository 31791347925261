import { Expand03, Settings01 } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Toggle from 'src/components/Toggle/Toggle';
import { BasicContext } from 'src/context/BasicContext';
import { getCustomerById } from 'src/services/CustomerService';
import { getMapService } from 'src/services/IntegrationService';
import { orderList } from 'src/services/OrderService';
import { listQuote } from 'src/services/QuoteService';

import ActiveShipments from './ActiveShipments';
import CustomerDetail from './CustomerDetail';
import LaneActivity from './LaneActivity';
import LaneOverview from './LaneOverview';
import MapBox from './MapBox';
// import TrimbleMap from './TrimbleMap';

const initFilter = {
  isDisplayCustomers: false,
  isDisplayTransit: false,
  isDisplayLaneActivity: false,
  filterType: '',
};

const MapIt = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const { setMapService, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);

  const [customers, setCustomers] = useState<any>();
  const [allRoutes, setAllRoutes] = useState<[number, number][]>([]);
  const [customerId, setCustomerId] = useState<any>();
  const [searchResult, setSearchResult] = useState<any>(null);
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const [mapFilters, setMapFilters] = useState<any>(initFilter);
  const [routeFilters, setRouteFilters] = useState({
    radius: 1000,
    population: 5000,
  });
  const [customer, setCustomer] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [isLatestOrdersLoading, setIsPendingQuoteLoading] = useState(true);
  const [isPendingQuoteLoading, setIsLatestOrdersLoading] = useState(true);
  const [customerQuote, setCustomerQuote] = useState([]);
  const [quoteTotal, setQuoteTotal] = useState(0);

  const handleToggle = (event: any) => {
    setMapFilters(initFilter);
    const { checked, name } = event.target;

    console.log('checked,', checked, '  ', name);
    setIsDisplayDetail(true);
    setMapFilters((old: any) => ({ ...old, [name]: checked }));
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  }, []);

  const getCustomer = () => {
    console.log(
      'isLoading',
      isLoading,
      '  ',
      isLatestOrdersLoading,
      '  ',
      isPendingQuoteLoading
    );
    setIsLoading(true);
    getCustomerById(customerId)
      .then((response: any) => {
        console.log('response', response, customerQuote);
        setIsLoading(false);
        setCustomer(response?.data);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        console.error(e?.response?.message);
      });
  };

  const getCustomerOrders = () => {
    orderList({ customerId: customerId })
      .then((response: any) => {
        setOrders(response.data ?? []);
        setIsLatestOrdersLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLatestOrdersLoading(false);
      });
  };

  const getCustomerPendingQuote = async () => {
    setIsPendingQuoteLoading(true);

    try {
      const response: any = await listQuote({
        filter: { status: 'Opened' },
        customerId: customerId,
      });

      if (response?.data) {
        setCustomerQuote(response.data?.quotes);
        setQuoteTotal(response.total);
        setIsPendingQuoteLoading(false);
      }
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      setIsPendingQuoteLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
    getCustomerOrders();
    getCustomerPendingQuote();
  }, [customerId]);

  // const LoadCustomerDetail = () => {
  //   console.log('LoadCustomerDetail', LoadCustomerDetail);

  //   return (
  //     <CustomerDetail
  //       customerId={customerId}
  //       setCustomerId={setCustomerId}
  //       handleClose={() => setIsDisplayDetail(false)}
  //     />
  //   );
  // };

  return (
    <PageSectionLayout
      header={<></>}
      headerClassName="!p-0"
      contentClassName="!p-0"
    >
      <div className="relative h-full w-full geography-map">
        {/* {mapService === 'trimble_map' ? (
          <TrimbleMap
            setHasSearched={setHasSearched}
            setSearchResult={setSearchResult}
            isDisplayCustomers={mapFilters?.isDisplayCustomers}
            setIsDisplayDetail={setIsDisplayDetail}
            setCustomers={setCustomers}
          />
        ) : ( */}
        <MapBox
          setHasSearched={setHasSearched}
          setSearchResult={setSearchResult}
          isDisplayCustomers={mapFilters?.isDisplayCustomers}
          isDisplayDetail={isDisplayDetail}
          setIsDisplayDetail={setIsDisplayDetail}
          setCustomers={setCustomers}
          setCustomerId={setCustomerId}
          customers={customers}
          mapFilters={mapFilters}
          setAllRoutes={setAllRoutes}
          allRoutes={allRoutes}
          routeFilters={routeFilters}
          setMapFilters={setMapFilters}
          hasSearched={hasSearched}
        />
        {/* )} */}
        {isDisplayDetail ? (
          hasSearched ? (
            <LaneOverview
              routeFilters={routeFilters}
              setRouteFilters={setRouteFilters}
              handleClose={() => setIsDisplayDetail(false)}
              searchResult={searchResult}
            />
          ) : (
            <>
              {isDisplayDetail && mapFilters.isDisplayLaneActivity && (
                <LaneActivity
                  handleClose={() => {
                    setIsDisplayDetail(false);
                  }}
                />
              )}
              {isDisplayDetail && customerId && (
                <CustomerDetail
                  customerId={customerId}
                  isLoading={isLoading}
                  quoteTotal={quoteTotal}
                  isLatestOrdersLoading={isLatestOrdersLoading}
                  isPendingQuoteLoading={isPendingQuoteLoading}
                  customer={customer}
                  customerQuote={customerQuote}
                  orders={orders}
                  setCustomerId={setCustomerId}
                  handleClose={() => setIsDisplayDetail(false)}
                />
              )}
              {isDisplayDetail && mapFilters.isDisplayTransit && (
                <ActiveShipments />
              )}
            </>
          )
        ) : (
          <div className="fixed w-10 p-2 z-[2] cursor-pointer rounded-md border bottom-10 right-10 bg-white">
            <Expand03
              className="w-5 h-5 text-primary cursor-pointer"
              onClick={() => setIsDisplayDetail(true)}
            />
          </div>
        )}

        <div className="rounded-lg border border-utilityGray200  p-3 shadow-lg bg-white absolute left-4 bottom-4 z-[2] min-w-[300px]">
          <div className="flex items-center justify-between gap-2 mb-[18px]">
            <span className="text-xs font-normal text-grayLight900">
              Display Customers
            </span>
            <div className="flex">
              <Toggle
                id="display-customers-toggle"
                name="isDisplayCustomers"
                labelClassName="mr-2"
                isChecked={mapFilters.isDisplayCustomers}
                onChange={handleToggle}
              />
              <Settings01
                className={`w-4 h-4 ${
                  mapFilters.isDisplayCustomers
                    ? 'text-gray600'
                    : 'text-gray500'
                }`}
                onClick={() => {
                  setMapFilters((old: any) => ({
                    ...old,
                    filterType: 'customer_detail',
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-2 mb-[18px]">
            <span className="text-xs font-normal text-grayLight900">
              Display in-transit shipments
            </span>
            <div className="flex">
              <Toggle
                id="display-transit-toggle"
                name="isDisplayTransit"
                labelClassName="mr-2"
                isChecked={mapFilters.isDisplayTransit}
                onChange={handleToggle}
              />
              <Settings01
                className={`w-4 h-4 ${
                  mapFilters.isDisplayTransit ? 'text-gray600' : 'text-gray500'
                }`}
                onClick={() =>
                  setMapFilters((old: any) => ({
                    ...old,
                    filterType: 'in_transit',
                  }))
                }
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            <span className="text-xs font-normal text-grayLight900">
              Display Lane Activity
            </span>
            <div className="flex">
              <Toggle
                id="display-lane-actiovity"
                name="isDisplayLaneActivity"
                labelClassName="mr-2"
                isChecked={mapFilters.isDisplayLaneActivity}
                onChange={handleToggle}
              />
              <Settings01
                className={`w-4 h-4 ${
                  mapFilters.isDisplayLaneActivity
                    ? 'text-gray900'
                    : 'text-gray500'
                }`}
                onClick={() =>
                  setMapFilters((old: any) => ({
                    ...old,
                    filterType: 'lane_activity',
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageSectionLayout>
  );
};

export default MapIt;
