import {
  Calendar,
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import DatePicker from 'react-datepicker';

import ButtonCmp from '../ButtonCmp';
import ErrorMsg from '../errorMsg';

import { IDateSelectProps } from './dateSelect.interface';

const CustomDateInput = React.forwardRef(
  ({ value, onClick, dateSelectClassName }: any, ref: any) => (
    <div
      className={`form_control text-grayLight900 font-medium  [&.disabled]:border-borderPrimary [&.disabled]:bg-gray50 [&.disabled]:text-gray500 [&.disabled>svg]:hidden [&.disabled]:opacity-100 ${dateSelectClassName}`}
      onClick={onClick}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  )
);

CustomDateInput.displayName = 'CustomDateInput';

const renderCustomHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const decreaseYear = () => changeYear(date.getFullYear() - 1);
  const increaseYear = () => changeYear(date.getFullYear() + 1);

  return (
    <div className="my-3 mx-[23px] flex justify-between items-center">
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseYear}
        >
          <ChevronLeftDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <ChevronLeft className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
      <span>
        {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
      </span>
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <ChevronRight className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black lg:!py-1 lg:!px-1"
          onClick={increaseYear}
        >
          <ChevronRightDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
    </div>
  );
};

const DateSelect = React.forwardRef(
  (
    {
      inputName,
      placeholder,
      className,
      labelClassName,
      parentClassName,
      calendarIconClass,
      errorText,
      required,
      label,
      tabIndex,
      isShowCustomMonthYearHeader,
      dateSelectClassName,
      onChangeFunc,
      ...props
    }: IDateSelectProps,
    ref: any
  ) => {
    const onChangeDate = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined
    ) => {
      let customEvent: any = event;
      customEvent = {
        event,
        ...{ value: date, id: props.id, name: inputName },
      };
      onChangeFunc(customEvent);
    };

    return (
      <div className={`${parentClassName}`}>
        <div className="flex">
          {label && (
            <label className={`form_label mb-1.5 ${labelClassName}`}>
              {label}
              {required && (
                <span className="text-red-600 leading-4 text-xs ml-1">*</span>
              )}
            </label>
          )}
          {/* {required && (
            <span className="text-red-600 leading-4 text-xs">*</span>
          )} */}
        </div>
        <div
          className={`relative w-full cursor-pointer wal-single-date-picker`}
        >
          <DatePicker
            ref={ref}
            placeholderText={placeholder}
            onChange={onChangeDate}
            className={`${className} cursor-pointer `}
            tabIndex={tabIndex}
            showFourColumnMonthYearPicker={true}
            renderCustomHeader={
              isShowCustomMonthYearHeader ? renderCustomHeader : undefined
            }
            id={props.id}
            onKeyDown={(e) => {
              if (e?.keyCode === 9 || e?.which === 9) {
                ref?.current.setOpen(false);
              }
            }}
            fixedHeight={true}
            autoComplete="off"
            name={inputName}
            dropdownMode="select"
            showIcon={true}
            icon={<Calendar className="h-5 w-5 text-textSecondary" />}
            toggleCalendarOnIconClick
            calendarIconClassname={`icon absolute right-2 top-1/2 -translate-y-1/2 text-lg text-textSecondary ${calendarIconClass}`}
            customInput={
              <CustomDateInput
                value={props.selected}
                dateSelectClassName={dateSelectClassName}
              />
            }
            popperPlacement="top-end"
            {...props}
          />
        </div>
        {errorText && <ErrorMsg errorText={errorText} />}
      </div>
    );
  }
);

DateSelect.defaultProps = {
  inputName: '',
  className: '',
  labelClassName: '',
  label: '',
  errorText: null,
  id: '',
};

DateSelect.displayName = 'DateSelect';

export default DateSelect;
