import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const CreditCustomerLoaderRow = () => (
  <>
    <td className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]`}>
      <div className={`flex items-center gap-2  `}>
        <div className="table-profile-wrap notification-mark-wrap">
          <div className="table-profile-customer-circle-xl custom-loading">
            WA
          </div>
        </div>

        <p className="truncate text-grayLight900 font-semibold leading-5 custom-loading">
          Camilla Moses
        </p>
      </div>
    </td>

    <td className={`px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]`}>
      <div className="flex flex-wrap gap-1 custom-loading">
        <BadgeCmp style="modern" type="success">
          Form Completed
        </BadgeCmp>
      </div>
    </td>
    <td className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]`}>
      <div className={`flex items-center gap-3`}>
        <div className="table-profile-wrap">
          <div className="table-profile-customer-circle-sm  custom-loading">
            WA
          </div>
        </div>
        <p className="truncate custom-loading">Adrian Keith12</p>
      </div>
    </td>
    <td className={`px-5 py-4 min-w-[170px] w-[17.75%] max-w-[17.75%]`}>
      <span className="custom-loading">06 September 2024 11:50 AM</span>
    </td>
    <td className={`px-5 py-4 min-w-[220px] w-[17.75%] max-w-[17.75%]`}>
      <span className="custom-loading">
        Oct 18<sup>th</sup> , 09:52 AM
      </span>
    </td>
    <td className={`px-5 py-4 min-w-[195px] w-[17.75%] max-w-[17.75%]`}>
      <span className="custom-loading">dibifelocu@mailinator.com</span>
    </td>
    <td className={`px-5 py-4 w-20 max-w-20 min-w-20`}>
      <div className="w-4 h-4 custom-loading">
        <CheckCircle className="text-green500 w-4 h-4" />
      </div>
    </td>
  </>
);

export default CreditCustomerLoaderRow;
