import { ArrowRight, PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { getFormattedNumber, onError } from 'src/utils/CommonFunctions';

import defaultimage from '../../../../../assets//img/default-image.jpg';

interface IProps {
  handleClose: any;
  quotedList: any;
  currency: any;
  handleActionType: any;
}

const ConvertOrderModal = ({
  handleClose,
  quotedList = [],
  currency,
  handleActionType,
}: IProps) => (
  <CommonModal
    title={'Convert to an Order'}
    titleDesc={'Select the active quote you would like to move forward with'}
    handleClose={() => handleClose(false)}
    size={'max-w-[688px] overflow-unset'}
    isOverflow={true}
    modalClassName=""
    headerIcon={<PackagePlus />}
  >
    <ul className="w-full p-5 flex flex-col gap-y-5">
      {quotedList?.length > 0 ? (
        quotedList.map((data: any, index: any) => (
          <li
            key={index}
            className={`p-4 rounded-lg border border-utilityGray200 bg-transparent hover:bg-white hover:shadow hover:border-borderSecondary cursor-pointer flex items-center ${
              moment(data.expirationDate).isBefore(moment().startOf('day'))
                ? 'opacity-50 pointer-events-none'
                : ''
            }`}
            onClick={() => {
              handleClose(false);
              handleActionType('carrierOrderModel', '', data)();
            }}
          >
            <div className="flex flex-wrap  gap-2 w-full">
              <div className="flex gap-x-1.5 w-[32%]">
                <div className="flex-none">
                  {data.image ? (
                    <img
                      src={`${data.imageUrl}${data.image}`}
                      className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none "
                      alt={data.name}
                      title={data.name}
                      onError={onError}
                    />
                  ) : (
                    <img
                      src={defaultimage}
                      className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none "
                    />
                  )}
                </div>
                <div className="w-[calc(100%_-_50px)]">
                  {(data.name + (data.banyanPostFix || '')).length > 30 ? (
                    <TooltipCmp
                      message={`${data.name} ${data.banyanPostFix || ''}`}
                    >
                      <h6 className="text-grayLight900 text-sm font-medium truncate">
                        {`${data.name} ${data.banyanPostFix || ''}`}
                      </h6>
                    </TooltipCmp>
                  ) : (
                    <h6 className="text-grayLight900 text-sm font-medium truncate">
                      {`${data.name} ${data.banyanPostFix || ''}`}
                    </h6>
                  )}
                  <p className="text-grayLight600 text-xs leading-[1.5] font-medium truncate">
                    #{data.carrierQuoteId}
                  </p>
                </div>
              </div>
              <div className="min-w-[21%]">
                <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                  Total Cost
                </h6>
                <p className="text-grayLight900 text-sm font-medium">
                  {data.carrierQuoteId
                    ? currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(data.totalCostCAD)}`
                      : `$${getFormattedNumber(data.totalCostUSD)}`
                    : '-'}
                </p>
              </div>
              <div className="min-w-[21%]">
                <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                  Total Margin
                </h6>
                <p className="text-grayLight900 text-sm font-medium">
                  {data.margin === 0
                    ? '$0.00'
                    : currency === CURRENCY.CAD
                    ? `$${getFormattedNumber(data.margin)}`
                    : `$${getFormattedNumber(data.marginUSD)}`}
                </p>
              </div>
              <div className="min-w-[21%]">
                <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                  Total Price
                </h6>
                <p className="text-grayLight900 text-sm font-medium">
                  {data.carrierQuoteId
                    ? currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(data.finalCharge)} CAD`
                      : `$${getFormattedNumber(data.finalChargeUSD)} USD`
                    : '-'}
                </p>
              </div>
            </div>
            <ArrowRight className="w-4 h-4 flex-none text-primary" />
          </li>
        ))
      ) : (
        <li>
          <NotFoundUI
            title="No Active Quotes"
            desc="To proceed with the order, move your quotes to active quotes.we can only convert Active Quotes that are not expired"
            descClass="!max-w-[300px]"
          />
        </li>
      )}
    </ul>
  </CommonModal>
);

export default ConvertOrderModal;
