import {
  CheckSquareBroken,
  Share03,
  XClose,
} from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
} from 'src/utils/CommonFunctions';
// import BadgeCmp from 'src/components/BadgeCmp';

// import user from '../../../../assets/img/carrier1.png';

const ConflictsCommissionReportRow = ({ data, setAction }: any) => (
  <>
    {/* <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
      <InfoCircle className="w-4 h-4 text-fgErrorPrimary" />
    </td> */}
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex items-center">
        {data.orderCommissioneeImage ? (
          <div className="table-profile-wrap notification-mark-wrap">
            <img
              className="table-profile-icn-circle-xl"
              src={data.orderCommissioneeImageUrl + data.orderCommissioneeImage}
              alt={data.csrFullName}
              title={data.csrFullName}
            />
            {data?.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className="table-profile-wrap notification-mark-wrap">
            <div className="table-profile-user-circle-xl">
              {getShortName(`${data.orderCommissionees}`)}
            </div>
            {data?.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>
        )}
        <div className="pl-3 text-xs font-medium max-w-32">
          <h6 className="text-grayLight900 font-medium leading-tight truncate flex">{`${data.orderCommissionees}`}</h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #{data?.fullID}
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4  w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex gap-1 flex-wrap">
        {(data?.orderStatus === 'new' ||
          data?.orderStatus === 'saved' ||
          data?.orderStatus === 'quoted' ||
          data?.orderStatus === 'spot-qq-requested' ||
          data?.orderStatus === 'spot-quote-requested' ||
          data?.orderStatus === 'pending' ||
          data?.orderStatus === 'draft-quick-quote' ||
          data?.orderStatus === 'quick-quoted' ||
          data?.orderStatus === 'reject' ||
          data?.orderStatus === 'archived' ||
          data?.orderStatus === 'expired') && (
          <BadgeCmp style="modern" type="gray">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'no-quote' ||
          data?.orderStatus === 'no-quick-quote' ||
          data?.orderStatus === 'cancelled') && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'tender' ||
          data?.orderStatus === 'booked' ||
          data?.orderStatus === 'invoice-paid' ||
          data?.orderStatus === 'delivered') && (
          <BadgeCmp style="modern" type="lightblue">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'invoice-created' ||
          data?.orderStatus === 'invoice-sent') && (
          <BadgeCmp style="modern" type="error">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {/* Tags */}
        {data?.orderStatus === 'dispatched' && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}

        {/* <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
          <BadgeCmp style="modern" type="primary">
            Dispatched
          </BadgeCmp>
          <BadgeCmp style="modern" type="warning">
            Invoice- created
          </BadgeCmp> */}
      </div>
    </td>

    <td className="px-5 py-4 w-[17.78%] max-w-[17.78%] min-w-[17.78%]">
      <span>
        {' '}
        <span
          dangerouslySetInnerHTML={{
            __html: data?.orderCreatedAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data?.orderCreatedAt,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span>${getFormattedNumber(data?.combinedCost)}</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span>${getFormattedNumber(data?.combinedRevenue)}</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span>${getFormattedNumber(data?.combinedMargin)}</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex items-center">
        {data?.month} {data?.year}
        <Share03 className="ml-2 text-gray500 w-4 h-4 cursor-pointer hover:text-primary" />
      </div>
    </td>
    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-end items-center gap-3 flex ">
        <CheckSquareBroken
          className="w-4 h-4 text-fgSuccessPrimary cursor-pointer"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ type: 'resolve', orderId: data?.fullID },
            }))
          }
        />
        <XClose
          className="w-4 h-4 text-fgErrorPrimary cursor-pointer"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ type: 'remove', orderId: data?.fullID },
            }))
          }
        />
      </div>
    </td>
  </>
);

export default ConflictsCommissionReportRow;
