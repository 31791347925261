import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const CustomerDetailClaimsLoadingRaw = () => (
  <>
    <td className="px-5 py-4 min-w-52 w-52 max-w-52">
      <div className="flex items-center custom-loading">
        <div className="text-xs font-medium max-w-28">
          <div className="text-grayLight600 font-normal leading-tight truncate">
            #SQ-L-100502
          </div>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[18%] max-w-[18%] w-[18%]">
      <BadgeCmp
        style="modern"
        type={'success'}
        mainClassName={'custom-loading'}
      >
        <span data-test-id="wal-QuotingDashboard-status">Opened</span>
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 min-w-[18%] max-w-[18%] w-[18%]">
      <a
        className="text-primary700 font-normal leading-tight truncate underline custom-loading"
        target="_blank"
      >
        #WAL-1OID-20
      </a>
    </td>

    <td className="px-5 py-4 min-w-52 w-52 max-w-52">
      <span className="custom-loading">
        Apr 18<sup>th</sup> - 2024
      </span>
    </td>

    <td className="px-5 py-4 min-w-[17%] max-w-[17%] w-[17%]">
      <span className="custom-loading">$2023.69</span>
    </td>
  </>
);

export default CustomerDetailClaimsLoadingRaw;
