import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const CustomerSplitAsideTableRowLoader = () => (
  <>
    <td className="px-5 py-4 max-w-48 w-48 min-w-48">
      <div className="flex items-center gap-2 custom-loading">
        <div className="w-7 h-7 bg-primary rounded-full text-white flex items-center justify-center uppercase text-xs flex-none">
          {getShortName('TS')}
        </div>
        Test User
      </div>
    </td>
    <td className="px-5 py-4 pr-0">
      <span className="custom-loading">$20000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default CustomerSplitAsideTableRowLoader;
