import {
  SearchLg,
  Plus,
  UsersPlus,
  CheckCircle,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CommonModal from 'src/components/CommonModal';
import DeleteModal from 'src/components/DeleteModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { BasicContext } from 'src/context/BasicContext';

import BadgeCmp from '../../../components/BadgeCmp';
import ButtonCmp from '../../../components/ButtonCmp';
import CustomPagination from '../../../components/CustomPagination';
import InputText from '../../../components/InputText/InputText';
import NotFoundUI from '../../../components/NotFoundUI';
import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';
import { USER_ROLE } from '../../../constants/common';
import { AuthContext } from '../../../context/AuthContext';
import { deleteUser, listUser } from '../../../services/TeamMemberService';
import { useRolePermission } from '../../../utils/CommonFunctions';
import WalToast from '../../../utils/WalToast';
import AccessControlModal from '../AccessControlModal';
import CreateUpdateMemberModal from '../CreateUpdateMemberModal';
import CreateUpdateTeam from '../CreateUpdateTeam';
import MemberProfile from '../TeamManagement/MemberProfile';

import MemberRow from './MemberRow';
import LoadingRow from './MemberRow/LoadingRow';

const initAction = {
  mode: null,
  team: false,
};

const initParams = {
  search: '',
  page: 1,
  limit: 25,
  sortType: 'asc',
  sortField: 'firstName',
};

const TeamMembersAdminView = () => {
  const { setTooltipGlobalOpen } = useContext(BasicContext);
  const { currentUser } = useContext(AuthContext);
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const [action, setAction] = useState(initAction);
  const [isRefresh, setIsRefresh] = useState(true);
  const [team, setTeam] = useState<any>({});
  const [userCreateFormData, setUserCreateFormData] = useState({});
  const [adminParams, setAdminParams] = useState(initParams);
  const [adminList, setAdminList] = useState([]);
  const [adminListTotal, setAdminListTotal] = useState(0);
  const [adminListLoading, setAdminListLoading] = useState(true);
  const [inProgressFormData, setInProgressFormData] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [accountType, setAccountType] = useState();

  var accountTypeArr: any[] = [];

  if (hasRoleV2('admin')) {
    accountTypeArr = [
      {
        label:
          USER_ROLE.ADMIN.charAt(0).toUpperCase() + USER_ROLE.ADMIN.slice(1),
        value: USER_ROLE.ADMIN,
      },
      {
        label:
          USER_ROLE.MANAGER.charAt(0).toUpperCase() +
          USER_ROLE.MANAGER.slice(1),
        value: USER_ROLE.MANAGER,
      },
      {
        label:
          USER_ROLE.SALES.charAt(0).toUpperCase() + USER_ROLE.SALES.slice(1),
        value: USER_ROLE.SALES,
      },
      {
        label:
          USER_ROLE.OPERATIONS.charAt(0).toUpperCase() +
          USER_ROLE.OPERATIONS.slice(1),
        value: USER_ROLE.OPERATIONS,
      },
      {
        label:
          USER_ROLE.CLAIMS.charAt(0).toUpperCase() + USER_ROLE.CLAIMS.slice(1),
        value: USER_ROLE.CLAIMS,
      },
      {
        label:
          USER_ROLE.FINANCE.charAt(0).toUpperCase() +
          USER_ROLE.FINANCE.slice(1),
        value: USER_ROLE.FINANCE,
      },
    ];
  }

  if (hasRoleV2('manager')) {
    accountTypeArr = [
      {
        label:
          USER_ROLE.MANAGER.charAt(0).toUpperCase() +
          USER_ROLE.MANAGER.slice(1),
        value: USER_ROLE.MANAGER,
      },
      {
        label:
          USER_ROLE.SALES.charAt(0).toUpperCase() + USER_ROLE.SALES.slice(1),
        value: USER_ROLE.SALES,
      },
      {
        label:
          USER_ROLE.OPERATIONS.charAt(0).toUpperCase() +
          USER_ROLE.OPERATIONS.slice(1),
        value: USER_ROLE.OPERATIONS,
      },
      {
        label:
          USER_ROLE.CLAIMS.charAt(0).toUpperCase() + USER_ROLE.CLAIMS.slice(1),
        value: USER_ROLE.CLAIMS,
      },
      {
        label:
          USER_ROLE.FINANCE.charAt(0).toUpperCase() +
          USER_ROLE.FINANCE.slice(1),
        value: USER_ROLE.FINANCE,
      },
    ];
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAdminListLoading(true);
    setAdminList([]);
    listUser({ ...adminParams, type: accountType }, signal)
      .then((response: any) => {
        setAdminList(response.data);
        setAdminListTotal(response.total);
        setAdminListLoading(false);
      })
      .catch(console.log);

    setIsRefresh(false);

    return () => {
      controller.abort();
    };
  }, [isRefresh, adminParams, accountType]);

  const adminSearchDebounce = useCallback(
    debounce(
      (search: string) =>
        setAdminParams(() => ({ ...initParams, ...{ search } })),
      700
    ),
    []
  );

  const handleActionType =
    (keyAction: any, mode: any = null, teamRec: any = {}) =>
    () => {
      setTooltipGlobalOpen(false);
      setTeam(teamRec);
      setAction((old) => ({ ...old, [keyAction]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setUserCreateFormData({});
    setAction(initAction);
    setTeam({});
  }, []);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setTeam({});

      if (status) {
        deleteUser(team.id)
          .then(() => {
            setIsRefresh(true);
            WalToast.success(
              `${team?.firstName ?? 'User'} ${
                team?.lastName ?? ''
              } was removed from WALHQ`
            );
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting user account.'
            );
          });
      }
    },
    [team]
  );

  const headCells = useMemo(
    () => [
      {
        id: 'firstName',
        name: 'Name',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'isVerified',
        name: 'Status',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'lastLoginAt',
        name: 'Last Active',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'displayName',
        name: 'Account Type',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'action',
        sortable: false,
        rowClassName: '',
      },
    ],
    []
  );
  const recordsPerPageArray = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const handleAddNewMembers = () => {
    if (currentUser.teams && currentUser.teams.length > 0) {
      handleActionType('team', 'user-create')();
    } else if (hasPermissionV2('team_create')) {
      handleActionType('team', 'team-create-no-team')();
    }
  };

  const handleAdminsFilter = (event: any) => {
    setSearchInput(event.target.value);
    adminSearchDebounce(event.target.value);
  };

  const handleAdminsPagination = (page: number) => {
    setAdminParams((old) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Team Members"
            desc="Manage your team members and their account permissions here."
            mainContainerClassName="sm:flex-nowrap sm:flex-row"
            rightClassName="sm:w-auto"
            isShowNotificationBox
          />
        }
      >
        <div
          className={`${
            adminList.length < 10 || adminList.length == 0 ? 'min-h-full' : ''
          } h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col `}
        >
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">
                  {hasRoleV2('admin') || hasRoleV2('manager') ? 'All' : 'My'}{' '}
                  Members
                </h5>

                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${adminListLoading ? 'custom-loading' : ''}`}
                >
                  {adminListTotal} {adminListTotal > 1 ? 'Members' : 'Member'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage all organizations team members.
              </p>
            </div>
            <ButtonCmp
              type="button"
              className="btn-outline-primary flex smd:w-fit w-full"
              onClick={handleAddNewMembers}
              disabled={
                !(currentUser.teams && currentUser.teams.length > 0) &&
                !hasPermissionV2('team_create')
              }
              icon={<Plus className="text-primary w-4 h-4" />}
            >
              {currentUser.teams && currentUser.teams.length > 0
                ? 'Add Team Member'
                : 'Create New Team'}
            </ButtonCmp>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchTeam"
                placeholder="Search"
                value={searchInput}
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                onChangeFunc={handleAdminsFilter}
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                isClearable={true}
                parentClassName="table-searchInput sm:min-w-[auto] min-w-full"
              />
            </div>
            {(hasRoleV2('admin') || hasRoleV2('manager')) && (
              <div className="table-statustbox">
                <SelectBox
                  name="account_type"
                  placeholder="Filter by Type"
                  id="account_type"
                  className="form_control shadow"
                  size="sm"
                  options={accountTypeArr}
                  onChangeFunc={(event: any) => {
                    setAccountType(event?.value);
                  }}
                  isSearchable={false}
                  isClearable
                  value={accountTypeArr.find(
                    (val: any) => val.value === adminParams.limit
                  )}
                />
              </div>
            )}
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === adminParams.limit
                )}
                onChangeFunc={(event: any) => {
                  setAdminParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
              />
            </div>
          </div>

          <div className="flex-1 w-full border-t border-gray100">
            <div className="overflow-x-auto custom-scrollbar">
              <TableCmp
                headCells={headCells}
                tableDataArr={adminList}
                TableLoaderRowCmp={LoadingRow}
                TableRowCmp={MemberRow}
                params={adminParams}
                setParams={setAdminParams}
                tableRowCmpProps={{
                  handleActionType: handleActionType,
                }}
                isTableDataLoading={adminListLoading}
                numberOfSkeletonRows={adminParams.limit}
                isTableRowClickable={false}
              />
            </div>
          </div>
          {!adminList.length && !adminListLoading ? (
            <NotFoundUI
              title="No members found"
              desc="There are no data for team members you can create new team member."
              buttonText={`${
                hasPermissionV2('team_member_create')
                  ? currentUser.teams && currentUser.teams.length > 0
                    ? 'Add New Member'
                    : 'Create New Team'
                  : false
              }`}
              handleActionType={handleAddNewMembers}
              buttonDisabled={
                !(currentUser.teams && currentUser.teams.length > 0) &&
                !hasPermissionV2('team_create')
              }
            />
          ) : (
            ''
          )}
          {adminListTotal ? (
            <div className="w-full ">
              <CustomPagination
                recordsPerPage={adminParams.limit}
                totalRecords={adminListTotal}
                currentPage={adminParams.page}
                handlePagination={handleAdminsPagination}
              />
            </div>
          ) : null}
        </div>
      </PageSectionLayout>

      {(action.mode === 'user-create' || action.mode === 'user-update') && (
        <CreateUpdateMemberModal
          mode={action.mode}
          team={team}
          handleClose={(userFormDataToCreate: any) => {
            setInProgressFormData(undefined);

            if (action.mode === 'user-create' && userFormDataToCreate) {
              setUserCreateFormData(userFormDataToCreate);
              setAction((old) => ({
                ...old,
                team: true,
                mode: 'access-control' as any,
              }));
            } else if (action.mode === 'user-update' || !userFormDataToCreate) {
              handleDrawerClose();
            }
          }}
          setIsRefresh={setIsRefresh}
          handleCreateNewTeam={(inprogressFormData) => {
            setInProgressFormData(inprogressFormData);
            handleActionType('team_management', 'team-create-instant')();
          }}
          inProgressFormData={inProgressFormData}
        />
      )}

      {action.mode === 'access-control' && (
        <AccessControlModal
          handleClose={handleDrawerClose}
          team={team}
          userCreateFormData={userCreateFormData}
          setIsRefresh={setIsRefresh}
        />
      )}

      {(action.mode === 'team-create-no-team' ||
        action.mode === 'team-created-confirmation') && (
        <CommonModal
          title={
            action.mode === 'team-create-no-team'
              ? `Create a new team.`
              : `Team successfully created`
          }
          titleDesc={
            action.mode === 'team-create-no-team'
              ? `Add your first team before continuing.`
              : ``
          }
          handleClose={handleDrawerClose}
          headerIcon={
            action.mode === 'team-create-no-team' ? (
              <UsersPlus />
            ) : (
              <CheckCircle />
            )
          }
          secondaryBtnOnClick={handleDrawerClose}
          size="max-w-[400px]"
          primaryBtnText={
            action.mode === 'team-create-no-team'
              ? 'Create New Team'
              : 'Add Team Member'
          }
          primaryBtnOnClick={
            action.mode === 'team-create-no-team'
              ? handleActionType('team', 'team-create-instant')
              : handleActionType('team', 'user-create')
          }
          primaryButtonIcon={<Plus />}
        >
          <div className="self-stretch px-6 pt-6 flex-col justify-start items-start gap-1 flex mb-5">
            <div className="text-grayLight600 text-xs font-normal leading-tight">
              {action.mode === 'team-create-no-team'
                ? 'You have no organization teams. Please create a new team before adding new members to the platform.'
                : 'Team is successfully created.Please add new team member'}
            </div>
          </div>
        </CommonModal>
      )}

      {action.mode === 'team-create-instant' && (
        <CreateUpdateTeam
          mode={action.mode}
          team={team}
          handleClose={(successData: any) => {
            if (successData) {
              handleActionType('team', 'team-created-confirmation')();
            } else if (inProgressFormData) {
              handleActionType('team', 'user-create')();
            } else {
              handleDrawerClose();
            }
          }}
          setIsRefresh={setIsRefresh}
        />
      )}

      {action.mode === 'view_profile' && (
        <MemberProfile
          handleClose={handleDrawerClose}
          teamMemberId={team?.id}
        />
      )}

      {action.mode === 'user-delete' && (
        <DeleteModal
          moduleName={`${team.firstName ?? ''} from Team ${team?.name ?? ''}`}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default TeamMembersAdminView;
