'use client';
import { ArrowUp } from 'lucide-react';
import { useEditor } from 'novel';
import { addAIHighlight } from 'novel/extensions';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Markdown from 'react-markdown';
import { aiResponseGenerate } from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

import { Button } from '../ui/button';
import { Command, CommandInput } from '../ui/command';
import CrazySpinner from '../ui/icons/crazy-spinner';
import Magic from '../ui/icons/magic';
import { ScrollArea } from '../ui/scroll-area';

import AICompletionCommands from './ai-completion-command';
import AISelectorCommands from './ai-selector-commands';

interface AISelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function AISelector({ onOpenChange }: AISelectorProps) {
  const { editor }: any = useEditor();
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completion, setCompletion] = useState('');
  const [command, setCommand] = useState('');

  const getAIResponse = (textVal?: any) => {
    setIsLoading(true);
    const slice = editor.state.selection.content();
    const text = editor.storage.markdown.serializer.serialize(slice.content);
    const params = {
      prompt: textVal ?? text,
      option: selectedOption,
      command: command,
    };

    aiResponseGenerate(params)
      .then((response: any) => {
        setCompletion(response.message);
        setSelectedOption(null);
        setCommand('');
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        WalToast.error(
          e?.response?.data?.message ??
            'somethin went wrong please try again later!!'
        );
        setSelectedOption(null);
        setCommand('');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedOption) {
      if (completion) {
        getAIResponse(completion);
      } else {
        getAIResponse();
      }
    }
  }, [selectedOption]);

  const hasCompletion = completion.length > 0;

  return (
    <Command className="w-[350px]">
      {hasCompletion && (
        <div className="flex max-h-[400px]">
          <ScrollArea>
            <div className="prose p-2 px-4 prose-sm">
              <Markdown>{completion}</Markdown>
            </div>
          </ScrollArea>
        </div>
      )}

      {isLoading && (
        <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-purple-500">
          <Magic className="mr-2 h-4 w-4 shrink-0  " />
          AI is thinking
          <div className="ml-2 mt-1">
            <CrazySpinner />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="relative">
            <CommandInput
              value={inputValue}
              onInput={(e: any) => setInputValue(e?.target?.value)}
              autoFocus
              placeholder={
                hasCompletion
                  ? 'Tell AI what to do next'
                  : 'Ask AI to edit or generate...'
              }
              onFocus={() => addAIHighlight(editor)}
            />
            <Button
              size="icon"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-purple-500 hover:bg-purple-900"
              onClick={() => {
                // if (completion) {
                // return complete(completion, {
                //   body: { option: 'zap', command: inputValue },
                // }).then(() => setInputValue(''));
                setCommand(inputValue);
                setSelectedOption('zap');

                // return getAIResponse(completion);
                // } else {
                // setCommand(inputValue);
                // setSelectedOption('zap');
                // getAIResponse();
                // }

                // const slice = editor.state.selection.content();
                // const text = editor.storage.markdown.serializer.serialize(
                //   slice.content
                // );
                // complete(text, {
                //   body: { option: 'zap', command: inputValue },
                // }).then(() => setInputValue(''));
              }}
            >
              <ArrowUp className="h-4 w-4" />
            </Button>
          </div>
          {hasCompletion ? (
            <AICompletionCommands
              onDiscard={() => {
                editor.chain().unsetHighlight().focus().run();
                onOpenChange(false);
              }}
              completion={completion}
            />
          ) : (
            <AISelectorCommands
              inputValue={inputValue}
              onSelect={(value, option) => {
                setSelectedOption(option);
                // complete(value, { body: { option } });
              }}
            />
          )}
        </>
      )}
    </Command>
  );
}
