import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';

const NotificationSkeletonLoader = () => (
  <li className="bg-white px-6 [&:last-child>div]:border-b-0">
    <div className="border-b border-utilityGray200 py-5 flex gap-x-4 relative items-start">
      <div className="profile-icon-wrap rounded-full custom-loading ">
        <img src={avatar} className="table-profile-icn-circle-xl" />
      </div>
      <div className="flex-1">
        <div className="flex gap-x-1">
          <h6 className="text-sm text-grayLight600 font-normal flex-1 custom-loading">
            <p className="text-grayLight900 font-medium">Anita Cruz</p>
            &nbsp;has claimed quote on&nbsp;
            <span className="text-grayLight900 font-medium">
              01 Jul 2024 at 09:30AM
            </span>
          </h6>
        </div>
        <div className="flex justify-between gap-x-3 text-grayLight600 text-xs leading-[1.5] font-normal mt-1">
          <p className="custom-loading">Friday 20:14 PM</p>

          <p className="text-gray500 text-xs font-normal custom-loading">
            2 hours ago
          </p>
        </div>
        <div className=" bg-utilityindigo25 p-3 mt-3 custom-loading">
          <p className="text-textSecondary text-xs leading-[1.5] font-normal ">
            Lorem ipsum dolor sit amet
          </p>
        </div>
      </div>
    </div>
  </li>
);

export default NotificationSkeletonLoader;
