import { FilePlus03 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  onError,
} from 'src/utils/CommonFunctions';

interface IProps {
  handleClose: any;
  quotedList: any;
  currency: any;
  selectedDisplayOptions: any;
  setSelectedDisplayOptions: any;
  selectedLanguage: any;
  handleLanguageSelect: any;
  selectedQuoteToGeneratePdfId: any;
  setSelectedQuoteToGeneratePdfId: any;
  handleGeneratePdf: any;
  isPdfGenerating: any;
}

const GeneratePdfModal = ({
  handleClose,
  quotedList = [],
  currency,
  selectedDisplayOptions,
  setSelectedDisplayOptions,
  selectedLanguage,
  handleLanguageSelect,
  selectedQuoteToGeneratePdfId,
  setSelectedQuoteToGeneratePdfId,
  handleGeneratePdf,
  isPdfGenerating,
}: IProps) => {
  useEffect(() => {
    if (quotedList?.length === 1)
      setSelectedQuoteToGeneratePdfId(quotedList.map((data: any) => data.id));
  }, [quotedList]);

  return (
    <CommonModal
      title={'Generate PDF'}
      titleDesc={'Select the active quote you would like to move forward with'}
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus03 />}
      size={'max-w-[688px]'}
      isOverflow={true}
      modalClassName=""
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnText="Generate Quote"
      primaryBtnDisabled={!selectedQuoteToGeneratePdfId.length}
      primaryBtnOnClick={() => handleGeneratePdf()}
      primaryBtnLoading={isPdfGenerating}
    >
      <div className="w-full p-5">
        <ul className="flex flex-wrap -m-1.5">
          <li className=" sm:w-1/2 w-full p-1.5">
            <h6 className="textgrayLight900 text-xs font-semibold mb-1.5">
              Language Settings
            </h6>
            <div className="rounded-lg border border-utilityGray200 p-3 ">
              <div
                className="flex gap-x-1 justify-between cursor-pointer"
                onClick={() => handleLanguageSelect('english')}
              >
                <h6 className="text-grayLight900 text-xs font-normal">
                  English
                </h6>
                <CheckBox
                  checked={selectedLanguage === 'english'}
                  onChangeFunc={() => {}}
                ></CheckBox>
              </div>
              <div
                className="flex gap-x-1 justify-between mt-3 cursor-pointer"
                onClick={() => handleLanguageSelect('french')}
              >
                <h6 className="text-grayLight900 text-xs font-normal">
                  French
                </h6>
                <CheckBox
                  checked={selectedLanguage === 'french'}
                  onChangeFunc={() => {}}
                ></CheckBox>
              </div>
            </div>
          </li>
          <li className=" sm:w-1/2 w-full p-1.5">
            <h6 className="textgrayLight900 text-xs font-semibold mb-1.5">
              Display Settigns
            </h6>
            <div className="rounded-lg border border-utilityGray200 p-3 ">
              <div
                className="flex gap-x-2.5 justify-between cursor-pointer"
                onClick={() =>
                  setSelectedDisplayOptions((prevState: any) => ({
                    ...prevState,
                    displayCarrierNames: !prevState?.displayCarrierNames,
                  }))
                }
              >
                <h6 className="text-grayLight900 text-xs font-normal">
                  Display Carrier Names
                </h6>
                <CheckBox
                  checked={selectedDisplayOptions.displayCarrierNames}
                  onChangeFunc={() => {}}
                  // onChangeFunc={(e: any) =>
                  //   setSelectedDisplayOptions((prevState: any) => ({
                  //     ...prevState,
                  //     displayCarrierNames: e.target.checked,
                  //   }))
                  // }
                ></CheckBox>
              </div>
              <div
                className="flex gap-x-2.5 justify-between mt-3  cursor-pointer"
                onClick={() =>
                  setSelectedDisplayOptions((prevState: any) => ({
                    ...prevState,
                    displayDeliveryTime: !prevState?.displayDeliveryTime,
                  }))
                }
              >
                <h6 className="text-grayLight900 text-xs font-normal ">
                  Display Est. Delivery & Transit Time
                </h6>
                <CheckBox
                  checked={selectedDisplayOptions.displayDeliveryTime}
                  onChangeFunc={() => {}}
                  // onChangeFunc={(e: any) =>
                  //   setSelectedDisplayOptions((prevState: any) => ({
                  //     ...prevState,
                  //     displayDeliveryTime: e.target.checked,
                  //   }))
                  // }
                ></CheckBox>
              </div>
            </div>
          </li>
        </ul>
        <div className="mt-5 rounded-lg border border-utilityGray200">
          <div
            className="flex gap-x-3 justify-between px-4 py-2 border-b border-utilityGray200 items-center cursor-pointer"
            onClick={() => {
              let isChecked =
                quotedList.length > 0 &&
                quotedList.every((data: any) =>
                  selectedQuoteToGeneratePdfId.includes(data.id)
                );

              if (!isChecked) {
                setSelectedQuoteToGeneratePdfId(
                  quotedList.map((data: any) => data.id)
                );
              } else {
                setSelectedQuoteToGeneratePdfId([]);
              }
            }}
          >
            <h6 className="text-grayLight900 text-xs font-semibold">
              Select Quote Options
            </h6>
            <CheckBox
              checked={
                quotedList.length > 0 &&
                quotedList.every((data: any) =>
                  selectedQuoteToGeneratePdfId.includes(data.id)
                )
              }
              onChangeFunc={(e: any) => {
                const checked = e.target.checked;

                if (checked) {
                  setSelectedQuoteToGeneratePdfId(
                    quotedList.map((data: any) => data.id)
                  );
                } else {
                  setSelectedQuoteToGeneratePdfId([]);
                }
              }}
            ></CheckBox>
          </div>
          <ul className="px-4 py-1">
            {quotedList.map((data: any, index: any) => (
              <li
                key={index}
                className="flex last:border-0 border-b border-utilityGray200 py-4 cursor-pointer"
                onClick={() =>
                  setSelectedQuoteToGeneratePdfId((prevState: any) => {
                    if (prevState.includes(data.id)) {
                      return prevState.filter((id: any) => id !== data.id);
                    } else {
                      return [...prevState, data.id];
                    }
                  })
                }
              >
                <div className="flex flex-1 gap-y-2 flex-wrap">
                  <div className="flex items-center gap-x-1.5 w-[38%]">
                    {data.image ? (
                      <img
                        src={`${data.imageUrl}${data.image}`}
                        className="table-profile-icn-circle-xl rounded-md"
                        alt={data.name}
                        title={data.name}
                        onError={onError}
                      />
                    ) : (
                      <div className="table-profile-customer-circle-xl rounded-md">
                        WA
                      </div>
                    )}
                    <div className="w-[calc(100%_-_52px)]">
                      {(data.name + (data.banyanPostFix || '')).length > 30 ? (
                        <TooltipCmp
                          message={`${data.name} ${data.banyanPostFix || ''}`}
                        >
                          <h6 className="text-grayLight900 text-sm font-medium truncate">
                            {`${data.name} ${data.banyanPostFix || ''}`}
                          </h6>
                        </TooltipCmp>
                      ) : (
                        <h6 className="text-grayLight900 text-sm font-medium truncate">
                          {`${data.name} ${data.banyanPostFix || ''}`}
                        </h6>
                      )}
                      <p className="text-grayLight600 text-xs font-medium truncate">
                        #{data.carrierQuoteId}
                      </p>
                    </div>
                  </div>
                  <div className="min-w-[31%]">
                    <h6 className="text-grayLight600 text-xs font-medium">
                      Est. Delivery Date
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {data.transitTime > 0 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.projectedDeliveryDate
                              ? getDateWithSuffixFormat(
                                  moment
                                    .utc(data.projectedDeliveryDate)
                                    .format(`MMM Do - YYYY`)
                                )
                              : '-',
                          }}
                        />
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                  <div className="min-w-[31%]">
                    <h6 className="text-grayLight600 text-xs font-medium">
                      Total Price
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {currency === CURRENCY.CAD
                        ? `$${getFormattedNumber(data.finalCharge)} CAD`
                        : `$${getFormattedNumber(data.finalChargeUSD)} USD`}
                    </p>
                  </div>
                </div>
                <CheckBox
                  checked={selectedQuoteToGeneratePdfId.includes(data.id)}
                  onChangeFunc={() => {}}
                  classes="self-center"
                ></CheckBox>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </CommonModal>
  );
};
export default GeneratePdfModal;
