import { CheckSquareBroken, XClose } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const ConflictsCommissionReportLoadingRow = () => (
  <>
    {/* <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
      <div className="custom-loading">
        <XClose className="w-4 h-4 text-fgErrorPrimary " />
      </div>
    </td> */}
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 custom-loading">
        WAL-DRGH-1
      </h6>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex gap-1 flex-wrap custom-loading">
        <BadgeCmp style="modern" type="success">
          New
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-[17.78%] max-w-[17.78%] min-w-[17.78%]">
      <span className="custom-loading">Jun 7, 2023</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="custom-loading">$321.31</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="custom-loading">$432.33</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="font-semibold custom-loading">$901.06 CAD</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex items-center custom-loading">August 2024</div>
    </td>
    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-end items-center gap-3 flex custom-loading">
        <CheckSquareBroken className="w-4 h-4 text-fgSuccessPrimary" />
        <XClose className="w-4 h-4 text-fgErrorPrimary" />
      </div>
    </td>
  </>
);
export default ConflictsCommissionReportLoadingRow;
