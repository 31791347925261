import { yupResolver } from '@hookform/resolvers/yup';
import { Plus, Target05, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InternalChatAndNotes from 'src/app/Claims&Disputes/InternalChatAndNotes';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { ROUTES } from 'src/constants/routes';
import { getOrder, updateOrder } from 'src/services/OrderService';
import { listShippers } from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import defaultimage from '../../../assets/img/default-image.jpg';
import CreateOrder from '../createOrder';
import { defualtOrderValue, initAction, orderSchema } from '../order.interface';

import AddOrderDetails from './AddOrderDetails';
import AddressDetails from './AddressDetails';
import AppointmentDetails from './AppointmentDetails';
import AssignCarrier from './AssignCarrier';
import RecurringOrderModal from './RecurringOrder/RecurringOrderModal';
import SalePrice from './SalePrice';

const filterArr = [
  {
    value: 'order_first',
    name: 'Master (#WAL-JCH-12)',
  },
  {
    value: 'order_id',
    name: 'Master (#WAL-JCH-12)',
  },
];

const OrderDetails = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const [action, setAction] = useState(initAction);
  const [order, setOrder] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);

  const methods = useForm({
    resolver: yupResolver(orderSchema) as Resolver<any>,
    defaultValues: defualtOrderValue,
  });

  const { handleSubmit } = methods;

  console.log('shippers', shippers);
  console.log('consignees', consignees);
  console.log('shipperOptions', shipperOptions);
  console.log('consigneeOptions', consigneeOptions);

  const getOrderData = () => {
    setIsLoading(true);
    const param = { id };

    getOrder(param)
      .then((response) => {
        if (response?.data) {
          setOrder(response?.data);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => {
        // setIsShipperLoading(false)
      })
      .catch(console.error);
  };

  useEffect(() => {
    getOrderData();
    getShipper();
  }, []);

  const onSubmit = (data: any) => {
    const param: any = {
      shipperCompanyName: data?.shipper?.companyName,
      shipperContactName: data?.shipper?.contactName,
      shipperContactNumber: data?.shipper?.phoneNumber,
      shipperEmail: data?.shipper?.email,
      shipperFullAddress: data?.shipper?.shipperAddress?.fullAddress,
      shipperAddress1: data?.shipper?.shipperAddress?.address1,
      shipperCity: data?.shipper?.shipperAddress?.city,
      shipperState: data?.shipper?.shipperAddress?.state,
      shipperCountry: data?.shipper?.shipperAddress?.country,
      shipperPostal: data?.shipper?.shipperAddress?.postal,
      shipperLatitude: data?.shipper?.shipperAddress?.latitude,
      shipperLongitude: data?.shipper?.shipperAddress?.longitude,
      shipperStateCode: data?.shipper?.shipperAddress?.stateCode,
      shipperCountryCode: data?.shipper?.shipperAddress?.countryCode,
      consigneeCompanyName: data?.consignee?.companyName,
      consigneeContactName: data?.consignee?.contactName,
      consigneeContactNumber: data?.consignee?.phoneNumber,
      consigneeEmail: data?.consignee?.email,
      consigneeFullAddress: data?.consignee?.consigneeAddress?.fullAddress,
      consigneeAddress1: data?.consignee?.consigneeAddress?.address1,
      consigneeCity: data?.consignee?.consigneeAddress?.city,
      consigneeState: data?.consignee?.consigneeAddress?.state,
      consigneeCountry: data?.consignee?.consigneeAddress?.country,
      consigneePostal: data?.consignee?.consigneeAddress?.postal,
      consigneeLatitude: data?.consignee?.consigneeAddress?.latitude,
      consigneeLongitude: data?.consignee?.consigneeAddress?.longitude,
      consigneeStateCode: data?.consignee?.consigneeAddress?.stateCode,
      consigneeCountryCode: data?.consignee?.consigneeAddress?.countryCode,
      id: id,
      order_dimensions: data?.order_dimensions,
      declaredValue: data?.declaredValue,
      equipmentType: data?.equipmentType,
      linearFootage: data?.linearFootage,
      orderType: data?.orderType,
      poNumber: data?.poNumber,
      refNumber: data?.refNumber,
      specialNotes: data?.specialNotes,
    };

    updateOrder(param)
      .then((response) => {
        console.log('response', response);
      })
      .catch(console.error);
  };

  const [isShowOrderActionsDropDown, setIsShowOrderActionsDropDown] =
    useState(false);
  const [isOrderRecurringModal, setIsOrderRecurringModal] = useState(false);

  return (
    <PageSectionLayout
      header={
        <Header
          leftClassName="!w-auto"
          title={
            <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
              Order:
              <span className={`${isLoading ? 'custom-loading' : ''}`}>
                #{order ? order.orderId : 'CL-AOI1-141'}
              </span>
              <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                <TooltipCmp message={'name'}>
                  <div className="rounded-full bg-white">
                    <img
                      src={defaultimage}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                    />
                  </div>
                </TooltipCmp>
                <TooltipCmp message={'name'}>
                  <div className="rounded-full bg-white">
                    <img
                      src={defaultimage}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                    />
                  </div>
                </TooltipCmp>
              </div>
              <BadgeCmp
                style="modern"
                mainClassName={`rounded-md ${
                  isLoading ? 'custom-loading' : ''
                }`}
                type="success"
              >
                {order ? order.orderStatus : 'Opened'}
              </BadgeCmp>
            </div>
          }
          desc={`Detailed review of items, prices, and order status`}
          rightSideContent={
            <>
              {/* <button onClick={() => setIsOrderRecurringModal(true)}>
                Order Recurring
              </button> */}
              <OutsideClickHandler
                onOutsideClick={() => setIsShowOrderActionsDropDown(false)}
                containerClassName="relative"
              >
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => setIsShowOrderActionsDropDown((prev) => !prev)}
                >
                  Order Actions
                </ButtonCmp>
                <ul
                  className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                    isShowOrderActionsDropDown ? '' : 'hidden'
                  } `}
                >
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowOrderActionsDropDown(false);
                      setIsOrderRecurringModal(true);
                    }}
                  >
                    Recurring
                  </li>
                </ul>
              </OutsideClickHandler>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={() => {
                  setIsCreateOrder(true);
                }}
              >
                Create Order
              </ButtonCmp>
              <ButtonCmp className="btn_secondary_black lg:!px-[9px] !px-2">
                <XClose
                  className="w-4 h-4"
                  onClick={() => {
                    navigate(`${ROUTES.ORDERS}`);
                  }}
                />
              </ButtonCmp>
            </>
          }
        />
      }
      contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
    >
      <FormProvider {...methods}>
        <div className="flex h-full w-full">
          <div className="flex-1 xl:pr-8 pb-5 overflow-auto custom-scrollbar-v2 pt-6 flex flex-col">
            <div className="flex gap-2 justify-between mb-6 flex-wrap ml-1">
              <div>
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Customer
                </h6>

                <TooltipCmp
                  message={'customer name'}
                  parentClassName="md:!p-3 !p-1.5 max-w-90"
                >
                  <p
                    className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate `}
                  >
                    DSS Marine Incorporated (USD)
                  </p>
                </TooltipCmp>
              </div>
              <div className="sm:min-w-[17%] min-w-full">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Sales Rep.
                </h6>
                <div className="flex items-center gap-x-1">
                  <img
                    src={defaultimage}
                    className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                  {/* IF NO IMAGE FOUND BELOW COMMENTED CODE WILL BE USED */}
                  {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                WA
              </div> */}
                  <p className="text-sm">Olivia Rhye</p>
                </div>
              </div>
              <div className="sm:min-w-[17%] min-w-full">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Created At
                </h6>
                <p>-</p>
              </div>
              <div className="sm:min-w-[17%] min-w-full relative">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  From
                </h6>

                <p>-</p>
              </div>
              <div className="sm:min-w-[17%] min-w-full">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  To
                </h6>
                <p>-</p>
              </div>
            </div>
            <div className="pt-5 pb-4 flex items-center flex-wrap gap-x-2 border-t border-utilityGray200">
              <div className="flex-1">
                <h6 className="text-grayLight900 text-base font-semibold">
                  Overview
                </h6>
                <p className="text-grayLight600 text-sm font-medium">
                  Track and Review Every Detail of Your Order
                </p>
              </div>
              <div className="flex flex-wrap gap-2">
                <ButtonCmp
                  className="btn-outline-primary"
                  icon={<Plus className="w-4 h-4" />}
                >
                  Add Stop
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black min-w-[80px]"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </ButtonCmp>
              </div>
            </div>
            <TabButton
              parentClassName="!bg-gray50 !w-full "
              className={`!rounded-md !border-0 [&>span]:text-gray400 min-w-[200px]`}
              activeClassName=" !bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
              tabArray={filterArr}
              tabParentClassName="mb-4"
              handleOnClick={() => {}}
              isActive={'order_first'}
              isTab={true}
            />
            <ul className="flex flex-col gap-y-5">
              <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
                <AddressDetails action={action} setAction={setAction} />
              </li>
              <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
                <AppointmentDetails
                  action={action}
                  setAction={setAction}
                  orderId={id}
                />
              </li>
              <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
                <AddOrderDetails />
              </li>
              <SalePrice />
              <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
                <AssignCarrier />
              </li>
            </ul>
          </div>
          <div className="flex flex-col bg-gray25">
            <div className="pl-5 py-5 border-b border-l border-utilityGray200">
              <div className="rounded-lg relative h-[190px] overflow-hidden">
                <div className="h-full bg-gray200">Map</div>
                <div className="flex gap-1.5 items-center mt-2 px-1.5 py-1 rounded shadow-sm bg-white absolute bottom-2.5 left-2.5">
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <span className="text-xs text-grayLight900 font-medium">
                    Last update: Today, 11:03 AM
                  </span>
                </div>
                <Link
                  to={ROUTES.HOME}
                  className="flex gap-1.5 items-center px-1.5 py-1 rounded-md border border-borderPrimary bg-white absolute top-2.5 right-2.5"
                >
                  <Target05 className="w-4 h-4 text-grayLight600" />
                  <p className="text-textSecondary text-xs font-medium">
                    Open in Radar
                  </p>
                </Link>
              </div>
            </div>
            <InternalChatAndNotes
              // claim={claim}
              isClaim={true}
              notesCount={<></>}
              setNotesCount={<></>}
              isShowChatAndNotesSideBar={<></>}
              setIsShowChatAndNotesSideBar={<></>}
              chatWrapClassName="overflow-x-auto"
            />
          </div>
        </div>
      </FormProvider>

      {isCreateOrder && <CreateOrder setIsCreateOrder={setIsCreateOrder} />}
      {isOrderRecurringModal && (
        <RecurringOrderModal
          handleClose={() => setIsOrderRecurringModal(false)}
        />
      )}
    </PageSectionLayout>
  );
};

export default OrderDetails;
