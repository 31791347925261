import { Check, TextQuote, TrashIcon } from 'lucide-react';
import { useEditor } from 'novel';
import React from 'react';

import { CommandGroup, CommandItem, CommandSeparator } from '../ui/command';

const AICompletionCommands = ({
  completion,
  onDiscard,
}: {
  completion: string;
  onDiscard: () => void;
}) => {
  const { editor }: any = useEditor();

  return (
    <>
      <CommandGroup>
        <CommandItem
          className="gap-2 px-4"
          value="replace"
          onClick={() => {
            const selection = editor.view.state.selection;

            let newMessage = completion;

            if (completion.match(/"([^"]*)"/)) {
              let match = completion.match(/"([^"]*)"/);
              newMessage = match ? match[1]?.replace(/[.""]$/, '') : completion;
            }

            if (completion.match(/\*(.*?)\*/)) {
              let match = completion.match(/\*\*(.*?)\*\*/);
              newMessage = match ? match[1]?.replace(/[.""]$/, '') : completion;
            }

            if (completion.match(/\*\*(.*?)\*\*/)) {
              let match = completion.match(/\*\*(.*?)\*\*/);
              newMessage = match ? match[1]?.replace(/[.""]$/, '') : completion;
            }

            editor
              .chain()
              .focus()
              .insertContentAt(
                {
                  from: selection.from,
                  to: selection.to,
                },
                newMessage
              )
              .run();
          }}
        >
          <Check className="h-4 w-4 text-muted-foreground" />
          Replace selection
        </CommandItem>
        <CommandItem
          className="gap-2 px-4"
          value="insert"
          onClick={() => {
            const selection = editor.view.state.selection;
            editor
              .chain()
              .focus()
              .insertContentAt(selection.to + 1, completion)
              .run();
          }}
        >
          <TextQuote className="h-4 w-4 text-muted-foreground" />
          Insert below
        </CommandItem>
      </CommandGroup>
      <CommandSeparator />

      <CommandGroup>
        <CommandItem onClick={onDiscard} value="thrash" className="gap-2 px-4">
          <TrashIcon className="h-4 w-4 text-muted-foreground" />
          Discard
        </CommandItem>
      </CommandGroup>
    </>
  );
};

export default AICompletionCommands;
