import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import _, { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { AuthContext } from 'src/context/AuthContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import { listLoadInsurance } from 'src/services/LoadInsurance';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetter,
  downloadPdf,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../assets/img/Avatar7.png';

import LoadInsuranceLoading from './LoadInsuranceLoading';
import LoadInsuranceRow from './LoadInsuranceRow';
import RequestLoadInsurance from './RequestLoadInsurance';

const initAction = {
  mode: null,
  carrier: false,
};

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  status: 'all',
};

const filterArr: any = [
  {
    value: 'all',
    name: 'View All',
  },
  {
    value: 'Open',
    name: 'Open',
  },
  {
    value: 'Approved',
    name: 'Approved',
  },
  {
    value: 'Declined',
    name: 'Declined',
  },
];

const LoadInsurance = () => {
  const { hasRoleV2 } = useRolePermission();
  const [params, setParams] = useState<any>(initParams);
  // const [orderList, setOrderList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [userList, setUserList] = useState<any[]>([]);

  const [search, setSearch] = useState('');
  const [totals, setTotals] = useState(0);
  const [isRequestLoadInsurance, setIsRequestLoadInsurance] = useState(true);

  const [loadInsuranceList, setLoadInsuranceList] = useState<any>([]);
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const { entityType, setEntityType } = useContext(NotificationsContext);
  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);

  const headCells = useMemo(
    () => [
      {
        id: 'loadInsuranceId',
        name: 'Users',
        sortable: false,
        visible: true,
      },
      {
        id: 'Status',
        name: 'Status',
        sortable: true,
        visible: true,
      },
      {
        id: 'orderId',
        name: 'Order ID',
        sortable: false,
        visible: true,
      },
      {
        id: 'createdAt',
        name: 'Created Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'commodity',
        name: 'Commodity',
        sortable: true,
        visible: true,
      },
      {
        id: 'commercial',
        name: 'Commercial Value',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        name: '',
        visible: true,
      },
    ],
    []
  );
  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (currentUser?.roles && currentUser?.roles.length > 0) {
      const hasSalesOrOperations = currentUser?.roles.some(
        (role: any) => role.name === 'sales' || role.name === 'operations'
      );
      setIsRequestLoadInsurance(hasSalesOrOperations);
    } else if (
      (!currentUser?.roles || currentUser?.roles.length === 0) &&
      (currentUser?.role === 'sales' || currentUser?.role === 'operations')
    ) {
      setIsRequestLoadInsurance(true);
    }
  }, [currentUser]);

  const recordsPerPageArray = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      const unassignedSelectBoxOption = {
        value: 'unassigned',
        label: 'Unassigned',
        image: null,
        isImage: false,
      };

      if (hasRoleV2('admin')) {
        setUserList([unassignedSelectBoxOption, ...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  // useEffect(() => {
  //   getOrders()
  //     .then((response) => {
  //       // const orders = getSelectBoxOptions(response.data, 'fullId', 'fullId');
  //       setOrderList(response.data);
  //     })
  //     .catch(() => WalToast.error('orders not fetched'));
  // }, []);

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchCustomer') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null, data: any = {}) =>
    () => {
      console.log(actionKey, ' ', mode, ' data :>> ', data);

      if (mode === 'request') {
        if (isRequestLoadInsurance)
          setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
        else
          WalToast.error(
            "Unable to request load insurance. Please switch to a Sales or Operations profile, or create one if you don't have it yet."
          );
      } else {
        setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
      }
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const getLoadInsuranceList = (signal?: any) => {
    setIsLoading(true);
    setLoadInsuranceList([]);
    listLoadInsurance(params, signal)
      .then((response: any) => {
        setLoadInsuranceList(response.data);
        setTotals(response.total);
        setIsLoading(false);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        console.error(e?.response?.message);
      });
  };

  useEffect(() => {
    const listApiController = new AbortController();
    const listApiSignal = listApiController.signal;
    getLoadInsuranceList(listApiSignal);

    return () => {
      listApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    if (entityType === 'load_insurance_created') {
      getLoadInsuranceList();
      setEntityType(null);
    }
  }, [entityType]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Load Insurance"
          desc="Manage, segment and view your load insurance details here."
          mainContainerClassName="md:flex-nowrap"
          isShowNotificationBox
        />
      }
    >
      <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  ">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">
                {capitalizeFirstLetter(params.status)} Load Insurance Requests
              </h5>
              <BadgeCmp
                style="modern"
                mainClassName={isLoading ? 'custom-loading' : ''}
                type="success"
              >
                {totals} Insurance
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              View and manage load insurance requests for your orders.
            </p>
          </div>

          {!isLoading && (
            <ButtonCmp
              type="button"
              className="btn-outline-primary  flex smd:w-fit w-full"
              onClick={handleActionType('Load Insurance', 'request')}
              iconSide="left"
              icon={<Plus className="text-primary w-4 h-4" />}
            >
              Request Load Insurance
            </ButtonCmp>
          )}
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
              <TabButton
                tabArray={filterArr}
                parentClassName=""
                className=""
                activeClassName="!bg-utilityGray100"
                isActive={params.status}
                handleOnClick={(e: any) => {
                  setParams((old: any) => ({
                    ...old,
                    ...{ status: e?.target?.dataset?.value, page: 1 },
                  }));
                }}
              />
            </div>
          </div>
          <InputText
            inputName="searchCustomer"
            placeholder="Search"
            className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
            icon={
              <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
            }
            value={search}
            inputType="text"
            onChangeFunc={handleFilter}
            parentClassName="table-searchInput"
            isClearable={true}
          />
          {!hasRoleV2('user') && (
            <>
              <div className="table-selectbox xl:block hidden">
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  options={teamListOptions}
                  isDisabled={params?.member === 'unassigned'}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      ...{
                        team: event?.value ?? 'all',
                        member: 'all',
                        page: 1,
                      },
                    }));
                  }}
                  value={teamListOptions.filter(
                    (val: any) => params.team === val.value
                  )}
                />
              </div>
              <div className="table-selectbox xl:block hidden">
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  noOptionMessage="No Users Found"
                  isSearchable={true}
                  className="form_control"
                  isClearable={true}
                  options={userList}
                  onChangeFunc={(event: any) => {
                    if (event?.value === 'unassigned') {
                      setParams((old: any) => ({
                        ...old,
                        ...{
                          team: 'all',
                          member: event?.value ?? 'all',
                          page: 1,
                        },
                      }));
                    } else {
                      setParams((old: any) => ({
                        ...old,
                        ...{ member: event?.value ?? 'all', page: 1 },
                      }));
                    }
                  }}
                  value={userList.filter(
                    (user: any) => params.member === user.value
                  )}
                />
              </div>
            </>
          )}
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div
          data-test-id="userTest"
          className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col"
        >
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              headCells={headCells}
              tableDataArr={loadInsuranceList}
              params={params}
              setParams={setParams}
              TableLoaderRowCmp={LoadInsuranceLoading}
              TableRowCmp={LoadInsuranceRow}
              tableRowCmpProps={{
                dowloadPdf: (rowData: any) => {
                  downloadPdf(`${rowData?.pdfUrl}${rowData.pdfName}`);
                },
                handleActionType: handleActionType,
              }}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={12}
              isTableRowClickable={true}
              // onRowClick={(data: any) =>
              //   navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)
              // }
            />
          </div>
          {!isLoading && !loadInsuranceList.length && (
            <NotFoundUI
              title="No Load Insurance Found"
              desc="There are no data for load insurance."
              containerClassName="min-h-[unset]"
            />
          )}
        </div>
        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
        {action.mode === 'request' ? (
          <RequestLoadInsurance
            // orderData={orderList}
            handleClose={() => {
              getLoadInsuranceList();
              handleDrawerClose();
            }}
          />
        ) : (
          ''
        )}
      </div>
    </PageSectionLayout>
  );
};
export default LoadInsurance;
