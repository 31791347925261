import {
  ChevronDown,
  ChevronLeftDouble,
  ChevronUp,
  Eye,
  EyeOff,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';
import { customFromNow, getShortName } from 'src/utils/CommonFunctions';

import MessageListCloseLoading from './Skeleton/MessageListCloseLoading';
import MessageListLoading from './Skeleton/MessageListLoading';

interface IProps {
  initialLoading: boolean;
  loading: boolean;
  loadMore: boolean;
  isLoadingMore: boolean;
  emailData: any[];
  selectedEmail: any;
  scrollContainerRef: any;
  userEmail: string;
  params: any;
  setParams: any;
  isEmailToggle: boolean;
  setIsEmailToggle: React.Dispatch<React.SetStateAction<boolean>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  totalEmails: number;
  handleSelectedEmail: (emailDetails: any) => void;
  deletingEmails: {
    [key: string]: boolean;
  };
  handleDeleteQuote: (item: any) => Promise<void>;
}

const MessageListSection = ({
  initialLoading,
  loading,
  loadMore,
  isLoadingMore,
  emailData,
  selectedEmail,
  scrollContainerRef,
  userEmail,
  params,
  setParams,
  isEmailToggle,
  setIsEmailToggle,
  search,
  setSearch,
  totalEmails,
  handleSelectedEmail,
  deletingEmails,
  handleDeleteQuote,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1549);
  const [openThreads, setOpenThreads] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleThread = (emailId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenThreads((prev) => ({
      ...prev,
      [emailId]: !prev[emailId],
    }));
  };

  const toggleEmail = () => {
    setIsEmailToggle(true);
    setParams((old: any) => ({
      ...old,
      page: 1,
      isQuoteEmail: !params?.isQuoteEmail,
    }));
  };

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    setIsEmailToggle(true);
    const { value } = event.target;
    setSearch(value);
    searchDebounce(value);
  };

  return (
    <>
      {initialLoading ? (
        <MessageListLoading />
      ) : (
        <>
          <div
            className={` border-r border-utilityGray200 flex-col justify-start items-start flex transition-all duration-[0.5s] max-xlm:absolute max-xlm:bg-white max-xlm:z-[1] max-sm:w-full h-full ${
              isOpen ? 'xlm:w-[25%] sm:w-[320px]' : 'xlm:w-[69px] sm:w-[69px]'
            }`}
          >
            <div className={`w-full px-4 py-5 items-center flex `}>
              <div
                className={`flex items-center transition-all duration-[0.5s]  ${
                  isOpen
                    ? 'sm:w-[calc(100%_-_36px)] w-full'
                    : 'w-0 invisible opacity-0'
                }`}
              >
                <div className={`flex flex-col mr-2 truncate flex-1`}>
                  <div className={`flex gap-2 items-center`}>
                    <h5
                      className={`text-grayLight900 text-lg leading-5 font-semibold`}
                    >
                      {params?.isQuoteEmail ? 'Inbox' : 'Other'}
                    </h5>
                    <BadgeCmp
                      type="primary"
                      style="modern"
                      isHidePillDot
                      mainClassName={`${
                        loading ? 'custom-loading' : ''
                      } bg-utilityPurple50 border-utilityPurple200`}
                      badgeTextColor="!text-primary fon-medium"
                    >
                      {totalEmails}
                    </BadgeCmp>
                  </div>
                  <p
                    className={`text-xs leading-[18px] font-medium text-gray500  max-w-60 truncate mt-1`}
                  >
                    {userEmail}
                  </p>
                </div>
                {/* {emailData.length != 0 && ( */}
                <div className="sm:mr-3">
                  <TooltipCmp
                    message={params?.isQuoteEmail ? 'Other' : 'Inbox'}
                    childrenClassName="!block"
                  >
                    <ButtonCmp
                      className={`${
                        params?.isQuoteEmail
                          ? 'btn-outline-primary'
                          : 'btn_secondary_black'
                      } lg:px-[9px] px-2 `}
                      onClick={toggleEmail}
                    >
                      {params?.isQuoteEmail ? (
                        <Eye className={`w-4 h-4`} />
                      ) : (
                        <EyeOff className={`w-4 h-4`} />
                      )}
                    </ButtonCmp>
                  </TooltipCmp>
                </div>
                {/* )} */}
              </div>
              <ButtonCmp
                className="btn_secondary_black lg:px-[9px] px-2 max-sm:hidden"
                onClick={toggleSidebar}
                icon={
                  <ChevronLeftDouble
                    className={`w-4 h-4 ${isOpen ? '' : 'rotate-180'}`}
                  />
                }
              >
                <></>
              </ButtonCmp>
            </div>

            <div
              onClick={() => !isOpen && toggleSidebar()}
              className={`w-full ${!isOpen ? 'cursor-pointer' : ''}`}
            >
              <InputText
                inputName="search"
                placeholder="Search"
                className={`
                        cursor-pointer bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input
                        ${isOpen ? '' : '!pr-0 text-[0]'}
                      `}
                icon={
                  <SearchLg className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                isClearable
                inputType="text"
                parentClassName="w-full px-4 pb-3 "
                onChangeFunc={handleFilter}
                inputWrapClass={`!justify-start [&>svg:nth-child(3)]:transition-all [&>svg:nth-child(3)]:duration-[0.5s] ${
                  isOpen ? ' ' : '[&>svg:nth-child(3)]:opacity-0'
                }`}
              />
            </div>

            <div
              className="w-full flex-1 overflow-auto custom-scrollbar-v2 overflow-x-hidden"
              ref={scrollContainerRef}
            >
              {(!loading || loadMore) &&
                emailData?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="last:border-b-0 border-b border-utilityGray200"
                  >
                    {/* {isOpen ? ( */}
                    <>
                      <div
                        className={`p-4 flex items-center cursor-pointer ${
                          item?.emailBody?.id === selectedEmail?.emailBody?.id
                            ? 'bg-gray50'
                            : ''
                        }`}
                        key={index}
                        onClick={() => handleSelectedEmail(item)}
                      >
                        <div
                          key={index}
                          className={`w-9 h-9 rounded-full bg-utilityGray100 text-primary text-sm flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative shadow-lg `}
                        >
                          {getShortName(
                            `${item?.emailBody?.from?.emailAddress?.name}`
                          )}
                          <p
                            className={` ${
                              item?.isRead
                                ? ''
                                : 'w-2 h-2 bg-primary500 rounded-full -left-[0px] top-0.5 absolute'
                            }`}
                          ></p>
                        </div>

                        {/* <p
                                  className={` ${
                                    item?.isRead
                                      ? 'w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start'
                                      : 'w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start'
                                  }`}
                                ></p> */}
                        <div
                          className={`flex-1 ml-3  transition-all duration-[0.5s] ${
                            isOpen ? ' ' : 'text-[0] opacity-0 invisible'
                          } `}
                        >
                          <div className="flex items-center gap-x-1">
                            <h6
                              className={`text-sm text-textSecondary line-clamp-1 ${
                                item?.isRead ? 'font-medium' : 'font-bold'
                              }`}
                            >
                              {item?.emailBody?.from?.emailAddress?.name}
                            </h6>
                            {/* EMAIL THREAD COUNT */}
                            {item?.thread?.length > 1 && (
                              <div
                                className="flex items-center gap-x-0.5 px-1 rounded border border-utilityGray200 bg-utilityGray100 text-[10px] leading-[1.6]"
                                onClick={(e) => toggleThread(item.id, e)}
                              >
                                {item?.thread?.length}
                                {openThreads[item.id] ? (
                                  <ChevronUp className="w-3 h-3" />
                                ) : (
                                  <ChevronDown className="w-3 h-3" />
                                )}
                              </div>
                            )}
                          </div>
                          <p
                            className={`text-xs leading-[18px] font-medium line-clamp-1 ${
                              item?.isRead
                                ? 'text-gray500 '
                                : 'text-textSecondary font-semibold'
                            }`}
                          >
                            {`${item?.emailBody?.bodyPreview?.substring(
                              0,
                              40
                            )} ...`}
                          </p>
                        </div>
                        <div
                          className={`text-xs text-grayLight600 ml-1.5 transition-all duration-[0.5s] ${
                            isOpen ? ' ' : 'text-[0] opacity-0 invisible'
                          }  ${item?.isRead ? 'font-normal' : 'font-bold'}`}
                        >
                          {/* {moment(item?.emailBody?.receivedDateTime).fromNow(
                                    true
                                  )} */}
                          {customFromNow(item?.createdAt)}
                        </div>
                        <ButtonCmp
                          className="btn_secondary_black lg:px-[9px] px-2 ml-1.5"
                          icon={
                            deletingEmails[item.id] ? (
                              <span className="loading loading-spinner loading-xs"></span>
                            ) : (
                              <XClose className="w-4 h-4" />
                            )
                          }
                          disabled={deletingEmails[item.id]}
                          onClick={() => handleDeleteQuote(item)}
                        >
                          <></>
                        </ButtonCmp>
                      </div>
                      {/* THREAD EMAIL START */}
                      {item?.thread?.length > 1 && openThreads[item.id] && (
                        <div
                          className={`${openThreads[item.id] ? '' : 'hidden'}`}
                        >
                          {item?.thread?.map(
                            (threadItem: any, threadIndex: number) => (
                              <div key={threadIndex}>
                                <div
                                  className={`p-4 flex items-center cursor-pointer ${
                                    threadItem?.emailBody?.id ===
                                    selectedEmail?.emailBody?.id
                                      ? 'bg-utilityGray100'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    handleSelectedEmail(threadItem)
                                  }
                                >
                                  {!isOpen && (
                                    <div
                                      // key={index}
                                      className={`w-7 h-7 rounded-full bg-utilityGray100 text-primary text-sm flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative shadow-lg `}
                                    >
                                      {getShortName(
                                        `${item?.emailBody?.from?.emailAddress?.name}`
                                      )}
                                      <p
                                        className={` ${
                                          item?.isRead
                                            ? ''
                                            : 'w-2 h-2 bg-primary500 rounded-full -left-[0px] top-0.5 absolute'
                                        }`}
                                      ></p>
                                    </div>
                                  )}
                                  <div className={`w-9 h-9 invisible `}></div>
                                  <div
                                    className={`flex-1 ml-3  transition-all duration-[0.5s] ${
                                      isOpen
                                        ? ' '
                                        : 'text-[0] opacity-0 invisible'
                                    } `}
                                  >
                                    <h6
                                      className={`text-sm text-textSecondary line-clamp-1 ${
                                        threadItem?.isRead
                                          ? 'font-medium'
                                          : 'font-bold'
                                      }`}
                                    >
                                      {
                                        threadItem?.emailBody?.from
                                          ?.emailAddress?.name
                                      }
                                    </h6>
                                    <p
                                      className={`text-xs leading-[18px] font-medium line-clamp-1 ${
                                        threadItem?.isRead
                                          ? 'text-gray500'
                                          : 'text-textSecondary font-semibold'
                                      }`}
                                    >
                                      {`${threadItem?.emailBody?.bodyPreview?.substring(
                                        0,
                                        40
                                      )} ...`}
                                    </p>
                                  </div>
                                  <div
                                    className={`text-xs text-grayLight600 ml-1.5 transition-all duration-[0.5s] ${
                                      isOpen
                                        ? ' '
                                        : 'text-[0] opacity-0 invisible'
                                    }  ${
                                      threadItem?.isRead
                                        ? 'font-normal'
                                        : 'font-bold'
                                    }`}
                                  >
                                    {customFromNow(threadItem?.createdAt)}
                                  </div>
                                  <ButtonCmp
                                    className="btn_secondary_black lg:px-[9px] px-2 ml-1.5 invisible"
                                    icon={<XClose className="w-4 h-4" />}
                                  >
                                    <></>
                                  </ButtonCmp>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                      {/* THREAD EMAIL END */}
                    </>
                  </div>
                ))}
              {(loading || loadMore) && isOpen && (
                <MessageListLoading
                  isLoadMore={loadMore}
                  loading={loading}
                  isEmailToggle={isEmailToggle}
                  isLoadingMore={isLoadingMore}
                />
              )}
              {(loading || loadMore) && !isOpen && (
                <MessageListCloseLoading isLoadMore={loadMore} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MessageListSection;
