import { Send01, XClose } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { TIMEZONE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import {
  getChatMessageList,
  markMessageAsRead,
  sendMessage,
} from 'src/services/ClaimAndDisputeService';
import { getShortName } from 'src/utils/CommonFunctions';

import InternalChatCard from './InternalChatCard';
import InternalChatsSkeletonLoader from './InternalChatsSkeletonLoader';

const groupMessages = (messagesObj: any[]) => {
  const groupedMessages: any[] = [];
  let currentGroup: any = null;
  let isUnreadGroup: any = true;

  messagesObj.forEach((message) => {
    if (message.special) {
      // Push the current group before the special message
      if (currentGroup) {
        groupedMessages.push(...currentGroup);
        currentGroup = null; // Reset currentGroup after pushing
      }
      // Add the special message
      groupedMessages.push({ ...message, showSenderAndDate: false });

      return; // Break the group after the special message
    }

    const messageTime = new Date(message.createdAt).getTime();
    const lastMessageInGroupTime = currentGroup
      ? new Date(currentGroup[currentGroup.length - 1].createdAt).getTime()
      : null;

    if (message.isUnread) {
      if (isUnreadGroup) {
        if (currentGroup) {
          groupedMessages.push(...currentGroup);
        }

        currentGroup = null;
        isUnreadGroup = false;
      }

      if (
        currentGroup &&
        message?.sender?.id === currentGroup[0]?.sender?.id &&
        lastMessageInGroupTime &&
        messageTime - lastMessageInGroupTime <= 5 * 60 * 1000
      ) {
        currentGroup.push({
          ...message,
          showSenderAndDate: false,
        });
      } else {
        if (currentGroup) {
          groupedMessages.push(...currentGroup);
        }
        currentGroup = [
          {
            ...message,
            showSenderAndDate: true,
          },
        ];
      }

      return;
    }

    if (
      currentGroup &&
      message?.sender?.id === currentGroup?.[0]?.sender?.id &&
      lastMessageInGroupTime &&
      messageTime - lastMessageInGroupTime <= 5 * 60 * 1000
    ) {
      // Add to the current group if the sender is the same and within 5 minutes
      currentGroup.push({ ...message, showSenderAndDate: false });
    } else {
      // Push the current group if it exists
      if (currentGroup) {
        groupedMessages.push(...currentGroup);
      }

      // Start a new group, always show the sender and date, even for the current user
      currentGroup = [{ ...message, showSenderAndDate: true }];
    }
  });

  // Add any remaining group
  if (currentGroup) {
    groupedMessages.push(...currentGroup);
  }

  return groupedMessages;
};

interface IProps {
  data: any;
  typingUsers: any;
  members: any;
  messages: any;
  setMessages: any;
  setMembers: any;
  currentTab: any;
  setCurrentTab: any;
  setIsShowChatAndNotesSideBar: any;
  lastMessageId: any;
  setLastMessageId: any;
}

const InternalChat = ({
  data,
  typingUsers,
  members,
  messages,
  setMessages,
  setMembers,
  setIsShowChatAndNotesSideBar,
  lastMessageId,
  setLastMessageId,
}: IProps) => {
  const currentUser = useContext(AuthContext);
  let { appearance } = useContext(BasicContext);

  const [onlineMembers, setOnlineMembers] = useState<null | number>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateMessageLoading, setIsCreateMessageLoading] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(true);
  const [groupID, setGroupID] = useState<number | null>(null);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [isMakeReadMessage, setIsMakeReadMessage] = useState(false);
  const [lastMessageRecordId, setLastMessageRecordId] = useState<any>(null);

  const messageRefs = useRef([]);
  const perPage = 15;

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [messagesList, setMessagesList] = useState<any[]>([]);

  useEffect(() => {
    const groupedMsgList = groupMessages(messages);
    setMessagesList(groupedMsgList);
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;

      setTimeout(() => {
        const inputElement = document.getElementById('chatInput');

        if (inputElement) {
          inputElement.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (page === 2) scrollToBottom();
  }, [messagesList, page]);

  const memberNames = members.map((member: any) => member.firstName).join(', ');

  const { handleSubmit, control, reset, watch } = useForm({});

  const messageWatcher = watch('message');
  const groupIDRef = useRef(groupID);
  const typeRef = useRef('loadInsurance');
  const entityIdRef = useRef(data.id);
  const lastMessageIdRef = useRef(lastMessageId);
  const lastMessageRecordIdRef = useRef(lastMessageRecordId);

  useEffect(() => {
    groupIDRef.current = groupID;
    typeRef.current = 'load_insurance';
    entityIdRef.current = data.id;
    lastMessageIdRef.current = lastMessageId;
    lastMessageRecordIdRef.current = lastMessageRecordId;
  }, [groupID, data.id, lastMessageId, lastMessageRecordId]);

  const getClaimAndDisputeChatMessageList = (
    orderID: number,
    entityID: number,
    pageCount: number
  ) => {
    setIsLoading(true);

    getChatMessageList({
      orderID,
      entityID,
      pageCount,
      perPage,
      isLoadInsurance: true,
    })
      .then((response: any) => {
        if (response && response.data) {
          const responseData = response.data;
          setMembers(responseData.members);

          setGroupID(responseData.groupId);

          if (
            responseData.messages.length === 0 ||
            responseData.messages.length < perPage
          ) {
            setIsMoreLoading(false);
          }

          // setMessages([...responseData.messages, ...messages]);
          setMessages((prevMessages: any) => [
            ...responseData.messages,
            ...prevMessages,
          ]);

          setPage((prevPage) => prevPage + 1);
          setHasUnreadMessages(responseData.hasUnreadMessages);
          setLastMessageId(responseData.lastMessageId);
          setLastMessageRecordId(responseData?.lastReadRecord?.message);

          if (responseData?.lastReadRecord?.message) {
            if (
              responseData.messages.some(
                (message: any) =>
                  message.id === responseData.lastReadRecord.message
              )
            ) {
              setIsMakeReadMessage(true);
            }
          } else {
            if (
              responseData.messages.length === 0 ||
              responseData.messages.length < perPage
            ) {
              setIsMakeReadMessage(true);
            }
          }

          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight -
              chatContainerRef.current.clientHeight;
          }
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const countOfOnlineMembers = members?.filter(
      (member: any) => member.user_status === 'online'
    )?.length;
    setOnlineMembers(countOfOnlineMembers);
  }, [members]);

  const fetchMoreMessages = async (pageCount: any) => {
    try {
      if (!isLoading && isMoreLoading) {
        getClaimAndDisputeChatMessageList(data.orderID, data.id, pageCount);
      }
    } catch (error) {
      console.error('Failed to load older messagesList:', error);
    }
  };

  const debouncedFetchMoreMessages = useCallback(
    debounce(fetchMoreMessages, 300),
    [page]
  );

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleWheel = () => {
      if (chatContainer && chatContainer.scrollTop === 0 && !isLoading) {
        if (data.orderID) {
          debouncedFetchMoreMessages(page);
        }
      }
    };

    if (chatContainer) {
      chatContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('wheel', handleWheel);
      }
      debouncedFetchMoreMessages.cancel();
    };
  }, [data.orderID, page, isLoading]);

  useEffect(() => {
    if (data.fullId) {
      getClaimAndDisputeChatMessageList(data.fullId, data.id, page);
    }
  }, [data.fullId]);

  const sendMessageToUsers = (formData: any) => {
    const id = data.id;
    setIsCreateMessageLoading(true);

    sendMessage({
      id,
      orderID: data.fullId,
      groupId: groupID,
      senderID: currentUser.currentUser.id,
      content: formData,
      type: 'load_insurance',
    })
      .then((response: any) => {
        if (response && response.data) {
          reset({ message: '' });
          scrollToBottom();
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsCreateMessageLoading(false);
      });
  };

  const HandleChange = async (formData: any) => {
    if (formData.message && formData.message != '') {
      sendMessageToUsers(formData);
    }
  };

  const getFormattedDateTime = (date: any, convertTimezone = false) => {
    let momentDate = moment(date);

    if (convertTimezone) {
      momentDate = momentDate.utc(date).tz(appearance?.timezone ?? TIMEZONE);
    }

    const now = moment();
    const diffInSeconds = now.diff(momentDate, 'seconds');

    if (diffInSeconds < 60) {
      return 'a few seconds ago';
    }

    return momentDate.format('MMM Do YYYY, h:mm A');
  };

  const markMessagesAsRead = () => {
    const param = {
      groupId: groupIDRef.current,
      type: typeRef.current,
      entityId: entityIdRef.current,
      lastMessageId: lastMessageIdRef.current,
    };

    markMessageAsRead(param)
      .then(() => {
        const updatedMessages = messages.map((message: any) => ({
          ...message,
          isUnread: false,
        }));
        setIsLoading(false);
        setMessages(updatedMessages);
        setHasUnreadMessages(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (hasUnreadMessages && messages.length > 0 && isMakeReadMessage) {
      setTimeout(() => {
        markMessagesAsRead();
      }, 5000);
    }
  }, [hasUnreadMessages, messages, isMakeReadMessage]);

  useEffect(() => {
    setMessages([]);

    return () => {
      if (
        (lastMessageIdRef.current &&
          lastMessageRecordIdRef.current &&
          lastMessageIdRef.current > lastMessageRecordIdRef.current) ||
        (lastMessageIdRef.current && !lastMessageRecordIdRef.current)
      ) {
        markMessagesAsRead();
      }
    };
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <div className="p-5 pb-4 border-b border-utilityGray200">
        <div className="flex justify-between gap-1">
          <div className="flex items-center flex-wrap">
            <h6 className="mr-1 text-grayLight900 text-md font-semibold">
              Internal Chat
            </h6>

            <BadgeCmp
              style="modern"
              type="success"
              mainClassName={`${isLoading ? 'custom-loading' : ''}`}
            >
              {onlineMembers && onlineMembers} Online
            </BadgeCmp>
          </div>
          <div
            className="cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full xl:hidden"
            onClick={() => setIsShowChatAndNotesSideBar(false)}
          >
            <XClose className="w-4 h-4 text-gray400 hover:text-textSecondary" />
          </div>
        </div>

        <div className="flex flex-wrap text-grayLight600 font-normal text-xs mb-4">
          {!isLoading ? (
            memberNames && memberNames
          ) : (
            <>
              <span className="custom-loading">Zaheer,</span>
              <span className="custom-loading"> Jet,</span>
              <span className="custom-loading"> Subir,</span>
              <span className="custom-loading"> Subir,</span>
              <span className="custom-loading"> Jordan</span>
            </>
          )}
        </div>
      </div>

      <div
        className="p-5 flex-1 overflow-auto custom-scrollbar-v2 flex"
        ref={chatContainerRef}
      >
        <ul className="w-full mt-auto">
          {isLoading && <InternalChatsSkeletonLoader />}

          {messagesList &&
            messagesList.length > 0 &&
            messagesList.map((message: any, index: number) => (
              <InternalChatCard
                internalChat={message}
                currentUser={currentUser}
                getFormattedDateTime={getFormattedDateTime}
                key={index}
                messageRefs={messageRefs}
              />
            ))}
          {typingUsers.length > 0 &&
            typingUsers.map((user: any, index: number) => (
              <li className="flex mt-4 gap-3 max-w-[305px]" key={index}>
                <div className="w-7 h-7 flex-none relative">
                  {user.imageUrl ? (
                    <img
                      src={user.imageUrl + user.image}
                      className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary"
                    />
                  ) : (
                    <div className="w-7 h-7 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none  mr-2 text-xs">
                      {getShortName(`${user?.firstName} ${user?.lastName}`)}
                    </div>
                  )}
                  <span className="border border-white rounded-full bg-successSecondary w-2 h-2 absolute bottom-0 right-0"></span>
                </div>
                <div>
                  <div className="flex items-center w-full mb-1.5">
                    <p className="text-grayLight600 font-normal text-xs">
                      {user.firstName} {user.lastName},&nbsp;
                    </p>
                    <p className="text-grayLight600 font-normal text-xs">
                      Just now
                    </p>
                  </div>

                  <div className="rounded-lg rounded-tl-none border border-utilityGray200 px-2.5 py-1.5 text-grayLight900 font-normal text-xs mb-2 bg-gray50 w-fit">
                    <div className="flex gap-1.5 typing-animation min-h-[14px] items-center">
                      <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
                      <div className="w-1 h-1 rounded-full  bg-gray400"></div>
                      <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <form onSubmit={handleSubmit(HandleChange)}>
        <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="message"
                placeholder="Message"
                id="chatInput"
                className=""
                value={value}
                onChangeFunc={onChange}
                parentClassName="flex-1"
              />
            )}
          />
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            disabled={!messageWatcher || isLoading || isCreateMessageLoading}
            loading={isCreateMessageLoading}
          >
            <Send01 className="w-4 h-4" />
          </ButtonCmp>
        </div>
      </form>
    </div>
  );
};

export default InternalChat;
