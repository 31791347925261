import { UserPlus01 } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';

import CommonModal from '../../../components/CommonModal';
import Loader from '../../../components/Loader';
import { AuthContext } from '../../../context/AuthContext';
import { listPermission } from '../../../services/PermissionService';
import { listRole } from '../../../services/RoleService';
import {
  createUser,
  getUserDetail,
  updateUserPermission,
} from '../../../services/TeamMemberService';
import WalToast from '../../../utils/WalToast';

import ModulePermissionCard from './ModulePermissionCard';

interface IProps {
  handleClose: () => void;
  team: any;
  userCreateFormData?: any;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccessControlModal = ({
  handleClose,
  team,
  userCreateFormData = {},
  setIsRefresh,
}: IProps) => {
  const { reloadUser } = useContext(AuthContext);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [isUserDetailLoading, setIsUserDetailLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState({});
  const [teamPermissionsFromUserDetail, setTeamPermissionsFromUserDetail] =
    useState<number[]>([]);
  const [allowedPermissionAsPerRole, setAllowedPermissionAsPerRole] = useState(
    []
  );
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  useEffect(() => {
    if (!(Object.keys(team).length === 0 && team.constructor === Object)) {
      setIsUserDetailLoading(true);
      getUserDetail(team.id)
        .then((response: any) => {
          // if (Array.isArray(response.data?.permissions)) {
          //   const permissionArr = response.data?.permissions.map(
          //     (permission: any) => permission.id
          //   );
          //   setTeamPermissionsFromUserDetail(permissionArr);
          // } else if (typeof response.data?.permissionIds === 'object') {
          setTeamPermissionsFromUserDetail(response.data?.permissionIds);
          // }
        })
        .catch(console.log)
        .finally(() => setIsUserDetailLoading(false));
    } else {
      setTeamPermissionsFromUserDetail(userCreateFormData?.permissions);
    }

    listPermission()
      .then((response: any) => {
        setPermissions(response.data);
      })
      .catch(console.log)
      .finally(() => setIsPermissionsLoading(false));

    listRole()
      .then((response: any) => {
        let roleId: any;

        if (
          !(
            Object.keys(userCreateFormData).length === 0 &&
            userCreateFormData.constructor === Object
          )
        ) {
          roleId = userCreateFormData.role;
        } else if (
          !(Object.keys(team).length === 0 && team.constructor === Object)
        ) {
          if (team.roles) {
            let roles = [];

            if (typeof team.roles === 'string') {
              roles = JSON.parse(team.roles);
            } else {
              roles = team.roles;
            }

            roleId = roles.map((role: any) => role.id);
          } else {
            roleId = team?.role?.id;
          }
        }

        let allowedPermissions: any = {};

        if (Array.isArray(roleId)) {
          roleId.forEach((id: any) => {
            const roleRec = response.data.find(
              (resRole: any) => resRole.id == id
            );

            if (roleRec) {
              allowedPermissions[roleRec.displayName] = roleRec.permissions.map(
                (prm: any) => prm.id
              );
            }
          });
        } else {
          const roleRec = response.data.find(
            (resRole: any) => resRole.id == roleId
          );

          allowedPermissions[roleRec.displayName] = roleRec.permissions.map(
            (prm: any) => prm.id
          );
        }

        setAllowedPermissionAsPerRole(allowedPermissions);
        setUserPermissionList(allowedPermissions);
      })
      .catch(console.log)
      .finally(() => setIsRoleLoading(false));
  }, []);

  useEffect(() => {
    if (teamPermissionsFromUserDetail?.length > 0) {
      setUserPermissionList(teamPermissionsFromUserDetail);
    }
  }, [teamPermissionsFromUserDetail]);

  const handleSaveUserWithPermission = () => {
    const permissionsArray = Object.entries(userPermissionList).reduce(
      (acc: any, [key, val]: [string, any]) => {
        const flattenedPermissions = Object.values(val).flat();

        acc[key] = flattenedPermissions;

        return acc;
      },
      {}
    );

    if (
      !(
        Object.keys(userCreateFormData).length === 0 &&
        userCreateFormData.constructor === Object
      )
    ) {
      // here create form data
      const newUserCreateFormData = {
        ...userCreateFormData,
        permissions: JSON.stringify(permissionsArray),
      };
      const appendFormData: any = new FormData();
      Object.entries(newUserCreateFormData).forEach((data) => {
        const [key, value] = data;

        if (key === 'team') {
          return;
        }
        appendFormData.append(key, value);
      });

      setIsLoading(true);

      // pushing image to last
      const imageValueOfFormToAppend = appendFormData.get('image');
      appendFormData.delete('image');
      appendFormData.append('image', imageValueOfFormToAppend);

      createUser(appendFormData)
        .then(() => {
          setIsRefresh(true);
          reloadUser();
          handleClose();
          WalToast.success(
            `${userCreateFormData?.firstName} ${userCreateFormData?.lastName} has added to WALHQ`
          );
        })
        .finally(() => setIsLoading(false))
        .catch((e: any) => {
          console.log(e);
          WalToast.error(e?.response?.data?.message ?? 'New member not added');
        });
    } else if (
      !(Object.keys(team).length === 0 && team.constructor === Object)
    ) {
      setIsLoading(true);
      updateUserPermission(team.id, { permission: permissionsArray })
        .then(() => {
          setIsRefresh(true);
          handleClose();
          WalToast.success('User permissions updated');
        })
        .finally(() => setIsLoading(false))
        .catch((e: any) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Member permission not updated Successfully'
          );
        });
    }
  };

  const handleAccordionClick = (roleKey: string) => {
    if (openAccordion === roleKey) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(roleKey);
    }
  };

  return (
    <CommonModal
      title="Manage Member Permissions"
      titleDesc="Select the modules and submodules permissions to assign to the member."
      handleClose={handleClose}
      headerIcon={<UserPlus01 />}
      size={'max-w-3xl max-h-[83vh]'}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSaveUserWithPermission}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      primaryBtnLoading={isLoading}
    >
      <div className="h-full">
        <div className="flex flex-col justify-between h-full">
          <div className="w-full p-5">
            {/* <div className="pb-5 border-b border-utilityGray200 justify-start items-start gap-2.5">
                            <div className="text-grayLight900 text-[18px] font-semibold leading-7">Set User Permissions</div>
                        </div> */}
            {isPermissionsLoading || isUserDetailLoading || isRoleLoading ? (
              <div className="relative h-72">
                <Loader />
              </div>
            ) : (
              Object.entries(allowedPermissionAsPerRole).map(
                ([roleKey, rolePermissions]: [any, any[]], indexId: number) => (
                  <AccordionCmp
                    key={`role_${indexId}_${roleKey}`}
                    isOpen={openAccordion === roleKey}
                    className="[&:not(:first-child)]:mt-5 shadow-sm"
                    title={
                      <div className="xxl:gap-1 gap-1 flex flex-col w-[calc(100%_-_100px)]">
                        <div className="text-textSecondary text-base font-semibold">
                          {roleKey}
                        </div>
                        <div className="text-grayLight600 text-xs font-normal">
                          Select the {roleKey} modules and submodules
                          permissions to assign to the member.
                        </div>
                      </div>
                    }
                    contentClassName="!p-0"
                    handleClick={() => handleAccordionClick(roleKey)}
                  >
                    <div>
                      {permissions.map((permission: any, index: number) => (
                        <ModulePermissionCard
                          key={`ModulePermissionCard_${index}_${
                            permission.id ?? 'key'
                          }`}
                          teamId={team.id}
                          permission={permission}
                          setUserPermissionList={setUserPermissionList}
                          // teamPermissionsFromUserDetail={
                          //   teamPermissionsFromUserDetail
                          // }
                          teamPermissionsFromUserDetail={
                            teamPermissionsFromUserDetail?.[roleKey] ||
                            teamPermissionsFromUserDetail
                          }
                          allowedPermissionAsPerRole={rolePermissions}
                          roleKey={roleKey}
                        />
                      ))}
                    </div>
                  </AccordionCmp>
                )
              )
            )}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default AccessControlModal;
