import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import {
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

interface IProps {
  segmentListOption: any;
  teamListOptions: any;
  userList: any;
  handleClose: any;
  params: any;
  setParams: any;
  setFilterCount: any;
  setSelectedCustomersForSalesRepReassign: any;
}

const CustomerFilterModal = ({
  segmentListOption,
  teamListOptions,
  userList,
  handleClose,
  params,
  setParams,
  setFilterCount,
  setSelectedCustomersForSalesRepReassign,
}: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const uiState = useSelector((state: any) => state.MobileView);
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const [memberId, setMemberId] = useState(params?.member ?? 'all');
  const [segment, setSegment] = useState(params?.segment ?? '');
  const [isInitLoaded, setIsInitLoaded] = useState(true);
  const [segmentModifier, setSegmentModifier] = useState(
    params?.segmentModifier ?? ''
  );
  const [segmentModifierStartRange, setSegmentModifierStartRange] = useState(
    params?.segmentModifierStartRange ?? ''
  );
  const [segmentModifierEndRange, setSegmentModifierEndRange] = useState(
    params?.segmentModifierEndRange ?? ''
  );
  const [segmentModifiersListOption, setSegmentModifiersListOption] = useState<
    any[]
  >([]);
  const [inactiveRangeValidationError, setInactiveRangeValidationError] =
    useState<string | undefined>(undefined);

  const handleSave = () => {
    if (segment === CUSTOMER_SEGMENT_TYPES.INACTIVE) {
      if (segmentModifierStartRange !== '' && segmentModifierEndRange === '') {
        setInactiveRangeValidationError(
          'Please select inactive modifier end range'
        );

        return;
      } else if (
        segmentModifierStartRange === '' &&
        segmentModifierEndRange !== ''
      ) {
        setInactiveRangeValidationError(
          'Please select inactive modifier start range'
        );

        return;
      } else if (
        segmentModifierStartRange !== '' &&
        segmentModifierEndRange !== '' &&
        Number(segmentModifierStartRange) >= Number(segmentModifierEndRange)
      ) {
        setInactiveRangeValidationError(
          'End range should be bigger than Start range'
        );

        return;
      }
    }

    let fCount = 0;
    if (segmentModifier != '') fCount += 1;
    if (segmentModifierStartRange != '' && segmentModifierEndRange != '')
      fCount += 1;

    if (uiState.isMobile || uiState.currentBreakpoint === 'LG') {
      if (teamId != 'all') fCount += 1;
      if (memberId != 'all') fCount += 1;
    }
    if (uiState.isMobile && segment != '') fCount += 1;

    if (segment !== params.segment) {
      setSelectedCustomersForSalesRepReassign([]);
    }

    setFilterCount(fCount);
    setParams((old: any) => ({
      ...old,
      segment: segment,
      segmentModifier: segmentModifier,
      page: 1,
      limit: 50,
      team: teamId,
      member: memberId,
      segmentModifierStartRange: segmentModifierStartRange,
      segmentModifierEndRange: segmentModifierEndRange,
    }));
    handleClose();
  };

  useEffect(() => {
    const isFirstLoadAndHasModifierValue =
      isInitLoaded &&
      (params.segmentModifier ||
        (params?.segmentModifierStartRange && params?.segmentModifierEndRange));

    if (isFirstLoadAndHasModifierValue) {
      setSegmentModifier(params.segmentModifier);
      setSegmentModifierStartRange(params?.segmentModifierStartRange);
      setSegmentModifierEndRange(params?.segmentModifierEndRange);
      setIsInitLoaded(false);
    }

    setSegmentModifiersListOption([]);

    if (!isFirstLoadAndHasModifierValue) {
      setSegmentModifier('');
      setSegmentModifierStartRange('');
      setSegmentModifierEndRange('');
    }

    if ([CUSTOMER_SEGMENT_TYPES.TOP].includes(segment)) {
      const segmentDaysOptions = [
        { value: '5', label: '5 Customers' },
        { value: '10', label: '10 Customers' },
        { value: '25', label: '25 Customers' },
      ];
      setSegmentModifiersListOption(segmentDaysOptions);
      if (!isFirstLoadAndHasModifierValue) setSegmentModifier('25');
    } else if (
      [
        CUSTOMER_SEGMENT_TYPES.NEW,
        CUSTOMER_SEGMENT_TYPES.ACTIVE,
        CUSTOMER_SEGMENT_TYPES.DORMANT,
      ].includes(segment)
    ) {
      const segmentMonthOptions = [
        { value: '30_days', label: '30 Days' },
        { value: '60_days', label: '60 Days' },
        { value: '90_days', label: '90 Days' },
        { value: '180_days', label: '180 Days' },
        { value: '360_days', label: '360 Days' },
        { value: 'this_year', label: 'This Year' },
      ];
      setSegmentModifiersListOption(segmentMonthOptions);

      if (CUSTOMER_SEGMENT_TYPES.NEW) {
        if (!isFirstLoadAndHasModifierValue) setSegmentModifier('30_days');
      } else if (CUSTOMER_SEGMENT_TYPES.ACTIVE) {
        if (!isFirstLoadAndHasModifierValue) setSegmentModifier('30_days');
      } else if (CUSTOMER_SEGMENT_TYPES.DORMANT) {
        if (!isFirstLoadAndHasModifierValue) setSegmentModifier('360_days');
      }
    } else if ([CUSTOMER_SEGMENT_TYPES.AT_RISK].includes(segment)) {
      const segmentAtRiskMonthOptions = [
        { value: '30', label: '30 Days' },
        { value: '45', label: '45 Days' },
        { value: '60', label: '60 Days' },
        { value: '90', label: '90 Days' },
        { value: '180', label: '180 Days' },
      ];
      setSegmentModifiersListOption(segmentAtRiskMonthOptions);
      if (!isFirstLoadAndHasModifierValue) setSegmentModifier('45');
    } else if ([CUSTOMER_SEGMENT_TYPES.INACTIVE].includes(segment)) {
      const segmentAtRiskMonthOptions = [
        { value: '30', label: '30 Days' },
        { value: '60', label: '60 Days' },
        { value: '90', label: '90 Days' },
        { value: '180', label: '180 Days' },
        { value: '360', label: '360 Days' },
      ];
      setSegmentModifiersListOption(segmentAtRiskMonthOptions);

      if (!isFirstLoadAndHasModifierValue) {
        setSegmentModifierStartRange('90');
        setSegmentModifierEndRange('360');
      }
    }
  }, [segment]);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[535px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5">
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3">
          <div className="sm:flex flex-wrap items-center mdm:hidden">
            <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36 `}>
              Segment
            </label>
            <SelectBox
              name="segmentGroup"
              id="segmentGroup"
              className="form_control"
              size="sm"
              placeholder="All Tags"
              isClearable={true}
              parentClassName="smd:flex-1"
              options={segmentListOption}
              onChangeFunc={(event: any) => setSegment(event?.value ?? '')}
              value={segmentListOption.filter(
                (val: any) => segment === val.value
              )}
            />
          </div>
          {[
            CUSTOMER_SEGMENT_TYPES.TOP,
            CUSTOMER_SEGMENT_TYPES.NEW,
            CUSTOMER_SEGMENT_TYPES.ACTIVE,
            CUSTOMER_SEGMENT_TYPES.AT_RISK,
            CUSTOMER_SEGMENT_TYPES.DORMANT,
          ].includes(segment) &&
            segmentModifiersListOption?.length > 0 && (
              <div className="sm:flex flex-wrap items-center">
                <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36 `}>
                  Tag Modifiers
                </label>
                <SelectBox
                  name="segmentModifier"
                  id="segmentModifier"
                  className="form_control"
                  size="sm"
                  placeholder={`Tag Modifiers`}
                  parentClassName="smd:flex-1"
                  isClearable={true}
                  options={segmentModifiersListOption}
                  onChangeFunc={(event: any) =>
                    setSegmentModifier(event?.value ?? '')
                  }
                  value={segmentModifiersListOption.filter(
                    (val: any) => segmentModifier === val.value
                  )}
                />
              </div>
            )}
          {[CUSTOMER_SEGMENT_TYPES.INACTIVE].includes(segment) &&
            segmentModifiersListOption?.length > 0 && (
              <>
                <div className="sm:flex flex-wrap ">
                  <label
                    className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36 lg:h-9 sm:h-[34px] sm:flex items-center`}
                  >
                    Tag Modifier
                  </label>
                  <div className="flex-1">
                    <div className="flex flex-wrap -m-1">
                      <SelectBox
                        name="segmentModifierStartRange"
                        id="segmentModifierStartRange"
                        className="form_control"
                        size="sm"
                        placeholder={`Inactive Start Range`}
                        parentClassName="p-1 smd:w-1/2 w-full"
                        isClearable={true}
                        options={segmentModifiersListOption}
                        onChangeFunc={(event: any) =>
                          setSegmentModifierStartRange(event?.value ?? '')
                        }
                        value={segmentModifiersListOption.filter(
                          (val: any) => segmentModifierStartRange === val.value
                        )}
                      />

                      <SelectBox
                        name="segmentModifierEndRange"
                        id="segmentModifierEndRange"
                        className="form_control"
                        size="sm"
                        placeholder={`Inactive End Range`}
                        parentClassName="p-1 smd:w-1/2 w-full"
                        isClearable={true}
                        options={segmentModifiersListOption}
                        onChangeFunc={(event: any) =>
                          setSegmentModifierEndRange(event?.value ?? '')
                        }
                        value={segmentModifiersListOption.filter(
                          (val: any) => segmentModifierEndRange === val.value
                        )}
                      />
                    </div>
                    <ErrorMsg errorText={inactiveRangeValidationError} />
                  </div>
                </div>
              </>
            )}

          {!hasRoleV2('user') && (
            <>
              <div className="sm:flex flex-wrap items-center xl:hidden">
                <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36`}>
                  Teams
                </label>
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  parentClassName="smd:flex-1"
                  options={teamListOptions}
                  onChangeFunc={(event: any) => {
                    setTeamId(event?.value ?? 'all');
                    setMemberId('all');
                  }}
                  value={
                    teamId
                      ? teamListOptions.filter(
                          (val: any) => teamId === val.value
                        )
                      : null
                  }
                />
              </div>

              <div className="sm:flex flex-wrap items-center xl:hidden">
                <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36`}>
                  Users
                </label>
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  className="form_control"
                  isClearable={true}
                  isSearchable={true}
                  options={teamMember}
                  parentClassName="smd:flex-1"
                  noOptionMessage="No Users Found"
                  onChangeFunc={(event: any) =>
                    setMemberId(event?.value ?? 'all')
                  }
                  value={
                    memberId
                      ? teamMember.filter(
                          (user: any) => memberId === user.value
                        )
                      : null
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerFilterModal;
