import { isArray } from 'lodash';

import {
  getDateWithSuffixFormat,
  getFormattedNumber,
} from '../../../utils/CommonFunctions';

export const yearFilterArr = [
  '1980-1989',
  '1990-1999',
  '2000-2009',
  '2010-2019',
  '2020-2029',
];

export const GraphType = {
  TOTAL_MARGIN: {
    value: 'total_margin',
    label: 'total margin',
  },
  ORDER_COUNT: {
    value: 'order_count',
    label: 'orders',
  },
  MARGIN_PERCENTAGE: {
    value: 'margin_percentage',
    label: 'margin percentage',
  },
  DAILY_MARGIN: {
    value: 'daily_margin',
    label: 'average daily margin',
  },
  CUSTOMER: {
    value: 'customer',
    label: 'customers',
  },
  NEW_CUSTOMER: {
    value: 'new_customer',
    label: 'new customers',
  },
};
export const TimeRanges = [
  { label: 'Last 7 days', value: 'last_7_days', key: 'last_7_days' },
  { label: 'Last 30 days', value: 'last_30_days', key: 'last_30_days' },
  { label: 'Last 90 days', value: 'last_90_days', key: 'last_90_days' },
  { label: 'Last Year', value: 'last_year', key: 'last_year' },
  { label: 'Weekly', value: 'weekly', key: 'weekly' },
  { label: 'Monthly', value: 'monthly', key: 'monthly' },
  { label: 'Quarterly', value: 'quarterly', key: 'quarterly' },
  { label: 'Yearly', value: 'yearly', key: 'yearly' },
  { label: 'All Time', value: 'all_time', key: 'all_time' },
  // { label: 'Today', value: 'today', key: 'today' },
  { label: 'Custom', value: 'custom', key: 'custom' },
];
export interface CustomerData {
  name: string;
  orderCount: number;
  city: string;
  sales: number;
  profit: number;
  profitPercentage: number;
  change: number;
}

export type SortOrder = 'asc' | 'desc';

export const bookMarginArray: any = [
  {
    value: 'projected',
    name: 'Projected',
  },
  {
    value: 'booked',
    name: 'Booked',
  },
  {
    value: 'delivered',
    name: 'Delivered',
  },
];

export const FilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];

export const commonOptions = {
  chart: {
    animations: {
      enabled: false,
    },
    type: 'bar',
    height: 350,
    toolbar: {
      show: false, // This hides the download option
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadiusApplication: 'end',
      barHeight: '80%',
      borderRadius: 5,
    },
  },
  colors: ['#3538CD', '#A4BCFD', '#6172F3'], // Adjust the colors if needed to match your chart
  annotations: {
    points: [],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
  },
  states: {
    hover: {
      filter: {
        type: 'none', // Setting filter type to none will disable hover effects
      },
    },
  },
  stroke: {
    colors: ['transparent'],
    width: 5,
  },
};

export const salesOptions: any = {
  xaxis: {
    categories: [],
  },
  yaxis: {
    min: 0,
    max: 1000,
    tickAmount: 5,
  },
  tooltip: {
    theme: 'dark',
    position: 'top',
    y: {
      formatter: function (val: any) {
        return val ? getFormattedNumber(val.toFixed(2)) : 0;
      },
    },
    custom: function ({ series, dataPointIndex, w }: any) {
      let tooltipTitle = w.globals.labels[dataPointIndex];

      if (isArray(w.globals.labels[dataPointIndex])) {
        let wdt1 = getDateWithSuffixFormat(w.globals.labels[dataPointIndex][0]);
        let wdt2 = getDateWithSuffixFormat(w.globals.labels[dataPointIndex][1]);
        tooltipTitle = `${wdt1} ${wdt2}`;
      } else {
        tooltipTitle = getDateWithSuffixFormat(
          w.globals.labels[dataPointIndex]
        );
      }
      let tooltipContent = `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">   
				${tooltipTitle}
			</div>`;
      w.globals.seriesNames.forEach((seriesName: string, index: number) => {
        tooltipContent += `
				<div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
					<span class="apexcharts-tooltip-marker" style="height:10px; width:10px; background-color: ${
            w.globals.colors[index]
          }">
					</span>
					<div class="apexcharts-tooltip-y-group">
						<span class="apexcharts-tooltip-text-y-label">${seriesName}: </span>
						<span class="apexcharts-tooltip-text-y-value"> ${
              series[index]?.[dataPointIndex] > 0
                ? `$${getFormattedNumber(series[index][dataPointIndex])}`
                : 0
            }</span>
					</div>
				</div>`;
      });

      return tooltipContent;
    },
  },
  ...commonOptions,
};

export const avgMarginOption: any = {
  chart: {
    type: 'area',
    height: 350,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false, // Disable the toolbar
    },
    sparkline: {
      enabled: true, // Enable Sparkline for minimalistic chart
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2, // Decrease this value to reduce the line weight
  },
  yaxis: {
    labels: {
      show: false, // Hide y-axis labels
    },
  },
  xaxis: {
    // type: 'datetime',
    labels: {
      show: false, // Hide x-axis labels
    },
  },
  grid: {
    show: false, // Hide the grid lines
  },
  colors: ['#069454'],
  tooltip: {
    enabled: false,
    theme: 'dark',
  },
};

export const loadBooksOption: any = {
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false, // Disable the toolbar
    },
    sparkline: {
      enabled: true, // Enable Sparkline for minimalistic chart
    },
  },
  stroke: {
    width: 2, // Decrease this value to reduce the line weight
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {
      show: false, // Hide y-axis labels
    },
  },
  xaxis: {
    labels: {
      show: false, // Hide x-axis labels
    },
  },
  grid: {
    show: false, // Hide the grid lines
  },
  colors: ['#444CE7'],
  tooltip: {
    enabled: false,
    theme: 'dark',
  },
};
