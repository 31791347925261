import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';

const InternalChatsSkeletonLoader = () => (
  <>
    <li className="flex items-center my-4">
      <span className="h-[1px] bg-utilityGray200 w-full"></span>
      <p className="text-grayLight600 text-sm text-center px-2 custom-loading">
        Today
      </p>
      <span className="h-[1px] bg-utilityGray200 w-full"></span>
    </li>

    <li className="flex justify-end gap-3 ">
      <div className="xl:max-w-[305px] max-w-[230px]">
        <div className="flex justify-end gap-2 items-center w-full mb-1.5  mt-4">
          {/* <p className="text-textSecondary text-xs custom-loading">You</p> */}
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            10 mins ago
          </p>
        </div>

        <div className="rounded-lg rounded-tr-none px-3.5 py-2.5 font-normal text-xs bg-primary50 text-grayLight900 custom-loading">
          Hey Anita, I’ve had a read through and made some notes:
          https://docs.google.com/docu...
        </div>
      </div>
    </li>
    <li className="flex mt-4 gap-3 max-w-[305px]">
      <div className="w-7 h-7 flex-none relative custom-loading rounded-full ">
        <img
          src={avatar}
          className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary custom-loading "
        />
        <span className="border border-white rounded-full bg-successSecondary w-2 h-2 absolute bottom-0 right-0 custom-loading"></span>
      </div>
      <div>
        <div className="flex items-center w-full mb-1.5">
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Anita Cruz,&nbsp;
          </p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Just now
          </p>
        </div>

        <div className="rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 text-grayLight900 font-normal text-xs mb-2 bg-gray50 custom-loading">
          Thank you for the quick turnaround. Looking now.
        </div>
      </div>
    </li>
    <li className="flex justify-end  gap-3 ">
      <div className="xl:max-w-[305px] max-w-[230px]">
        <div className="flex justify-end gap-2 items-center w-full mb-1.5 mt-4">
          {/* <p className="text-textSecondary text-xs custom-loading">You</p> */}
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            10 mins ago
          </p>
        </div>

        <div className="rounded-lg rounded-tr-none px-3.5 py-2.5 font-normal text-xs bg-primary50 text-grayLight900 custom-loading">
          Hey Anita, I’ve had a read through and made some notes:
          https://docs.google.com/docu...
        </div>
      </div>
    </li>
    <li className="flex mt-4 gap-3 max-w-[305px]">
      <div className="w-7 h-7 flex-none relative custom-loading rounded-full ">
        <img
          src={avatar}
          className="w-7 h-7 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary "
        />
        <span className="border border-white rounded-full bg-successSecondary w-2 h-2 absolute bottom-0 right-0 custom-loading"></span>
      </div>
      <div>
        <div className="flex items-center w-full mb-1.5">
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Anita Cruz,&nbsp;
          </p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Just now
          </p>
        </div>

        <div className="rounded-lg rounded-tl-none border border-utilityGray200 px-2.5 py-1.5 text-grayLight900 font-normal text-xs mb-2 bg-gray50 w-fit custom-loading">
          <div className="flex gap-1.5 typing-animation min-h-[14px] items-center">
            <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
            <div className="w-1 h-1 rounded-full  bg-gray400"></div>
            <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
          </div>
        </div>
      </div>
    </li>
  </>
);

export default InternalChatsSkeletonLoader;
