import * as Yup from 'yup';

export interface IMapAddress {
  fullAddress: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  latitude: string;
  longitude: string;
  stateCode: string;
  countryCode: string;
}

export interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

export const initShipperAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

export interface IDimensionProps {
  handlingUnit: number | null;
  handlingUnitNo: number | null;
  weight: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  totalWeight: number | null;
  freightClass: number | null;
  refNumber: number | null;
  isStack: boolean | null;
}

export const classOptions = [
  {
    label: 'LTL',
    value: 'ltl',
  },
  {
    label: 'FTL',
    value: 'ftl',
  },
];

export const equipmentTypeOptions = [
  { label: 'Dryvan', value: 'dryvan' },
  { label: 'Flatbed', value: 'flatbed' },
  { label: 'Step-Deck', value: 'step-deck' },
  { label: 'Reefer', value: 'reefer' },
  { label: 'Straight Truck', value: 'straight-truck' },
  { label: 'Roll-tite', value: 'roll-tite' },
  { label: 'RGN/Lowboy', value: 'rgn-lowboy' },
];

export const initAction = {
  mode: null,
  shipper: false,
  consignee: false,
};

export const scriptId = 'google-maps-api';

// export const orderSchema = Yup.object({
//   shipper: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   consignee: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   orderType: Yup.string()
//     .required('Order type is required')
//     .oneOf(['ltl', 'ftl'], 'Invalid order type'),
//   equipmentType: Yup.string()
//     .required('Equipment type is required')
//     .oneOf(['dryvan', 'flatbed', 'reefer'], 'Invalid equipment type'),
//   poNumber: Yup.string().required('PO number is required'),
//   // .matches(
//   //   /^[a-zA-Z0-9]+$/,
//   //   'PO number should contain only alphanumeric characters'
//   // ),
//   refNumber: Yup.string().required('Reference number is required'),
//   linearFootage: Yup.number()
//     .required('Linear footage is required')
//     .min(1, 'Linear footage must be at least 1')
//     .max(100, 'Linear footage cannot exceed 100'),
//   declaredValue: Yup.number()
//     .required('Declared value is required')
//     .min(0, 'Declared value cannot be negative'),
//   specialNotes: Yup.string()
//     .max(500, 'Special notes cannot exceed 500 characters')
//     .nullable(),

//   order_dimensions: Yup.array().of(
//     Yup.object().shape({
//       handlingUnit: Yup.number()
//         .nullable()
//         .required('Handling unit is required'),
//       handlingUnitNo: Yup.number()
//         .nullable()
//         .required('Handling unit number is required'),
//       weight: Yup.number().nullable().required('Weight is required'),
//       length: Yup.number().nullable().required('Length is required'),
//       width: Yup.number().nullable().required('Width is required'),
//       height: Yup.number().nullable().required('Height is required'),
//       totalWeight: Yup.number().nullable().required('Total weight is required'),
//       freightClass: Yup.number()
//         .nullable()
//         .required('Freight class is required'),
//       refNumber: Yup.number()
//         .nullable()
//         .required('Reference number is required'),
//       isStack: Yup.boolean().nullable().required('IsStack is required'),
//     })
//   ),
// });

export const orderSchema = Yup.object({
  shipper: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
  }),
  consignee: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
  }),
  orderType: Yup.string()
    .required('Order type is required')
    .oneOf(['ltl', 'ftl'], 'Invalid order type'),
  equipmentType: Yup.string()
    .required('Equipment type is required')
    .oneOf(['dryvan', 'flatbed', 'reefer'], 'Invalid equipment type'),
  poNumber: Yup.string().required('PO number is required'),
  refNumber: Yup.string().required('Reference number is required'),
  linearFootage: Yup.number()
    .required('Linear footage is required')
    .min(1, 'Linear footage must be at least 1')
    .max(100, 'Linear footage cannot exceed 100'),
  declaredValue: Yup.number()
    .required('Declared value is required')
    .min(0, 'Declared value cannot be negative'),
  specialNotes: Yup.string()
    .max(500, 'Special notes cannot exceed 500 characters')
    .nullable(),
  order_dimensions: Yup.array().of(
    Yup.object().shape({
      handlingUnit: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Handling unit is required'),
      handlingUnitNo: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Handling unit number is required'),
      weight: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Weight is required'),
      length: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Length is required'),
      width: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Width is required'),
      height: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Height is required'),
      totalWeight: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Total weight is required'),
      freightClass: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Freight class is required'),
      refNumber: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Reference number is required'),
      isStack: Yup.boolean()
        .nullable()
        .required('Stacking information is required'),
    })
  ),
});

export const dimensionObj: IDimensionProps = {
  handlingUnit: null,
  handlingUnitNo: null,
  weight: null,
  length: null,
  width: null,
  height: null,
  totalWeight: null,
  freightClass: null,
  refNumber: null,
  isStack: null,
};

export const defualtOrderValue = {
  shipper: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
  },
  consignee: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
  },
  orderType: 'ltl',
  equipmentType: 'dryvan',
  poNumber: '',
  refNumber: '',
  linearFootage: 0,
  declaredValue: 0,
  specialNotes: '',
  order_dimensions: [dimensionObj],
};
