import React from 'react';
import CheckBox from 'src/components/CheckBox';

const CreditSafeCompanyLoadingRow = () => (
  <>
    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px] flex gap-2">
      <CheckBox classes="custom-loading" onChangeFunc={() => {}} />
      <span className="custom-loading">456</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">456fg</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">country</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Test Company Organisation</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Test Company Organisation</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Test Company Organisation</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Status</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Status</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Status</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Status</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">dsfdsfs</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Headers Quarter</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">+91 79878979877</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">#21414</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Significant Items</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">Significant Items</span>
    </td>

    <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
      <span className="custom-loading">#ghfhfhg</span>
    </td>
  </>
);

export default CreditSafeCompanyLoadingRow;
