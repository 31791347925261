import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import TableCmp from 'src/components/TableCmp';

import FormModal from './form';
import salesPriceRow from './salesPriceRow';

const SalePrice = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const salesHeadCells = useMemo(
    () => [
      {
        id: 'customer',
        name: 'Customer',
      },
      {
        id: 'baseSalePrice',
        name: 'Base Sale Price',
      },
      {
        id: 'Additionals',
        name: 'Additionals',
      },
      {
        id: 'totalSalePrice',
        name: 'Total Sale Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  return (
    <>
      <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
        <h6 className="text-textSecondary text-sm font-medium mb-1.5">
          Add Sale Price
        </h6>
        <div className="rounded-xl border border-borderSecondary bg-white shadow-xs p-4 text-center">
          <p className="text-grayLight600 text-xs font-normal mb-2">
            In order to continue, please assign a carrier to facilitate the next
            actions
          </p>
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            onClick={() => setIsFormOpen(true)}
            icon={<Plus className="w-4 h-4" />}
          >
            Add Sale Price
          </ButtonCmp>
        </div>
        {isFormOpen && <FormModal closeForm={closeForm} />}
      </li>
      <li>
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
            <TableCmp
              headCells={salesHeadCells}
              tableDataArr={[{}]}
              // TableLoaderRowCmp={CarriersListLoading}
              TableRowCmp={salesPriceRow}
              numberOfSkeletonRows={1}
            />
          </div>
        </div>
      </li>
    </>
  );
};

export default SalePrice;
