import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { orderList } from 'src/services/OrderService';

import CustomerGroupOrderLoadingRaw from './CustomerGroupOrderLoadingRaw';
import CustomerGroupOrderRow from './CustomerGroupOrderRow';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const initParams: any = {
  sortType: 'desc',
  sortField: 'orderCreatedAt',
  page: 1,
  search: '',
  limit: 25,
};

interface IProps {
  groupCustomersIds: any;
}

const CustomerGroupOrdersTable = ({ groupCustomersIds }: IProps) => {
  const [params, setParams] = useState(initParams);
  const [orders, setOrders] = useState<any[]>([]);
  const [isLatestOrdersLoading, setIsLatestOrdersLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const ordersApiController = new AbortController();
    const ordersApiSignal = ordersApiController.signal;

    if (groupCustomersIds?.length > 0) {
      setOrders([]);
      setIsLatestOrdersLoading(true);
      orderList({ ...params, customerId: groupCustomersIds }, ordersApiSignal)
        .then((response: any) => {
          setOrders(response.data ?? []);
          setTotal(response.total ?? 0);
          setIsLatestOrdersLoading(false);
        })
        .catch((e: any) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLatestOrdersLoading(false);
        });
    }

    return () => {
      ordersApiController.abort();
    };
  }, [groupCustomersIds, params]);

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setParams((old: any) => ({ ...old, ...{ search: value, page: 1 } }));
  };

  const headCells = useMemo(
    () => [
      {
        id: 'fullId',
        name: 'Order ID',
        width: 'w-[30%]',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Order Status',
        width: 'w-[30%]',
        sortable: true,
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        width: 'w-[20%]',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Price',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        width: 'w-[10%]',
        sortable: true,
      },
    ],
    []
  );

  return (
    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
      <div className="w-full flex-col flex">
        <div className="table-top-header flex-wrap ">
          <div className="table-left-wrap ">
            <div className="table-title-wrap">
              <h5 className="table-title">All Orders</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${
                  isLatestOrdersLoading ? 'custom-loading' : ''
                }`}
              >
                {total} {total === 1 || total === 0 ? 'order' : 'orders'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              View complete order history for this customer group.
            </p>
          </div>
          <div className="table-right-wrap max-mdm:w-full">
            <InputText
              inputName="searchCustomer"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              isClearable={true}
              parentClassName="table-searchInput max-mdm:flex-1"
              inputType="text"
              onChangeFunc={handleSearch}
            />
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
          <TableCmp
            headCells={headCells}
            params={params}
            setParams={setParams}
            tableDataArr={orders}
            TableRowCmp={CustomerGroupOrderRow}
            TableLoaderRowCmp={CustomerGroupOrderLoadingRaw}
            isTableDataLoading={isLatestOrdersLoading}
            numberOfSkeletonRows={15}
          />
        </div>

        {!isLatestOrdersLoading && !orders?.length && (
          <NotFoundUI
            title="No latest orders found"
            desc="There are no Latest orders found."
            containerClassName="min-h-[160px] !h-auto"
          />
        )}

        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={total}
          currentPage={params.page}
          handlePagination={(page: number) => {
            setParams((old: any) => ({ ...old, page }));
          }}
        />
      </div>
    </div>
  );
};

export default CustomerGroupOrdersTable;
