import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  formatAddress,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const OrderRow = ({ data }: any) => {
  const { currency } = useContext(BasicContext);

  const getAddressWholeString = (
    first: string,
    second: string,
    third: string
  ) =>
    [first, second, third].filter((e) => e).length
      ? [first, second, third].filter((e) => e).toString()
      : false;

  return (
    <>
      <td className={`px-5 py-4 min-w-48 w-48  `}>
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center">
            {data.orderCommissioneeImage ? (
              <div className="table-profile-wrap notification-mark-wrap">
                <img
                  className="table-profile-icn-circle-xl"
                  src={
                    data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                  }
                  alt={data.orderCommissioneeFirstName}
                  title={data.orderCommissioneeFirstName}
                  onError={onError}
                />
                {data?.hasUnreadNotification ? (
                  <span className={`notification-dot`}></span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="table-profile-wrap notification-mark-wrap">
                <div className="table-profile-user-circle-xl">
                  {getShortName(
                    `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                  )}
                </div>
                {data?.hasUnreadNotification ? (
                  <span className={`notification-dot`}></span>
                ) : (
                  ''
                )}
              </div>
            )}
            <div className="pl-3 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.fullId}
              </p>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-xs font-medium max-w-32">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.orderCommissionees?.split(',').length} Sales Reps.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.fullId}
            </p>
          </div>
        ) : data.orderCommissionees ? (
          <div className="flex items-center">
            <div className="table-profile-user-circle-xl">
              {getShortName(data.orderCommissionees)}
            </div>
            <div className="pl-2 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1">
                <span className="inline-block max-w-32 truncate">
                  {data.orderCommissionees}
                </span>
                <TooltipCmp
                  message={`${data.orderCommissionees} is not an active user on WALHQ`}
                >
                  <InfoCircle className="h-3.5 w-3.5" />
                </TooltipCmp>
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.fullId}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="table-profile-wrap notification-mark-wrap">
              <div className="table-profile-empty-circle-xl">&nbsp;</div>
              {data?.hasUnreadNotification ? (
                <span className={`notification-dot`}></span>
              ) : (
                ''
              )}
            </div>
            <div className="pl-2 text-xs font-medium max-w-32">
              <p>Unassigned</p>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.fullId}
              </p>
            </div>
          </div>
        )}
      </td>
      <td className={`px-5 py-4 min-w-[125px] max-w-[125px] w-[125px]  `}>
        {(data.orderStatus === 'new' ||
          data.orderStatus === 'saved' ||
          data.orderStatus === 'quoted' ||
          data.orderStatus === 'spot-qq-requested' ||
          data.orderStatus === 'spot-quote-requested' ||
          data.orderStatus === 'pending' ||
          data.orderStatus === 'draft-quick-quote' ||
          data.orderStatus === 'quick-quoted' ||
          data.orderStatus === 'reject' ||
          data.orderStatus === 'archived' ||
          data.orderStatus === 'expired') && (
          <BadgeCmp style="modern" type="gray">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'no-quote' ||
          data.orderStatus === 'no-quick-quote' ||
          data.orderStatus === 'cancelled') && (
          <BadgeCmp style="modern" type="pink">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'tender' ||
          data.orderStatus === 'booked' ||
          data.orderStatus === 'invoice-paid' ||
          data.orderStatus === 'delivered') && (
          <BadgeCmp style="modern" type="lightblue">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'invoice-created' ||
          data.orderStatus === 'invoice-sent') && (
          <BadgeCmp style="modern" type="error">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {/* Tags */}
        {data.orderStatus === 'dispatched' && (
          <BadgeCmp style="modern" type="pink">
            {data.orderStatus}
          </BadgeCmp>
        )}
      </td>
      <td className={`px-5 py-4 max-w-40 min-w-40 w-40  `}>
        {data.customerName?.length > 22 ? (
          <TooltipCmp
            childrenClassName="!block truncate max-w-32"
            message={data.customerName}
          >
            {data.customerName}
          </TooltipCmp>
        ) : (
          <div>{data.customerName}</div>
        )}
      </td>
      <td className={`px-5 py-4 max-w-40 min-w-40 w-40  `}>
        <TooltipCmp
          message={formatAddress(
            getAddressWholeString(
              data.orderFromCity,
              data.orderFromState,
              data.orderFromPostal
            )
          )}
          type="dark"
          parentClassName=""
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[130px]">
              {`${
                getAddressWholeString(
                  data.orderFromCity,
                  data.orderFromState,
                  data.orderFromPostal
                )
                  ? formatAddress(
                      getAddressWholeString(
                        data.orderFromCity,
                        data.orderFromState,
                        data.orderFromPostal
                      )
                    )
                  : '-'
              }`}
            </span>
          </p>
        </TooltipCmp>
        <TooltipCmp
          message={formatAddress(
            getAddressWholeString(
              data.orderToCity,
              data.orderToState,
              data.orderToPostal
            )
          )}
          type="dark"
          parentClassName="max-w-90"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[130px]">{`${
              getAddressWholeString(
                data.orderToCity,
                data.orderToState,
                data.orderToPostal
              )
                ? formatAddress(
                    getAddressWholeString(
                      data.orderToCity,
                      data.orderToState,
                      data.orderToPostal
                    )
                  )
                : '-'
            }`}</span>
          </p>
        </TooltipCmp>
      </td>
      <td className={`px-5 py-4 min-w-52 w-52 max-w-52  `}>
        <span
          dangerouslySetInnerHTML={{
            __html: data.orderCreateTime
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.orderCreateTime,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className={`px-5 py-4 font-normal min-w-36 w-36 max-w-36 `}>
        <span>
          $
          {data.combinedRevenue
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedRevenue
                  : data.combinedRevenueUSD,
                true,
                true
              )
            : '0.00'}
          {/* {currency} */}
        </span>
      </td>
      <td className={`px-5 py-4 font-normal min-w-36 w-36 max-w-36  `}>
        <span>
          $
          {data.combinedCost
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedCost
                  : data.combinedCostUSD,
                true,
                true
              )
            : '0.00'}{' '}
          {/* {currency} */}
        </span>
      </td>
      <td className={`px-5 py-4 font-normal min-w-32 max-w-32 w-32  `}>
        <div className="flex gap-1">
          {data.combinedMarginPercentage
            ? getFormattedNumber(data.combinedMarginPercentage)
            : 0}
          %
          {!!data.isCostCalculatedFromRevenue && (
            <TooltipCmp message={<div>{`Margin estimated`}</div>}>
              <InfoCircle className="h-3.5 w-3.5 text-primary" />
            </TooltipCmp>
          )}
        </div>
      </td>
      <td className={`px-5 py-4 font-semibold min-w-36 max-w-36 w-36  `}>
        <span>
          $
          {data.combinedMargin
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedMargin
                  : data.combinedMarginUSD,
                true,
                true
              )
            : '0.00'}{' '}
          {/* {currency} */}
        </span>
      </td>
    </>
  );
};

export default OrderRow;
