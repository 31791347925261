'use client';

import { DialogProps } from '@radix-ui/react-dialog';
import React, { forwardRef } from 'react';

import { cn } from '../lib/utils';

import { Dialog, DialogContent } from './dialog';
import Magic from './icons/magic';

// Replacing Command component
const Command = forwardRef(({ className, children, ...props }: any, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
      className
    )}
    {...props}
  >
    {children}
  </div>
));

// CommandDialog to show modal
const CommandDialog = ({ children, ...props }: DialogProps) => (
  <Dialog {...props}>
    <DialogContent className="overflow-hidden p-0 shadow-lg">
      <Command className="p-2">{children}</Command>
    </DialogContent>
  </Dialog>
);

// CommandInput to handle input with optional Magic icon
const CommandInput = forwardRef(
  ({ className, onInput, ...props }: any, ref) => (
    <div className="flex items-center border-b px-4">
      <Magic className="mr-2 h-4 w-4 shrink-0 text-purple-500" />
      <input
        ref={ref}
        type="text"
        className={cn(
          'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        onInput={onInput}
        {...props}
      />
    </div>
  )
);

// CommandList to render list of items
const CommandList = forwardRef(
  ({ className, children, ...props }: any, ref) => (
    <ul
      ref={ref}
      className={cn(
        'max-h-[300px] overflow-y-auto overflow-x-hidden',
        className
      )}
      {...props}
    >
      {children}
    </ul>
  )
);

// CommandItem for each selectable item in the list
const CommandItem = forwardRef(
  ({ className, onClick, children, ...props }: any, ref) => (
    <li
      ref={ref}
      className={cn(
        'relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-accent-foreground',
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </li>
  )
);

// CommandEmpty for empty state
const CommandEmpty = forwardRef(
  ({ className, children, ...props }: any, ref) => (
    <div
      ref={ref}
      className={cn('py-6 text-center text-sm', className)}
      {...props}
    >
      {children || 'No results found'}
    </div>
  )
);

// CommandGroup to group items (optional)
const CommandGroup = forwardRef(
  ({ className, children, ...props }: any, ref) => (
    <div
      ref={ref}
      className={cn(
        'overflow-hidden p-1 text-foreground [&_heading]]:px-2 [&_heading]]:py-1.5 [&_heading]]:text-xs [&_heading]]:font-medium [&_heading]]:text-muted-foreground',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

// CommandSeparator for separating groups
const CommandSeparator = forwardRef(({ className, ...props }: any, ref) => (
  <div ref={ref} className={cn('-mx-1 h-px bg-white', className)} {...props} />
));

// CommandShortcut for keyboard shortcuts (optional)
const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      'ml-auto text-xs tracking-widest text-muted-foreground',
      className
    )}
    {...props}
  />
);

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandItem,
  CommandEmpty,
  CommandGroup,
  CommandSeparator,
  CommandShortcut,
};
