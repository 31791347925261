import React, { useEffect, useState } from 'react';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';

import CustomerOrdersTableRow from './CustomerOrdersTableRow';

interface IProps {
  isLoading?: boolean;
  handleClose?: () => void;
  selectedCustomer: any;
}

const CustomerOrdersDetail = ({ isLoading, selectedCustomer }: IProps) => {
  const [filteredOrderList] = useState<any[]>([
    ...selectedCustomer.orderDetails,
  ]);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageItem, setPageItem] = useState<any[]>([
    ...selectedCustomer.orderDetails,
  ]);
  let recordsPerPage = 5;

  useEffect(() => {
    if (filteredOrderList?.length > recordsPerPage) {
      const pageItems = filteredOrderList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredOrderList]);
    }
  }, [filteredOrderList, currentPage, recordsPerPage]);

  return (
    <>
      {pageItem.map((val: any) => (
        <tr
          key={val?.id}
          className="text-grayLight600 border-b border-utilityGray200 font-normal text-xs bg-gray25 transition-all duration-[0.5s]"
        >
          <CustomerOrdersTableRow data={val} />
        </tr>
      ))}

      {(!isLoading && !filteredOrderList?.length) ||
      (!isLoading && filteredOrderList?.length > recordsPerPage) ? (
        <tr className="text-grayLight600 border-b border-utilityGray200 font-normal text-xs bg-gray25 transition-all duration-[0.5s] ">
          <td colSpan={10} className="px-[63px] py-4">
            {!isLoading && !filteredOrderList?.length && (
              <NotFoundUI
                title="No Customer Data Found"
                desc="There are no data for selectedCustomer split related data."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
            <div className="w-full flex justify-between">
              {!isLoading && filteredOrderList?.length > recordsPerPage && (
                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={filteredOrderList?.length}
                  currentPage={currentPage}
                  handlePagination={setCurrentPage}
                  parentClassName="!p-0 !border-0"
                />
              )}
            </div>
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerOrdersDetail;
