import { useState, useEffect, useRef, useContext } from 'react';
import { BasicContext } from 'src/context/BasicContext';

const useCountUp = (end: number, duration = 1000) => {
  const { ordersUpdateSocketTriggeredCount } = useContext(BasicContext);
  const [count, setCount] = useState(0);
  const previousEnd = useRef(0);

  useEffect(() => {
    let handle: any = undefined;

    if (!ordersUpdateSocketTriggeredCount) {
      previousEnd.current = end;
      setCount(end);
    } else {
      let startValue = Number(previousEnd.current);
      const totalFrames = duration / 10;
      const increment = end / totalFrames;

      handle = setInterval(() => {
        startValue += increment;

        if (startValue >= end) {
          setCount(end);
          previousEnd.current = end;
          clearInterval(handle);
        } else {
          setCount(Math.ceil(startValue));
        }
      }, 10);
    }

    return () => {
      if (handle) {
        clearInterval(handle);
      }
    };
  }, [end, duration, ordersUpdateSocketTriggeredCount]);

  return count;
};

export default useCountUp;
