import { yupResolver } from '@hookform/resolvers/yup';
import {
  CoinsStacked02,
  CurrencyDollar,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { CustomersAllList } from 'src/services/CustomerService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import AdditionalCharge from '../AdditionalCharge';

const schema = yup.object().shape({
  customer_id: yup.number().required('This field is required'),
  base_sale_price: yup.number().required('This field is required'),
  fuel: yup.number().required('This field is required'),
  total_sale_price: yup.number().required('This field is required'),
  price: yup.number().required('This field is required'),
  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup.number().required('charge is required'),
      price: yup.number().required('price is required'),
    })
  ),
});

interface IProps {
  closeForm: () => void;
}

interface IFormData {
  customer_id: number;
  base_sale_price: number;
  fuel: number;
  total_sale_price: number;
  price: number;
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    price: null | number;
  }>;
}

const FormModal = ({ closeForm }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const headerIcon = <CoinsStacked02 className="w-7 h-7" />;
  // const { handleSubmit, control } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     customer_id: undefined,
  //     base_sale_price: undefined,
  //     fuel: undefined,
  //     total_sale_price: undefined,
  //     price: undefined,
  //     additional_charge: [
  //       {
  //         id: 0,
  //         charge: null,
  //         price: null,
  //       },
  //     ],
  //   },
  // });

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      customer_id: undefined,
      base_sale_price: undefined,
      fuel: undefined,
      total_sale_price: undefined,
      price: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          price: null,
        },
      ],
    },
  });
  const { handleSubmit, control } = methods;

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };
  useEffect(() => {
    getCustomerList();
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    // const formattedData = {
    //   ...formData,
    //   appointmentDate: moment(formData.appointmentDate).format('YYYY-MM-DD'),
    //   type,
    //   orderId,
    // };

    // createOrUpdateAppointment(formattedData)
    //   .then((response) => {
    //     if (response.data) {
    //       console.log('appointment set properly', response.data);
    //       handleClose();
    //     }
    //   })
    //   .catch(console.error)
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  return (
    <CommonModal
      title="Add Sale Price"
      titleDesc="Attach a carrier to this order, if applicable at this time."
      handleClose={closeForm}
      headerIcon={headerIcon}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText="Assign"
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      isOverflow={false}
      secondaryBtnOnClick={closeForm}
    >
      <FormProvider {...methods}>
        <div className="p-5 flex flex-col gap-y-4">
          <Controller
            name="customer_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                label="Customer"
                labelClassName="form_label block mb-1.5"
                name="customerId"
                id="customerId"
                size="sm"
                placeholder="All Customers"
                noOptionMessage="No Customers Found"
                isSearchable={true}
                className="form_control"
                isClearable={true}
                options={customerList}
                onChangeFunc={(e: any) => onChange(e?.value)}
                value={customerList?.find((e) => e?.value == value)}
                isLoading={isCustomerLoading}
                errorText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="base_sale_price"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Base Sale Price "
                inputName="base_sale_price"
                placeholder="Enter Base Sale Price"
                parentClassName=""
                className="pl-8"
                value={value}
                onChangeFunc={onChange}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />
          <AdditionalCharge />

          <Controller
            name="fuel"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Fuel"
                inputName="fuel"
                placeholder="Enter Fuel Value"
                parentClassName=""
                className="pl-8 w-full"
                value={value}
                onChangeFunc={onChange}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />

          <Controller
            name="total_sale_price"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Total Sale Price"
                inputName="total_sale_price"
                placeholder="Enter Total Sale Price"
                parentClassName=""
                className="pl-8 w-full"
                value={value}
                onChangeFunc={onChange}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />
        </div>
      </FormProvider>
    </CommonModal>
  );
};

export default FormModal;
