import { InfoCircle, User01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const updateOrAddEntry = (array: any, entry: any) => {
  // Find the index of the entry with the same cId
  const index = array.findIndex(
    (el: any) => el.customerId === entry.customerId
  );

  if (index > -1) {
    // If found, update the existing entry
    array[index] = entry;
  } else {
    // If not found, add the new entry to the array
    array.push(entry);
  }
};

const CustomerReassignRow = ({
  data,
  currentIndex,
  salesUserList,
  isSalesUsersListLoading,
  customerAssignedSalesReps,
  setCustomerAssignedSalesReps,
}: any) => {
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const customerNameMapping = customerAssignedSalesReps.find(
      (cusSaleRep: any) => cusSaleRep.customerId === data.id
    );
    setSelectedUser(customerNameMapping?.salesRep);
  }, [customerAssignedSalesReps, data]);

  const handleUserChange = (event: any, orderRecObj: any) => {
    setSelectedUser(event?.value);
    let updatedCustomerAssignedSalesReps = [...customerAssignedSalesReps];
    updateOrAddEntry(updatedCustomerAssignedSalesReps, {
      customerId: orderRecObj.id,
      salesRep: event?.value,
    });
    setCustomerAssignedSalesReps(
      updatedCustomerAssignedSalesReps.filter(
        (cusSaleRep) => cusSaleRep.salesRep
      )
    );
  };

  return (
    <>
      <td className="px-5 py-4 max-w-96 w-96 min-w-96">
        <div className="flex items-center gap-3">
          {data.image ? (
            <img
              className="table-profile-icn-circle-xl"
              src={data.imageUrl + data.image}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl">
              {getShortName(`${data.name}`)}
            </div>
          )}
          {data.name.length > 25 ? (
            <TooltipCmp message={data.name} parentClassName="xs:w-80 w-64">
              <span className="truncate block max-w-64 text-sm whitespace-nowrap font-semibold ">
                {data.name}
              </span>
            </TooltipCmp>
          ) : (
            <span className="truncate block max-w-64  text-sm whitespace-nowrap font-semibold ">
              {data.name}
            </span>
          )}
        </div>
      </td>

      <td className="px-5 py-3 ">
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center min-w-92">
            {data.orderCommissioneeImage ? (
              <img
                className="table-profile-icn-circle-sm"
                src={
                  data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                }
                alt={data.orderCommissioneeFirstName}
                title={data.orderCommissioneeFirstName}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-sm">
                <span>
                  {getShortName(
                    `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                  )}
                </span>
              </div>
            )}
            <div className="pl-2">
              <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-primary font-semibold leading-tight cursor-pointer">
            {data.orderCommissionees?.split(',').length} Sales Reps.
          </div>
        ) : data.orderCommissionees ? (
          <div className="font-semibold leading-tight cursor-pointer flex gap-1">
            <p className="max-w-52 truncate">{data.orderCommissionees}</p>
            <TooltipCmp
              message={`${data.orderCommissionees} is not an active user on WALHQ`}
            >
              <InfoCircle className="h-4 w-4" />
            </TooltipCmp>
          </div>
        ) : (
          <></>
        )}
      </td>
      <td className="px-5 py-4 w-[250px] min-w-[250px] max-w-[250px]">
        <div className="w-[203px]">
          <SelectBox
            name="userListGroup"
            id="userListGroup"
            size="sm"
            placeholder="Select team member"
            placeholderIcon={<User01 className="h-4 w-4" />}
            isSearchable={true}
            className="form_control"
            isClearable={true}
            options={salesUserList}
            onChangeFunc={(e: any) => handleUserChange(e, data)}
            value={salesUserList.filter(
              (user: any) => selectedUser === user.value
            )}
            isLoading={isSalesUsersListLoading}
            menuPlaceOption={currentIndex < 2 ? 'bottom' : 'top'}
          />
        </div>
      </td>
    </>
  );
};

export default CustomerReassignRow;
